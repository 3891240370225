import get from 'lodash/get';
import RoboadvisorService from './roboadvisor.service';

export default class ConfigRespService {
    formatSubmitIntentData = (data, ratingMessagesShown, liveChatMessagesShown) => {
        const answers = get(data, 'type', []).map(answer => {
            const obj = {};

            if (answer.hasOwnProperty('content')) {
                obj['content'] = answer.content;
                obj['type'] = RoboadvisorService.responseTypes.text;
            }
            if (answer.hasOwnProperty('buttons')) {
                obj['buttons'] = answer.buttons;
                obj['type'] = RoboadvisorService.responseTypes.buttons;
            }
            if (answer.hasOwnProperty('carousel')) {
                obj['carousel'] = answer.carousel;
                obj['type'] = RoboadvisorService.responseTypes.carousel;
            }
            obj['language'] = get(data, 'language', 'EN');

            // adds last answer to all messages
            obj['lastAnswer'] = ratingMessagesShown;
            obj['requestLiveChat'] = liveChatMessagesShown;

            return obj;
        });

        return {
            answers: answers,
            channel: get(data, 'channel', ''),
            intentId: get(data, 'intents', '0'),
            language: get(data, 'language', ''),
        };
    };

    checkIfIntentIsLast = data => {
        return data.reduce((isLast = false, item) => {
            if (get(item, 'lastAnswer', false)) return true;
            return isLast;
        }, false);
    };

    checkIfLiveChatMessagesShown = data => {
        return data.reduce((isLiveChat = false, item) => {
            if (get(item, 'requestLiveChat', false)) return true;
            return isLiveChat;
        }, false);
    };

    groupByMultipleItems = (array, f) => {
        const groups = {};
        array.forEach(o => {
            var group = JSON.stringify(f(o));
            groups[group] = groups[group] || [];
            groups[group].push(o);
        });

        return Object.keys(groups).map(group => {
            return {
                id: group,
                responses: groups[group],
            };
        });
    };

    processResponsesForReview = data => {
        return this.groupByMultipleItems(data, item => {
            return [item.channel, item.language, item.intent.id];
        });
    };
}
