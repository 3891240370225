import get from 'lodash/get';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Sidebar } from 'primereact/sidebar';
import { useCallback } from 'react';
import { connect } from 'react-redux';
import AddUserGroupForm from '../../modules/userGroups/AddUserGroupForm';
import {
    deleteUserGroup,
    toggleAddNewUserGroupSidebar,
} from '../../store/actions/userGroupsActions';

const AddNewGroup = ({ userGroup, deleteUserGroup, toggleSidebar, sidebarOpened, hook }) => {
    const confirmDeleteGroup = useCallback(() => {
        confirmDialog({
            message: `Are you sure you want to delete this group?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteUserGroup(get(userGroup, 'id', -1)),
        });
    }, [deleteUserGroup, userGroup]);

    return (
        <div>
            <div className="addNewUserGroupWrap">
                {userGroup ? (
                    <Button
                        type="button"
                        className="delete-btn mr-2"
                        onClick={confirmDeleteGroup}
                        icon="fa fa-trash-o"
                        label="Remove group"
                        tooltip={'Remove the selected group'}
                        tooltipOptions={{ position: 'top' }}
                    />
                ) : null}
                <Button
                    type="button"
                    className="add-btn"
                    onClick={() => toggleSidebar(true)}
                    icon="fa fa-plus"
                    label="Add group"
                    tooltip={'Add group'}
                    tooltipOptions={{ position: 'top' }}
                />
            </div>

            <Sidebar
                position="right"
                className="above-all"
                visible={sidebarOpened}
                onHide={() => toggleSidebar(false)}
            >
                <AddUserGroupForm hook={hook} />
            </Sidebar>
        </div>
    );
};

const mapDispatchToProps = {
    toggleSidebar: toggleAddNewUserGroupSidebar,
    deleteUserGroup,
};

const mapStateToProps = state => {
    return {
        sidebarOpened: state.userGroups.showAddNew,
        userGroup: state.userGroups.userGroup,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewGroup);
