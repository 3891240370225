import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getContrast } from '../../../utils/colorUtils';
import styles from './ChatFeed.module.css';
import Message from './Message';
import MessagePosition from './messagePosition';

const ChatFeed = ({
    messages,
    messageLeftBgColor,
    messageRightBgColor,
    messageCenterBgColor,
    chatFeedContainerStyle,
    chatFeedStyle,
}) => {
    const [shouldScrollOnNewMessage, setShouldScrollOnNewMessage] = useState(true);
    const chatFeedContainerRef = useRef();
    const chatFeedRef = useRef();
    const debounceTimerId = useRef(-1);
    const messageCountBeforeScrollUpEvent = useRef(0);
    const [unreadMessages, setUnreadMessages] = useState(0);
    const messagesAvailable = Array.isArray(messages) && messages.length > 0;
    const messageColorMap = useMemo(
        () => ({
            [MessagePosition.LEFT]: messageLeftBgColor ?? '#0084FF',
            [MessagePosition.RIGHT]: messageRightBgColor ?? '#484848',
            [MessagePosition.CENTER]: messageCenterBgColor ?? '#CDCDCD',
        }),
        [messageLeftBgColor, messageRightBgColor, messageCenterBgColor]
    );

    const scrollToBottom = useCallback(() => {
        if (chatFeedContainerRef.current && chatFeedRef.current) {
            chatFeedContainerRef.current.scrollTo({
                top: chatFeedRef.current.scrollHeight,
                behavior: 'smooth',
            });
            setShouldScrollOnNewMessage(true);
        }
    }, [chatFeedContainerRef, chatFeedRef]);

    const getScrollDistanceToBottom = useCallback(
        () =>
            Math.abs(
                parseInt(
                    chatFeedContainerRef.current.scrollHeight -
                        chatFeedContainerRef.current.scrollTop -
                        chatFeedContainerRef.current.clientHeight
                )
            ),
        [chatFeedContainerRef]
    );
    const onScroll = useCallback(
        e => {
            clearTimeout(debounceTimerId.current);
            debounceTimerId.current = setTimeout(() => {
                if (chatFeedContainerRef.current && chatFeedRef.current) {
                    const toBottom = getScrollDistanceToBottom();
                    setShouldScrollOnNewMessage(toBottom > 100 ? false : true);
                }
            }, 500);
        },
        [debounceTimerId, chatFeedContainerRef, chatFeedRef, getScrollDistanceToBottom]
    );

    const onScrollToBottom = useCallback(() => scrollToBottom(), [scrollToBottom]);

    useEffect(() => {
        if (shouldScrollOnNewMessage) {
            scrollToBottom();
            messageCountBeforeScrollUpEvent.current = messages.length;
            setUnreadMessages(0);
        } else {
            const currentMessageNumber = messages.length;
            setUnreadMessages(currentMessageNumber - messageCountBeforeScrollUpEvent.current);
        }
    }, [
        messages,
        shouldScrollOnNewMessage,
        messageCountBeforeScrollUpEvent,
        setUnreadMessages,
        scrollToBottom,
    ]);

    return (
        <div className="position-relative" style={{ flex: '1 0 10px', overflow: 'auto' }}>
            {!shouldScrollOnNewMessage ? (
                <div className={styles.scrollToBottomButton} onClick={onScrollToBottom}>
                    {unreadMessages ? (
                        <span className={styles.unreadMessages}>{unreadMessages}</span>
                    ) : null}
                    <i
                        className="pi pi-arrow-down"
                        style={{
                            color: getContrast(messageColorMap[MessagePosition.LEFT]),
                            fontWeight: 600,
                        }}
                    ></i>
                </div>
            ) : null}
            <div
                id="myid"
                ref={chatFeedContainerRef}
                style={{ overflowX: 'auto', ...chatFeedContainerStyle }}
                onScroll={onScroll}
            >
                <div ref={chatFeedRef} style={{ ...chatFeedStyle }}>
                    {messagesAvailable ? (
                        <>
                            {messages.map((m, index, array) => (
                                <div key={index}>
                                    {index === 0 ||
                                    (index > 0 && m.senderName !== array[index - 1].senderName) ? (
                                        <div
                                            className={`d-flex flex-row ${
                                                m.messagePosition === MessagePosition.LEFT
                                                    ? 'justify-content-start'
                                                    : m.messagePosition === MessagePosition.RIGHT
                                                    ? 'justify-content-end'
                                                    : 'justify-content-center'
                                            }`}
                                            style={{
                                                fontWeight: '600',
                                                color: '#999999',
                                                marginTop: '3px',
                                            }}
                                        >
                                            {m.senderName}
                                        </div>
                                    ) : null}
                                    <Message
                                        content={m.content}
                                        messagePosition={m.messagePosition}
                                        messageTs={m.messageTs}
                                        sendingStatus={m.sendingStatus}
                                        bgColor={messageColorMap[m.messagePosition]}
                                    />
                                </div>
                            ))}
                        </>
                    ) : (
                        <span>No messages.</span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ChatFeed;
