import { Button } from 'primereact/button';
import Control from '../../components/UI/Control/Control';
import GeneralMessage from '../../components/UI/GeneralMessage';

const IntentReportGridFilter = ({ hook }) => {
    const {
        generalErrorList,
        control,
        orderOptions,
        onSubmit,
        defaultOrderDirection,
        registerValidationFor,
        fieldErrorFor,
    } = hook;
    return (
        <form className="container-fluid card p-4" noValidate onSubmit={onSubmit}>
            <GeneralMessage errorList={generalErrorList} />
            <div className="row">
                <div className="col-3">
                    <Control
                        error={fieldErrorFor.dateFrom?.message}
                        registerRef={registerValidationFor?.dateFrom}
                        control={control}
                        name="dateFrom"
                        type="datepicker"
                        label="From date"
                        value=""
                        showTime
                    />
                </div>
                <div className="col-3">
                    <Control
                        error={fieldErrorFor.dateTo?.message}
                        registerRef={registerValidationFor?.dateTo}
                        control={control}
                        name="dateTo"
                        type="datepicker"
                        label="To date"
                        value=""
                        showTime
                    />
                </div>
                <div className="col-2">
                    <Control
                        error={fieldErrorFor.name?.message}
                        registerRef={registerValidationFor?.name}
                        control={control}
                        name="name"
                        type="text"
                        label="Name"
                    />
                </div>
                <div className="col-2">
                    <Control
                        error={fieldErrorFor.direction?.message}
                        registerRef={registerValidationFor?.direction}
                        control={control}
                        name="direction"
                        type="select"
                        label="Direction"
                        options={orderOptions}
                        value={defaultOrderDirection}
                    />
                </div>
                <div className="col-2 d-flex align-items-center justify-content-end">
                    <Button
                        style={{ marginTop: '8px', width: '100%' }}
                        type="submit"
                        className="add-btn"
                        icon="pi pi-list"
                        tooltip={'Generate report'}
                        label="Generate"
                        tooltipOptions={{ position: 'top' }}
                    />
                </div>
            </div>
        </form>
    );
};

export default IntentReportGridFilter;
