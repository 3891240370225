import useIntentReport from '../../hooks/useIntentReport';
import Grid from '../../components/UI/Grid/Grid';
import IntentReportGridFilter from './IntentReportGridFilter';

const IntentReportGrid = () => {
    const hook = useIntentReport(),
        {
            gridMeta,
            onGridPage,
            intentGridHeaders,
            intentGridData,
            selectedIntent,
            changeSelectedIntent,
            messagesGridData,
            messagesGridHeaders,
        } = hook;

    return (
        <>
            <IntentReportGridFilter hook={hook} />
            <section className="container-fluid card p-4 mb-5">
                <div className="row">
                    <div className="col-12">
                        <Grid
                            selection={selectedIntent}
                            changeSelectedConversation={changeSelectedIntent}
                            selectionMode="single"
                            hover={true}
                            paginator={true}
                            lazy={true}
                            total={gridMeta.total}
                            rows={gridMeta.limit}
                            first={gridMeta.offset}
                            onPage={onGridPage}
                            columns={intentGridHeaders}
                            data={intentGridData}
                        />
                    </div>
                </div>
            </section>
            <section className="container-fluid card p-4 mb-5">
                <div className="row">
                    <div className="col-12">
                        <h2>Intent-Classified Messages</h2>
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        {Array.isArray(messagesGridData) && messagesGridData.length ? (
                            <Grid columns={messagesGridHeaders} data={messagesGridData} />
                        ) : (
                            <span>No intent selected.</span>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
};

export default IntentReportGrid;
