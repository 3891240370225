import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PlayButton from "../../modules/configResp/PlayButton";
import Control from "../UI/Control/Control";

const PredefinedMessageEditItem = ({ getValues, setValue, clearErrors, ...props }) => {
    const canSupportTextToSpeech = props.appFeatures?.textToSpeech === true;
    const textToSpeechRef = useRef(null);

    const wrapTextWithTagOnClick = (leftTag, rightTag) => {
        return e => {
            e.preventDefault();
            if (!textToSpeechRef.current) {
                return;
            }

            const start = textToSpeechRef.current.selectionStart;
            const end = textToSpeechRef.current.selectionEnd;

            if (start === undefined) {
                return;
            }
            if (end === undefined) {
                return;
            }

            // Get the current value from the form's input instead of the form's state
            // in order to avoid misalign with the cursor values.
            const content = textToSpeechRef.current.value;

            const changedContent = `${content.substring(0, start)}${leftTag}${content.substring(
                start,
                end
            )}${rightTag}${content.substring(end)}`;

            setValue("alternativeContent", changedContent);
            textToSpeechRef.current.selectionStart = start + leftTag.length;
            textToSpeechRef.current.selectionEnd = end + leftTag.length;
            textToSpeechRef.current.focus();
        };
    };

    return (
        <Dialog
            visible={props.visibleEditDialog}
            style={{ width: "450px" }}
            header="Edit Predefined Message"
            onHide={props.closeEditDialog}
        >
            <form noValidate className="container-fluid" id="editPredefinedMessage">
                {canSupportTextToSpeech ? (
                    <PlayButton
                        style={{ marginBottom: "10px" }}
                        watchText={props.message}
                        watchTextToSpeech={props.alternativeContent}
                        language={props.language}
                    />
                ) : null}
                <div className="p-field">
                    <Control
                        id="message"
                        name="message"
                        type="textarea"
                        value={props.message}
                        error={props.fieldErrorFor.message?.message}
                        registerRef={props.registerValidationFor.message}
                        label="Message *"
                        onChange={props.onMessageChange}
                    />
                </div>
                {canSupportTextToSpeech ? (
                    <>
                        <div className="p-field">
                            <Control
                                ref={textToSpeechRef}
                                id="alternativeContent"
                                name="alternativeContent"
                                type="textarea"
                                value={props.alternativeContent}
                                error={props.fieldErrorFor.alternativeContent?.message}
                                registerRef={props.registerValidationFor.alternativeContent}
                                label="Text-to-speech"
                                onChange={props.onAlternativeContentChange}
                            />
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <Button
                                    className="btn-info mr-2 mb-2"
                                    onClick={wrapTextWithTagOnClick("<emphasis>", "</emphasis>")}
                                    label="<emphasis>"
                                />
                                <Button
                                    className="btn-info mr-2 mb-2"
                                    onClick={wrapTextWithTagOnClick(
                                        '<say-as interpret-as="currency, telephone, verbatim, date, characters, time">',
                                        "</say-as>"
                                    )}
                                    label="<say-as>"
                                />
                                <Button
                                    className="btn-info mr-2 mb-2"
                                    onClick={wrapTextWithTagOnClick(
                                        '<break strength="x-weak, weak, medium, strong, x-strong"/>',
                                        ""
                                    )}
                                    label="<break>"
                                />
                                <Button
                                    className="btn-info mr-2 mb-2"
                                    onClick={e => {
                                        e.preventDefault();
                                        const text = getValues(`alternativeContent`);
                                        setValue(
                                            `alternativeContent`,
                                            `<speak>${text
                                                .replaceAll("<speak>", "")
                                                .replaceAll("</speak>", "")}</speak>`
                                        );
                                        clearErrors(`alternativeContent`);
                                    }}
                                    label="Use SSML"
                                />
                            </div>
                        </div>
                    </>
                ) : null}
                <div className="p-field">
                    <Control
                        id="description"
                        name="description"
                        type="textarea"
                        label="Description"
                        value={props.description}
                        error={props.fieldErrorFor.description?.message}
                        registerRef={props.registerValidationFor.description}
                        onChange={props.onDescriptionChange}
                    />
                </div>
                <div style={{ textAlign: "right" }}>
                    <Button
                        type="button"
                        className="info-btn mr-2"
                        icon="fa fa-minus"
                        label="CANCEL"
                        tooltipOptions={{ position: "top" }}
                        onClick={props.closeEditDialog}
                    />
                    <Button
                        className="add-btn"
                        icon="fa fa-check"
                        label="CONFIRM"
                        disabled={props.disabled}
                        tooltipOptions={{ position: "top" }}
                        onClick={props.saveEdits}
                    />
                </div>
            </form>
        </Dialog>
    );
};

const mapStateToProps = state => ({
    appFeatures: state.app.features,
});

export default connect(mapStateToProps, null)(withRouter(PredefinedMessageEditItem));
