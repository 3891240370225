import Config from '../config/config';

export const downloadBlobFileWithLink = (response, name, setSpinnerEffect) => {
    try {
        const blob = new Blob([response.data]);
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        let fileName = '';
        const contentDisposition = response.headers['content-disposition'];
        if (contentDisposition) {
            fileName = contentDisposition.substring(contentDisposition.indexOf('filename=') + 9);
        } else if (name) {
            fileName = name;
        } else {
            fileName = 'file';
        }
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
        if (!Config.isProduction) {
            console.log('TODO collect errors', error);
        }
    } finally {
        if (setSpinnerEffect) {
            setSpinnerEffect(false);
        }
    }
};
