import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useConfirmDialog from '../hooks/useConfirmDialog';
import ApiRequests from '../http/ApiRequests';
import axiosErrorHandler from '../http/AxiosErrorHandler';
import RoboadvisorService from '../services/roboadvisor.service';

const useApproveTraining = props => {
    const api = useMemo(() => new ApiRequests(), []);

    const [dataToApproveReject, setDataToApproveReject] = useState([]),
        [modelsToApproveReject, setModelsToApproveReject] = useState([]),
        [nowElementsList, setNowElementsList] = useState([]),
        [futureElementsList, setFutureElementsList] = useState([]),
        [linkedElementList, setlinkedElementList] = useState([]),
        [generalErrorList, setGeneralErrorList] = useState([]),
        [successMessage, setSuccessMessage] = useState(null),
        [trainingEnabled, setTrainingEnabled] = useState(true),
        [trainingInProgress, setTrainingInProgress] = useState(false),
        [trainingElapsedTime, setTrainingElapsedTime] = useState(''),
        [lastTrainingStartTime, setLastTrainingStartTime] = useState(''),
        [lastTrainingDuration, setLastTrainingDuration] = useState(''),
        [lastTrainingExecutor, setLastTrainingExecutor] = useState(''),
        [currentlyLoadedModel, setCurrentlyLoadedModel] = useState(''),
        [loadedModelApprovedBy, setLoadedModelApprovedBy] = useState(''),
        [loadedModelApprovedTs, setLoadedModelApprovedTs] = useState(''),
        [modelApproveSpinner, setModelApproveSpinner] = useState(false),
        confirm = useConfirmDialog();

    const status = {
            APPROVED: 'APPROVED',
            NEW: 'NEW',
            UPDATED: 'UPDATED',
            DELETED: 'DELETED',
        },
        method = {
            getIntentExampleList: 'getIntentExampleList',
            getKeywordSynonymList: 'getKeywordSynonymList',
            approveIntent: 'approveIntent',
            rejectIntent: 'rejectIntent',
            approveExample: 'approveExample',
            rejectExample: 'rejectExample',
            approveKeyword: 'approveKeyword',
            rejectKeyword: 'rejectKeyword',
            approveSynonym: 'approveSynonym',
            rejectSynonym: 'rejectSynonym',
        },
        type = { intent: 'intent', keyword: 'keyword' },
        intentStatusMessages = {
            added: 'New intent added',
            enabled: 'Intent enabled',
            disabled: 'Intent disabled',
            renamed: (currentName, newName) => `Intent renamed from ${currentName} to ${newName}`,
            deleted: 'Intent deleted',
            exDeleted: 'Intent example deleted',
            exAdded: 'New Intent example added',
        },
        synonymStatusMessages = {
            added: 'New synonym added',
            deleted: 'Synonym deleted',
            exAdded: 'New synonym example added',
            exDeleted: 'Synonym example deleted',
    };

    const getBotTrainingStatus = useCallback(() => {
        api.getBotTrainingStatus()
            .then(res => {
                setTrainingEnabled(res.data.trainingEnabled);
                setTrainingInProgress(res.data.trainingInProgress);
                setTrainingElapsedTime(getElapsedTrainingTime(res.data.trainingStartTime));
                setLastTrainingStartTime(res.data.lastTrainingStartTime);
                setLastTrainingExecutor(res.data.lastTrainingExecutor);
                setLastTrainingDuration(res.data.lastTrainingDuration);
                setCurrentlyLoadedModel(res.data.currentlyLoadedModel);
                setLoadedModelApprovedBy(res.data.loadedModelApprovedBy);
                setLoadedModelApprovedTs(res.data.loadedModelApprovedTs);
            })
            .catch(err => {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            });
    }, [api]);

    const populateDataForApproval = useCallback(() => {
        Promise.all([
            api.getNotApprovedInt({ approved: false }),
            api.getNotApprovedSQ({ approved: false }),
            api.getNotApprovedKw({ approved: false }),
            api.getNotApprovedSyn({ approved: false }),
            api.getPendingModels(),
        ])
            .then(([intentsForApproval, sampleQuestionsForApproval, keywordsForApproval, synonymsForApproval, modelsForApproval]) => {
                if (Array.isArray(modelsForApproval.data) && modelsForApproval.data.length) {
                    setModelsToApproveReject(modelsForApproval.data);
                    return;
                }
                setModelsToApproveReject([]);

                const intents = intentsForApproval.data
                    .filter(int => int.status !== status.APPROVED)
                    .concat(
                        sampleQuestionsForApproval.data
                            .filter(sq => sq.status !== status.APPROVED)
                            .map(sq => sq.intent),
                    )
                    .filter(
                        (int, index, allInt) =>
                            int && index === allInt.findIndex(intInAll => intInAll.id === int.id),
                    );
                const keywords = keywordsForApproval.data
                    .filter(kw => kw.status !== status.APPROVED)
                    .concat(
                        synonymsForApproval.data
                            .filter(syn => syn.status !== status.APPROVED)
                            .map(sn => sn.synonym),
                    )
                    .filter(
                        (kw, index, kwAll) =>
                            kw && index === kwAll.findIndex(kwInAll => kwInAll.id === kw.id),
                    );

                const res = [
                    ...intents.map(intent => ({
                        type: type.intent,
                        id: intent.id,
                        name: intent.name,
                        status: intent.status,
                        enabled: intent.enabled,
                        pendingName: intent.pendingName,
                    })),
                    ...keywords.map(kw => ({
                        type: type.keyword,
                        id: kw.id,
                        name: kw.name,
                        status: kw.status,
                    })),
                ];
                setDataToApproveReject(res);
            })
            .catch(err => {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
                setDataToApproveReject([]);
                setModelsToApproveReject([]);
            });
    }, [api, status.APPROVED, type.intent, type.keyword]);

    useEffect(() => populateDataForApproval(), [populateDataForApproval]);

    useEffect(() => {
        getBotTrainingStatus();
        // use periodic checks only if training is enabled
        if (trainingEnabled) {
            const interval = setInterval(() => {
                getBotTrainingStatus();
            }, 1000 * 10);
            return () => clearInterval(interval);
        }
    }, [getBotTrainingStatus, trainingEnabled]);
    
    const toglePreview = async (ind, modification) => {
        const newdataToApproveReject = dataToApproveReject.map((ch, i) => {
            if (i !== ind) delete ch.preview;
            else ch.preview = !ch.preview;
            return ch;
        });
        setDataToApproveReject(newdataToApproveReject);
        try {
            const { currentElements, modifiedElements } = await getLinkedElemenList(modification);
            setNowElementsList(currentElements);
            setFutureElementsList(modifiedElements);
        } catch (err) {
            const [errorList] = axiosErrorHandler(err);
            setGeneralErrorList(errorList);
        }
    };

    const getLinkedElemenList = async modification => {
        const apiMethod =
            modification.type === type.intent
                ? method.getIntentExampleList
                : method.getKeywordSynonymList;
        try {
            const allLinkedElementList = await api[apiMethod](modification.id);
            setlinkedElementList(allLinkedElementList.data);

            return {
                currentElements: allLinkedElementList.data.filter(el => el.status !== status.NEW),
                modifiedElements: allLinkedElementList.data.filter(
                    el => el.status !== status.DELETED
                ),
            };
        } catch (err) {
            const [errorList] = axiosErrorHandler(err);
            setGeneralErrorList(errorList);
        }
    };

    const approveModification = async modification => {
        const [approveMainElementMethod, approveSubElementMethod] =
                modification.type === type.intent
                    ? [method.approveIntent, method.approveExample]
                    : [method.approveKeyword, method.approveSynonym],
            mainElementToApprove =
                modification.status !== status.APPROVED
                    ? [{ id: modification.id, method: approveMainElementMethod }]
                    : [],
            allElementsToApprove = [
                ...mainElementToApprove,
                ...linkedElementList
                    .filter(el => el.status !== status.APPROVED)
                    .map(el => ({ id: el.id, method: approveSubElementMethod })),
            ];
        try {
            await Promise.all(
                allElementsToApprove.map(elem => api[elem.method](elem.id))
            );
            setDataToApproveReject(
                dataToApproveReject.filter(
                    change => !(change.id === modification.id && change.type === modification.type)
                )
            );
            setSuccessMessage({ success: 'Changes approved.' });
        } catch (err) {
            const [errorList] = axiosErrorHandler(err);
            setGeneralErrorList(errorList);
        }
    };

    const rejectModification = async modification => {
        const [rejectMainElementMethod, rejectSubElementMethod] =
                modification.type === type.intent
                    ? [method.rejectIntent, method.rejectExample]
                    : [method.rejectKeyword, method.rejectSynonym],
            mainElementToReject =
                modification.status !== status.APPROVED
                    ? [{ id: modification.id, method: rejectMainElementMethod }]
                    : [],
            allElementsToReject = [
                ...mainElementToReject,
                ...linkedElementList
                    .filter(el => el.status !== status.APPROVED)
                    .map(el => ({ id: el.id, method: rejectSubElementMethod })),
            ];
        try {
            await Promise.all(
                allElementsToReject.map(elem => api[elem.method](elem.id))
            );

            setDataToApproveReject(
                dataToApproveReject.filter(
                    change => !(change.id === modification.id && change.type === modification.type)
                )
            );
            setSuccessMessage({ success: 'Changes rejected.' });
        } catch (err) {
            const [errorList] = axiosErrorHandler(err);
            setGeneralErrorList(errorList);
        }
    };

    const approveAllModifications = useCallback(() => {
        Promise.all([
            api.approveAllIntents().then(() => api.approveAllExamples()),
            api.approveAllKeywords().then(() => api.approveAllSynonyms()),
        ])
            .then(() => populateDataForApproval())
            .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setGeneralErrorList(errorList);
                },
            );
    }, [api, populateDataForApproval]);

    const confirmApproveAllModifications = useCallback(() => {
        const confirmationData = {
            message: 'Are you sure you want to approve all modifications?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => approveAllModifications(),
        };
        confirm(confirmationData);
    }, [approveAllModifications, confirm]);

    const rejectAllModifications = useCallback(() => {
        Promise.all([
            api.rejectAllIntents().then(() => api.rejectAllExamples()),
            api.rejectAllKeywords().then(() => api.rejectAllSynonyms()),
        ])
            .then(() => populateDataForApproval())
            .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setGeneralErrorList(errorList);
                },
            );
    }, [api, populateDataForApproval]);

    const confirmRejectAllModifications = useCallback(() => {
        const confirmationData = {
            message: 'Are you sure you want to reject all modifications?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => rejectAllModifications(),
        };
        confirm(confirmationData);
    }, [confirm, rejectAllModifications]);

    const startBotTraining = async () => {
        try {
            setTrainingInProgress(true);
            await api.startBotTraining();
            setSuccessMessage({ success: 'Chatbot has been trained successfully' });
        } catch (err) {
            setTrainingInProgress(false);
            const [errorList] = axiosErrorHandler(err);
            setGeneralErrorList(errorList);
        }
    };

    const confirmApproveModification = modification => {
        const confirmationData = {
            message: 'Are you sure you want to approve this modification?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => approveModification(modification),
        };
        confirm(confirmationData);
    };

    const confirmRejectModification = modification => {
        const confirmationData = {
            message: 'Are you sure you want to reject this modification?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => rejectModification(modification),
        };
        confirm(confirmationData);
    };

    const confirmStartBotTraining = () => {
        const confirmationData = {
            message: 'Are you sure you want to train the chat bot?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => startBotTraining(),
        };
        confirm(confirmationData);
    };

    const getLastTrainingTime = () => {
        if (lastTrainingStartTime) {
            return moment(lastTrainingStartTime)
                .local()
                .format(RoboadvisorService.dateTimeMomentFormatMinutes);
        }
    };

    const getElapsedTrainingTime = trainingStartTime => {
        if (trainingStartTime) {
            const now = moment();
            const duration = moment.duration(now.diff(trainingStartTime));
            return Math.round(duration.asSeconds());
        }
    };

    const getIntentStatusDetailed = (change, futureElementsList, nowElementsList) =>{
        const message = {
          status: '',
          message: '',
        };

        if (change) {
            const statusMessages = change.type === type.intent ? intentStatusMessages : synonymStatusMessages;
            if (change.status === status.UPDATED && change.type === type.intent) {
                message.status = status.UPDATED;
                if (change.pendingName != null) {
                    message.message = statusMessages.renamed(change.name, change.pendingName);
                } else if (change.enabled === false) {
                    message.message = statusMessages.disabled;
                } else if (change.enabled === true) {
                    message.message = statusMessages.enabled;
                }
            } else if (change.status === 'NEW') {
                message.status = status.NEW;
                message.message = statusMessages.added;
            } else if (change.status === 'DELETED') {
                message.status = status.DELETED;
                message.message = statusMessages.deleted;
            } else if (change.status === 'APPROVED') {
                if (futureElementsList && nowElementsList) {
                    const addedElements = futureElementsList.filter(e => !nowElementsList.includes(e));
                    const removedElements = nowElementsList.filter(e => !futureElementsList.includes(e));
                    if (addedElements.length > 0 && removedElements.length > 0) {
                        message.status = status.UPDATED;
                        message.message = `${
                            statusMessages.exDeleted
                        } and ${statusMessages.exAdded.toLowerCase()}`;
                    } else if (addedElements.length > 0) {
                        message.status = status.NEW;
                        message.message = statusMessages.exAdded;
                    } else if (removedElements.length > 0) {
                        message.status = status.DELETED;
                        message.message = statusMessages.exDeleted;
                    } 
                }
            }
        }
        return message;
    };

    const approveModel = useCallback(
        id => {
            setModelApproveSpinner(true);
            api.approvePendingModel(id)
                .then(() => {
                    populateDataForApproval();
                    getBotTrainingStatus();
                })
                .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setGeneralErrorList(errorList);
                })
                .finally(() => setModelApproveSpinner(false));
        },
        [api, getBotTrainingStatus, populateDataForApproval]
    );

    const rejectModel = useCallback(
        id => {
            setModelApproveSpinner(true);
            api.rejectPendingModel(id)
                .then(() => populateDataForApproval())
                .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setGeneralErrorList(errorList);
                })
                .finally(() => setModelApproveSpinner(false));
        },
        [api, populateDataForApproval]
    );

    const confirmApproveModel = useCallback(
        model => {
            const confirmationData = {
                message: 'Are you sure you want to approve this model update?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => approveModel(model.id),
            };
            confirm(confirmationData);
        },
        [approveModel, confirm]
    );

    const confirmRejectModel = useCallback(
        model => {
            const confirmationData = {
                message: 'Are you sure you want to reject this model update?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => rejectModel(model.id),
            };
            confirm(confirmationData);
        },
        [confirm, rejectModel]
    );

    return {
        dataToApproveReject,
        modelsToApproveReject,
        nowElementsList,
        futureElementsList,
        generalErrorList,
        successMessage,
        toglePreview,
        confirmApproveModel,
        confirmRejectModel,
        confirmApproveModification,
        confirmRejectModification,
        confirmApproveAllModifications,
        confirmRejectAllModifications,
        confirmStartBotTraining,
        trainingEnabled,
        trainingInProgress,
        trainingElapsedTime,
        getLastTrainingTime,
        lastTrainingExecutor,
        lastTrainingDuration,
        currentlyLoadedModel,
        loadedModelApprovedBy,
        loadedModelApprovedTs,
        getIntentStatusDetailed,
        status,
        modelApproveSpinner,
    };
};

export default useApproveTraining;
