import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
    clearOverwrite,
    endConversation,
    sendMessage,
    setGeneralErrorList,
    setSuccessMessage,
} from '../../store/actions/liveChatActions';
import LiveChatBanDialog from '../liveChatBan/LiveChatBanDialog';
import LiveChatAssignDialog from '../liveChatReassign/liveChatAssignDialog';
import LiveChatWindow from './LiveChatWindow';
import './LiveChatWindowPanel';

const LiveChatWindowPanel = ({
    conversations,
    selectedConversationId,
    template,
    sendMessage,
    endConversation,
    setGeneralErrorList,
    setSuccessMessage,
    clearOverwrite,
}) => {
    const [dialogVisible, setDialogVisible] = useState(false);
    const [assignDialogVisible, setAssignDialogVisible] = useState(false);
    const [banData, setBanData] = useState('');
    const hideDialog = useCallback(() => setDialogVisible(false), [setDialogVisible]);
    const showDialog = useCallback(() => setDialogVisible(true), [setDialogVisible]);
    const hideAssignDialog = useCallback(
        () => setAssignDialogVisible(false),
        [setAssignDialogVisible]
    );
    const selectedConversation = useMemo(
        () => conversations.find(conv => conv.id === selectedConversationId),
        [selectedConversationId, conversations]
    );
    const showAssignDialog = useCallback(
        () => setAssignDialogVisible(true),
        [setAssignDialogVisible]
    );
    const onSuccessfulSubmit = useCallback(
        () => endConversation(selectedConversationId),
        [endConversation, selectedConversationId]
    );
    const confirmEndConversation = useCallback(() => {
        if (selectedConversationId) {
            confirmDialog({
                message: `Would you like to end conversation with client ${selectedConversationId}?`,
                header: 'Confirmation',
                icon: 'fa fa-user',
                accept: () => {
                    endConversation(selectedConversationId);
                },
            });
        }
    }, [endConversation, selectedConversationId]);

    useEffect(() => {
        if (selectedConversationId && setBanData && conversations) {
            const currentSessionId = conversations.find(
                conv => conv.id === selectedConversationId
            )?.session;
            if (currentSessionId) {
                setBanData({ sessionId: currentSessionId });
            }
        }
    }, [setBanData, selectedConversationId, conversations]);
    return (
        <>
            {selectedConversationId ? (
                <>
                    <LiveChatBanDialog
                        data={banData}
                        setGeneralErrorList={setGeneralErrorList}
                        setSuccessMessage={setSuccessMessage}
                        dialogVisible={dialogVisible}
                        hideDialog={hideDialog}
                        onSuccessfulSubmit={onSuccessfulSubmit}
                    />
                    <LiveChatAssignDialog
                        sessionId={selectedConversationId}
                        setGeneralErrorList={setGeneralErrorList}
                        setSuccessMessage={setSuccessMessage}
                        dialogVisible={assignDialogVisible}
                        hideDialog={hideAssignDialog}
                        onSuccessfulSubmit={onSuccessfulSubmit}
                    />
                    <div
                        className="d-flex p-2 flex-row"
                        style={{
                            borderBottom: '1px solid #cccccc',
                        }}
                    >
                        <div className="d-flex flex-row flex-nowrap align-items-start">
                            <div className="position-relative mr-2">
                                <i
                                    className="fa fa-user ml-2 mr-1"
                                    style={{
                                        fontSize: '3em',
                                        color: selectedConversation?.profileColor
                                            ? selectedConversation?.profileColor
                                            : '#000',
                                    }}
                                ></i>
                                {selectedConversation?.conversationEnded ? (
                                    <i
                                        className="pi pi-times-circle my-2 ml-2 mr-1"
                                        style={{
                                            position: 'absolute',
                                            right: '0.05em',
                                            top: '1.5em',
                                            fontSize: '1em',
                                            color: '#f86c6b',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                        }}
                                    ></i>
                                ) : null}
                            </div>
                            <span
                                className="mr-2"
                                style={{ wordWrap: 'break-word', fontSize: '1rem' }}
                                title="session id"
                            >
                                {selectedConversation?.session}
                            </span>
                        </div>

                        <div
                            className="d-flex flex-row align-items-start ml-auto"
                            style={{ flex: '1 0 160px' }}
                        >
                            {banData ? (
                                <Button
                                    className="p-button-warning p-button-sm"
                                    onClick={showDialog}
                                    label="Ban User"
                                    style={{ minWidth: '85px' }}
                                    disabled={selectedConversation?.conversationEnded}
                                />
                            ) : null}
                            <Button
                                className={`p-button-danger ml-2 
                                 mr-2  p-button-sm`}
                                onClick={showAssignDialog}
                                label="Reassign"
                                style={{ minWidth: '85px' }}
                                disabled={selectedConversation?.conversationEnded}
                            />
                            <Button
                                className={`p-button-danger ml-2 mr-2  p-button-sm`}
                                onClick={confirmEndConversation}
                                style={{ minWidth: '150px' }}
                                label="Close Conversation"
                            />
                        </div>
                    </div>
                    <LiveChatWindow
                        selectedConversation={selectedConversation}
                        conversationId={selectedConversationId}
                        messages={
                            conversations.find(conv => conv.id === selectedConversationId)?.messages
                        }
                        sessionId={
                            conversations.find(conv => conv.id === selectedConversationId)?.session
                        }
                        sendMessage={sendMessage}
                        overwriteChatInput={template}
                        clearOverwrite={clearOverwrite}
                    />
                </>
            ) : (
                <p className="m-2">No selected conversation.</p>
            )}
        </>
    );
};

const mapStateToProps = state => {
    return {
        conversations: state.liveChat.conversations,
        selectedConversationId: state.liveChat.selectedConversationId,
        template: state.liveChat.template,
    };
};

const mapDispatchToProps = {
    sendMessage: sendMessage,
    endConversation: endConversation,
    setSuccessMessage: setSuccessMessage,
    setGeneralErrorList: setGeneralErrorList,
    clearOverwrite: clearOverwrite,
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveChatWindowPanel);
