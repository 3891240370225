import { Button } from 'primereact/button';
import React from 'react';
import GeneralMessage from '../../components/UI/GeneralMessage';
import Grid from '../../components/UI/Grid/Grid';
import useLocationCoordinatesApproval from '../../hooks/useLocationCoordinatesApproval';

const LocationCoordinatesApproval = () => {
    const hook = useLocationCoordinatesApproval();

    const areButtonsDisabled = () => {
        return !hook.gridData || hook.gridData.length === 0;
    };

    return (
        <>
            <GeneralMessage
                className="c-msg"
                successMessage={hook.successMessage}
                errorList={hook.generalErrorList}
            />
            <div className="animated fadeIn">
                <div className="container-fluid card p-4 mb-5 animated fadeIn">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="display-5">Pending Locations & Coordinates</h2>
                            <hr />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Grid columns={hook.gridHeaders} data={hook.gridData} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <Button
                        type="button"
                        className="add-btn mr-2"
                        icon="fa fa-check"
                        onClick={() => hook.confirmReviewPendingCoordinates(true)}
                        tooltip="You can approve only changes made by someone else."
                        label="Approve All"
                        disabled={areButtonsDisabled()}
                        tooltipOptions={{ position: 'top' }}
                    />
                    <Button
                        type="button"
                        className="delete-btn mr-2"
                        icon="fa fa-times"
                        onClick={() => hook.confirmReviewPendingCoordinates(false)}
                        tooltip="Reject all changes in the list."
                        label="Reject All"
                        disabled={areButtonsDisabled()}
                        tooltipOptions={{ position: 'top' }}
                    />
                </div>
            </div>
        </>
    );
};

export default LocationCoordinatesApproval;
