import { confirmDialog } from 'primereact/confirmdialog';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import ApiRequests from '../http/ApiRequests';
import axiosErrorHandler from '../http/AxiosErrorHandler';
import RoboadvisorService from '../services/roboadvisor.service';
import { validator } from '../services/validationService';
import { trimStringsRecursively } from '../utils/formUtils';
import { validateSsmlWrapperTag } from "../utils/validateUtils";

const useBotResponseForm = ({ data, setGeneralErrorList }) => {
    const api = useMemo(() => new ApiRequests(), []);
    const [apiErrors, setApiErrors] = useState([]);
    const [successMessage, setSuccessMessage] = useState(null);
    const [intentList, setIntentList] = useState([]);
    const [intentName, language] = useMemo(() => {
        if (Array.isArray(data) && data.length) {
            return [
                data[0]?.intent?.name ? data[0].intent.name : "",
                data[0]?.language ? data[0].language : "",
            ];
        } else {
            return ["", ""];
        }
    }, [data]);
    /** Usually data should not be formatted but here
     *  the data needs formatting because the backend API is missing a DTO which
     *  requires a flexible approach when dealing with the form.*/
    const formattedData = useMemo(() => {
        if (Array.isArray(data) && data.length) {
            const firstElement = data[0];
            return {
                language: firstElement.language,
                channel: firstElement.channel,
                intentId: firstElement.intent?.id,
                lastAnswer: firstElement.lastAnswer,
                requestLiveChat: firstElement.requestLiveChat,
                answers: data,
            };
        } else {
            return null;
        }
    }, [data]);
    const {
        register,
        handleSubmit,
        errors,
        control,
        getValues,
        setValue,
        formState,
        reset,
        clearErrors,
    } = useForm({});

    const { fields, append, remove } = useFieldArray({
        control,
        name: "answers",
        keyName: "fieldId",
    });

    const noWhiteSpaceValidate = value =>
        value.trim() !== "" || validator.errorMessages.ONLY_WHITE_SPACE_CONTENT;

    const { isDirty } = formState;
    const registerValidationFor = useMemo(
        () => ({
            language: () => register(),
            channel: () => register(),
            intentId: () => register(),
            type: () =>
                register({
                    required: validator.errorMessages.MISSING_TYPE,
                }),
            content: () =>
                register({
                    required: validator.errorMessages.MISSING_CONTENT,
                    validate: noWhiteSpaceValidate,
                }),
            text: () =>
                register({
                    required: "Enter a message.",
                    validate: value =>
                        value.trim() !== "" || validator.errorMessages.ONLY_WHITE_SPACE_CONTENT,
                }),
            textToSpeech: () =>
                register({
                    validate: validateSsmlWrapperTag,
                }),
            value: () => register(),
            title: () =>
                register({
                    required: validator.errorMessages.MISSING_TITLE,
                    validate: noWhiteSpaceValidate,
                }),
            subtitle: () => register(),
            image: () =>
                register({
                    required: validator.errorMessages.MISSING_IMAGE,
                    validate: noWhiteSpaceValidate,
                }),
            buttonMetadata: () => ({
                required: validator.errorMessages.MISSING_METADATA,
                validate: value => {
                    if (value.length > 1 && value.substring(1).trim() === "") {
                        return validator.errorMessages.ONLY_WHITE_SPACE_CONTENT;
                    }
                    if (!value.match(/^\/[a-zа-я0-9_]+#[A-Z]{2}$/)) {
                        return validator.errorMessages.WRONG_METADATA_FORMAT;
                    }
                    return true;
                },
            }),
            metadata: () => register(),
            url: () =>
                register({
                    required: validator.errorMessages.MISSING_URL,
                    validate: noWhiteSpaceValidate,
                }),
            default: () => register(),
            requestLiveChat: () => ({
                required: false,
            }),
            lastAnswer: () => ({
                required: false,
            }),
        }),
        [register]
    );

    const onSubmit = data => {
        trimStringsRecursively(data);
        if (!(Array.isArray(data.answers) && data.answers?.length)) {
            api.deleteIntentAnswer(data)
                .then(res => {
                    setSuccessMessage({
                        success: "Request to delete answer is sent for approval.",
                    });
                    reset(getValues());
                })
                .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setApiErrors(errorList);
                });
        } else {
            const formattedData = {
                ...data,
                answers: data.answers.map(an => ({
                    ...an,
                    language: data?.language,
                    channel: data?.channel,
                    intentId: data?.intentId,
                    lastAnswer: data?.lastAnswer,
                    requestLiveChat: data?.requestLiveChat,
                })),
            };
            api.editIntentAnswers(formattedData)
                .then(() => {
                    setSuccessMessage({ success: "Successfully sent responses for review" });
                    reset(getValues());
                })
                .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setApiErrors(errorList);
                });
        }
    };

    const deleteWholeAnswer = useCallback(
        e => {
            e.preventDefault();
            const complexAnswerId = getValues(["language", "channel", "intentId"]);
            confirmDialog({
                message: `Are you sure you want to delete the answer for this intent? Your request for deletion will be sent for approval.`,
                header: "Confirmation",
                icon: "pi pi-exclamation-triangle",
                accept: () =>
                    api
                        .deleteIntentAnswer(complexAnswerId)
                        .then(res => {
                            setSuccessMessage({
                                success: "Request to delete answer is sent for approval.",
                            });
                            reset();
                        })
                        .catch(err => {
                            const [errorList] = axiosErrorHandler(err);
                            setApiErrors(errorList);
                        }),
                reject: () => {},
            });
        },
        [api, getValues, reset]
    );

    const addNewResponse = useCallback(
        () =>
            append({
                type: RoboadvisorService.responseTypes.text,
                buttons: [],
                carousel: [],
                content: "",
                alternativeContent: "",
            }),
        [append]
    );

    useEffect(() => {
        if (Array.isArray(formattedData.answers) && formattedData.answers.length) {
            setValue("language", formattedData?.language);
            setValue("channel", formattedData?.channel);
            setValue("intentId", formattedData?.intentId);
            setValue("lastAnswer", formattedData?.lastAnswer);
            setValue("requestLiveChat", formattedData?.requestLiveChat);

            const newAnswers =
                Array.isArray(formattedData.answers) &&
                formattedData.answers.length &&
                formattedData.answers[0].id
                    ? formattedData.answers.map(answer => {
                          const buttons = answer.buttons;
                          return {
                              id: answer.id,
                              buttons: Array.isArray(buttons)
                                  ? buttons.map(button => ({
                                        metadata: button.metadata,
                                        text: button.text,
                                        value: button.value,
                                    }))
                                  : [],
                              carousel: Array.isArray(answer.carousel)
                                  ? answer.carousel.map(car => ({
                                        image: car.image,
                                        metadata: car.metadata,
                                        subtitle: car.subtitle,
                                        title: car.title,
                                        url: car.url,
                                    }))
                                  : [],
                              content: answer.content ? answer.content : "",
                              alternativeContent: answer.alternativeContent
                                  ? answer.alternativeContent
                                  : "",
                              type: answer.type,
                          };
                      })
                    : [];
            setValue("answers", newAnswers);
        }
    }, [formattedData, setValue]);

    const searchIntent = useCallback(
        e => {
            // if the search string does not start with '/' do not show suggestions
            if (!e.query.startsWith("/")) {
                setIntentList([]);
                return;
            }
            api.searchIntent({
                approved: true,
                name: e.query.replace("/", "").trim(),
            })
                .then(response => {
                    setIntentList(
                        response.data.map(i => ({ name: "/" + i.name + "#" + language }))
                    );
                })
                .catch(error => {
                    const [errorList] = axiosErrorHandler(error);
                    setGeneralErrorList(errorList);
                });
        },
        [api, language, setGeneralErrorList]
    );

    return {
        onSubmit: handleSubmit(onSubmit),
        registerValidationFor,
        errors,
        control,
        successMessage,
        generalErrorList: apiErrors,
        fields,
        remove,
        intentName,
        deleteWholeAnswer,
        addNewResponse,
        formattedData,
        isDirty,
        searchIntent,
        intentList,
        setValue,
        getValues,
        clearErrors,
    };
};

export default useBotResponseForm;
