import React from 'react';
import WorkingHoursComponent from './WorkingHoursComponent';

const WeeklyWorkingHoursPanel = ({
    weeklyWorkingHours,
    setGeneralErrorList,
    setSuccessMessage,
    reload,
}) => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <h2 className="display-5">Weekly Working Hours</h2>
                    <hr />
                </div>
            </div>
            {Array.isArray(weeklyWorkingHours) && weeklyWorkingHours.length
                ? weeklyWorkingHours.map((wwh, index) => (
                      <div className="row" key={index}>
                          <WorkingHoursComponent
                              data={wwh}
                              setGeneralErrorList={setGeneralErrorList}
                              setSuccessMessage={setSuccessMessage}
                              reload={reload}
                          />
                      </div>
                  ))
                : null}
        </div>
    );
};

export default WeeklyWorkingHoursPanel;
