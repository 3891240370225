import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import useSearchLiveChatTemplate from '../../hooks/useSearchLiveChatTemplate';
import { setGeneralErrorList, setTemplate } from '../../store/actions/liveChatActions';
import LiveChatTemplateCard from './LiveChatTemplateCard';
import './LiveChatTemplatePanel.css';

const LiveChatTemplatePanel = ({ setGeneralErrorList, selectedConversationId, setTemplate }) => {
    const { templates, onSearchTemplate, loading } = useSearchLiveChatTemplate({
        setGeneralErrorList,
    });
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const templateContentRef = useRef();
    const templateDescriptionRef = useRef();
    const isSelectedTemplate = useCallback(
        id =>
            selectedTemplate && id === selectedTemplate.id
                ? 'live-chat-selected-template-card'
                : '',
        [selectedTemplate]
    );
    const useSelectedTemplate = useCallback(() => {
        if (selectedConversationId && selectedTemplate) {
            setTemplate(selectedTemplate);
            setSelectedTemplate(null);
        } else if (!selectedTemplate) {
            setGeneralErrorList(['Please select a template from the list above.']);
        } else if (!selectedConversationId) {
            setGeneralErrorList(['Please start a conversation before using templates.']);
        }
    }, [selectedConversationId, selectedTemplate, setGeneralErrorList, setTemplate]);

    useEffect(() => {
        if (selectedTemplate) {
            if (templateContentRef.current) {
                templateContentRef.current.value = selectedTemplate.content;
            }
            if (templateDescriptionRef.current) {
                templateDescriptionRef.current.value = selectedTemplate.description;
            }
        }
    }, [selectedTemplate, templateContentRef, templateDescriptionRef]);

    return (
        <div className="d-flex flex-column align-items-stretch h-100">
            <div
                className="form-control d-flex flex-row justify-content-start align-items-center m-2 position-relative"
                style={{ width: 'auto' }}
            >
                <i className="pi pi-search" />
                <InputText
                    name="templateSearchString"
                    type="text"
                    placeholder="Search for template..."
                    onChange={onSearchTemplate}
                    style={{
                        padding: 0,
                        border: 'none',
                        marginLeft: '0.4rem',
                        marginRight: '2rem',
                        width: '100%',
                    }}
                />
                {loading ? (
                    <i
                        className="pi pi-spin pi-spinner ml-auto position-absolute"
                        style={{ right: '1rem' }}
                    />
                ) : null}
            </div>

            <div className="mb-2" style={{ overflow: 'auto', flex: '1 0 150px' }}>
                {Array.isArray(templates) && templates.length ? (
                    templates.map((template, index) => (
                        <LiveChatTemplateCard
                            key={template.id}
                            className={`live-chat-template-card ${isSelectedTemplate(
                                template.id
                            )} ${index % 2 === 0 ? 'odd-line' : ''}`}
                            onClick={() =>
                                setSelectedTemplate(templates.find(t => t.id === template.id))
                            }
                            name={template.name}
                            description={template.description}
                        />
                    ))
                ) : (
                    <p className="ml-2">No templates found.</p>
                )}
            </div>
            <Button
                className="p-button-success m-2"
                label="Use Template"
                onClick={useSelectedTemplate}
            />
            {selectedTemplate ? (
                <div className="d-flex flex-column" style={{ overflow: 'auto', flex: '1 0 150px' }}>
                    <p className="mt-3 mb-1 mx-2" style={{ fontWeight: 500 }}>
                        Description
                    </p>
                    <span className="mx-2">
                        {selectedTemplate.description || 'No description available.'}
                    </span>
                    <p className="mt-3 mb-1 mx-2" style={{ fontWeight: 500 }}>
                        Content
                    </p>
                    <span className="mx-2">
                        {selectedTemplate.content || 'No content available.'}
                    </span>
                </div>
            ) : (
                <div
                    className="d-flex flex-column"
                    style={{ overflow: 'auto', flex: '1 0 150px' }}
                ></div>
            )}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        selectedConversationId: state.liveChat.selectedConversationId,
    };
};

const mapDispatchToProps = {
    setGeneralErrorList: setGeneralErrorList,
    setTemplate: setTemplate,
};
export default connect(mapStateToProps, mapDispatchToProps)(LiveChatTemplatePanel);
