import React, { useState } from 'react';
import labels from '../../constants/labels';
import WorkingHoursComponent from './WorkingHoursComponent';
import WorkingHoursComponentDialog from './WorkingHoursComponentDialog';
import WorkingHoursHistoryDialog from './WorkingHoursHistoryDialog';

const ExceptionalWorkingDaysPanel = ({
    exceptionalWorkingHours,
    history,
    onPage,
    first,
    rows,
    total,
    setGeneralErrorList,
    setSuccessMessage,
    reload,
}) => {
    const [dialogVisible, setDialogVisible] = useState(false);
    const [historyDialogVisible, setHistoryDialogVisible] = useState(false);
    const hideDialog = () => setDialogVisible(false);
    const showDialog = () => setDialogVisible(true);
    const showHistoryDialog = () => setHistoryDialogVisible(true);
    const hideHistoryDialog = () => setHistoryDialogVisible(false);

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-between align-items-end">
                            <h2 className="display-5" style={{ marginBottom: '1px' }}>
                                Exceptional Working Hours
                            </h2>
                            <button
                                className="btn btn-primary"
                                style={{
                                    backgroundColor: '#F6B682',
                                    borderColor: '#F6B682',
                                }}
                                onClick={showHistoryDialog}
                            >
                                History
                            </button>
                        </div>
                        <hr />
                    </div>
                </div>
                <div className="d-flex row">
                    <div className="col-12">
                        <button className="btn btn-primary mr-5 mb-1" onClick={showDialog}>
                            New Working Hours
                        </button>
                    </div>
                </div>
                {Array.isArray(exceptionalWorkingHours) && exceptionalWorkingHours.length ? (
                    exceptionalWorkingHours.map(data => (
                        <div className="row" key={data.id}>
                            <WorkingHoursComponent
                                data={data}
                                readonly
                                setGeneralErrorList={setGeneralErrorList}
                                setSuccessMessage={setSuccessMessage}
                                reload={reload}
                            />
                        </div>
                    ))
                ) : (
                    <span>{labels.NO_DATA_AVAILABLE}</span>
                )}
            </div>
            <WorkingHoursHistoryDialog
                history={history}
                onPage={onPage}
                rows={rows}
                total={total}
                first={first}
                setGeneralErrorList={setGeneralErrorList}
                setSuccessMessage={setSuccessMessage}
                reload={reload}
                dialogVisible={historyDialogVisible}
                hideDialog={hideHistoryDialog}
            />
            <WorkingHoursComponentDialog
                setGeneralErrorList={setGeneralErrorList}
                setSuccessMessage={setSuccessMessage}
                reload={reload}
                dialogVisible={dialogVisible}
                hideDialog={hideDialog}
            />
        </>
    );
};

export default ExceptionalWorkingDaysPanel;
