import React from 'react';

const Label = ({ children, value }) => {
    return (
        <>
            <label style={{ marginBottom: '5px', fontWeight: 500 }}>{value}</label>
            {children}
        </>
    );
};

export default Label;
