import { SET_SIDEBAR_OPENED } from '../actions/sidebarActions';

const INIT_STATE = {
    sidebarOpened: false,
};

const sidebarReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_SIDEBAR_OPENED:
            return Object.assign({}, state, {
                sidebarOpened: action.payload,
            });

        default:
            return { ...state };
    }
};

export default sidebarReducer;
