import React from 'react';
import './WelcomeMessagesApproval.css';
import { WelcomeMessageApprove } from '../../modules/WelcomeMessageApprove/WelcomeMessageApprove';

const WelcomeMessagesApproval = props => {
    return (
        <>
            <h1 className="mb-5 display-4">Awaiting approval</h1>
            <WelcomeMessageApprove />
        </>
    );
};

export default WelcomeMessagesApproval;
