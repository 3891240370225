import { useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';

const GeneralMessage = ({
    className,
    errorList,
    successMessage,
    sticky = false,
    focusOnErrorRef,
}) => {
    const messageRef = useRef(null);
    const repeatingErrorMessageLookUp = useRef({});
    useEffect(() => {
        if (Array.isArray(errorList) && errorList.length > 0) {
            const newErrors = errorList.filter(
                errorMessage => repeatingErrorMessageLookUp.current[errorMessage] !== true
            );
            if (messageRef.current && errorList && newErrors.length > 0) {
                if (errorList.length > 0) {
                    messageRef.current.show(
                        newErrors.map(err => {
                            // true marks the message as shown and the setTimeout
                            // will clear that status after the given time.
                            // This will prevent multiple toast messages with the same
                            // error message to appear at once. The toast's default visible time is 3000ms.
                            repeatingErrorMessageLookUp.current[err] = true;
                            setTimeout(() => {
                                repeatingErrorMessageLookUp.current[err] = null;
                            }, 4000);
                            return {
                                sticky,
                                severity: 'error',
                                summary: err,
                                closable: false,
                            };
                        })
                    );
                    if (focusOnErrorRef && focusOnErrorRef.current) {
                        focusOnErrorRef.current.focus();
                    }
                } else {
                    messageRef.current.clear();
                }
            }
        }
    }, [errorList, focusOnErrorRef, sticky]);

    useEffect(() => {
        if (messageRef.current && successMessage) {
            messageRef.current.show({
                sticky,
                severity: 'success',
                summary: successMessage.success,
                closable: false,
            });
        }
    }, [sticky, successMessage]);

    return <Toast ref={messageRef} position="top-right" className={className} />;
};

export default GeneralMessage;
