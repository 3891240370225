import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { FileUpload } from 'primereact/fileupload';
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';
import GeneralMessage from '../../components/UI/GeneralMessage';
import useImportExport from '../../hooks/useImportExport';
import ImportExportModel from './ImportExportModel';
import './importExportModule.css';

const ImportExportModule = () => {
    const {
        onUpload,
        downloadNlu,
        downloadResponses,
        successMessage,
        generalErrorList,
        uploadSpinner,
        toggleShowExamples,
        showExamples,
        onSelect,
        uploadInputRef,
        selectFileErrorMessage,
    } = useImportExport();

    const ymlFormat =
        'version: "2.0"\nnlu:\n  - intent: test_intent1\n    examples: |\n\t- Hello\n\t- Hi\n  - intent: test_intent2\n    examples: |\n\t- Bye\n\t- cya\n  - synonym: credit\n    examples: |\n\t- credit card account\n\t- credit account';

    const jsonFormat = {
        predefinedMessages: [
            {
                id: 1,
                messageName: 'INSUFFICIENT_OR_INVALID_INPUT',
                messageContent: 'Недостатъчни или невалидни данни.',
                language: 'BG',
                description: '',
                updatedBy: 'System',
                updatedTs: 1617806534.933152,
                pendingMessages: [],
            },
        ],
        welcomeMessages: [
            {
                id: 1,
                pageId: null,
                channel: 'API',
                language: 'BG',
                responses: [
                    {
                        id: 1,
                        sequentialOrder: 1,
                        type: 'TEXT',
                        content: {
                            carousel: null,
                            buttons: null,
                            text: 'Здравейте. Как мога да ви помогна?',
                        },
                    },
                ],
                default: true,
            },
        ],
        intentAnswers: [
            {
                id: 1,
                intent: {
                    status: 'APPROVED',
                    approvedTs: 1617806534.63582,
                    approvedBy: 'System',
                    updatedTs: null,
                    updatedBy: null,
                    id: 1,
                    name: 'faq_session_problem',
                    pendingName: null,
                    uploadedTs: 1617806534.63582,
                    uploadedBy: 'System',
                    deleted: false,
                },
                content:
                    'В момента имате активна сесия - необходимо е да изчакате 10 минути, след което ще може да достъпите интернет- или мобилното банкиране отново.',
                buttons: null,
                carousel: null,
                order: 1,
                type: 'TEXT',
                channel: 'API',
                language: 'BG',
                lastAnswer: true,
                status: null,
                createdTs: 1617806534.63582,
                createdBy: 'System',
                approvedBy: null,
            },
        ],
    };

    const infoLabel = (
        <div>
            <div className="ie-info-label-container">
                <p className="ie-info-label">
                    Select files for data import. Supported formats are .yml and .json. Need to
                    train the model.
                </p>
                <Button
                    onClick={e => {
                        e.stopPropagation();
                        toggleShowExamples();
                    }}
                    label={showExamples ? 'Hide examples' : 'Show examples'}
                />
            </div>
            {showExamples ? (
                <div className="animated fadeIn ie-example-container">
                    <div className="ie-example">
                        <label>YML</label>
                        <InputTextarea
                            className="ie-textarea"
                            value={ymlFormat}
                            rows={10}
                            cols={30}
                        />
                    </div>
                    <div className="ie-example">
                        <label>JSON</label>
                        <InputTextarea
                            className="ie-textarea"
                            value={JSON.stringify(jsonFormat, null, 2)}
                            rows={10}
                            cols={50}
                        />
                    </div>
                </div>
            ) : null}
        </div>
    );
    return (
        <>
            <GeneralMessage
                className="c-msg"
                successMessage={successMessage}
                errorList={generalErrorList}
            />
            <div className="container-fluid card p-4 mb-5 animated fadeIn">
                <div className="row">
                    <div className="col-3">
                        <h2 className="display-5">Export Data</h2>
                        <hr />
                        <div className="d-flex justify-content-center mt-4">
                            <div className="d-flex flex-column ">
                                <Button
                                    label="Download NLU data"
                                    onClick={downloadNlu}
                                    className="mb-3"
                                    style={{ maxWidth: '300px' }}
                                />
                                <Button
                                    label="Download responses"
                                    onClick={downloadResponses}
                                    className="mb-3"
                                    style={{ maxWidth: '300px' }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-1">
                        <Divider layout="vertical" />
                    </div>

                    <div className="col-8">
                        <h2 className="display-5">Import Data</h2>
                        <hr />
                        {uploadSpinner ? (
                            <ProgressSpinner strokeWidth="4" />
                        ) : (
                            <>
                                {infoLabel}
                                <FileUpload
                                    ref={uploadInputRef}
                                    uploadHandler={onUpload}
                                    onSelect={onSelect}
                                    customUpload={true}
                                    accept=".yml,.json"
                                    emptyTemplate={<p className="p-m-0">Drag and drop files.</p>}
                                    multiple={true}
                                />
                                <p className="text-danger">{selectFileErrorMessage}</p>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <ImportExportModel />
        </>
    );
};

export default ImportExportModule;
