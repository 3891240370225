import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';
import Control from '../../components/UI/Control/Control';
import GeneralMessage from '../../components/UI/GeneralMessage';

const SummaryReportFilter = ({ hook }) => {
    const {
        registerValidationFor,
        onSubmit,
        onExport,
        groupByOptions,
        defaultGroupByOption,
        stillDownload,
        generalErrorList,
        fieldErrorFor,
        control,
    } = hook;

    const showExportButton = () => {
        if (stillDownload) {
            return <ProgressSpinner strokeWidth="4" />;
        }
        return (
            <Button
                style={{ marginTop: '8px' }}
                type="button"
                className="add-btn"
                tooltip={'Export to excel'}
                label="Export"
                tooltipOptions={{ position: 'top' }}
                onClick={onExport}
            />
        );
    };

    const formElement = (
        <form className="container-fluid card p-4" noValidate>
            <GeneralMessage errorList={generalErrorList} />
            <div className="row">
                <div className="col-2">
                    <Control
                        error={fieldErrorFor.begin?.message}
                        registerRef={registerValidationFor?.begin}
                        control={control}
                        name="begin"
                        type="datepicker"
                        label="After"
                        showTime
                        value=""
                    />
                </div>
                <div className="col-2">
                    <Control
                        error={fieldErrorFor.end?.message}
                        registerRef={registerValidationFor?.end}
                        control={control}
                        name="end"
                        type="datepicker"
                        label="Before"
                        showTime
                        value=""
                    />
                </div>
                {/* <div className="col-2">
                    <Control
                        error={fieldErrorFor.intent?.message}
                        registerRef={registerValidationFor?.intent}
                        control={control}
                        name="intent"
                        type="select"
                        label="Intent"
                        options={[]}
                        value=""
                    />
                </div>
                <div className="col-2">
                    <Control
                        error={fieldErrorFor.channel?.message}
                        registerRef={registerValidationFor?.channel}
                        control={control}
                        name="channel"
                        type="select"
                        label="Channel"
                        options={[]}
                        value=""
                    />
                </div>
                <div className="col-2">
                    <Control
                        error={fieldErrorFor.reportCategory?.message}
                        registerRef={registerValidationFor?.reportCategory}
                        control={control}
                        name="reportCategory"
                        type="select"
                        label="R eport category"
                        options={[]}
                        value=""
                    />
                </div> */}
                <div className="col-2 d-flex align-items-center">
                    <Button
                        style={{ marginTop: '8px' }}
                        type="button"
                        className="add-btn"
                        icon="fa fa-search"
                        tooltip={'Search'}
                        label="Search"
                        tooltipOptions={{ position: 'top' }}
                        onClick={onSubmit}
                    />
                </div>
                <div className="col-2 offset-2">
                    <Control
                        error={fieldErrorFor.groupBy?.message}
                        registerRef={registerValidationFor?.groupBy}
                        control={control}
                        name="groupBy"
                        type="select"
                        label="Group By"
                        options={groupByOptions}
                        value={defaultGroupByOption}
                    />
                </div>
                <div className="col-2 d-flex align-items-center">{showExportButton()}</div>
            </div>
        </form>
    );

    return formElement;
};

export default SummaryReportFilter;
