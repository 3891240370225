import { Tag } from 'primereact/tag';
import React, { useCallback, useMemo } from 'react';
import './LiveChatCard.css';

const LiveChatCard = ({
    conversation,
    conversationId,
    sessionId,
    onClick,
    selectedActiveChatId,
    index,
    language,
    channel,
    profileColor,
    unreadMessagesCount,
}) => {
    const onClickFunction = useCallback(
        e => {
            e.preventDefault();
            if (onClick) {
                onClick(conversationId);
            }
        },
        [conversationId, onClick]
    );

    const sessionIdFormatted = useMemo(
        () => (sessionId.length < 8 ? sessionId : sessionId.substring(0, 7) + '...'),
        [sessionId]
    );

    return (
        <div
            className={`live-chat-card d-flex flex-row justify-content-start ${
                selectedActiveChatId === conversationId
                    ? 'selected-live-chat-card'
                    : typeof index === 'number' && (index + 1) % 2 === 1
                    ? 'odd-line'
                    : ''
            }`}
            onClick={onClickFunction}
        >
            <div className="position-relative">
                <i
                    className="fa fa-user my-2 ml-2 mr-1"
                    style={{
                        fontSize: '3em',
                        color: profileColor ? profileColor : '#000',
                    }}
                ></i>
                {conversation?.conversationEnded ? (
                    <i
                        className="pi pi-times-circle my-2 ml-2 mr-1"
                        style={{
                            position: 'absolute',
                            right: '0.05em',
                            top: '2em',
                            fontSize: '1em',
                            color: '#f86c6b',
                            backgroundColor: '#fff',
                            borderRadius: '50%',
                        }}
                    ></i>
                ) : null}
            </div>
            <div className="d-flex flex-column w-100">
                <div className="d-flex flex-row justify-content-between">
                    <span className="mt-2 mx-1 live-chat-card-client-name" title="Conversation id">
                        Conv. {conversationId}
                    </span>
                    <div className="d-flex flex-nowrap flex-row align-items-start mt-2 mx-1">
                        {language ? (
                            <Tag className="live-chat-info-tag ml-1" value={language} />
                        ) : null}
                        {channel ? (
                            <Tag
                                className="live-chat-info-tag ml-1"
                                value={channel === 'API' ? 'WEB' : channel}
                            />
                        ) : null}
                    </div>
                </div>
                {conversationId ? (
                    <div className="d-flex flex-row justify-content-between pt-1">
                        <span className="live-chat-init-message-count ml-1">
                            {conversation?.conversationEnded ? (
                                <strike>Client {sessionIdFormatted}</strike>
                            ) : (
                                <>Client {sessionIdFormatted}</>
                            )}
                        </span>
                        {unreadMessagesCount ? (
                            <span
                                className="mr-1 px-2 bg-danger"
                                style={{
                                    borderRadius: '1rem',
                                    fontWeight: 600,
                                }}
                            >
                                {unreadMessagesCount}
                            </span>
                        ) : null}
                    </div>
                ) : null}
                <div className="d-flex juflex-row justify-content-start my-1"></div>
            </div>
        </div>
    );
};

export default LiveChatCard;
