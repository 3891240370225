import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import ApiRequests from '../http/ApiRequests';
import axiosErrorHandler from '../http/AxiosErrorHandler';

const useTemplates = () => {
    const api = useMemo(() => new ApiRequests(), []);
    const { control } = useForm({});
    const [generalErrorList, setGeneralErrorList] = useState([]);
    const [successMessage, setSuccessMessage] = useState(null);
    const [name, setName] = useState('');
    const [content, setContent] = useState('');
    const [description, setDescription] = useState('');
    const [editId, setEditId] = useState(0);
    const [visibleEditDialog, setVisibleEditDialog] = useState(false);
    const [reviewGridData, setForAcceptanceGridData] = useState([]);
    const [formIsDirty, setFormIsDirty] = useState(false);
    const reviewGridHeaders = [
        {
            name: 'Template name',
            key: 'name',
        },
        {
            name: 'Template description',
            key: 'description',
        },
        {
            name: 'Template content',
            key: 'content',
        },
        {
            name: 'Status',
            key: 'status',
        },
    ];
    const [activeTemplatesGridData, setActiveTemplatesGridData] = useState([]);
    const activeTemplatesGridHeaders = [
        {
            name: 'Template name',
            key: 'name',
        },
        {
            name: 'Template description',
            key: 'description',
        },
        {
            name: 'Template content',
            key: 'content',
        },
    ];
    const forAcceptanceStateRef = useRef();
    forAcceptanceStateRef.current = reviewGridData;
    const activeTemplatesStateRef = useRef();
    activeTemplatesStateRef.current = activeTemplatesGridData;

    const editClickHandler = entry => {
        setVisibleEditDialog(true);
        setEditId(entry.id);
        setContent(entry.content);
        setDescription(entry.description);
        setName(entry.name);
    };

    const loadActivePhrasesContent = useCallback(async () => {
        try {
            const res = await api.getLiveChatTemplates();
            let gridD = res.data.map(entry => ({
                name: entry.name,
                description: entry.description,
                content: entry.content,
                id: entry.id,
            }));
            setActiveTemplatesGridData(gridD);
        } catch (err) {
            const [errorList] = axiosErrorHandler(err);
            setGeneralErrorList(errorList);
        }
    }, [api]);

    const onApprove = useCallback(
        entry => {
            api.approveLiveChatTemplate(entry.id)
                .then(res => {
                    setSuccessMessage({ success: 'Successfully approved template change' });
                    setForAcceptanceGridData(
                        forAcceptanceStateRef.current.filter(i => i.id !== entry.id)
                    );
                    loadActivePhrasesContent();
                })
                .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setGeneralErrorList(errorList);
                });
        },
        [api, loadActivePhrasesContent]
    );

    const onReject = useCallback(
        entry => {
            api.rejectLiveChatTemplate(entry.id)
                .then(() => {
                    setSuccessMessage({ success: 'Successfully rejected template change' });
                    setForAcceptanceGridData(
                        forAcceptanceStateRef.current.filter(i => i.id !== entry.id)
                    );
                })
                .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setGeneralErrorList(errorList);
                });
        },
        [api]
    );

    const onPutForRemoval = useCallback(
        async template => {
            api.deleteLiveChatTemplateById(template.id)
                .then(res => {
                    setSuccessMessage({ success: 'Successfully put template for removal' });
                    let reviewEntry = {
                        name: res.data.name,
                        description: res.data.description,
                        content: res.data.content,
                        status: res.data.status,
                        id: res.data.id,
                    };
                    const updatedForDeleteData = [...forAcceptanceStateRef.current, reviewEntry];
                    setForAcceptanceGridData(updatedForDeleteData);
                })
                .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setGeneralErrorList(errorList);
                });
        },
        [api]
    );

    const closeEditDialog = () => {
        setVisibleEditDialog(false);
        setEditId(0);
        setContent('');
        setDescription('');
        setName('');
    };

    useEffect(() => {
        async function loadForApprovalLiveChatTemplates() {
            try {
                const res = await api.getPendingLiveChatTemplates();
                let gridD = res.data.map(entry => {
                    if (entry.status === 'NEW') entry.status = 'UPDATED';

                    return {
                        name: entry.name,
                        description: entry.description,
                        content: entry.content,
                        status: entry.status,
                        id: entry.id,
                    };
                });
                setForAcceptanceGridData(gridD);
            } catch (err) {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            }
        }
        loadForApprovalLiveChatTemplates();
    }, [api]);

    useEffect(() => {
        loadActivePhrasesContent();
    }, [loadActivePhrasesContent, api]);

    const onSubmit = async () => {
        api.createLiveChatTemplate({ name, description, content })
            .then(res => {
                setSuccessMessage({ success: 'Successfully added template for review' });
                onNameChange('');
                onContentChange('');
                onDescriptionChange('');
                let reviewEntry = {
                    name: res.data.name,
                    content: res.data.content,
                    description: res.data.description,
                    id: res.data.id,
                };
                const updatedReviewData = [...reviewGridData, reviewEntry];
                setForAcceptanceGridData(updatedReviewData);
            })
            .catch(err => {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            });
    };

    const onEditSubmit = async () => {
        api.updateLiveChatTemplateById(editId, { name, description, content })
            .then(res => {
                setSuccessMessage({ success: 'Successfully added template for edit' });
                onNameChange('');
                onContentChange('');
                onDescriptionChange('');
                setEditId(0);
                setVisibleEditDialog(false);

                let reviewEntry = {
                    name: res.data.name,
                    content: res.data.content,
                    description: res.data.description,
                    status: res.data.status,
                    id: res.data.id,
                };
                const updatedReviewData = [...reviewGridData, reviewEntry];
                setForAcceptanceGridData(updatedReviewData);
            })
            .catch(err => {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            });
    };

    const onNameChange = phrase => {
        setName(phrase);
        setFormIsDirty(true);
    };

    const onContentChange = phrase => {
        setContent(phrase);
        setFormIsDirty(true);
    };

    const onDescriptionChange = phrase => {
        setDescription(phrase);
        setFormIsDirty(true);
    };

    return {
        generalErrorList,
        successMessage,
        onSubmit,
        control,
        name,
        onNameChange,
        content,
        onContentChange,
        description,
        onDescriptionChange,
        reviewGridHeaders,
        reviewGridData,
        activeTemplatesGridHeaders,
        activeTemplatesGridData,
        visibleEditDialog,
        closeEditDialog,
        onEditSubmit,
        formIsDirty,
        editClickHandler,
        onPutForRemoval,
        onApprove,
        onReject,
    };
};

export default useTemplates;
