import { Dialog } from 'primereact/dialog';
import React from 'react';
import DatePicker from '../../components/UI/Control/DatePicker';
import TimePicker from '../../components/UI/Control/TimePicker';
import useLiveChatWorkingHours from '../../hooks/useLiveChatWorkingHours';

const WorkingHoursComponentDialog = ({
    setGeneralErrorList,
    setSuccessMessage,
    reload,
    dialogVisible,
    hideDialog,
}) => {
    const { control, onSubmit, fieldErrorFor, registerValidationFor } = useLiveChatWorkingHours({
        setGeneralErrorList,
        setSuccessMessage,
        reload,
        onSuccessfulSubmit: hideDialog,
    });

    return (
        <Dialog
            header="Create New Working Hours"
            visible={dialogVisible}
            style={{ width: '50vw' }}
            breakpoints={{ '960px': '75vw', '640px': '100vw' }}
            onHide={hideDialog}
            contentStyle={{ overflow: 'visible' }}
        >
            <form noValidate onSubmit={onSubmit}>
                <div className="container-fluid" style={{ maxWidth: '400px' }}>
                    <div className="row">
                        <div className="col-12">
                            <DatePicker
                                error={fieldErrorFor.date?.message}
                                registerRef={registerValidationFor.date}
                                label="Date"
                                type="datepicker"
                                name="date"
                                control={control}
                                appendTo={'self'}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <TimePicker
                                name="startHour"
                                label="From"
                                control={control}
                                registerRef={registerValidationFor.startHour}
                                error={fieldErrorFor.startHour?.message}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <TimePicker
                                name="endHour"
                                label="To"
                                control={control}
                                registerRef={registerValidationFor.endHour}
                                error={fieldErrorFor.endHour?.message}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 d-flex justify-content-end mt-4">
                        <button className="btn btn-primary mr-2" type="submit">
                            Send for Approval
                        </button>
                        <button
                            className="btn btn-danger mr-2"
                            onClick={e => {
                                e.preventDefault();
                                hideDialog();
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </form>
        </Dialog>
    );
};

export default WorkingHoursComponentDialog;
