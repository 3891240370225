import GeneralMessage from '../../components/UI/GeneralMessage';
import Grid from '../../components/UI/Grid/Grid';

const ActivePhrasesPanel = ({ hook }) => {
    const { successMessage, generalErrorList, activePhrasesGridHeaders, activePhrasesGridData } =
        hook;

    const element = (
        <div className="animated fadeIn">
            <GeneralMessage
                className="c-msg"
                successMessage={successMessage}
                errorList={generalErrorList}
            />

            <div className="container-fluid card p-4 mb-5">
                <div className="row">
                    <div className="col-12">
                        <h2 className="display-5">Active Multilingual phrases</h2>
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Grid columns={activePhrasesGridHeaders} data={activePhrasesGridData} />
                    </div>
                </div>
            </div>
        </div>
    );
    return element;
};

export default ActivePhrasesPanel;
