import React from 'react';
import { getContrast } from '../../../utils/colorUtils';
import styles from './Message.module.css';

const MessageBody = ({ content, bgColor, className, style, ...props }) => {
    const textColor = bgColor ? getContrast(bgColor) : 'black';
    return (
        <div
            className={`${styles.messageBody} ${className ? className : ''}`}
            style={{
                backgroundColor: bgColor ? bgColor : 'gray',
                color: textColor,
                ...style,
            }}
            {...props}
        >
            <span>{content}</span>
        </div>
    );
};

export default MessageBody;
