export const validateSsmlWrapperTag = value => {
    if (value.length < 2) {
        return;
    }
    // no SSML
    if (!value.includes("<")) {
        return;
    }
    const openSignPosition = value.indexOf("<");
    for (let i = openSignPosition; i < value.length; i++) {
        if (value[i] === ">" && !value.match(/^\<speak\>.*<\/speak>$/)) {
            return 'SSML is detected. Please press the button "Use SSML".';
        }
    }
};
