import { SHOW_TEST_INTENT_SIDEBAR } from '../actions/testIntentAction';

const INIT_STATE = {
    showSendText: false,
};

const testIntentReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SHOW_TEST_INTENT_SIDEBAR:
            return {
                ...state,
                showSendText: action.payload
            };
        default:
            return { ...state };
    }

};

export default testIntentReducer;
