import get from 'lodash/get';
import { Button } from 'primereact/button';
import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import Control from '../../components/UI/Control/Control';
import FormControl from '../../components/UI/Control/FormControl';
import Label from '../../components/UI/Control/Label';
import { AutoComplete } from 'primereact/autocomplete';
import FormButtonWrapper from '../../hoc/FormButtonWrapper';
import { Tooltip } from 'primereact/tooltip';

const WelcomeMessageButtonsResponse = ({
    registerValidationFor,
    errors,
    control,
    namePrefix,
    searchIntent,
    intentList,
}) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name: `${namePrefix}.content.buttons`,
    });

    return (
        <>
            <div className="row">
                <div className="col-12">
                    {Array.isArray(fields) && fields.length
                        ? fields.map((field, index) => (
                              <div className="row" key={field.id}>
                                  <div className="col-3">
                                      <Control
                                          error={get(
                                              errors,
                                              `${namePrefix}.content.buttons[${index}].text.message`,
                                              ''
                                          )}
                                          registerRef={registerValidationFor?.text}
                                          name={`${namePrefix}.content.buttons[${index}].text`}
                                          type="text"
                                          label="Label *"
                                          value={field?.text}
                                          placeholder="Button label (text)"
                                      />
                                  </div>
                                  <div className="col-4">
                                      <Control
                                          error={get(
                                              errors,
                                              `${namePrefix}.content.buttons[${index}].value.message`,
                                              ''
                                          )}
                                          registerRef={registerValidationFor?.value}
                                          name={`${namePrefix}.content.buttons[${index}].value`}
                                          type="text"
                                          label="Value"
                                          value={field?.value}
                                          placeholder="Button value (text)"
                                      />
                                  </div>
                                  <div className="col-4">
                                      <Controller
                                          defaultValue={field?.metadata}
                                          control={control}
                                          name={`${namePrefix}.content.buttons[${index}].metadata`}
                                          rules={registerValidationFor?.buttonMetadata()}
                                          render={({ onChange, onBlur, value, name }) => {
                                              const error = get(
                                                  errors,
                                                  `${namePrefix}.content.buttons[${index}].metadata.message`,
                                                  ''
                                              );
                                              return (
                                                  <FormControl>
                                                      <Label value="Metadata *">
                                                          <span
                                                              data-pr-tooltip="Only active intents. No spaces. Should end with #EN or #BG according to the language"
                                                              className="label-info ml-2"
                                                          >
                                                              <i
                                                                  className="fa fa-info"
                                                                  aria-hidden="true"
                                                              ></i>
                                                          </span>
                                                          <Tooltip
                                                              target={`.label-info`}
                                                              position={'top'}
                                                          />
                                                          <AutoComplete
                                                              className="d-block"
                                                              inputClassName={`form-control ${
                                                                  error ? ' invalid-input' : ''
                                                              }`}
                                                              placeholder="/intent_name#BG"
                                                              value={value}
                                                              suggestions={intentList}
                                                              field="name"
                                                              completeMethod={searchIntent}
                                                              onChange={e => {
                                                                  const newValue = e.value;
                                                                  onChange(
                                                                      typeof newValue === 'object'
                                                                          ? newValue.name
                                                                          : newValue
                                                                  );
                                                              }}
                                                          />
                                                          {error ? (
                                                              <p className="error-message">
                                                                  {error}
                                                              </p>
                                                          ) : null}
                                                      </Label>
                                                  </FormControl>
                                              );
                                          }}
                                      />
                                  </div>
                                  <div className="col-1">
                                      <FormButtonWrapper>
                                          <Button
                                              className="delete-btn d-block"
                                              onClick={() => remove(index)}
                                              icon="fa fa-trash-o"
                                          />
                                      </FormButtonWrapper>
                                  </div>
                              </div>
                          ))
                        : null}
                </div>
            </div>
            <div className="row">
                <div className="col-11"></div>
                <div className="col-1">
                    <Button
                        className="add-btn"
                        style={{ marginLeft: '-112px', minWidth: '150px' }}
                        onClick={() => {
                            append({ metadata: '', text: '', value: '' });
                        }}
                        icon="fa fa-plus-circle"
                        label="Add button"
                    />
                </div>
            </div>
        </>
    );
};

export default WelcomeMessageButtonsResponse;
