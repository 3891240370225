const intentReportFilterSchema = register => {
    return {
        dateFrom: () => register(),
        dateTo: () => register(),
        name: () => register(),
        direction: () => register(),
    };
};

export default intentReportFilterSchema;
