import React from "react";
import GeneralMessage from "../../components/UI/GeneralMessage";
import useChatBotSettings from "../../hooks/useChatBotSettings";
import BotResponseConfigHistoryPanel from "../../modules/botSettings/BotResponseConfigHistoryPanel";
import BotResponseConfigPanel from "../../modules/botSettings/BotResponseConfigPanel";
import BotResponseConfigReviewPanel from "../../modules/botSettings/BotResponseConfigReviewPanel";

const ChatBotSettings = ({
                             generalErrorList,
                             successMessage,
                             onSubmit,
                             control,
                             confidence,
                             onConfidenceChanged,
                             botResponsesOn,
                             onEnabledStateChanged,
                             isUpdateButtonDisabled,
                             reviewGridHeaders,
                             reviewGridData,
                             historyGridHeaders,
                             historyGridData,
                             forwarding,
                             onForwardingChanged,
                             textToSpeech,
                             onTextToSpeechChanged,
                         }) => {
    const hook = useChatBotSettings({
        generalErrorList,
        successMessage,
        onSubmit,
        control,
        confidence,
        onConfidenceChanged,
        botResponsesOn,
        onEnabledStateChanged,
        isUpdateButtonDisabled,
        reviewGridHeaders,
        reviewGridData,
        historyGridHeaders,
        historyGridData,
        forwarding,
        onForwardingChanged,
        textToSpeech,
        onTextToSpeechChanged,
    });

    return (
        <>
            <GeneralMessage
                className="c-msg"
                successMessage={hook.successMessage}
                errorList={hook.generalErrorList}
            />
            <BotResponseConfigPanel hook={hook} />
            <BotResponseConfigReviewPanel hook={hook} />
            <BotResponseConfigHistoryPanel hook={hook} />
        </>
    );
};

export default ChatBotSettings;
