import './Login.css';

const LoginFormWarningMessage = ({ message }) => {
    return message ? (
        <div>
            <div className="row mb-2">
                <div className="col-1">
                    <i className="fa fa-exclamation-triangle" />
                </div>
                <div className="col-10">
                    <div className="login-warning-message">{message} </div>
                </div>
            </div>
        </div>
    ) : (
        <div></div>
    );
};

export default LoginFormWarningMessage;
