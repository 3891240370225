import { Button } from 'primereact/button';
import React from 'react';
import Control from '../../components/UI/Control/Control';
import GeneralMessage from '../../components/UI/GeneralMessage';
import useLocationForm from '../../hooks/useLocationForm';

const LocationForm = ({ data, onSuccessfulSubmit, onCancel }) => {
    const { fieldErrorFor, registerValidationFor, generalErrorList, successMessage, onSubmit } =
        useLocationForm({
            data,
            onSuccessfulSubmit,
        });
    return (
        <form noValidate onSubmit={onSubmit}>
            <GeneralMessage
                className="c-msg"
                successMessage={successMessage}
                errorList={generalErrorList}
            />
            <input type="hidden" name="id" ref={registerValidationFor.id()} />
            <Control
                name="name"
                registerRef={registerValidationFor.name}
                label="Location Name"
                error={fieldErrorFor.name?.message}
            />
            <Control
                name="latitude"
                registerRef={registerValidationFor.latitude}
                label="Latitude"
                error={fieldErrorFor.latitude?.message}
            />
            <Control
                name="longitude"
                registerRef={registerValidationFor.longitude}
                label="Longitude"
                error={fieldErrorFor.longitude?.message}
            />
            <Button className="mr-2" label="Save" />
            <Button
                className="p-button-secondary"
                onClick={onCancel}
                label="Cancel"
                type="button"
            />
        </form>
    );
};

export default LocationForm;
