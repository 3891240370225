import { Button } from 'primereact/button';
import TextInput from '../../components/UI/Control/TextInput';
import GeneralMessage from '../../components/UI/GeneralMessage';

const ForbiddenPhraseForm = ({ hook }) => {
    const { generalErrorList, successMessage, onSubmit, forbiddenPhrase, onForbiddenPhraseChange } =
        hook;

    const formElement = (
        <div className="animated fadeIn">
            <GeneralMessage
                className="c-msg"
                successMessage={successMessage}
                errorList={generalErrorList}
            />
            <form noValidate id="profileInfoForm">
                <div className="container-fluid card p-4 mb-5 animated fadeIn">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="display-5">Add forbidden phrase</h2>
                            <hr />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            <TextInput
                                label="Forbidden Phrase"
                                info="The phrase that won't be permitted to use"
                                value={forbiddenPhrase}
                                onChange={onForbiddenPhraseChange}
                            />
                        </div>
                        <div className="col-2 d-flex align-items-center">
                            <Button
                                style={{ marginTop: '8px' }}
                                type="button"
                                className="add-btn"
                                icon="fa fa-plus"
                                tooltip={'Add phrase'}
                                label="Add"
                                disabled={!forbiddenPhrase}
                                tooltipOptions={{ position: 'top' }}
                                onClick={onSubmit}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );

    return formElement;
};

export default ForbiddenPhraseForm;
