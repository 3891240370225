import React from 'react';
import GeneralMessage from '../../components/UI/GeneralMessage';
import useLiveChatSettings from "../../hooks/useLiveChatSettings";
import LiveChatConfigPanel from "../../modules/liveChatConfiguration/LiveChatConfigPanel";
import LiveChatConfigReviewPanel from "../../modules/liveChatConfiguration/LiveChatConfigReviewPanel";
import LiveChatConfigHistoryPanel from "../../modules/liveChatConfiguration/LiveChatConfigHistoryPanel";

const LiveChatConfiguration = () => {
    const hook = useLiveChatSettings();

    return (
        <>
            <GeneralMessage
                className="c-msg"
                successMessage={hook.successMessage}
                errorList={hook.generalErrorList}
            />
            <LiveChatConfigPanel hook={hook} />
            <LiveChatConfigReviewPanel hook={hook} />
            <LiveChatConfigHistoryPanel hook={hook} />
        </>
    );
};

export default LiveChatConfiguration;
