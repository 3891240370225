import get from 'lodash/get';
import merge from 'lodash/merge';
import axiosErrorHandler from '../http/AxiosErrorHandler';
import Config from '../config/config';
export default class ReportsService {
    AVERAGE_BOT_MESSAGES_PER_CONVERSATION = 'averageBotMessagesPerConversation';
    AVERAGE_CUSTOMER_MESSAGES_PER_CONVERSATION = 'averageCustomerMessagesPerConversation';
    AVERAGE_CONVERSATIONS_DURATION = 'averageConversationDuration';
    AVERAGE_DURATION_OF_FINISHED_CONVERSATIONS = 'averageDurationOfFinishedConversations';
    AVERAGE_DURATION_OF_ABANDONED_CONVERSATIONS = 'averageDurationOfAbandonedConversations';
    AVERAGE_RESPONSE_TIME = 'averageResponseTime';
    AVERAGE_SPEECH_MESSAGE_RATIO = 'averageSpeechMessageRatio';
    AVERAGE_SPEECH_RECOGNITION_RATE = 'speechRecognitionRate';
    LEVEL_OF_SELF_SERVICE = 'levelOfSelfService';
    TOTAL_UNIQUE_USERS = 'totalUniqueUsers';
    TOTAL_ENGAGED_USERS = 'totalEngagedUsers';
    TOTAL_CHATBOT_MESSAGES = 'totalChatbotMessages';
    TOTAL_CUSTOMER_MESSAGES = 'totalCustomerMessages';
    TOTAL_CONVERSATIONS = 'totalConversations';
    TOTAL_REJECTED_CONVERSATIONS = 'totalRejectedConversations';
    TOTAL_SUCCESSFULL_CONVERSATIONS = 'totalSuccessfullyFinishedConversations';
    TOTAL_UNFINISHED_CONVERSATIONS = 'totalUnfinishedConversations';
    TOTAL_CONVERSATIONS_WITH_SPEECH_INPUT = 'totalConversationsWithSpeechInput';
    TOTAL_SPEECH_INPUTS = 'totalSpeechInputs';
    TOTAL_UNRECOGNIZED_SPEECH_INPUTS = 'totalUnrecognizedSpeechInputs';
    TOTAL_CONVERSATIONS_WITH_SPEECH_OUTPUT = 'totalConversationsWithSpeechOutput';
    TOTAL_SPEECH_OUTPUTS = 'totalSpeechOutputMessages';
    TOTAL_CHARS_IN_SPEECH_OUTPUTS = 'totalCharactersInSpeechOutputMessages';
    POSITIVE_RATE_COUNT = 'positiveRateCount';
    NEGATIVE_RATE_COUNT = 'negativeRateCount';

    mergeTotalsAndAveragesData = data => {
        // average stats are before the totals
        const averageStats = get(data, '[0].data', null);
        const totalStats = get(data, '[1].data', null);
        return merge(totalStats, averageStats);
    };

    // Tile unit and label based on category
    getTileDetails = type => {
        const obj = {};
        switch (type) {
            case this.TOTAL_UNIQUE_USERS:
                obj['label'] = 'Total number of users';
                obj['unit'] = 'users';
                break;
            case this.TOTAL_CHATBOT_MESSAGES:
                obj['label'] = 'Total number of chatbot messages';
                obj['unit'] = 'messages';
                break;
            case this.TOTAL_CUSTOMER_MESSAGES:
                obj['label'] = 'Total received customer messages';
                obj['unit'] = 'messages';
                break;
            case this.TOTAL_CONVERSATIONS:
                obj['label'] = 'Total number of conversations';
                obj['unit'] = 'conversations';
                break;
            case this.TOTAL_REJECTED_CONVERSATIONS:
                obj['label'] = 'Total rejected conversations';
                obj['unit'] = 'conversations';
                break;
            case this.TOTAL_SUCCESSFULL_CONVERSATIONS:
                obj['label'] = 'Successfully finished conversations';
                obj['unit'] = 'conversations';
                break;
            case this.TOTAL_UNFINISHED_CONVERSATIONS:
                obj['label'] = 'Total unfinished conversations';
                obj['unit'] = 'conversations';
                break;
            case this.TOTAL_ENGAGED_USERS:
                obj['label'] = 'Total engaged users';
                obj['unit'] = 'users';
                break;
            case this.AVERAGE_BOT_MESSAGES_PER_CONVERSATION:
                obj['label'] = 'Average number of chatbot messages per conversation';
                obj['unit'] = 'messages';
                break;
            case this.AVERAGE_CUSTOMER_MESSAGES_PER_CONVERSATION:
                obj['label'] = 'Average number of Customer messages per conversation';
                obj['unit'] = 'messages';
                break;
            case this.AVERAGE_CONVERSATIONS_DURATION:
                obj['label'] = 'Average duration of the conversations';
                obj['unit'] = 'sec';
                break;
            case this.AVERAGE_DURATION_OF_FINISHED_CONVERSATIONS:
                obj['label'] = 'Average duration of the successfully finished conversation';
                obj['unit'] = 'sec';
                break;
            case this.AVERAGE_DURATION_OF_ABANDONED_CONVERSATIONS:
                obj['label'] = 'Average duration of the conversations not finished by the chatbot';
                obj['unit'] = 'sec';
                break;
            case this.AVERAGE_RESPONSE_TIME:
                obj['label'] = 'Average response time';
                obj['unit'] = 'sec';
                break;
            case this.POSITIVE_RATE_COUNT:
                obj['label'] = 'Count positive rate';
                obj['unit'] = 'messages';
                break;
            case this.NEGATIVE_RATE_COUNT:
                obj['label'] = 'Count negative rate';
                obj['unit'] = 'messages';
                break;
            case this.AVERAGE_SPEECH_MESSAGE_RATIO:
                obj['label'] = 'Average speech messages in conversations with speech';
                obj['unit'] = 'messages';
                break;
            case this.AVERAGE_SPEECH_RECOGNITION_RATE:
                obj['label'] = 'Rate of understood speech messages';
                obj['unit'] = 'messages';
                break;
            case this.TOTAL_CONVERSATIONS_WITH_SPEECH_INPUT:
                obj['label'] = 'Total number of conversations with speech input';
                obj['unit'] = 'conversations';
                break;
            case this.TOTAL_SPEECH_INPUTS:
                obj['label'] = 'Total number of speech inputs';
                obj['unit'] = 'messages';
                break;
            case this.TOTAL_UNRECOGNIZED_SPEECH_INPUTS:
                obj['label'] = 'Total number of unrecognized speech inputs';
                obj['unit'] = 'messages';
                break;
            case this.TOTAL_CONVERSATIONS_WITH_SPEECH_OUTPUT:
                obj['label'] = 'Total number of conversations with speech output';
                obj['unit'] = 'conversations';
                break;
            case this.TOTAL_SPEECH_OUTPUTS:
                obj['label'] = 'Total number of speech outputs';
                obj['unit'] = 'messages';
                break;
            case this.TOTAL_CHARS_IN_SPEECH_OUTPUTS:
                obj['label'] = 'Number of characters read via Text-to-speech';
                obj['unit'] = '';
                break;
            default:
                obj['label'] = '';
                obj['unit'] = '';
                break;
        }

        return obj;
    };

    downloadBlobFileWithLink = (response, name, setSpinnerEffect) => {
        try {
            const blob = new Blob([response.data]);
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            let fileName = '';
            const contentDisposition = response.headers['content-disposition'];
            if (contentDisposition) {
                fileName = contentDisposition.substring(
                    contentDisposition.indexOf('filename=') + 9
                );
            } else if (name) {
                fileName = name;
            } else {
                fileName = 'file';
            }
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(downloadUrl);
            if (setSpinnerEffect) {
                setSpinnerEffect(false);
            }
        } catch (error) {
            if (!Config.isProduction) {
                console.log('TODO collect errors', error);
            }
        } finally {
            if (setSpinnerEffect) {
                setSpinnerEffect(false);
            }
        }
    };

    handleBlobError = (error, setGeneralErrorList, setSpinnerEffect) => {
        const handleError = error => {
            const [errorList] = axiosErrorHandler(error);
            setGeneralErrorList(errorList);
            if (setSpinnerEffect) {
                setSpinnerEffect(false);
            }
        };

        if (error.response && error.response.data) {
            const blob = new Blob([error.response.data], { type: 'text/plain; charset=utf-8' });
            blob.text()
                .then(textResponse => JSON.parse(textResponse))
                .then(resp => {
                    error.response.data = { ...error.response.data, message: resp.message };
                    handleError(error);
                });
        } else {
            handleError(error);
        }
    };
}
