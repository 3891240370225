import AuthService from '../services/auth.service';
import HttpRequests from './HttpRequests';
import ServerApis from './ServerApis';

export default class ApiRequests {
    http = new HttpRequests();
    serverApis = new ServerApis();
    auth = new AuthService();

    logIn = data => {
        return this.http.post(this.serverApis.logIn(), data);
    };
    logOut = () => {
        return this.http.get(this.serverApis.logOut());
    };
    userInfo = () => {
        return this.http.get(this.serverApis.userInfo());
    };
    getIntentList = () => {
        return this.http.get(this.serverApis.getIntentList());
    };
    getIntentAnswerSearchResults = data => {
        return this.http.post(this.serverApis.intentAnswerSearch(), data);
    };
    approveIntentAnswers = data => {
        return this.http.post(this.serverApis.approveIntentAnswers(data), data);
    };
    rejectIntentAnswers = data => {
        return this.http.post(this.serverApis.rejectIntentAnswers(data), data);
    };

    approveAllIntentAnswers = () => this.http.put(this.serverApis.approveAllIntentAnswers());

    rejectAllIntentAnswers = () => this.http.put(this.serverApis.rejectAllIntentAnswers());

    addIntent = data => {
        return this.http.post(this.serverApis.addIntent(), data);
    };
    editIntentAnswers = data => {
        return this.http.post(this.serverApis.editIntentAnswers(), data);
    };
    deleteIntentAnswer = complexId => {
        return this.http.delete(this.serverApis.deleteIntentAnswer(complexId));
    };
    getIntentAnswer = id => {
        return this.http.get(this.serverApis.getIntentAnswer(id));
    };
    getIntentAnswerAudioTrack = (textToSpeech, language) =>
        this.http.post(
            this.serverApis.getTextToSpeechAudioTrack(),
            { text: textToSpeech, language: language },
            {
                responseType: "blob",
            }
        );

    delIntent = intentId => {
        return this.http.delete(this.serverApis.delIntent(intentId));
    };

    updateIntentName = (intentId, data) => {
        return this.http.put(this.serverApis.updateIntent(intentId), data);
    };

    searchIntent = data => this.http.post(this.serverApis.searchIntent(), data);

    updateIntentEnableStatus = (intentId, enableStatus) => {
        return this.http.put(
            this.serverApis.changeIntentEnableStatus(intentId, enableStatus),
            null
        );
    };

    approveIntent = id => {
        return this.http.put(this.serverApis.approveIntent(id), id);
    };

    rejectIntent = id => {
        return this.http.put(this.serverApis.rejectIntent(id), id);
    };

    approveAllIntents = () => this.http.put(this.serverApis.approveAllIntents());

    rejectAllIntents = () => this.http.put(this.serverApis.rejectAllIntents());

    getIntentExampleList = intentId => {
        return this.http.get(this.serverApis.getIntentExampleList(intentId));
    };
    addIntentExample = data => {
        return this.http.post(this.serverApis.addIntentExample(), data);
    };
    delExample = exampleId => {
        return this.http.delete(this.serverApis.delExample(exampleId));
    };

    approveExample = id => {
        return this.http.put(this.serverApis.approveExample(id), id);
    };

    rejectExample = id => {
        return this.http.put(this.serverApis.rejectExample(id), id);
    };

    approveAllExamples = () => this.http.put(this.serverApis.approveAllExamples());

    rejectAllExamples = () => this.http.put(this.serverApis.rejectAllExamples());

    getKeywordList = () => {
        return this.http.get(this.serverApis.getKeywordList());
    };
    addKeyword = data => {
        return this.http.post(this.serverApis.addKeyword(), data);
    };
    delKeyword = intentId => {
        return this.http.delete(this.serverApis.delKeyword(intentId));
    };

    approveKeyword = id => {
        return this.http.put(this.serverApis.approveKeyword(id), id);
    };

    rejectKeyword = id => {
        return this.http.put(this.serverApis.rejectKeyword(id), id);
    };

    approveAllKeywords = () => this.http.put(this.serverApis.approveAllKeywords());

    rejectAllKeywords = () => this.http.put(this.serverApis.rejectAllKeywords());

    getKeywordSynonymList = intentId => {
        return this.http.get(this.serverApis.getKeywordSynonymList(intentId));
    };
    getAuditData = data => {
        return this.http.post(this.serverApis.getAuditData(), data);
    };
    addKeywordSynonym = data => {
        return this.http.post(this.serverApis.addKeywordSynonym(), data);
    };
    delSynonym = exampleId => {
        return this.http.delete(this.serverApis.delSynonym(exampleId));
    };

    approveSynonym = id => {
        return this.http.put(this.serverApis.approveSynonym(id), id);
    };

    rejectSynonym = id => {
        return this.http.put(this.serverApis.rejectSynonym(id), id);
    };

    approveAllSynonyms = () => this.http.put(this.serverApis.approveAllSynonyms());

    rejectAllSynonyms = () => this.http.put(this.serverApis.rejectAllSynonyms());

    getNotApprovedInt = data => {
        return this.http.post(this.serverApis.getNotApprovedInt(), data);
    };
    getNotApprovedSQ = data => {
        return this.http.post(this.serverApis.getNotApprovedSQ(), data);
    };
    getNotApprovedKw = data => {
        return this.http.post(this.serverApis.getNotApprovedKw(), data);
    };
    getNotApprovedSyn = data => {
        return this.http.post(this.serverApis.getNotApprovedSyn(), data);
    };
    getConversations = data => {
        return this.http.post(this.serverApis.getConversations(), data);
    };

    getConversationMessages = conversationId => {
        return this.http.get(this.serverApis.getConversationMessages(conversationId));
    };
    getUserGroups = () => {
        return this.http.get(this.serverApis.getUserGroups());
    };
    createUserGroup = data => {
        return this.http.post(this.serverApis.createUserGroup(), data);
    };
    deleteUserGroup = id => {
        return this.http.delete(this.serverApis.deleteUserGroup(id));
    };
    grantPermissions = (id, data) => {
        return this.http.post(this.serverApis.grantPermissions(id), data);
    };
    getAveragesReport = data => {
        return this.http.post(this.serverApis.getAveragesReport(), data);
    };
    getTotalStats = data => {
        return this.http.post(this.serverApis.getTotalStats(), data);
    };

    startBotTraining = () => {
        return this.http.post(this.serverApis.startBotTraining(), null);
    };
    getBotTrainingStatus = () => {
        return this.http.get(this.serverApis.getBotTrainingStatus());
    };
    getWelcomeMessages = data => {
        return this.http.post(this.serverApis.getWelcomeMessages(), data);
    };
    createOrUpdateWelcomeMessage = data => {
        return this.http.post(this.serverApis.welcomeMessages(), data);
    };
    approveWelcomeMessage = id => {
        return this.http.get(this.serverApis.approveWelcomeMessage(id));
    };
    rejectWelcomeMessage = id => {
        return this.http.get(this.serverApis.rejectWelcomeMessage(id));
    };
    deleteWelcomeMessage = id => {
        return this.http.delete(this.serverApis.welcomeMessage(id));
    };
    getCurrentBotConfiguration = () => {
        return this.http.get(this.serverApis.getCurrentBotConfiguration());
    };
    updateBotConfiguration = data => {
        return this.http.post(this.serverApis.updateBotConfiguration(), data);
    };
    getPendingBotConfigurations = () => {
        return this.http.get(this.serverApis.getPendingBotConfigurations());
    };
    approveBotConfiguration = id => {
        return this.http.put(this.serverApis.approveBotConfiguration(id), id);
    };
    rejectBotConfiguration = id => {
        return this.http.put(this.serverApis.rejectBotConfiguration(id), id);
    };
    getBotConfigurationHistory = () => {
        return this.http.get(this.serverApis.getBotConfigurationHistory());
    };
    getAppFeatures = () => {
        return this.http.get(this.serverApis.getAppFeatures());
    };
    deleteCoordinate = id => {
        return this.http.delete(this.serverApis.deleteCoordinates(id));
    };
    getApprovedCoordinates = () => {
        return this.http.get(this.serverApis.getApprovedCoordinates());
    };
    getPendingCoordinates = () => {
        return this.http.get(this.serverApis.getPendingCoordinates());
    };
    reviewPendingCoordinates = approve => {
        return this.http.get(this.serverApis.reviewPendingCoordinates(approve), null);
    };
    configureCoordinates = form => {
        return this.http.post(this.serverApis.configureCoordinates(), form);
    };
    createLocation = data => this.http.post(this.serverApis.createLocation(), data);
    updateLocation = data => this.http.put(this.serverApis.updateLocation(data.id), data);
    approvePendingLocation = id => this.http.get(this.serverApis.approvePendingLocation(id));
    rejectPendingLocation = id => this.http.get(this.serverApis.rejectPendingLocation(id));
    getPredefinedMessages = () => {
        return this.http.get(this.serverApis.getPredefinedMessages());
    };
    editPredefinedMessage = data => {
        return this.http.post(this.serverApis.editPredefinedMessage(), data);
    };
    approvePendingPredefinedMessage = id => {
        return this.http.put(this.serverApis.approvePendingPredefinedMessage(id));
    };
    rejectPendingPredefinedMessage = id => {
        return this.http.delete(this.serverApis.deletePendingPredefinedMessage(id));
    };
    sendTextToProcess = data => {
        return this.http.post(this.serverApis.processText(), data);
    };
    getLastApprovedTariff = () => {
        return this.http.get(this.serverApis.getLastApprovedTariff());
    };
    uploadTariff = data => {
        return this.http.post(this.serverApis.uploadTariff(), data);
    };
    getAllPendingTariffData = () => {
        return this.http.get(this.serverApis.getAllPendingTariffData());
    };
    approvePendingTariff = id => {
        return this.http.put(this.serverApis.approvePendingTariff(id));
    };
    rejectPendingTariff = id => {
        return this.http.delete(this.serverApis.rejectPendingTariff(id));
    };
    uploadDataFiles = data => {
        return this.http.post(this.serverApis.uploadDataFiles(), data);
    };
    downloadNluData = () => {
        return this.http.get(this.serverApis.downloadNluData());
    };
    downloadResponseData = () => {
        return this.http.get(this.serverApis.downloadResponseData());
    };

    downloadModelData = () =>
        this.http.get(this.serverApis.downloadModelData(), {
            responseType: "arraybuffer",
        });

    getPendingModels = () => this.http.get(this.serverApis.getPendingModels());

    approvePendingModel = id => this.http.put(this.serverApis.approvePendingModel(id));

    rejectPendingModel = id => this.http.put(this.serverApis.rejectPendingModel(id));

    fetchIntentReport = data => this.http.post(this.serverApis.fetchIntentReport(), data);

    getIntentMessages = data => this.http.post(this.serverApis.getIntentMessages(data?.id), data);

    downloadSummaryReportAsExcel = data => {
        return this.http.post(this.serverApis.downloadSummaryReport(), data, {
            responseType: "blob",
        });
    };

    downloadConversationReportAsExcel = data => {
        return this.http.post(this.serverApis.downloadConversationReport(), data, {
            responseType: "blob",
        });
    };

    getForwardedUnacceptedConversations = () => {
        return this.http.get(this.serverApis.getForwardedUnacceptedConversations());
    };

    getAssignedConversations = () => {
        return this.http.get(this.serverApis.getAssignedConversations());
    };

    assignForwardedConversation = (id, data) => {
        return this.http.post(this.serverApis.assignForwardedConversation(id, data.operator), null);
    };

    acceptForwardedConversation = id => {
        return this.http.post(this.serverApis.acceptForwardedConversation(id), null);
    };

    endConversation = id => this.http.delete(this.serverApis.endConversation(id));

    getLiveChatMessages = data => this.http.post(this.serverApis.getLiveChatMessages(), data);

    sendLiveChatMessage = data =>
        this.http.post(this.serverApis.sendLiveChatMessage(), data, {
            headers: { "X-message-sender": "ADMIN" },
        });

    getCurrentUser = () => {
        return this.http.get(this.serverApis.getCurrentUserInfo(), null);
    };

    createUser = () => {
        return this.http.post(this.serverApis.createUser(), null);
    };

    getUserById = id => {
        return this.http.get(this.serverApis.getUserById(id), null);
    };

    getUsersByPeriod = data => {
        return this.http.post(this.serverApis.getUsersByPeriod(), data);
    };

    updateUserInfo = (id, data) => {
        return this.http.put(this.serverApis.updateUserInfo(id), data);
    };

    approveUserUpdate = id => {
        return this.http.put(this.serverApis.approveUserInfoUpdate(id), null);
    };

    rejectUserUpdate = id => {
        return this.http.put(this.serverApis.rejectUserInfoUpdate(id), null);
    };

    getPendingUserChanges = () => {
        return this.http.get(this.serverApis.getAllPendingUsers(), null);
    };

    searchForUsers = data => {
        return this.http.post(this.serverApis.searchForUser(), data);
    };

    getUserStatusHistory = data => {
        return this.http.post(this.serverApis.getUserStatusHistory(), data);
    };

    getCurrentLiveChatConfiguration = () => {
        return this.http.get(this.serverApis.getCurrentLiveChatConfiguration());
    };
    updateLiveChatConfiguration = data => {
        return this.http.post(this.serverApis.updateLiveChatConfiguration(), data);
    };
    getPendingLiveChatConfigurations = () => {
        return this.http.get(this.serverApis.getPendingLiveChatConfigurations());
    };
    approveLiveChatConfiguration = id => {
        return this.http.put(this.serverApis.approveLiveChatConfiguration(id), id);
    };
    rejectLiveChatConfiguration = id => {
        return this.http.put(this.serverApis.rejectLiveChatConfiguration(id), id);
    };
    getLiveChatConfigurationHistory = () => {
        return this.http.get(this.serverApis.getLiveChatConfigurationHistory());
    };
    getAllApprovedLiveChatWorkingHours = data =>
        this.http.post(this.serverApis.getAllApprovedLiveChatWorkingHours(), data);
    getAllPendingLiveChatWorkingHours = () =>
        this.http.get(this.serverApis.getAllPendingLiveChatWorkingHours());
    createLiveChatWorkingHours = data =>
        this.http.post(this.serverApis.createLiveChatWorkingHours(), data);
    getLiveChatWorkingHours = id => this.http.get(this.serverApis.getLiveChatWorkingHours(id));
    updateLiveChatWorkingHours = data =>
        this.http.put(this.serverApis.updateLiveChatWorkingHours(data.id), data);
    deleteLiveChatWorkingHours = id =>
        this.http.delete(this.serverApis.deleteLiveChatWorkingHours(id));
    approveLiveChatWorkingHours = id =>
        this.http.get(this.serverApis.approveLiveChatWorkingHours(id));
    rejectLiveChatWorkingHours = id =>
        this.http.get(this.serverApis.rejectLiveChatWorkingHours(id));

    getForbiddenPhrases = () => this.http.get(this.serverApis.forbiddenPhrases());

    addForbiddenPhrase = data => this.http.post(this.serverApis.forbiddenPhrases(), data);

    approveForbiddenPhrase = id => this.http.post(this.serverApis.approveForbiddenPhrase(id), null);

    rejectForbiddenPhrase = id => this.http.delete(this.serverApis.rejectForbiddenPhrase(id));

    approveDeletingForbiddenPhrase = id =>
        this.http.delete(this.serverApis.approveDeletingForbiddenPhrase(id));

    rejectDeletingForbiddenPhrase = id =>
        this.http.put(this.serverApis.rejectDeletingForbiddenPhrase(id), null);

    requestToDeleteForbiddenPhrase = id =>
        this.http.put(this.serverApis.requestDeletingForbiddenPhrase(id), null);

    getForbiddenPhrasesToApprove = () => this.http.get(this.serverApis.phrasesToAprrove());

    getForbiddenPhrasesToRemove = () => this.http.get(this.serverApis.phrasesToRemove());

    getMultilingualPhrases = () => this.http.get(this.serverApis.multilingualPhrases());

    addMultilingualPhrase = data =>
        this.http.post(this.serverApis.multilingualPhrases(), data, {
            headers: { "content-type": "text/plain" },
        });

    approveMultilingualPhrase = id =>
        this.http.post(this.serverApis.approveMultilingualPhrase(id), null);

    rejectMultilingualPhrase = id => this.http.delete(this.serverApis.rejectMultilingualPhrase(id));

    approveDeletingMultilingualPhrase = id =>
        this.http.delete(this.serverApis.approveDeletingMultilingualPhrase(id));

    rejectDeletingMultilingualPhrase = id =>
        this.http.put(this.serverApis.rejectDeletingMultilingualPhrase(id), null);

    requestToDeleteMultilingualPhrase = id =>
        this.http.put(this.serverApis.requestDeletingMultilingualPhrase(id), null);

    getMultilingualPhrasesToApprove = () =>
        this.http.get(this.serverApis.multilingualPhrasesToAprrove());

    getMultilingualPhrasesToRemove = () =>
        this.http.get(this.serverApis.multilingualPhrasesToRemove());

    getLiveChatTemplates = () => this.http.get(this.serverApis.liveChatTemplates());

    createLiveChatTemplate = data => this.http.post(this.serverApis.liveChatTemplates(), data);

    getLiveChatTemplateById = id => this.http.get(this.serverApis.liveChatTemplate(id));

    updateLiveChatTemplateById = (id, data) =>
        this.http.put(this.serverApis.liveChatTemplate(id), data);

    deleteLiveChatTemplateById = id => this.http.delete(this.serverApis.liveChatTemplate(id));

    getPendingLiveChatTemplates = () => this.http.get(this.serverApis.pendingLiveChatTemplates());

    approveLiveChatTemplate = id =>
        this.http.get(this.serverApis.approvePendingLiveChatTemplate(id));

    rejectLiveChatTemplate = id => this.http.get(this.serverApis.rejectPendingLiveChatTemplate(id));

    searchLiveChatTemplate = data => this.http.post(this.serverApis.searchLiveChatTemplates(data));

    getLiveChatBans = () => this.http.get(this.serverApis.getLiveChatBans());
    createLiveChatBan = data => this.http.post(this.serverApis.createLiveChatBan(), data);
    updateLiveChatBan = data => this.http.put(this.serverApis.updateLiveChatBan(data.id), data);
    deleteLiveChatBan = id => this.http.delete(this.serverApis.deleteLiveChatBan(id));

    getLiveChatReportForSupervisor = data => {
        return this.http.post(this.serverApis.getLiveChatReportForSupervisor(), data);
    };
    exportLiveChatReportForSupervisor = data =>
        this.http.post(this.serverApis.exportLiveChatReportForSupervisor(), data, {
            responseType: "blob",
        });
    getLiveChatReportForOperator = data =>
        this.http.post(this.serverApis.getLiveChatReportForOperator(), data);
    exportLiveChatReportForOperator = data =>
        this.http.post(this.serverApis.exportLiveChatReportForOperator(), data, {
            responseType: "blob",
        });
    getLiveChatSummaryReportForAllOperators = data => {
        return this.http.post(this.serverApis.getLiveChatSummaryReportForAllOperators(), data);
    };
    exportLiveChatSummaryReportForAllOperators = data =>
        this.http.post(this.serverApis.exportLiveChatSummaryReportForAllOperators(), data, {
            responseType: "blob",
        });
    pingServer = () => this.http.get(this.serverApis.pingServer());
    getIntentsWithoutAnswers = lang =>
        this.http.get(this.serverApis.getIntentsWithoutAnswers(lang));
    getWrongMetadataButtons = () => this.http.get(this.serverApis.getWrongMetadataButtons());
    getIntentsWithOneExample = () => this.http.get(this.serverApis.getIntentsWithOneExample());
    getIntentsWithoutExamples = () => this.http.get(this.serverApis.getIntentsWithoutExamples());
    getIntentsWithDuplicateExamples = () =>
        this.http.get(this.serverApis.getIntentsWithDuplicateExamples());
    getSynonymsWithoutExamples = () => this.http.get(this.serverApis.getSynonymsWithoutExamples());
}
