import GeneralMessage from '../../components/UI/GeneralMessage';
import Grid from '../../components/UI/Grid/Grid';

const LiveChatConfigHistoryPanel = ({ hook }) => {
    const { successMessage, generalErrorList, historyGridHeaders, historyGridData } = hook;

    return (
        <div className="animated fadeIn">
            <GeneralMessage
                className="c-msg"
                successMessage={successMessage}
                errorList={generalErrorList}
            />

            <div className="container-fluid card p-4 mb-5 animated fadeIn">
                <div className="row">
                    <div className="col-12">
                        <h2 className="display-5">History</h2>
                        <hr/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Grid columns={historyGridHeaders} data={historyGridData}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LiveChatConfigHistoryPanel;
