import React from 'react';

const LiveChatTemplateCard = ({ name, description, ...otherProps }) => {
    const trimDescription = description => {
        if (typeof description === 'string') {
            if (description.length === 0) {
                return 'No description available.';
            } else if (description.length > 30) {
                return `${description.substring(0, 30)}...`;
            } else {
                return description;
            }
        } else {
            return description;
        }
    };
    return (
        <div
            {...otherProps}
            className={`d-flex flex-column ${otherProps.className ? otherProps.className : ''}`}
        >
            <span className="live-chat-selected-template-name mx-2 mt-1">{name}</span>
            <span className="live-chat-selected-template-description mx-2">
                {trimDescription(description)}
            </span>
        </div>
    );
};

export default LiveChatTemplateCard;
