import get from 'lodash/get';
import GlobalErrorMessages from '../config/globalErrorMessages';
import Config from '../config/config';

//returns [errorList, errorResponse]
const axiosErrorHandler = error => {
    if (error.response) {
        // client received an error response (5xx, 4xx)
        const httpCode = get(error.response, 'status', 0);
        switch (httpCode) {
            case 400:
            case 404:
            case 403:
                let errorMessages = get(error, 'response.data.errors', []);
                if (!errorMessages.length) {
                    errorMessages = [get(error, 'response.data.message', '')];
                }
                if (!errorMessages.length) {
                    errorMessages = [GlobalErrorMessages.SOMETHING_WENT_WRONG];
                }
                return [errorMessages, error.response];
            default:
                return [[GlobalErrorMessages.SOMETHING_WENT_WRONG], error.response];
        }
    } else if (error.request) {
        // client never received a response, or request never left
        return [[GlobalErrorMessages.SERVER_COMMUNICATION_FAILURE], null];
    } else {
        // anything else
        if (!Config.isProduction) {
            console.log('unknown error:', error);
        }
        return [[GlobalErrorMessages.SOMETHING_WENT_WRONG], null];
    }
};

export default axiosErrorHandler;
