import { Button } from 'primereact/button';
import Control from '../../components/UI/Control/Control';
import GeneralMessage from '../../components/UI/GeneralMessage';

const LiveChatConfigPanel = ({ hook }) => {
    const {
        onSubmit,
        successMessage,
        generalErrorList,
        chatWaitingPeriod,
        setChatWaitingPeriod,
        chatTimeout,
        setChatTimeout,
        ongoingChatTimeout,
        setOngoingChatTimeout,
        simultaneousChats,
        setSimultaneousChats,
        isUpdateButtonDisabled,
    } = hook;

    return (
        <div className="animated fadeIn">
            <GeneralMessage
                className="c-msg"
                successMessage={successMessage}
                errorList={generalErrorList}
            />
            <form noValidate id="botSettingsForm">
                <div className="container-fluid card p-4 mb-5 animated fadeIn">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="display-5">Configuration</h2>
                            <hr />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            <Control
                                name="simultaneousChats"
                                type="number"
                                label="Simultaneous Chats"
                                info="Number of simultaneous chats allowed for an operator"
                                value={simultaneousChats}
                                min={0}
                                max={Number.MAX_SAFE_INTEGER}
                                onChange={e => setSimultaneousChats(parseInt(e.target.value))}
                            />
                        </div>
                        <div className="col-2">
                            <Control
                                name="chatWaitingPeriod"
                                type="number"
                                label="Chat Waiting Period"
                                info="Value in seconds after which bot returns a message to the customer that the staff is busy"
                                value={chatWaitingPeriod}
                                step={100}
                                min={0}
                                max={Number.MAX_SAFE_INTEGER}
                                onChange={e => setChatWaitingPeriod(parseInt(e.target.value))}
                            />
                        </div>
                        <div className="col-2">
                            <Control
                                name="chatTimeout"
                                type="number"
                                label="Chat Timeout"
                                info="Timeout period (in seconds) after which the chat is terminated if it's not responded by the operator"
                                value={chatTimeout}
                                step={100}
                                min={0}
                                max={Number.MAX_SAFE_INTEGER}
                                onChange={e => setChatTimeout(parseInt(e.target.value))}
                            />
                        </div>
                        <div className="col-2">
                            <Control
                                name="ongoingChatTimeout"
                                type="number"
                                label="Ongoing Chat Timeout"
                                info="Timeout period (in seconds) after which the chat is terminated after client does not reply"
                                value={ongoingChatTimeout}
                                step={100}
                                min={0}
                                max={Number.MAX_SAFE_INTEGER}
                                onChange={e => setOngoingChatTimeout(parseInt(e.target.value))}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            <Button
                                type="button"
                                className="add-btn"
                                icon="fa fa-check"
                                label="UPDATE"
                                onClick={onSubmit}
                                disabled={isUpdateButtonDisabled()}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default LiveChatConfigPanel;
