import { Button } from 'primereact/button';
import RoboadvisorService from '../../services/roboadvisor.service';
import React from 'react';

const BotTrainingStatusPanel = props => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h1 className="display-4">Bot training status</h1>
                    </div>
                </div>
            </div>
            <div className="container-fluid card p-4 animated fadeIn">
                <div className="row">
                    <div className="col-6">
                        <h2 className="display-5 mb-4">
                            Bot training is currently{' '}
                            <strong
                                className={
                                    props.trainingInProgress || !props.trainingEnabled
                                        ? 'training-inprogress'
                                        : 'training-available'
                                }
                            >
                                {!props.trainingEnabled
                                    ? 'DISABLED'
                                    : props.trainingInProgress
                                    ? ' IN PROGRESS. '
                                    : ' AVAILABLE. '}
                            </strong>
                            {props.trainingElapsedTime
                                ? `Process started ${props.trainingElapsedTime} second(s) ago.`
                                : ''}
                        </h2>
                    </div>
                    <div className="col-6">
                        {props.loadedModelApprovedBy && props.loadedModelApprovedTs ? (
                            <h2 className="display-5 mb-4">
                                Currently loaded model: {props.currentlyLoadedModel} at{' '}
                                {RoboadvisorService.formatDateTime(
                                    props.loadedModelApprovedTs,
                                    RoboadvisorService.dateTimeMomentFormatMinutes
                                )}{' '}
                                by {props.loadedModelApprovedBy}
                            </h2>
                        ) : props.getLastTrainingTime() ? (
                            <h2 className="display-5 mb-4">
                                Previous training has been started by {props.lastTrainingExecutor}{' '}
                                at {props.getLastTrainingTime()} and it took{' '}
                                {props.lastTrainingDuration} second(s) to complete.
                            </h2>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
                {props.trainingEnabled ? (
                    <div className="row">
                        <div className="col-2">
                            <Button
                                type="button"
                                className={props.trainingInProgress ? 'delete-btn' : 'add-btn'}
                                label="Start training"
                                tooltip={'Start training'}
                                tooltipOptions={{ position: 'top' }}
                                onClick={() => props.confirmStartBotTraining()}
                                disabled={props.trainingInProgress}
                            />
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>
        </>
    );
};

export default BotTrainingStatusPanel;
