import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import labels from '../constants/labels';
import ApiRequests from '../http/ApiRequests';
import axiosErrorHandler from '../http/AxiosErrorHandler';
import { validator } from '../services/validationService';
import { parseTime, toLocalTime } from '../utils/dateTimeUtils';

const useLiveChatWorkingHours = ({
    data,
    setGeneralErrorList,
    setSuccessMessage,
    reload,
    onSuccessfulSubmit,
}) => {
    const [api] = useState(new ApiRequests());
    const { control, register, setValue, handleSubmit, getValues, errors } = useForm({});
    const { id, startHour, endHour, dayOfWeek, date } = data
        ? data
        : { id: null, dayOfWeek: null, startHour: null, endHour: null, date: null };

    const updateOrCreateWorkingHoursMethod = id
        ? api.updateLiveChatWorkingHours
        : api.createLiveChatWorkingHours;
    const updateOrCreateWorkingHours = useCallback(
        data => {
            const date = data.date
                ? new Date(
                      Date.UTC(data.date.getFullYear(), data.date.getMonth(), data.date.getDate())
                  )
                : null;
            updateOrCreateWorkingHoursMethod({
                startHour: toLocalTime(data.startHour),
                endHour: toLocalTime(data.endHour),
                id: data.id,
                date: date,
                dayOfWeek: data.dayOfWeek,
            })
                .then(response => {
                    setSuccessMessage({ success: labels.CHANGES_ARE_SAVED });
                    if (reload) reload();
                    if (onSuccessfulSubmit) onSuccessfulSubmit();
                })
                .catch(error => {
                    const [errorList] = axiosErrorHandler(error);
                    setGeneralErrorList(errorList);
                });
        },
        [
            onSuccessfulSubmit,
            reload,
            setGeneralErrorList,
            setSuccessMessage,
            updateOrCreateWorkingHoursMethod,
        ]
    );
    const deleteWorkingHours = useCallback(
        id => {
            api.deleteLiveChatWorkingHours(id)
                .then(() => {
                    setSuccessMessage({ success: labels.CHANGES_ARE_SAVED });
                    if (reload) reload();
                    if (onSuccessfulSubmit) onSuccessfulSubmit();
                })
                .catch(error => {
                    const [errorList] = axiosErrorHandler(error);
                    setGeneralErrorList(errorList);
                });
        },
        [api, onSuccessfulSubmit, reload, setGeneralErrorList, setSuccessMessage]
    );
    const approve = useCallback(
        id =>
            api
                .approveLiveChatWorkingHours(id)
                .then(response => {
                    setSuccessMessage({ success: labels.CHANGES_ARE_SAVED });
                    if (reload) reload();
                })
                .catch(error => {
                    const [errorList] = axiosErrorHandler(error);
                    setGeneralErrorList(errorList);
                }),
        [api, reload, setGeneralErrorList, setSuccessMessage]
    );
    const reject = useCallback(
        id =>
            api
                .rejectLiveChatWorkingHours(id)
                .then(response => {
                    setSuccessMessage({ success: labels.CHANGES_ARE_SAVED });
                    if (reload) reload();
                })
                .catch(error => {
                    const [errorList] = axiosErrorHandler(error);
                    setGeneralErrorList(errorList);
                }),
        [api, reload, setGeneralErrorList, setSuccessMessage]
    );

    const validateDate = useCallback(
        value => {
            return getValues('dayOfWeek') || value || validator.errorMessages.MISSING_DATE;
        },
        [getValues]
    );
    const validateDayOfWeek = useCallback(
        value => getValues('date') || value || validator.errorMessages.MISSING_DAY_OF_WEEK,
        [getValues]
    );
    const registerValidationFor = {
        startHour: () => ({ required: false }), // validator.errorMessages.MISSING_OPENING_TIME }),
        endHour: () => ({ required: false }), //  validator.errorMessages.MISSING_CLOSING_TIME }),
        date: () => ({ validate: validateDate }),
        dayOfWeek: () => register({ validate: validateDayOfWeek }),
    };
    useEffect(() => {
        register('id');
        register('startHour');
        register('endHour');
        register('dayOfWeek');
        register('date');

        setValue('id', id);
        setValue('startHour', parseTime(startHour));
        setValue('endHour', parseTime(endHour));
        setValue('dayOfWeek', dayOfWeek ? dayOfWeek : null);
        setValue('date', date ? date : null);
    }, [id, startHour, endHour, dayOfWeek, date, register, setValue]);

    return {
        control,
        approve,
        reject,
        onSubmit: handleSubmit((d, e) => {
            updateOrCreateWorkingHours(d);
        }, validator.extractErrorsFromInvalidForm(setGeneralErrorList)),
        getValues,
        fieldErrorFor: errors,
        setValue,
        registerValidationFor,
        deleteWorkingHours,
    };
};

export default useLiveChatWorkingHours;
