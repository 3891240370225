import { combineReducers } from 'redux';
import userGroupsReducer from './userGroupsReducer';
import authReducer from './authReducer';
import testIntentReducer from './testIntentReducer';
import sidebarReducer from './sidebarReducer';
import liveChatReducer from './liveChatReducer';
import superviseLiveChatReducer from './superviseLiveChatReducer';
import appReducer from "./appReducer";

const rootReducer = combineReducers({
    userGroups: userGroupsReducer,
    auth: authReducer,
    testIntentReducer: testIntentReducer,
    sidebar: sidebarReducer,
    liveChat: liveChatReducer,
    superviseLiveChat: superviseLiveChatReducer,
    app: appReducer,
});

export default rootReducer;
