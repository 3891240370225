import { Dialog } from 'primereact/dialog';
import React from 'react';
import Control from '../../components/UI/Control/Control';
import useLiveChatBan from '../../hooks/useLiveChatBan';

const LiveChatBanDialog = ({
    data,
    setGeneralErrorList,
    setSuccessMessage,
    dialogVisible,
    hideDialog,
    reload,
    onSuccessfulSubmit,
}) => {
    const { banDurationOptions, fieldErrorFor, onSubmit, registerValidationFor, onCancel } =
        useLiveChatBan({
            data,
            setGeneralErrorList,
            setSuccessMessage,
            hideDialog,
            reload,
            onSuccessfulSubmit,
        });
    return (
        <Dialog
            header="User Ban"
            visible={dialogVisible}
            style={{ width: '50vw' }}
            breakpoints={{ '960px': '75vw', '640px': '100vw' }}
            onHide={hideDialog}
        >
            <form noValidate onSubmit={onSubmit}>
                <div className="container-fluid" style={{ maxWidth: '400px' }}>
                    <div className="row">
                        <div className="col-12">
                            <Control
                                error={fieldErrorFor.bannedHours?.message}
                                registerRef={registerValidationFor.bannedHours}
                                label="Ban Duration"
                                type="select"
                                options={banDurationOptions}
                                name="bannedHours"
                                value=""
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Control
                                name="banReason"
                                label="Ban Reason"
                                type="textarea"
                                registerRef={registerValidationFor.banReason}
                                error={fieldErrorFor.banReason?.message}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 d-flex justify-content-end mt-4">
                        <button className="btn btn-primary mr-2" type="submit">
                            {data && data.id ? 'Update Ban' : 'Ban User'}
                        </button>
                        <button className="btn btn-danger mr-2" onClick={onCancel}>
                            Cancel
                        </button>
                    </div>
                </div>
            </form>
        </Dialog>
    );
};

export default LiveChatBanDialog;
