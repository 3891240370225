import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ApiRequests from '../http/ApiRequests';
import axiosErrorHandler from '../http/AxiosErrorHandler';
import { validator } from '../services/validationService';

const banDurationOptions = [
    { label: '1 hour', value: 1 },
    { label: '24 hours', value: 24 },
    { label: '72 hours', value: 72 },
];
const useLiveChatBan = ({
    data,
    setGeneralErrorList,
    setSuccessMessage,
    reload,
    hideDialog,
    onSuccessfulSubmit,
}) => {
    const [api] = useState(new ApiRequests());
    const { setValue, register, handleSubmit, errors, reset } = useForm({
        shouldUnregister: false,
    });

    const registerValidationFor = {
        bannedHours: () => register({ required: 'Please select ban duration.' }),
        banReason: () => register({ required: false }),
    };

    const onFormSubmit = useCallback(
        state => {
            let apiCall = state.id ? api.updateLiveChatBan : api.createLiveChatBan;
            const successMessage = state.id
                ? `Successfully updated ban with id ${state.id}.`
                : `Successfully created new ban.`;
            apiCall(state)
                .then(response => {
                    setSuccessMessage({ success: successMessage });
                    hideDialog();
                    if (reload) {
                        reload();
                    }
                    if (onSuccessfulSubmit) {
                        onSuccessfulSubmit();
                        reset();
                    }
                })
                .catch(error => {
                    const [errorList] = axiosErrorHandler(error);
                    setGeneralErrorList(errorList);
                });
        },
        [api, hideDialog, onSuccessfulSubmit, reload, reset, setGeneralErrorList, setSuccessMessage]
    );

    const onCancel = useCallback(
        e => {
            e.preventDefault();
            hideDialog();
            reset();
        },
        [hideDialog, reset]
    );
    useEffect(() => {
        if (data && data.id) {
            setValue('id', data.id);
            setValue('bannedHours', data.bannedHours);
            setValue('banReason', data.banReason);
        }
        if (data && data.sessionId) {
            setValue('sessionId', data.sessionId);
        }
    }, [data, setValue]);

    return {
        register,
        registerValidationFor,
        fieldErrorFor: errors,
        onSubmit: handleSubmit(
            onFormSubmit,
            validator.extractErrorsFromInvalidForm(setGeneralErrorList)
        ),
        banDurationOptions,
        reset,
        onCancel,
    };
};
export default useLiveChatBan;
