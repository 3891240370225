import React from 'react';
import ImportExportModule from '../../modules/ImportExport/ImportExportModule';

const ImportExport = () => {
    return (
        <div>
            <ImportExportModule />
        </div>
    );
};

export default ImportExport;
