export const permissions = {
    PERMISSION_VIEW_REPORT: 'PERMISSION_VIEW_REPORT',
    PERMISSION_MANAGE_GROUP_PERMISSIONS: 'PERMISSION_MANAGE_GROUP_PERMISSIONS',
    PERMISSION_VIEW_AUDIT: 'PERMISSION_VIEW_AUDIT',
    PERMISSION_VIEW_TRAINING_DATA: 'PERMISSION_VIEW_TRAINING_DATA',
    PERMISSION_MANAGE_TRAINING_DATA: 'PERMISSION_MANAGE_TRAINING_DATA',
    PERMISSION_VIEW_ANSWERS: 'PERMISSION_VIEW_ANSWERS',
    PERMISSION_MANAGE_ANSWERS: 'PERMISSION_MANAGE_ANSWERS',
    PERMISSION_MANAGE_BOT_VERSIONS: 'PERMISSION_MANAGE_BOT_VERSIONS',
    PERMISSION_LIVE_CHAT_OPERATOR: 'PERMISSION_LIVE_CHAT_OPERATOR',
    PERMISSION_LIVE_CHAT_SUPERVISOR: 'PERMISSION_LIVE_CHAT_SUPERVISOR',
    PERMISSION_LIVE_CHAT_REPORT_VIEWER: 'PERMISSION_LIVE_CHAT_REPORT_VIEWER',
    PERMISSION_MANAGE_LIVE_CHAT_CONFIGURATION: 'PERMISSION_MANAGE_LIVE_CHAT_CONFIGURATION',
};

export const formatLabelFromValue = value => {
    return value.split('PERMISSION_')[1].replaceAll('_', ' ').toLowerCase();
};

export const formDataToPermissions = data => {
    const permissions = Object.keys(data).reduce((permissionsArr, permission) => {
        if (data[permission]) permissionsArr.push(permission);
        return permissionsArr;
    }, []);

    return {
        permissions,
    };
};

export const canUser = {
    yesSheCan: () => true,
    // Bot Training
    viewPageApprvTrain: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_MANAGE_BOT_VERSIONS),
    viewPageAddInt: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_VIEW_TRAINING_DATA) ||
        userPermissions.includes(permissions.PERMISSION_MANAGE_TRAINING_DATA),
    viewPageAddSyn: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_VIEW_TRAINING_DATA) ||
        userPermissions.includes(permissions.PERMISSION_MANAGE_TRAINING_DATA),
    viewPageAnalyzeTrainingData: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_VIEW_TRAINING_DATA) ||
        userPermissions.includes(permissions.PERMISSION_MANAGE_TRAINING_DATA),
    // Messages
    viewPagePdfMessage: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_VIEW_ANSWERS) ||
        userPermissions.includes(permissions.PERMISSION_MANAGE_ANSWERS),
    viewPageConfigResp: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_VIEW_ANSWERS) ||
        userPermissions.includes(permissions.PERMISSION_MANAGE_ANSWERS),
    viewPageApprvResp: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_MANAGE_ANSWERS),
    viewPageWelcomeMessages: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_MANAGE_TRAINING_DATA),
    viewPageWelcomeMessagesApproval: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_MANAGE_TRAINING_DATA),
    viewPageAnalyzeResponses: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_VIEW_TRAINING_DATA) ||
        userPermissions.includes(permissions.PERMISSION_MANAGE_TRAINING_DATA),
    // Bot Settings
    viewPageBotRespSettings: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_MANAGE_BOT_VERSIONS),
    viewPageLocationCoordinates: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_MANAGE_TRAINING_DATA),
    viewPageLocationCoordinatesApproval: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_MANAGE_TRAINING_DATA),
    viewPageTariff: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_MANAGE_TRAINING_DATA),
    viewPageTariffApproval: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_MANAGE_TRAINING_DATA),
    viewPageImportExport: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_MANAGE_TRAINING_DATA),
    // Reporting
    viewPageCnvrstnReport: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_VIEW_REPORT),
    viewPageIntentReport: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_VIEW_REPORT),
    viewPageSmmryReport: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_VIEW_REPORT),
    // Audit Trails
    viewPageAuditTrails: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_VIEW_AUDIT),
    // User Groups
    viewPageUserGroups: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_MANAGE_GROUP_PERMISSIONS),
    // Live Chat
    viewPageLiveChat: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_LIVE_CHAT_OPERATOR),
    viewPageLiveChatConfiguration: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_MANAGE_LIVE_CHAT_CONFIGURATION),
    viewPageLiveChatWorkingHours: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_MANAGE_BOT_VERSIONS),
    viewPageLiveChatForbiddenPhrases: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_MANAGE_BOT_VERSIONS),
    viewPageLiveChatMultilingualPhrases: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_MANAGE_BOT_VERSIONS),
    viewPageLiveChatTemplates: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_MANAGE_LIVE_CHAT_CONFIGURATION),
    viewPageBanList: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_LIVE_CHAT_SUPERVISOR),
    viewPageDashboard: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_LIVE_CHAT_SUPERVISOR),
    viewPageOperatorDashboard: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_LIVE_CHAT_OPERATOR),
    viewPageSuperviseLiveChat: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_LIVE_CHAT_SUPERVISOR),
    // User
    viewPageProfile: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_LIVE_CHAT_OPERATOR),

    /**Action-Based Permissions */
    useLiveChat: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_LIVE_CHAT_SUPERVISOR) ||
        userPermissions.includes(permissions.PERMISSION_LIVE_CHAT_OPERATOR),
    superviseLiveChat: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_LIVE_CHAT_SUPERVISOR),
    // profile actions
    approveProfileChanges: userPermissions =>
        userPermissions.includes(permissions.PERMISSION_LIVE_CHAT_SUPERVISOR),
};
