import { Tooltip } from 'primereact/tooltip';
import React, { useMemo } from 'react';

const FlagCounter = ({ tooltipLabel, containerClassNames, textBeforeFlag }) => {
    const randomNumber = useMemo(() => Math.random().toString().substring(2, 6), []);
    return (
        <>
            <span
                data-pr-tooltip={tooltipLabel}
                className={`label-inf-${randomNumber} d-flex flex-row align-items-center ${containerClassNames}`}
            >
                {textBeforeFlag ? (
                    <span className="mr-1" style={{ fontSize: '1.5em' }}>
                        {textBeforeFlag}
                    </span>
                ) : null}

                <i className="pi pi-flag" style={{ fontSize: '1.3rem' }}></i>
            </span>
            {tooltipLabel ? <Tooltip target={`.label-inf-${randomNumber}`} position="top" /> : null}
        </>
    );
};

export default FlagCounter;
