import get from 'lodash/get';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import ApiRequests from '../http/ApiRequests';
import axiosErrorHandler from '../http/AxiosErrorHandler';
import conversRepFilterSchema from '../schemas/conversRepFilterSchema';
import ReportsService from '../services/reports.service';
import validationService from '../services/validationService';

const useConversRepData = props => {
    const api = useMemo(() => new ApiRequests(), []),
        reportService = new ReportsService(),
        filterFields = {
            dateFrom: 'dateFrom',
            dateTo: 'dateTo',
            sessionID: 'sessionID',
            channel: 'channel',
            startsAfter: 'startsAfter',
            endsBefore: 'endsBefore',
            orderDirection: 'orderDirection',
            orderCriteria: 'orderCriteria',
            begin: 'begin',
            end: 'end',
        },
        messageSenderValues = {
            CUSTOMER: 'Customer',
            CHATBOT: '--',
        },
        { register, handleSubmit, errors, control, getValues, setValue } = useForm(),
        validator = validationService(),
        initialGridMeta = useMemo(
            () => ({
                limit: 10,
                offset: 0,
                total: 0,
            }),
            []
        ),
        [generalErrorList, setGeneralErrorList] = useState([]),
        [conversationGridData, setConversationGridData] = useState([]),
        [gridMeta, setGridMeta] = useState({ ...initialGridMeta }),
        [gridFilter, setGridFilter] = useState({
            startsAfter: moment().startOf('day').utc().format(),
            orderDirection: 'DESCENDING',
            orderCriteria: 'START_TS',
        }),
        [selectedConversation, setSelectedConversation] = useState(null),
        [messagesGridData, setmessagesGridData] = useState([]),
        [stillDownload, setStillDownload] = useState(false),
        [showConfirmExportDialog, setShowConfirmExportDialog] = useState(false),
        openConfirmExportDialog = () => setShowConfirmExportDialog(true),
        closeConfirmExportDialog = () => setShowConfirmExportDialog(false),
        conversationGridHeaders = [
            { name: 'Conv. ID', key: 'convId', filter: false },
            { name: 'Session ID', key: 'sessionId', filter: false },
            { name: 'Channel', key: 'channel', filter: false },
            { name: 'Start Time', key: 'startTime', formatter: 'datetime', filter: false },
            { name: 'End Time', key: 'endTime', formatter: 'datetime', filter: false },
            { name: 'Duration', key: 'duration', filter: false },
            { name: 'Chatbot Messages', key: 'chatbotMsg', filter: false },
            { name: 'Customer Messages', key: 'customerMsg', filter: false },
            { name: 'Total Messages', key: 'totalMsg', filter: false },
            { name: 'Average Response Time', key: 'averageResponseTime', filter: false },
            { name: 'Finished', key: 'finished', formatter: 'boolean', filter: false },
            { name: 'Engaged', key: 'engaged', formatter: 'boolean', filter: false },
            { name: 'Rejected', key: 'rejected', formatter: 'boolean', filter: false },
            { name: 'User Satisfaction', key: 'userSatisfaction', filter: false },
        ],
        messagesGridHeaders = [
            { name: 'Conv. ID', key: 'convId', filter: false },
            { name: 'Session ID', key: 'sessionId', filter: false },
            { name: 'Channel', key: 'channel', filter: false },
            { name: 'Time', key: 'messageTimestamp', formatter: 'datetime', filter: false },
            { name: 'Chatbot/Customer', key: 'botCustomer', filter: false },
            { name: 'Intent', key: 'intent', filter: false },
            { name: 'Confidence', key: 'confidence', filter: false },
            { name: 'Message/Action', key: 'content', filter: false },
            { name: 'Response Time', key: 'responseTime', filter: false },
        ],
        channelOptions = [
            { label: 'All', value: '' },
            { label: 'Viber', value: 'VIBER' },
            { label: 'Messenger', value: 'MESSENGER' },
            { label: 'Web', value: 'API' },
        ],
        orderCriteriaOptions = [
            { label: 'Start Timestamp', value: 'START_TS' },
            { label: 'End Timestamp', value: 'END_TS' },
        ],
        defaultOrderCriteria = orderCriteriaOptions[0].value,
        orderDirectionOptions = [
            { label: 'Ascending', value: 'ASCENDING' },
            { label: 'Descending', value: 'DESCENDING' },
        ],
        defaultOrderDirection = orderDirectionOptions[1].value,
        onSubmit = data => {
            const filters = {};

            if (
                data[filterFields.dateFrom] &&
                data[filterFields.dateTo] &&
                moment(data[filterFields.dateTo]).isBefore(data[filterFields.dateFrom])
            ) {
                setGeneralErrorList([validator.errorMessages.INCORRECT_SEARCH_PERIOD]);
                return;
            }

            if (data[filterFields.dateFrom])
                filters[filterFields.startsAfter] = moment(data[filterFields.dateFrom])
                    .utc()
                    .format();
            if (data[filterFields.dateTo])
                filters[filterFields.endsBefore] = moment(data[filterFields.dateTo]).utc().format();
            if (data[filterFields.sessionID])
                filters[filterFields.sessionID] = data[filterFields.sessionID];
            if (data[filterFields.channel])
                filters[filterFields.channel] = data[filterFields.channel];
            if (data[filterFields.orderDirection])
                filters[filterFields.orderDirection] = data[filterFields.orderDirection];
            if (data[filterFields.orderCriteria])
                filters[filterFields.orderCriteria] = data[filterFields.orderCriteria];
            setGridFilter(filters);
            getConversations(filters, initialGridMeta);
            setSelectedConversation(null);
            setmessagesGridData([]);
        },
        onExport = data => {
            const requestData = {};
            if (
                data[filterFields.dateFrom] &&
                data[filterFields.dateTo] &&
                moment(data[filterFields.dateTo]).isBefore(data[filterFields.dateFrom])
            ) {
                setGeneralErrorList([validator.errorMessages.INCORRECT_SEARCH_PERIOD]);
                return;
            }
            if (data[filterFields.dateFrom])
                requestData[filterFields.begin] = moment(data[filterFields.dateFrom])
                    .utc()
                    .format();
            if (data[filterFields.dateTo])
                requestData[filterFields.end] = moment(data[filterFields.dateTo]).utc().format();
            if (data[filterFields.sessionID])
                requestData[filterFields.sessionID] = data[filterFields.sessionID];
            if (data[filterFields.channel])
                requestData[filterFields.channel] = data[filterFields.channel];
            if (data[filterFields.orderCriteria])
                requestData[filterFields.orderCriteria] = data[filterFields.orderCriteria];
            if (data[filterFields.orderDirection])
                requestData[filterFields.orderDirection] = data[filterFields.orderDirection];

            setStillDownload(true);
            api.downloadConversationReportAsExcel(requestData)
                .then(response => {
                    reportService.downloadBlobFileWithLink(
                        response,
                        'conversation_reports.xlsx',
                        setStillDownload
                    );
                    closeConfirmExportDialog();
                })
                .catch(err => {
                    reportService.handleBlobError(err, setGeneralErrorList, setStillDownload);
                    setStillDownload(false);
                });
        },
        getConversations = useCallback(
            (filter, meta) => {
                api.getConversations({ ...filter, ...meta })
                    .then(res => {
                        setConversationGridData(processData(get(res, 'data.conversations', [])));
                        setGridMeta({
                            limit: get(res, 'data.limit', 5),
                            offset: get(res, 'data.offset', meta.offset),
                            total: get(res, 'data.total', 0),
                        });
                    })
                    .catch(err => {
                        const [errorList] = axiosErrorHandler(err);
                        setGeneralErrorList(errorList);
                    });
            },
            [api]
        ),
        onGridPage = event => {
            getConversations(gridFilter, {
                limit: event.rows,
                offset: event.first,
                page: event.page + 1,
            });
        },
        processData = data => {
            const calcDuration = (start, end) => {
                start = moment(start);
                end = moment(end);
                var hrs = moment.utc(end.diff(start)).format('HH');
                var min = moment.utc(end.diff(start)).format('mm');
                var sec = moment.utc(end.diff(start)).format('ss');
                return [hrs, min, sec].join(':');
            };

            return data.map(item => ({
                convId: item.id,
                sessionId: item.session,
                channel: item.channel === 'API' ? 'WEB' : item.channel,
                startTime: item.startTs,
                endTime: item.endTs,
                duration: calcDuration(item.startTs, item.endTs),
                chatbotMsg: item.chatBotMessagesCount,
                customerMsg: item.clientMessagesCount,
                totalMsg: item.totalMessagesCount,
                averageResponseTime: item.averageResponseTime + 's',
                engaged: item.engaged,
                finished: item.finished,
                rejected: item.rejected,
                lbInteractions: 'lbInteractions',
                userSatisfaction: item.rating === null ? '--' : item.rating !== 0 ? item.rating : 0,
            }));
        };
    useEffect(() => {
        if (!getValues()[filterFields.dateFrom]) {
            setValue('dateFrom', moment().startOf('day').utc().toDate());
        }
        getConversations(gridFilter, initialGridMeta);
    }, [getConversations, initialGridMeta, gridFilter]);

    const changeSelectedConversation = async conversation => {
        try {
            setSelectedConversation(conversation);
            if (selectedConversation === conversation) {
                setSelectedConversation(null);
                setmessagesGridData([]);
            } else {
                let messages = await getConversationMessages(conversation.convId);
                messages = messages.map(msg => ({
                    convId: conversation.convId,
                    sessionId: conversation.sessionId,
                    channel: conversation.channel === 'API' ? 'WEB' : conversation.channel,
                    messageTimestamp: moment(msg.messageTs).format('YYYY-MM-DD HH:mm:ss'),
                    botCustomer: msg.customerId
                        ? messageSenderValues.CUSTOMER
                        : messageSenderValues.CHATBOT,
                    content:
                        msg.type === 'TEXT' || msg.type === 'REQUEST_LOCATION'
                            ? msg.content
                            : msg.type + ': ' + msg.content.substring(0, 100) + '...',
                    responseTime: msg.responseTime ? msg.responseTime + 'ms' : '',
                    intent: msg.recognizedIntent ? msg.recognizedIntent.name : '',
                    confidence: msg.confidence ? msg.confidence.toFixed(4) : '',
                }));
                setmessagesGridData(messages);
            }
        } catch (err) {
            const [errorList] = axiosErrorHandler(err);
            setGeneralErrorList(errorList);
        }
    };

    const getConversationMessages = async conversationId => {
        try {
            const messagesData = await api.getConversationMessages(conversationId);
            return messagesData.data;
        } catch (err) {
            const [errorList] = axiosErrorHandler(err);
            setGeneralErrorList(errorList);
        }
    };

    return {
        registerValidationFor: conversRepFilterSchema(register),
        channelOptions,
        conversationGridHeaders,
        conversationGridData,
        gridMeta,
        generalErrorList,
        onGridPage,
        messagesGridHeaders,
        messagesGridData,
        fieldErrorFor: errors,
        control,
        onSubmit: handleSubmit(
            onSubmit,
            validator.extractErrorsFromInvalidForm(setGeneralErrorList)
        ),
        selectedConversation,
        changeSelectedConversation,
        orderCriteriaOptions,
        defaultOrderCriteria,
        orderDirectionOptions,
        defaultOrderDirection,
        stillDownload,
        onExport: handleSubmit(
            onExport,
            validator.extractErrorsFromInvalidForm(setGeneralErrorList)
        ),
        openConfirmExportDialog,
        closeConfirmExportDialog,
        showConfirmExportDialog,
        setValue,
        getValues,
    };
};

export default useConversRepData;
