import validationService from '../services/validationService';

const userGroupSchema = register => {
    const validator = validationService();
    return {
        groupName: () =>
            register({
                required: validator.errorMessages.MISSING_USER_GROUP_NAME,
            }),
    };
};

export default userGroupSchema;
