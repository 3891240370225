import CheckboxControlled from '../../components/UI/Control/CheckboxControlled';
import Control from '../../components/UI/Control/Control';
import FormControl from '../../components/UI/Control/FormControl';
import Label from '../../components/UI/Control/Label';
import TextSearchInput from '../../components/UI/Control/TextSearchInput';
import GeneralMessage from '../../components/UI/GeneralMessage';
import FormControlWrapper from '../../hoc/FormControlWrapper';
import useFormConfigResp from '../../hooks/useFormConfigResp';
import BotResponseForm from './BotResponseForm';

const ConfigRespForm = props => {
    const {
        registerValidationFor,
        onSubmit,
        successMessage,
        generalErrorList,
        fieldErrorFor,
        langOptions,
        channelOptions,
        watchAllFields,
        filterIntents,
        loadingIntents,
        isFilterInputDisabled,
        intentSearchRef,
        selectedAnswer,
        setGeneralErrorList,
        sortOptions,
        onSortFormChange,
        sortedIntentList,
        _getValues,
        sortForm,
        watchSortBy,
    } = useFormConfigResp();

    return (
        <form noValidate className="container-fluid" id="loginForm" onSubmit={onSubmit}>
            <div className="animated fadeIn">
                <GeneralMessage
                    className="c-msg"
                    successMessage={successMessage}
                    errorList={generalErrorList}
                />
                <div className="row">
                    <div className="col-4">
                        <Control
                            error={fieldErrorFor.language?.message}
                            registerRef={registerValidationFor?.language}
                            name="language"
                            type="select"
                            label="Language"
                            value=""
                            options={langOptions}
                        />
                        <Control
                            error={fieldErrorFor.channel?.message}
                            registerRef={registerValidationFor?.channel}
                            name="channel"
                            type="select"
                            label="Channel"
                            options={channelOptions}
                            value=""
                            disabled={!watchAllFields.language}
                        />
                    </div>
                    <div className="col-6">
                        <FormControl>
                            <Label value="Select intent">
                                <TextSearchInput
                                    inputRef={intentSearchRef}
                                    delaySearch={400}
                                    searchCallback={filterIntents}
                                    loading={loadingIntents}
                                    disabled={isFilterInputDisabled}
                                    placeholder="Search intent..."
                                />
                            </Label>
                        </FormControl>

                        <FormControl>
                            <div className="d-inline-flex flex-row">
                                <select
                                    className="form-control trunkate mr-3"
                                    onChange={onSortFormChange}
                                    ref={sortForm.register}
                                    name="sortBy"
                                    defaultValue={_getValues('name')}
                                >
                                    {sortOptions.map(option => (
                                        <option value={option.value} key={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>

                                <CheckboxControlled
                                    name="sortOrderIsDesc"
                                    control={sortForm.control}
                                    label="Descending"
                                    beforeOnChange={onSortFormChange}
                                    disabled={!watchSortBy}
                                />
                            </div>
                        </FormControl>
                        <Control
                            error={fieldErrorFor.intentId?.message}
                            registerRef={registerValidationFor?.intentId}
                            name="intentId"
                            type="select"
                            options={sortedIntentList}
                            disabled={!watchAllFields.channel}
                            value=""
                            size="10"
                            labelAs="name"
                            valueAs="id"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        {selectedAnswer ? (
                            // change the key in order to force remount to reset the form and its state.
                            // This way the Confirm button will be again disabled after changiung the intent from the list above
                            <BotResponseForm
                                selectedAnswer={selectedAnswer}
                                key={selectedAnswer.length ? selectedAnswer[0].id : 1}
                                setGeneralErrorList={setGeneralErrorList}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        </form>
    );
};

export default ConfigRespForm;
