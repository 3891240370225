class Config {
    static domain = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '';
    // static wsEndpoint = process.env.REACT_APP_WS_URL; // web socket not used anymore
    static envName = process.env.REACT_APP_ENV_NAME ? process.env.REACT_APP_ENV_NAME : '';
    static isProduction = process.env.NODE_ENV === 'production';
    static apiEndpoint = Config.domain + 'api/';
    static apiEndpointAdmin = Config.apiEndpoint + 'admin/';
    static loginApiEndpoint = Config.apiEndpoint + 'auth/login';
    static wsTopics = {
        getPendingConversations: () => '/topic/conversations',
        getConversationMessages: sessionId => '/topic/chat/client/' + sessionId,
        sendConversationMessages: sessionId => '/topic/chat/admin/' + sessionId,
        getAssignedConversations: userId => '/topic/assigned/' + userId,
    };
    static wsReconnectDelay = 10000;
    static wsHeartbeatIncoming = 15000;
    static wsHeartbeatOutgoing = 15000;
    static liveChatAudioNotificationSourceWav = '/notification-sound.wav';
}

export default Config;
