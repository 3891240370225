import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { Sidebar } from 'primereact/sidebar';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import Control from '../../components/UI/Control/Control';
import GeneralMessage from '../../components/UI/GeneralMessage';
import Grid from '../../components/UI/Grid/Grid';
import useTextProcess from '../../hooks/useTextProcess';
import { setTestIntentSidebarVisible } from '../../store/actions/testIntentAction';

const TextProcessItem = ({ sidebarVisible, setVisible }) => {
    const hook = useTextProcess(),
        {
            fieldErrorFor,
            registerValidationFor,
            onSubmit,
            disablePanel,
            intentGridHeader,
            entityGridHeaders,
            entities,
            intentRanging,
            generalErrorList,
        } = hook;

    const submitOnEnter = useCallback(
        e => {
            if (e.key === 'Enter' && e.shiftKey === false && onSubmit) {
                e.preventDefault();
                const data = { content: e.target.value };
                return onSubmit(data);
            }
        },
        [onSubmit]
    );

    return (
        <Sidebar
            visible={sidebarVisible}
            position="right"
            baseZIndex={1000}
            onHide={() => {
                setVisible(false);
                disablePanel();
            }}
            style={{ width: '50em' }}
            blockScroll={true}
        >
            <form noValidate className="container-fluid" id="processText">
                <GeneralMessage className="c-msg" errorList={generalErrorList} />
                <div className="row">
                    <div className="col-12">
                        <Control
                            id="text-to-process"
                            name="text"
                            type="textarea"
                            onKeyPress={submitOnEnter}
                            placeholder="Text"
                            label="Message"
                            error={fieldErrorFor.text?.message}
                            registerRef={registerValidationFor.text}
                        />
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12 text-right">
                        <Button
                            type="button"
                            className="info-btn mr-2"
                            icon="fa fa-minus"
                            tooltip={'Cancel'}
                            label="CANCEL"
                            tooltipOptions={{ position: 'top' }}
                            onClick={() => {
                                setVisible(false);
                                disablePanel();
                            }}
                        />

                        <Button
                            className="add-btn"
                            icon="fa fa-check"
                            tooltip={'Send'}
                            label="SEND"
                            type="submit"
                            tooltipOptions={{ position: 'top' }}
                            onClick={onSubmit}
                        />
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                        <Panel
                            header="Intent Rankings"
                            className="mb-2"
                            style={{ fontSize: '14px', color: '#333333' }}
                        >
                            <Grid
                                columns={intentGridHeader}
                                data={intentRanging}
                                classes="entities-grid"
                            />
                        </Panel>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                        <Panel header="Entities" className="mb-2" style={{ fontSize: '14px' }}>
                            <Grid
                                columns={entityGridHeaders}
                                data={entities}
                                classes="entities-grid"
                            />
                        </Panel>
                    </div>
                </div>
            </form>
        </Sidebar>
    );
};

const mapDispatchToProps = {
    setVisible: setTestIntentSidebarVisible,
};

const mapStateToProps = state => {
    return {
        sidebarVisible: state.testIntentReducer.showSendText,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextProcessItem);
