import {
    SHOW_ADD_NEW_USER_GROUP_SIDEBAR,
    CHANGE_USER_GROUP_PERMISSION,
    ADD_USER_GROUP_TO_STATE,
    SET_USER_GROUPS,
    SET_USER_GROUP,
    //Success
    SUCCESS_MESSAGE,
    // Errors
    ERROR_MESSAGE,
} from '../actions/userGroupsActions';

const INIT_STATE = {
    showAddNew: false,
    allUserGroups: [],
    userGroup: null,
    // Success
    successMessage: null,
    // Errors
    errorMessage: [],
};

const userGroupsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SHOW_ADD_NEW_USER_GROUP_SIDEBAR:
            return Object.assign({}, state, {
                showAddNew: action.payload,
            });
        case SET_USER_GROUPS:
            return Object.assign({}, state, {
                allUserGroups: action.payload,
            });
        case ADD_USER_GROUP_TO_STATE:
            return Object.assign({}, state, {
                allUserGroups: [...state.allUserGroups, action.payload],
            });
        case SET_USER_GROUP:
        case CHANGE_USER_GROUP_PERMISSION:
            return Object.assign({}, state, {
                userGroup: action.payload,
            });
        // Success cases
        case SUCCESS_MESSAGE:
            return Object.assign({}, state, {
                successMessage: action.payload,
            });
        // Error cases
        case ERROR_MESSAGE:
            return Object.assign({}, state, {
                errorMessage: action.payload,
            });
        default:
            return { ...state };
    }
};

export default userGroupsReducer;
