import get from 'lodash/get';
import { Button } from 'primereact/button';
import React from 'react';
import { useFieldArray } from 'react-hook-form';
import Control from '../../components/UI/Control/Control';
import FormButtonWrapper from '../../hoc/FormButtonWrapper';

const WelcomeMessageCarouselResponse = ({ registerValidationFor, errors, control, namePrefix }) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name: `${namePrefix}.content.carousel`,
    });
    return (
        <>
            <div className="row">
                <div className="col-12">
                    {Array.isArray(fields) && fields.length
                        ? fields.map((field, index) => (
                              <div className="row mb-2" key={field.id}>
                                  <div className="col-2">
                                      <Control
                                          error={get(
                                              errors,
                                              `${namePrefix}.content.carousel[${index}].title.message`,
                                              ''
                                          )}
                                          registerRef={registerValidationFor?.title}
                                          name={`${namePrefix}.content.carousel[${index}].title`}
                                          type="text"
                                          label="Title *"
                                          value={field?.title}
                                          placeholder="Title text"
                                      />
                                  </div>
                                  <div className="col-2">
                                      <Control
                                          error={get(
                                              errors,
                                              `${namePrefix}.content.carousel[${index}].subtitle.message`,
                                              ''
                                          )}
                                          registerRef={registerValidationFor?.subtitle}
                                          name={`${namePrefix}.content.carousel[${index}].subtitle`}
                                          type="text"
                                          label="Subtitle"
                                          value={field?.subtitle}
                                          placeholder="Subtitle text"
                                      />
                                  </div>
                                  <div className="col-3">
                                      <Control
                                          error={get(
                                              errors,
                                              `${namePrefix}.content.carousel[${index}].image.message`,
                                              ''
                                          )}
                                          registerRef={registerValidationFor?.image}
                                          name={`${namePrefix}.content.carousel[${index}].image`}
                                          type="text"
                                          label="Image resource *"
                                          info="The image has to be either a JPG or PNG file, 237x165 or equal"
                                          value={field?.image}
                                          placeholder="External URL"
                                      />
                                  </div>
                                  <div className="col-2">
                                      <Control
                                          error={get(
                                              errors,
                                              `${namePrefix}.content.carousel[${index}].url.message`,
                                              ''
                                          )}
                                          registerRef={registerValidationFor?.url}
                                          name={`${namePrefix}.content.carousel[${index}].url`}
                                          type="text"
                                          label="URL *"
                                          value={field?.url}
                                          placeholder="URL address"
                                      />
                                  </div>
                                  <div className="col-2">
                                      <Control
                                          error={get(
                                              errors,
                                              `${namePrefix}.content.carousel[${index}].metadata.message`,
                                              ''
                                          )}
                                          registerRef={registerValidationFor?.metadata}
                                          name={`${namePrefix}.content.carousel[${index}].metadata`}
                                          type="text"
                                          label="Metadata"
                                          value={field?.metadata}
                                          placeholder="Metadata (text)"
                                      />
                                  </div>
                                  <div className="col-1">
                                      <FormButtonWrapper>
                                          <Button
                                              className="delete-btn"
                                              onClick={() => {
                                                  remove(index);
                                              }}
                                              icon="fa fa-trash-o"
                                              tooltipOptions={{ position: 'top' }}
                                          />
                                      </FormButtonWrapper>
                                  </div>
                              </div>
                          ))
                        : null}
                </div>
            </div>
            <div className="row">
                <div className="col-11"></div>
                <div className="col-1">
                    <Button
                        className="add-btn"
                        style={{ marginLeft: '-112px', minWidth: '150px' }}
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            append({
                                image: '',
                                metadata: '',
                                subtitle: '',
                                title: '',
                                url: '',
                            });
                        }}
                        icon="fa fa-plus-circle"
                        label="Add slide"
                    />
                </div>
            </div>
        </>
    );
};

export default WelcomeMessageCarouselResponse;
