import { Button } from 'primereact/button';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import BlinkingIcon from '../../components/UI/BlinkingIcon';
import {
    refreshActiveChats,
    setSelectedConversationId,
} from '../../store/actions/superviseLiveChatActions';
import SupervisedLiveChatCard from './SupervisedLiveChatCard';

const SupervisedChatsPanel = ({
    conversations,
    selectedConversationId,
    setSelectedConversationId,
    refreshActiveChats,
}) => {
    const checkForUnreadMessages = useCallback(
        () => conversations.some(conv => conv.unreadMessagesCount > 0),
        [conversations]
    );
    const onSwitchBetweenActiveChats = useCallback(
        id => () => {
            setSelectedConversationId(id);
        },
        [setSelectedConversationId]
    );
    return (
        <>
            <div
                className="d-flex justify-content-between flex-row align-items-center px-2"
                style={{ borderBottom: '1px solid #cccccc', height: '60px' }}
            >
                <span className="pt-1 d-flex align-items-center">
                    <h2 className="display-5 mr-2" style={{ marginBottom: 0 }}>
                        Active Chats
                    </h2>
                    {checkForUnreadMessages() ? <BlinkingIcon /> : null}
                </span>
                <Button className="ml-3" onClick={refreshActiveChats} icon="pi pi-refresh" />
            </div>
            <div style={{ maxHeight: 'calc(100% - 60px)', overflow: 'auto' }}>
                <div className="d-flex flex-column mb-2" style={{ overflowY: 'auto' }}>
                    {Array.isArray(conversations) && conversations.length ? (
                        conversations.map((conv, index) => (
                            <SupervisedLiveChatCard
                                key={index}
                                index={index}
                                conversationID={conv.data.id}
                                onClick={onSwitchBetweenActiveChats(conv.data.id)}
                                selectedActiveChatId={selectedConversationId}
                                sessionId={conv.data.session}
                                language={conv.data.language}
                                channel={conv.data.channel}
                                unreadMessagesCount={conv.unreadMessagesCount}
                                profileColor={conv.profileColor}
                                operatorAvatar={conv.operator?.avatar}
                                operatorName={conv.operator?.username ?? 'Operator'}
                            />
                        ))
                    ) : (
                        <p className="ml-2 mt-1">No active chats.</p>
                    )}
                </div>
            </div>
        </>
    );
};

const mapStateToProps = state => {
    return {
        conversations: state.superviseLiveChat.conversations,
        selectedConversationId: state.superviseLiveChat.selectedConversationId,
    };
};

const mapDispatchToProps = {
    setSelectedConversationId: setSelectedConversationId,
    refreshActiveChats: refreshActiveChats,
};

export default connect(mapStateToProps, mapDispatchToProps)(SupervisedChatsPanel);
