export const convertObjectArrayToObject = (array, keyProperty, initialObject) => {
    const initialValue = initialObject ? { ...initialObject } : {};
    return array.reduce((obj, item) => {
        const nextKeyValue = item[keyProperty];
        const existingMemberWithThatKey = obj[nextKeyValue];
        if (!existingMemberWithThatKey) {
            obj[nextKeyValue] = item;
        }
        return obj;
    }, initialValue);
};
