import { useEffect, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import axiosErrorHandler from '../http/AxiosErrorHandler';
import { validator } from '../services/validationService';
import ApiRequests from '../http/ApiRequests';

const useLocationForm = ({
    data, // if data is already available
    onSuccessfulSubmit, // if we want to signal to other components that this form's submition was successful
}) => {
    const [api] = useState(new ApiRequests());
    const [successMessage, setSuccessMessage] = useState();
    const [generalErrorList, setGeneralErrorList] = useState();
    const { setValue, register, handleSubmit, errors, reset } = useForm();
    const registerValidationFor = {
        id: () => register(),
        name: () => register({ required: 'Name is required.' }),
        longitude: () =>
            register({
                required: 'Longitude is required.',
                validate: value => !isNaN(value) || 'Longitude must be a number.',
            }),
        latitude: () =>
            register({
                required: 'Latitude is required.',
                validate: value => !isNaN(value) || 'Latitude must be a number.',
            }),
    };

    const onFormSubmit = useCallback(
        state => {
            const id = state.id;
            const apiCall = id ? api.updateLocation : api.createLocation;
            const locationName = state.name ? state.name : '';
            apiCall(state)
                .then(response => {
                    setSuccessMessage({ success: `Location ${locationName} sucessfully created.` });
                    reset();
                    onSuccessfulSubmit(response.data);
                })
                .catch(error => {
                    const [errorList] = axiosErrorHandler(error);
                    setGeneralErrorList(errorList);
                });
        },
        [onSuccessfulSubmit, reset, api]
    );

    useEffect(() => {
        if (data) {
            setValue('id', data.id);
            setValue('name', data.name);
            setValue('longitude', data.longitude);
            setValue('latitude', data.latitude);
        }
    }, [data, setValue]);

    return {
        fieldErrorFor: errors,
        registerValidationFor,
        onSubmit: handleSubmit(
            onFormSubmit,
            validator.extractErrorsFromInvalidForm(setGeneralErrorList)
        ),
        successMessage,
        generalErrorList,
    };
};
export default useLocationForm;
