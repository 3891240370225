import moment from 'moment';
import { Button } from 'primereact/button';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import Control from '../../components/UI/Control/Control';
import GeneralMessage from '../../components/UI/GeneralMessage';
import ApiRequests from '../../http/ApiRequests';
import axiosErrorHandler from '../../http/AxiosErrorHandler';
import DashboardTile from '../../modules/liveChatDashboard/DashboardTile';
import GeneralConversationReport from '../../modules/liveChatDashboard/GeneralConversationReport';
import OperatorSpecificReport from '../../modules/liveChatDashboard/OperatorSpecificReport';
import RefreshInterval from '../../modules/liveChatDashboard/RefreshInterval';
import { formatSummaryReportData, refreshIntervalOptions } from '../../reports/reports';
import RoboadvisorService from '../../services/roboadvisor.service';
import { validator } from '../../services/validationService';
import { downloadBlobFileWithLink } from '../../utils/fileUtils';

const LiveChatDashboard = ({ currentUser }) => {
    const [generalErrorList, setGeneralErrorList] = useState([]);
    const [api] = useState(new ApiRequests());
    const [reportData, setReportData] = useState(null);
    const [dataPeriod, setDataPeriod] = useState(null);
    const [reloadCounter, setReloadCounter] = useState(0);

    const reload = () => setReloadCounter(Math.random());

    //FORM
    const { control, errors, handleSubmit, getValues } = useForm();
    const registerValidationFor = useMemo(
        () => ({
            begin: () => ({ required: 'Choose a begin date' }),
            end: () => ({
                validate: value =>
                    !value ||
                    moment(getValues('period.begin')).isBefore(value) ||
                    '"To" date is earlier than "From" date.',
            }),
        }),
        [getValues]
    );
    const fieldErrorFor = errors;

    // END FORM

    const loadReport = useCallback(
        filter => {
            api.getLiveChatSummaryReportForAllOperators(filter)
                .then(summaryReportResponse => {
                    if (summaryReportResponse.data) {
                        const data = summaryReportResponse.data;
                        setDataPeriod(data.period);
                        const formattedData = formatSummaryReportData(data);
                        setReportData(formattedData);
                    }
                })
                .catch(error => {
                    const [errorList] = axiosErrorHandler(error);
                    setGeneralErrorList(errorList);
                });
        },
        [api, setGeneralErrorList, setReportData]
    );

    const exportReport = useCallback(
        filter => {
            api.exportLiveChatSummaryReportForAllOperators(filter)
                .then(response => {
                    if (response) {
                        downloadBlobFileWithLink(response);
                    }
                })
                .catch(error => {
                    const [errorList] = axiosErrorHandler(error);
                    setGeneralErrorList(errorList);
                });
        },
        [api]
    );

    const onSubmit = useMemo(
        () =>
            handleSubmit(data => {
                loadReport({ ...data, username: currentUser.username });
            }, validator.extractErrorsFromInvalidForm(setGeneralErrorList)),
        [currentUser, handleSubmit, loadReport]
    );

    const onExportSubmit = useMemo(
        () =>
            handleSubmit(data => {
                exportReport({ ...data, username: currentUser.username });
            }, validator.extractErrorsFromInvalidForm(setGeneralErrorList)),
        [currentUser, handleSubmit, exportReport]
    );

    useEffect(() => {
        if (currentUser && currentUser.username) {
            Promise.all([
                api.getLiveChatSummaryReportForAllOperators({
                    period: {
                        begin: getValues('period.begin'),
                        end: getValues('period.end'),
                    },
                    username: currentUser.username,
                }),
                api.searchForUsers(['ONLINE']),
            ])
                .then(([summaryReportResponse, usersOnlineResponse]) => {
                    if (summaryReportResponse.data) {
                        const data = summaryReportResponse.data;
                        setDataPeriod(data.period);
                        const formattedData = formatSummaryReportData(data);
                        setReportData(formattedData);
                    }
                })
                .catch(error => {
                    const [errorList] = axiosErrorHandler(error);
                    setGeneralErrorList(errorList);
                });
        }
    }, [reloadCounter, currentUser, api, getValues]);

    return (
        <>
            <GeneralMessage className="c-msg" errorList={generalErrorList} />

            {/* <PageRootNav label="Dashboard" /> */}

            <div className="container-fluid animated fadeIn card p-4">
                <div className="d-flex ">
                    <RefreshInterval reload={reload} intervalOptions={refreshIntervalOptions} />
                </div>
            </div>

            <div className="container-fluid animated fadeIn card p-4">
                <div className="row">
                    <div className="col-12">
                        <h2>General Report</h2>
                        <hr />
                    </div>
                </div>
                <form className="row w-100" noValidate>
                    <div className="col-2">
                        <Control
                            error={fieldErrorFor.period?.begin?.message}
                            registerRef={registerValidationFor?.begin}
                            control={control}
                            name="period.begin"
                            type="datepicker"
                            label="From"
                            showTime
                            value={moment().startOf('day')._d}
                        />
                    </div>
                    <div className="col-2">
                        <Control
                            error={fieldErrorFor.period?.end?.message}
                            registerRef={registerValidationFor?.end}
                            control={control}
                            name="period.end"
                            type="datepicker"
                            label="To"
                            showTime
                            value={null}
                        />
                    </div>
                    <div className="col-2">
                        <div className="form-control-block d-flex flex-column">
                            <label>&nbsp;</label>
                            <Button
                                className="p-button-sm"
                                label="Load Report"
                                type="submit"
                                onClick={onSubmit}
                            />
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="form-control-block d-flex flex-column">
                            <label>&nbsp;</label>
                            <Button
                                className="p-button-sm"
                                label="Export"
                                type="submit"
                                onClick={onExportSubmit}
                            />
                        </div>
                    </div>
                </form>
                <hr />
                {dataPeriod && Array.isArray(reportData) && reportData.length ? (
                    <>
                        <div className="row">
                            <div className="col-12">
                                <h2 className="display-6 mb-4" style={{ fontWeight: 600 }}>
                                    Live Chat Summary report from{' '}
                                    {RoboadvisorService.formatDateTime(
                                        dataPeriod.begin,
                                        RoboadvisorService.dateTimeMomentFormatMinutes
                                    )}{' '}
                                    to&nbsp;
                                    {RoboadvisorService.formatDateTime(
                                        dataPeriod.end,
                                        RoboadvisorService.dateTimeMomentFormatMinutes
                                    )}
                                </h2>
                            </div>
                        </div>
                        <div className="row">
                            {Array.isArray(reportData) && reportData.length
                                ? reportData.map((data, index) => (
                                      <DashboardTile
                                          key={index}
                                          label={data.label}
                                          value={data.value}
                                          unit={data.unit}
                                      />
                                  ))
                                : null}
                        </div>
                    </>
                ) : (
                    <span>No report data.</span>
                )}
            </div>

            <OperatorSpecificReport
                preselectedOperator={null}
                reloadCounter={reloadCounter}
                setGeneralErrorList={setGeneralErrorList}
            />
            <GeneralConversationReport
                reloadCounter={reloadCounter}
                setGeneralErrorList={setGeneralErrorList}
                includeForwarded={true}
            />
        </>
    );
};

const mapStateToProps = state => ({
    currentUser: state.auth.currentUser,
});
export default connect(mapStateToProps, null)(LiveChatDashboard);
