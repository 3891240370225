import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { confirmDialog } from 'primereact/confirmdialog';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import ApiRequests from '../http/ApiRequests';
import axiosErrorHandler from '../http/AxiosErrorHandler';
import validationService from '../services/validationService';
import { sortByDateCompareFunction } from '../utils/sortUtils';

const useFormLogin = () => {
    const [intentAnswers, setIntentAnswers] = useState([]);
    const {
        register,
        handleSubmit,
        errors,
        watch,
        control,
        getValues,
        reset,
        setValue,
        trigger,
        clearErrors,
    } = useForm();
    const api = useMemo(() => new ApiRequests(), []);
    const watchAllFields = watch();
    const validator = useMemo(validationService, []);
    const [sortFormChangeIncrement, setSortFormChangeIncrement] = useState(1);
    const [generalErrorList, setGeneralErrorList] = useState([]);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const { getValues: _getValues, ...sortForm } = useForm({
        defaultValues: { sortBy: 'name', sortOrderIsDesc: false },
    });
    const watchSortBy = sortForm.watch('sortBy');
    const [sortedIntentList, setSortedIntentList] = useState([]);
    const [intentsOptions, setIntentsOptions] = useState([]);
    const [selectedIntent, setSelectedIntent] = useState(null);
    const [ratingMessagesShown, setRatingMessagesShown] = useState(false);
    const [liveChatMessagesShown, setLiveChatMessagesShown] = useState(false);
    const registerValidationFor = useMemo(
        () => ({
            language: () =>
                register({
                    required: validator.errorMessages.MISSING_LANG,
                }),
            channel: () =>
                register({
                    required: validator.errorMessages.MISSING_CHANNEL,
                }),
            intentId: () =>
                register({
                    required: validator.errorMessages.MISSING_INENT,
                }),
        }),
        [
            register,
            validator.errorMessages.MISSING_CHANNEL,
            validator.errorMessages.MISSING_INENT,
            validator.errorMessages.MISSING_LANG,
        ]
    );
    const langOptions = [
        { label: 'English', value: 'EN' },
        { label: 'Bulgarian', value: 'BG' },
    ];
    const channelOptions = [
        { label: 'Viber', value: 'VIBER' },
        { label: 'Messenger', value: 'MESSENGER' },
        { label: 'Web', value: 'API' },
    ];
    const sortOptions = useMemo(
        () => [
            { label: 'Alphabetical', value: 'name' },
            { label: 'Created', value: 'uploadedTs' },
            { label: 'Last Updated', value: 'updatedTs' },
        ],
        []
    );

    const onSortFormChange = useCallback(() => {
        setSortFormChangeIncrement(Math.random());
    }, []);

    const onSubmit = useCallback(
        data => {
            api.getIntentAnswerSearchResults({ ...data, pending: false })
                .then(response => {
                    if (response.data) {
                        if (Array.isArray(response.data) && response.data.length) {
                            setSelectedAnswer(response.data);
                        } else {
                            // if no responses available we need some data to set up the form
                            setSelectedAnswer([
                                {
                                    language: data.language,
                                    channel: data.channel,
                                    intent: intentsOptions.find(
                                        intent => intent.id === parseInt(data.intentId)
                                    ),
                                    lastAnswer: false,
                                    requestLiveChat: false,
                                },
                            ]);
                        }
                    }
                })
                .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setGeneralErrorList(errorList);
                    setSelectedAnswer(null);
                })
                .finally(clearErrors);
        },
        [api, clearErrors, intentsOptions]
    );

    const intentSearchRef = useRef();
    const [isFilterInputDisabled, setIsFilterInputDisabled] = useState(true);
    const [loadingIntents, setLoadingIntents] = useState(false);

    const getOptionClassName = option => {
        const status = get(option, 'status', '');
        const enableStatus = get(option, 'enabled', true);
        if (status === 'APPROVED' && !enableStatus) {
            return 'disabled';
        }
        return status.toLowerCase();
    };

    const getIntentList = useCallback(() => {
        api.getIntentList()
            .then(res => {
                const intentList = res.data.map(i => ({
                    ...i,
                    id: i.id,
                    name: i.name,
                    className: getOptionClassName(i),
                }));
                setIntentsOptions(intentList);
            })
            .catch(err => {
                setIntentsOptions([]);
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            });
    }, [api, setIntentsOptions]);

    const clearIntentSearch = useCallback(() => {
        if (intentSearchRef.current) {
            intentSearchRef.current.value = '';
            getIntentList();
        }
    }, [intentSearchRef, getIntentList]);

    const filterIntents = useCallback(
        text => {
            setLoadingIntents(true);
            trigger();
            api.searchIntent({ approved: true, name: text })
                .then(response => {
                    const intentList = response.data.map(i => ({
                        id: i.id,
                        name: i.name,
                        className: getOptionClassName(i),
                    }));
                    setIntentsOptions(intentList);
                })
                .catch(error => {
                    const [errorList] = axiosErrorHandler(error);
                    setGeneralErrorList(errorList);
                })
                .finally(() => setLoadingIntents(false));
        },
        [trigger, api]
    );

    useEffect(() => {
        getIntentList();
    }, [api, getIntentList]);

    useEffect(() => {
        setIsFilterInputDisabled(false);
    }, [watchAllFields.language, watchAllFields.channel, clearIntentSearch]);

    useEffect(() => {
        const language = getValues('language');
        const channel = getValues('channel');
        const intentId = getValues('intentId');
        if (language && channel && intentId) {
            onSubmit({ language, channel, intentId });
        } else {
            setSelectedAnswer(null);
        }
    }, [
        watchAllFields.language,
        watchAllFields.channel,
        watchAllFields.intentId,
        getValues,
        onSubmit,
    ]);

    const sortIntents = useCallback((intents, getValuesPar) => {
        const sortBy = getValuesPar('sortBy');
        const sortOrderIsDesc = getValuesPar('sortOrderIsDesc');
        if (sortBy) {
            const listCopy = [...intents];
            if (sortBy === 'name') {
                const sortedByNameIntents = orderBy(
                    listCopy,
                    sortBy,
                    sortOrderIsDesc ? ['desc'] : ['asc']
                );
                setSortedIntentList(sortedByNameIntents);
            } else {
                const sortedIntents = listCopy.sort(
                    sortByDateCompareFunction(sortBy, sortOrderIsDesc)
                );
                setSortedIntentList(sortedIntents);
            }
        }
    }, []);

    useEffect(() => {
        const sortBy = _getValues('sortBy');
        if (sortBy) {
            sortIntents(intentsOptions, _getValues);
        } else {
            // if sortBy input is reset
            setSortedIntentList(intentsOptions);
        }
    }, [intentsOptions, sortIntents, _getValues, sortFormChangeIncrement]);

    const deleteWholeAnswer = useCallback(
        e => {
            e.preventDefault();
            const complexAnswerId = getValues(['language', 'channel', 'intentId']);
            confirmDialog({
                message: `Are you sure you want to delete the answer for this intent? Your request for deletion will be sent for approval.`,
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () =>
                    api
                        .deleteIntentAnswer({
                            language: complexAnswerId.language,
                            channel: complexAnswerId.channel,
                            intentId: complexAnswerId.intentId,
                        })
                        .then(res => {
                            setSuccessMessage({
                                success: 'Request to delete answer is sent for approval.',
                            });
                            reset();
                            setSelectedIntent(null);
                        })
                        .catch(err => {
                            const [errorList] = axiosErrorHandler(err);
                            setGeneralErrorList(errorList);
                        }),
                reject: () => {},
            });
        },
        [api, getValues, reset]
    );

    return {
        registerValidationFor,
        onSubmit: handleSubmit(
            onSubmit,
            validator.extractErrorsFromInvalidForm(setGeneralErrorList)
        ),
        successMessage,
        generalErrorList,
        fieldErrorFor: errors,
        langOptions,
        channelOptions,
        watchAllFields,
        intentsOptions,
        selectedIntent,
        control,
        getValues,
        reset,
        setValue,
        register,
        errors,
        intentAnswers,
        setIntentAnswers,
        ratingMessagesShown,
        setRatingMessagesShown,
        liveChatMessagesShown,
        setLiveChatMessagesShown,
        deleteWholeAnswer,
        filterIntents,
        loadingIntents,
        isFilterInputDisabled,
        intentSearchRef,
        selectedAnswer,
        setGeneralErrorList,
        sortOptions,
        onSortFormChange,
        sortedIntentList,
        _getValues,
        sortForm,
        watchSortBy,
    };
};

export default useFormLogin;
