import React from 'react';

/** This component is used to properly align
 *  buttons next to form input controls
 */
const FormButtonWrapper = ({ classNameList, children, ...props }) => {
    return (
        <div
            className={`d-flex align-items-start`}
            style={{ marginTop: 'calc(1.5em + 5px)' }}
            {...props}
        >
            {children}
        </div>
    );
};

export default FormButtonWrapper;
