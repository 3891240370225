import React from 'react';
import Grid from '../../components/UI/Grid/Grid';
import useGeneralConversationReport from '../../hooks/useGeneralConversationReport';
import GeneralConversationReportFilter from './GeneralConversationReportFilter';

const GeneralConversationReport = ({ reloadCounter, setGeneralErrorList, includeForwarded }) => {
    const hook = useGeneralConversationReport({ reloadCounter, includeForwarded });
    const {
        conversationGridHeaders,
        conversationGridData,
        gridMeta,
        messagesGridHeaders,
        messagesGridData,
        onGridPage,
        selectedConversation,
        changeSelectedConversation,
    } = hook;
    return (
        <div className="container-fluid animated fadeIn card p-4">
            <div className="row">
                <div className="col-12">
                    <h2>General Conversation Report</h2>
                    <hr />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <GeneralConversationReportFilter hook={hook} />
                    <hr />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Grid
                        selection={selectedConversation}
                        changeSelectedConversation={changeSelectedConversation}
                        selectionMode="single"
                        paginator={true}
                        lazy={true}
                        total={gridMeta.total}
                        rows={gridMeta.limit}
                        first={gridMeta.offset}
                        onPage={onGridPage}
                        columns={conversationGridHeaders}
                        data={conversationGridData}
                    />
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-12">
                    <h2>Conversation Messages</h2>
                    <hr />
                    {messagesGridData.length && messagesGridData.length > 0 ? (
                        <Grid
                            paginator={false}
                            lazy={true}
                            columns={messagesGridHeaders}
                            data={messagesGridData}
                        />
                    ) : (
                        <span>No messages available.</span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default GeneralConversationReport;
