import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { appMenuItems } from '../menu/appMenu';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Sidebar from '../components/Sidebar/Sidebar';
import NotificationSound from '../components/UI/NotificationSound';
import TextProcessItem from '../modules/textProcess/TextProcessItem';
import ProtectedRoute from '../routes/ProtectedRoute';
import { routes } from '../routes/Routes';
import './appLayout.css';
import PageHeader from '../components/PageHeader/PageHeader';
import { ConfirmDialog } from 'primereact/confirmdialog';
import InactivityTimer from '../components/UI/InactivityTimer/InactivityTimer';
import useLogout from '../hooks/useLogout';
import { useSelector } from 'react-redux';
import { canUser } from '../permissions/permissions';

const AppLayout = ({ sidebarOpened }) => {
    const routeList = Object.keys(routes).map(key => (
        <ProtectedRoute
            path={routes[key].path}
            component={routes[key].component}
            key={key}
            exact={true}
            checkPermissions={routes[key].checkPermissions}
        />
    ));
    const user = useSelector(store => store.auth.currentUser);
    const logout = useLogout();

    return (
        <>
            <NotificationSound />
            <Header />
            <ConfirmDialog />
            {user && canUser.useLiveChat(user.permissions) ? (
                <InactivityTimer
                    inactivityTimeoutMs={7200000} // 2h per request from sportdepot
                    showDialogDurationMs={60000}
                    onInactivity={logout}
                />
            ) : null}
            <div className={`app-body sidebar-show`}>
                <Sidebar />
                <main className={`${sidebarOpened ? 'collapsed' : ''} main-layout`}>
                    <div className="main-pages-container">
                        <PageHeader menuItems={appMenuItems} />
                        <Switch>
                            {routeList}
                            {/* catch all not found routes and redirect to home */}
                            <Route>
                                <Redirect to={{ pathname: routes.base.path }} />
                            </Route>
                        </Switch>
                        <TextProcessItem />
                    </div>
                </main>
                <Footer />
            </div>
        </>
    );
};

const mapStateToProps = state => {
    return {
        sidebarOpened: state.sidebar.sidebarOpened,
    };
};

export default connect(mapStateToProps)(AppLayout);
