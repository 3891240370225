import React, { useMemo } from 'react';
import ProfileConfigPanel from '../../modules/profile/ProfileInformation';
import ProfileReviewPanel from '../../modules/profile/ProfileReviewPanel';
import useProfileConfig from '../../hooks/useProfileConfig';
import { connect } from 'react-redux';
import { canUser } from '../../permissions/permissions';

const Profile = ({ currentUser }) => {
    const userPermissions = useMemo(
        () =>
            currentUser && Array.isArray(currentUser.permissions) ? currentUser.permissions : [],
        [currentUser]
    );
    const hook = useProfileConfig({ userPermissions });
    return (
        <>
            <h1 className="mb-5 display-4">Profile information</h1>
            <ProfileConfigPanel hook={hook} />
            {canUser.approveProfileChanges(userPermissions) ? (
                <ProfileReviewPanel hook={hook} />
            ) : null}
        </>
    );
};

const mapStateToProps = state => ({
    currentUser: state.auth.currentUser,
});

export default connect(mapStateToProps, null)(Profile);
