import get from 'lodash/get';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import * as React from 'react';
import Config from '../../../config/config';
import RoboadvisorService from '../../../services/roboadvisor.service';
import './Grid.css';

class Grid extends React.Component {
    state = {
        filters: {},
        sortField: '',
        sortOrder: 0,
    };

    imageFormatter = (rowData, column) => {
        return (
            <img src={Config.domain + rowData[column.field]} style={{ height: '80px' }} alt="" />
        );
    };

    linkFormatter = (rowData, column) => {
        return Config.domain + rowData[column.field];
    };

    dateFormatter = (rowData, column) => {
        return RoboadvisorService.formatDateTime(
            rowData[column.field],
            RoboadvisorService.dateMomentFormat
        );
    };

    dateTimeFormatter = (rowData, column) => {
        return RoboadvisorService.formatDateTime(
            get(rowData, column.field, ''),
            RoboadvisorService.dateTimeMomentFormatSeconds
        );
    };

    booleanFormatter = (rowData, column) => {
        return rowData[column.field] ? 'Yes' : 'No';
    };

    booleanWithIconsFormatter = (rowData, column) => {
        return rowData[column.field] ? (
            <i className="pi pi-check" style={{ marginLeft: '10px' }}></i>
        ) : (
            ''
        );
    };

    deliveryMethodFormatter = (rowData, column) => {
        return RoboadvisorService.formatDeliveryTypes(rowData[column.field]);
    };

    filterTypeFormatter = (rowData, column) => {
        return RoboadvisorService.formatFilterTypes(rowData[column.field]);
    };

    constFormatted = (rowData, column) => {
        return rowData[column.field]
            ? rowData[column.field].toLowerCase().replaceAll('_', ' ')
            : null;
    };

    arrayList = (rowData, column) => {
        const result = [];
        rowData[column.field].forEach((elem, index) =>
            result.push(
                <li key={'elem' + index}>
                    <em> {elem}</em>
                </li>
            )
        );
        return result;
    };

    rowClass = data => {
        if (data.pendingMessages)
            return {
                'datatable-row-highlighter': data.pendingMessages.length !== 0,
            };

        switch (data.status) {
            case 'NEW':
                return {
                    'datatable-row-highlighter-new': true,
                };
            case 'DELETED':
                return {
                    'datatable-row-highlighter-delete': true,
                };
            default:
                return;
        }
    };

    renderColumns = () => {
        const dynamicColumns = [];
        if (this.props.expandTable) {
            dynamicColumns.push(<Column expander style={{ width: '3em' }} key="Expander" />);
        }

        for (const element of this.props.columns) {
            let body;

            switch (element.formatter) {
                case 'image':
                    body = this.imageFormatter;
                    break;
                case 'const':
                    body = this.constFormatted;
                    break;
                case 'linkFormatter':
                    body = this.linkFormatter;
                    break;
                case 'date':
                    body = this.dateFormatter;
                    break;
                case 'datetime':
                    body = this.dateTimeFormatter;
                    break;
                case 'boolean':
                    body = this.booleanFormatter;
                    break;
                case 'booleanWithIcons':
                    body = this.booleanWithIconsFormatter;
                    break;
                case 'deliveryMethod':
                    body = this.deliveryMethodFormatter;
                    break;
                case 'filterTypes':
                    body = this.filterTypeFormatter;
                    break;
                case 'arrayList':
                    body = this.arrayList;
                    break;

                default:
                    // if you need to use formatter only once you can directly
                    // set the formatter under 'formatterFunc' when you declare the
                    // Grid's headers
                    if (typeof element.formatterFunc === 'function') {
                        body = element.formatterFunc;
                    } else {
                        body = null;
                    }
            }

            dynamicColumns.push(
                <Column
                    key={element.key}
                    field={element.key}
                    header={element.name}
                    headerClassName={element.headerClassName}
                    sortable={get(element, 'sort', false)}
                    filter={get(element, 'filter', false)}
                    filterMatchMode='contains'
                    body={body}
                    style={
                        get(element, 'style', null) !== null
                            ? element.style
                            : { textOverflow: 'ellipsis' }
                    }
                />
            );
        }
        // Adding actions column only if there are action parameters
        if (
            this.props.deleteAction ||
            this.props.editAction ||
            this.props.viewAction ||
            this.props.approveAction ||
            this.props.rejectAction
        ) {
            dynamicColumns.push(
                <Column
                    key="Actions"
                    header="Actions"
                    body={this.actionTemplate}
                    className="actionBtns"
                    field="actionsColumn" // must have 'field' in order to produce proper column reordering
                />
            );
        }

        return dynamicColumns;
    };

    actionTemplate = (rowData, column) => {
        const rowId = rowData.Id;
        const buttons = (
            <div className="d-flex flex-row flex-nowrap">
                {this.props.editAction ? (
                    <Button
                        type="button"
                        id={'edit_' + rowId}
                        style={{ border: 'none', marginRight: '5px' }}
                        onClick={() => this.props.editAction(rowData)}
                        icon="fa fa-edit"
                        tooltip={'Edit'}
                        tooltipOptions={{ position: 'top' }}
                    />
                ) : null}
                {this.props.deleteAction ? (
                    <Button
                        type="button"
                        id={'delete_' + rowId}
                        style={{ background: '#f86c6b', border: 'none' }}
                        onClick={() => this.props.deleteAction(rowData)}
                        icon="fa fa-trash-o"
                        tooltip={'Delete'}
                        tooltipOptions={{ position: 'top' }}
                    />
                ) : null}
                {this.props.viewAction ? (
                    <Button
                        type="button"
                        id={'view' + rowId}
                        style={{ background: '#33cc99', border: 'none' }}
                        onClick={() => this.props.viewAction(rowData)}
                        icon="fa fa-eye"
                        tooltip={'View'}
                        tooltipOptions={{ position: 'top' }}
                    />
                ) : null}
                {this.props.approveAction ? (
                    <Button
                        type="button"
                        id={'approve' + rowId}
                        icon="fa fa-check"
                        className="add-btn mr-2"
                        onClick={() => this.props.approveAction(rowData)}
                        label="Approve"
                    />
                ) : null}
                {this.props.rejectAction ? (
                    <Button
                        type="button"
                        id={'reject' + rowId}
                        icon="fa fa-times"
                        className="delete-btn"
                        onClick={() => this.props.rejectAction(rowData)}
                        label="Reject"
                    />
                ) : null}
            </div>
        );

        return buttons;
    };

    onFilter = event => {
        // TODO
    };

    onSort = event => {
        // TODO
    };

    render() {
        return (
            <>
                {this.props.filter}
                <DataTable
                    expandedRows={this.props.selectedRow}
                    rowExpansionTemplate={this.props.rowExpansionTemplate}
                    onRowToggle={this.props.onRowToggle}
                    onRowExpand={this.props.onRowExpand}
                    onRowCollapse={this.props.onRowCollapse}
                    dataKey={this.props.expandDataKey}
                    rowHover={this.props.hover}
                    selection={this.props.selection ? this.props.selection : null}
                    onSelectionChange={e => this.props.changeSelectedConversation(e.value)}
                    selectionMode={
                        ['single', 'multiple'].includes(this.props.selectionMode)
                            ? this.props.selectionMode
                            : null
                    }
                    paginator={this.props.paginator}
                    rows={this.props.rows}
                    onPage={this.props.onPage}
                    onSort={this.onSort}
                    onFilter={this.onFilter}
                    filters={this.state.filters}
                    lazy={this.props.lazy}
                    first={this.props.first}
                    totalRecords={this.props.total}
                    value={this.props.data}
                    sortMode="multiple"
                    sortField={this.state.sortField}
                    sortOrder={this.state.sortOrder}
                    autoLayout={false}
                    resizableColumns={false}
                    reorderableColumns={this.props.reorderable}
                    emptyMessage={'No results'}
                    header={this.props.header}
                    className={this.props.classes}
                    rowClassName={this.rowClass}
                    responsiveLayout="scroll"
                    size="small"
                >
                    {this.renderColumns()}
                </DataTable>
            </>
        );
    }
}

export default Grid;
