import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React from 'react';
import Control from '../UI/Control/Control';

const LiveChatTemplateEdit = ({ hook }) => {
    const {
        control,
        name,
        onNameChange,
        description,
        onDescriptionChange,
        content,
        onContentChange,
        visibleEditDialog,
        closeEditDialog,
        formIsDirty,
        onEditSubmit,
    } = hook;
    return (
        <Dialog
            visible={visibleEditDialog}
            style={{ width: '450px' }}
            header="Edit Template"
            onHide={closeEditDialog}
        >
            <div className="p-field">
                <Control
                    name="templateName"
                    type="text"
                    control={control}
                    label="Template name"
                    info="The name of the live chat template"
                    value={name}
                    onChange={e => {
                        onNameChange(e.target.value);
                    }}
                />
            </div>
            <div className="p-field">
                <Control
                    name="templateDescription"
                    type="text"
                    control={control}
                    label="Template description"
                    info="The description of the live chat template"
                    value={description}
                    onChange={e => {
                        onDescriptionChange(e.target.value);
                    }}
                />
            </div>
            <div className="p-field">
                <Control
                    name="templateContent"
                    type="textarea"
                    control={control}
                    label="Template content"
                    info="The content of the live chat template"
                    value={content}
                    onChange={e => {
                        onContentChange(e.target.value);
                    }}
                    style={{ minHeight: 'unset' }}
                />
            </div>
            <div style={{ textAlign: 'right' }}>
                <Button
                    type="button"
                    className="info-btn mr-2"
                    icon="fa fa-minus"
                    label="CANCEL"
                    tooltipOptions={{ position: 'top' }}
                    onClick={closeEditDialog}
                />
                <Button
                    className="add-btn"
                    icon="fa fa-check"
                    label="CONFIRM"
                    disabled={!formIsDirty}
                    tooltipOptions={{ position: 'top' }}
                    onClick={onEditSubmit}
                />
            </div>
        </Dialog>
    );
};

export default LiveChatTemplateEdit;
