import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import React from 'react';
import FlagCounter from '../../components/UI/FlagCounter';
import GeneralMessage from '../../components/UI/GeneralMessage';
import Grid from '../../components/UI/Grid/Grid';
import useLocationCoordinates from '../../hooks/useLocationCoordinates';
import LocationCoordinatesConfigPanel from '../../modules/locationCoordinates/LocationCoordinatesConfigPanel';
import LocationForm from '../../modules/locationCoordinates/LocationForm';
import { routes } from '../../routes/Routes';

const LocationCoordinates = () => {
    const hook = useLocationCoordinates();
    hook.panelTitle = 'Approved Locations & Coordinates';

    return (
        <>
            <GeneralMessage
                className="c-msg"
                successMessage={hook.successMessage}
                errorList={hook.generalErrorList}
            />
            <LocationCoordinatesConfigPanel hook={hook} />
            <div className="animated fadeIn">
                <Dialog
                    visible={!!hook.locationForEdit}
                    style={{ width: '450px' }}
                    modal="true"
                    header="Edit location"
                    onHide={hook.onCancelEditLocation}
                >
                    <LocationForm
                        data={hook.locationForEdit}
                        onSuccessfulSubmit={hook.onEditLocationSubmit}
                        onCancel={hook.onCancelEditLocation}
                    />
                </Dialog>
                <Dialog
                    visible={hook.newLocationDialogIsOpen}
                    style={{ width: '450px' }}
                    modal="true"
                    header="New location"
                    onHide={hook.onCancelNewLocation}
                >
                    <LocationForm
                        onSuccessfulSubmit={hook.onNewLocationSubmit}
                        onCancel={hook.onCancelNewLocation}
                    />
                </Dialog>
                <div className="container-fluid card p-4 mb-5 animated fadeIn">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="display-5">{hook.panelTitle}</h2>
                            <hr />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="w-100 d-flex flex-row justify-content-begin align-items-center">
                                <Button label="Add New Location" onClick={hook.onNewLocation} />
                                <Divider layout="vertical" type="solid" />
                                <span className="mr-1" style={{ fontSize: '1.5em' }}>
                                    Location changes awaiting approval:
                                </span>
                                <a
                                    href={routes.locationCoordinatesApproval.path}
                                    className="d-flex flex-row align-items-center text-decoration-none"
                                >
                                    <FlagCounter
                                        tooltipLabel="New locations awaiting approval"
                                        containerClassNames="ml-1 mr-1 text-success"
                                        textBeforeFlag={`(${hook.pendingLocationCount.new})`}
                                    />
                                    <FlagCounter
                                        tooltipLabel="Location updates awaiting approval"
                                        containerClassNames="ml-1 mr-1 text-warning"
                                        textBeforeFlag={`(${hook.pendingLocationCount.updated})`}
                                    />
                                    <FlagCounter
                                        tooltipLabel="Location remove requests awaiting approval"
                                        containerClassNames="ml-1 mr-1 text-danger"
                                        textBeforeFlag={`(${hook.pendingLocationCount.deleted})`}
                                    />
                                </a>
                            </div>
                            <hr />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Grid
                                columns={hook.gridHeaders}
                                data={hook.locationList}
                                editAction={hook.onEditLocation}
                                deleteAction={hook.confirmDeleteCoordinates}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LocationCoordinates;
