import React from 'react';
import useForbiddenPhrases from '../../hooks/useForbiddenPhrases';
import ForbiddenPhraseForm from '../../modules/forbiddenPhrases/ForbiddenPhraseForm';
import PhrasesForApprovalPanel from '../../modules/forbiddenPhrases/PhrasesForApprovalPanel';
import ActivePhrasesPanel from '../../modules/forbiddenPhrases/ActivePhrasesPanel';

const ForbiddenPhrasses = () => {
    const hook = useForbiddenPhrases();

    return (
        <>
            <ForbiddenPhraseForm hook={hook} />
            <PhrasesForApprovalPanel hook={hook} />
            <ActivePhrasesPanel hook={hook} />
        </>
    );
};

export default ForbiddenPhrasses;
