import ApiRequests from '../http/ApiRequests';
import axiosErrorHandler from '../http/AxiosErrorHandler';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import get from 'lodash/get';
import useConfirmDialog from './useConfirmDialog';
import { useForm } from 'react-hook-form';
import validationService from '../services/validationService';
import { Tooltip } from 'primereact/tooltip';
import { validateSsmlWrapperTag } from "../utils/validateUtils";

const usePredefinedMessageData = () => {
    const api = useMemo(() => new ApiRequests(), []);
    const { register, handleSubmit, errors, getValues, setValue, clearErrors } = useForm();
    const validator = validationService();
    const [predefinedMessages, setPredefinedMessages] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRowLanguage, setSelectedRowLanguage] = useState("");
    const [selectedRowMessage, setSelectedRowMessage] = useState("");
    const [selectedRowAlternativeContent, setSelectedRowAlternativeContent] = useState("");
    const [selectedRowDescription, setSelectedRowDescription] = useState("");
    const [selectedRowId, setSelectedRowId] = useState(0);
    const [generalErrorList, setGeneralErrorList] = useState([]);
    const [visibleEdit, setVisibleEdit] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
    const [originalMessage, setOriginalMessage] = useState("");
    const [originalAlternativeContent, setOriginalAlternativeContent] = useState("");
    const [originalDescription, setOriginalDescription] = useState("");

    const confirm = useConfirmDialog();

    const canSupportTextToSpeech = true; // TODO: check if feature is enabled for this client

    const tooltipFormatterFunc = rowData => {
        const tooltip = rowData.alternativeContent;
        return (
            <div>
                {rowData.messageContent}
                {canSupportTextToSpeech && tooltip ? (
                    <>
                        <span data-pr-tooltip={tooltip} className={`label-inf-${rowData.id}`}>
                            {" "}
                            <i className="fa fa-volume-up" />
                        </span>
                        <Tooltip target={`.label-inf-${rowData.id}`} position="top" />
                    </>
                ) : null}
            </div>
        );
    };

    const predefinedGridHeaders = [
        { name: "ID", key: "id" },
        { name: "Message Name", key: "messageName" },
        { name: "Content", key: "messageContent", formatterFunc: tooltipFormatterFunc },
        { name: "Description", key: "description" },
        { name: "Language", key: "language" },
        { name: "Updated By", key: "updatedBy" },
        { name: "Update Time", key: "updatedTs", formatter: "datetime" },
        { name: "Awaiting", key: "awaiting" },
    ];

    const pendingGridHeaders = [
        { name: "ID", key: "id" },
        { name: "Content", key: "messageContent", formatterFunc: tooltipFormatterFunc },
        { name: "Description", key: "description" },
        { name: "Created By", key: "createdBy" },
        { name: "Created Time", key: "createdTs", formatter: "datetime" },
    ];

    const getDefaultMessages = useCallback(() => {
        api.getPredefinedMessages()
            .then(res => {
                setPredefinedMessages(processData(get(res, "data", [])));
            })
            .catch(err => {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            });
    }, [api]);

    useEffect(() => {
        getDefaultMessages();
    }, [getDefaultMessages]);

    const processData = data => {
        return data.map(item => ({
            id: item.id,
            messageName: item.messageName,
            messageContent: item.messageContent,
            alternativeContent: item.alternativeContent,
            language: item.language,
            description: item.description,
            updatedBy: item.updatedBy,
            updatedTs: item.updatedTs,
            pendingMessages: item.pendingMessages,
            awaiting: item.pendingMessages.length,
        }));
    };

    const saveEditedPredefinedMessage = formData => {
        const data = {
            targetMessageId: selectedRowId,
            messageContent: formData.message,
            alternativeContent: formData.alternativeContent ? formData.alternativeContent : "",
            description: formData.description ? formData.description : "",
        };
        api.editPredefinedMessage(data)
            .then(() => {
                setSuccessMessage({ success: "Successfully saved changes" });
                getDefaultMessages();
            })
            .catch(err => {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            });

        closeEditDialog();
    };

    const registerValidationFor = {
        message: () =>
            register({
                required: validator.errorMessages.MISSING_CONTENT,
                validate: value => value.trim() !== "" || "Text is missing.",
            }),
        alternativeContent: () =>
            register({
                validate: validateSsmlWrapperTag,
            }),
        description: () => register(),
    };

    const onRowToggle = expandedData => {
        setSelectedRow(expandedData.data);
    };

    const closeEditDialog = () => {
        setVisibleEdit(false);
        setSelectedRowLanguage("");
        setSelectedRowMessage("");
        setSelectedRowAlternativeContent("");
        setSelectedRowDescription("");
        setSelectedRowId(0);
        setSaveButtonDisabled(true);
        setOriginalMessage("");
        setOriginalDescription("");
        setOriginalAlternativeContent("");
    };

    const editClickHandler = selectedElement => {
        setSelectedRowLanguage(selectedElement.language);
        setSelectedRowMessage(selectedElement.messageContent);
        setOriginalMessage(selectedElement.messageContent);
        setSelectedRowAlternativeContent(selectedElement.alternativeContent ?? "");
        setOriginalAlternativeContent(selectedElement.alternativeContent ?? "");
        setOriginalDescription(selectedElement.description);
        setSelectedRowDescription(selectedElement.description);
        setSelectedRowId(selectedElement.id);
        setVisibleEdit(true);
    };

    const approveClickHandler = selectedElement => {
        const acceptFunc = async () => {
            try {
                await api.approvePendingPredefinedMessage(selectedElement.id);
                setSuccessMessage({ success: "Successfully approved changes" });
                getDefaultMessages();
            } catch (err) {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            }
        };

        const conformProps = {
            message: `Are you sure you want to Approve pending predefined message with id ${selectedElement.id}?`,
            header: "Confirm Approve",
            icon: "pi pi-plus-circle",
            accept: () => acceptFunc(),
            reject: () => {},
        };

        confirm(conformProps);
    };

    const rejectClickHandler = selectedElement => {
        const acceptFunction = async () => {
            try {
                await api.rejectPendingPredefinedMessage(selectedElement.id);
                setSuccessMessage({ success: "Successfully rejected changes" });
                getDefaultMessages();
            } catch (err) {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            }
        };

        const conformProps = {
            message: `Are you sure you want to Reject and Delete pending predefined message with id ${selectedElement.id}?`,
            header: "Confirm Approve",
            icon: "fa fa-trash-o",
            accept: () => acceptFunction(),
            reject: () => {},
        };

        confirm(conformProps);
    };

    const editMessageHandler = e => {
        setSelectedRowMessage(e.target.value);
        originalMessage !== e.target.value
            ? setSaveButtonDisabled(false)
            : setSaveButtonDisabled(true);
    };

    const editAlternativeContentHandler = e => {
        setSelectedRowAlternativeContent(e.target.value);
        originalAlternativeContent !== e.target.value
            ? setSaveButtonDisabled(false)
            : setSaveButtonDisabled(true);
    };

    const editDescriptionHandler = e => {
        setSelectedRowDescription(e.target.value);
        originalDescription !== e.target.value
            ? setSaveButtonDisabled(false)
            : setSaveButtonDisabled(true);
    };

    return {
        predefinedMessages,
        predefinedGridHeaders,
        onRowToggle,
        pendingGridHeaders,
        selectedRow,
        editClickHandler,
        approveClickHandler,
        rejectClickHandler,
        visibleEdit,
        closeEditDialog,
        onSubmit: handleSubmit(
            saveEditedPredefinedMessage,
            validator.extractErrorsFromInvalidForm(setGeneralErrorList)
        ),
        selectedRowLanguage,
        selectedRowMessage,
        selectedRowAlternativeContent,
        selectedRowDescription,
        editDescriptionHandler,
        editMessageHandler,
        editAlternativeContentHandler,
        successMessage,
        generalErrorList,
        saveButtonDisabled,
        fieldErrorFor: errors,
        registerValidationFor,
        getValues,
        setValue,
        clearErrors,
    };
};

export default usePredefinedMessageData;
