import React, { useCallback, useMemo, useEffect, useState } from 'react';
import GeneralMessage from '../../components/UI/GeneralMessage';
import * as constants from '../../constants/constants';
import ApiRequests from '../../http/ApiRequests';
import axiosErrorHandler from '../../http/AxiosErrorHandler';
import ExceptionalWorkingDaysPanel from '../../modules/liveChatWorkingHours/ExceptionalWorkingDaysPanel';
import PendingApprovalWorkingHours from '../../modules/liveChatWorkingHours/PendingApprovalWorkingHours';
import WeeklyWorkingHoursPanel from '../../modules/liveChatWorkingHours/WeeklyWorkingHoursPanel';
import get from 'lodash/get';

const LiveChatWorkingHours = () => {
    const [api] = useState(new ApiRequests());
    const [generalErrorList, setGeneralErrorList] = useState([]);
    const [successMessage, setSuccessMessage] = useState(null);
    const [allPendingWorkingHours, setAllPendingWorkingHours] = useState([]);
    const [weeklyWorkingHours, setWeeklyWorkingHours] = useState([]);
    const [exceptionalWorkingHours, setExceptionalWorkingHours] = useState([]);
    const [history, setHistory] = useState();
    const [reloadOnChange, setReloadOnChange] = useState(0);
    const reload = () => {
        setReloadOnChange(Math.random());
    };
    const initialGridMeta = useMemo(
        () => ({
            limit: 10,
            page: 0,
            offset: 0,
        }),
        []
    );
    const [gridMeta, setGridMeta] = useState({ ...initialGridMeta });

    const extractWeeklyWorkingHours = useCallback(approvedWorkingHoursList => {
        let weekWorkingHoursList = [];
        Object.keys(constants.WEEK_DAYS).forEach(day => {
            const matchingWorkingHoursArray = approvedWorkingHoursList.filter(
                wh => wh.dayOfWeek === day
            );
            if (matchingWorkingHoursArray.length) {
                weekWorkingHoursList.push({ ...matchingWorkingHoursArray[0] });
            } else {
                weekWorkingHoursList.push({ dayOfWeek: day });
            }
        });
        return weekWorkingHoursList;
    }, []);

    const extractExceptionalWorkingHours = useCallback(
        approvedWorkingHours => approvedWorkingHours.filter(awh => !awh.dayOfWeek),
        []
    );

    const checkIfUpdateIsPending = useCallback(
        (workingHours, pendingWorkingHours) =>
            workingHours.map(wh => ({
                ...wh,
                changesArePendingApproval: pendingWorkingHours.some(
                    pwh =>
                        (pwh.date && pwh.date === wh.date) ||
                        (pwh.dayOfWeek && pwh.dayOfWeek === wh.dayOfWeek)
                ),
            })),
        []
    );

    useEffect(() => {
        Promise.all([
            api.getAllApprovedLiveChatWorkingHours(initialGridMeta),
            api.getAllPendingLiveChatWorkingHours(),
        ])
            .then(([approvedWorkingHoursResponse, pendingWorkingHoursResponse]) => {
                setAllPendingWorkingHours(pendingWorkingHoursResponse.data);
                setWeeklyWorkingHours(
                    checkIfUpdateIsPending(
                        extractWeeklyWorkingHours(
                            approvedWorkingHoursResponse.data.weeklyWorkingHours
                        ),
                        pendingWorkingHoursResponse.data
                    )
                );
                setExceptionalWorkingHours(
                    checkIfUpdateIsPending(
                        extractExceptionalWorkingHours(
                            approvedWorkingHoursResponse.data.exceptionalWorkingHours
                        ),
                        pendingWorkingHoursResponse.data
                    )
                );
                setHistory(approvedWorkingHoursResponse.data.history);
                setGridMeta({
                    limit: get(approvedWorkingHoursResponse, 'data.history.limit', 5),
                    offset: get(approvedWorkingHoursResponse, 'data.history.offset', 1),
                    total: get(approvedWorkingHoursResponse, 'data.history.total', 0),
                });
            })
            .catch(error => {
                const [errorList] = axiosErrorHandler(error);
                setGeneralErrorList(errorList);
            });
    }, [
        reloadOnChange,
        api,
        checkIfUpdateIsPending,
        extractExceptionalWorkingHours,
        extractWeeklyWorkingHours,
        initialGridMeta,
    ]);

    const getHistoryWorkingHours = useCallback(
        meta => {
            api.getAllApprovedLiveChatWorkingHours({ forwarded: true, ...meta })
                .then(res => {
                    setHistory(res.data.history);
                    setGridMeta({
                        limit: get(res, 'data.history.limit', 10),
                        offset: get(res, 'data.history.offset', meta.offset),
                        total: get(res, 'data.history.total', 0),
                    });
                })
                .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setGeneralErrorList(errorList);
                });
        },
        [api]
    );

    const onGridPage = event => {
        getHistoryWorkingHours({
            limit: event.rows,
            offset: event.first,
            page: event.page + 1,
        });
    };

    return (
        <>
            <GeneralMessage
                className="c-msg"
                successMessage={successMessage}
                errorList={generalErrorList}
            />

            <div className=" card p-4 mb-5 animated fadeIn">
                <WeeklyWorkingHoursPanel
                    weeklyWorkingHours={weeklyWorkingHours}
                    setGeneralErrorList={setGeneralErrorList}
                    setSuccessMessage={setSuccessMessage}
                    reload={reload}
                />
            </div>
            <div className=" card p-4 mb-5 animated fadeIn">
                <ExceptionalWorkingDaysPanel
                    exceptionalWorkingHours={exceptionalWorkingHours}
                    history={history}
                    onPage={onGridPage}
                    total={gridMeta.total}
                    rows={gridMeta.limit}
                    first={gridMeta.offset}
                    setGeneralErrorList={setGeneralErrorList}
                    setSuccessMessage={setSuccessMessage}
                    reload={reload}
                />
            </div>
            <div className=" card p-4 mb-5 animated fadeIn">
                <PendingApprovalWorkingHours
                    data={allPendingWorkingHours}
                    setGeneralErrorList={setGeneralErrorList}
                    setSuccessMessage={setSuccessMessage}
                    reload={reload}
                />
            </div>
        </>
    );
};

export default LiveChatWorkingHours;
