import axios from 'axios';
import { allRoutes } from '../routes/Routes';

// axios.defaults.withCredentials = false;
axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = `XMLHttpRequest`;
// axios.defaults.headers.post['content-type'] doesn't work becase it always gets
// combinet with whatever value is in axios.defaults.headers['content-type']
// setting the content-type here is not a problem since axios knows
// not to send it with get and delete requests
axios.defaults.headers['content-type'] = 'application/json';
const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response && error.response.status) {
            switch (error.response.status) {
                case 401:
                    if (window.location.href !== window.location.origin + allRoutes.login.path) {
                        window.location = window.location.origin + allRoutes.login.path;
                    }
                    break;
                default:
                    break;
            }
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
