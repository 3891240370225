import { Tag } from 'primereact/tag';
import React, { useCallback } from 'react';

const SupervisedLiveChatCard = ({
    conversationID,
    onClick,
    selectedActiveChatId,
    index,
    unreadMessagesCount,
    language,
    channel,
    profileColor,
    operatorName,
    operatorAvatar,
    sessionId,
}) => {
    const onClickFunction = useCallback(
        e => {
            e.preventDefault();
            if (onClick) {
                onClick(conversationID);
            }
        },
        [conversationID, onClick]
    );

    return (
        <div
            className={`live-chat-card d-flex flex-column justify-content-start p-2 ${
                selectedActiveChatId === conversationID
                    ? 'selected-live-chat-card'
                    : typeof index === 'number' && (index + 1) % 2 === 1
                    ? 'odd-line'
                    : ''
            }`}
            onClick={onClickFunction}
        >
            <div
                className="d-flex flex-row align-items-start mb-1"
                style={{ borderBottom: '1px solid gray' }}
            >
                <div className="d-flex flex-row flex-wrap align-items-start">
                    <h5 className="mr-2">CONVERSATION {conversationID} </h5>
                    <div className="d-flex juflex-row justify-content-start mb-1">
                        {language ? (
                            <Tag className="live-chat-info-tag mr-1" value={language} />
                        ) : null}
                        {channel ? (
                            <Tag
                                className="live-chat-info-tag mr-1"
                                value={channel === 'API' ? 'WEB' : channel}
                            />
                        ) : null}
                    </div>
                </div>
                {unreadMessagesCount ? (
                    <span
                        className="ml-auto px-2 bg-danger "
                        style={{
                            borderRadius: '1rem',
                            fontWeight: 600,
                        }}
                    >
                        {unreadMessagesCount}
                    </span>
                ) : null}
            </div>
            <div className="d-flex flex-row align-items-center ml-2">
                <div
                    className="d-flex justify-content-center align-items-center mr-1"
                    style={{
                        minWidth: '40px',
                        minHeight: '40px',
                        borderRadius: '50%',
                        backgroundColor: 'lightGray',
                    }}
                >
                    <i
                        className="fa fa-user"
                        style={{
                            fontSize: '30px',
                            color: profileColor ? profileColor : '#000',
                            display: 'inline-block',
                        }}
                    ></i>
                </div>
                <div className="mr-1">
                    {operatorAvatar ? (
                        <img
                            src={operatorAvatar}
                            alt=""
                            style={{
                                display: 'inline-block',
                                width: '40px',
                                height: '40px',
                                borderRadius: '50%',
                            }}
                        />
                    ) : (
                        <div className="operator-avatar">
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '1.5rem',
                                    color: 'white',
                                    backgroundColor: 'brown',
                                    borderRadius: '50%',
                                    width: '40px',
                                    height: '40px',
                                }}
                            >
                                <span>{operatorName[0].toUpperCase()}</span>
                            </div>
                        </div>
                    )}
                </div>
                <div className="d-flex flex-column ml-2" style={{ overflow: 'hidden' }}>
                    <span
                        className="live-chat-card-client-name"
                        style={{
                            fontStyle: 'italic',
                            color: '#b1b1b1',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                        }}
                        title={operatorName}
                    >
                        {operatorName}
                    </span>
                    <span className="live-chat-card-client-name" style={{ fontStyle: 'italic' }}>
                        {sessionId}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default SupervisedLiveChatCard;
