import { Button } from 'primereact/button';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ApiRequests from '../http/ApiRequests';
import axiosErrorHandler from '../http/AxiosErrorHandler';

const useLiveChatSettings = () => {
    const api = useMemo(() => new ApiRequests(), []);
    const [generalErrorList, setGeneralErrorList] = useState([]);
    const [successMessage, setSuccessMessage] = useState(null);
    const [simultaneousChats, setSimultaneousChats] = useState('');
    const [chatWaitingPeriod, setChatWaitingPeriod] = useState('');
    const [chatTimeout, setChatTimeout] = useState('');
    const [ongoingChatTimeout, setOngoingChatTimeout] = useState('');
    const [reviewGridData, setReviewGridData] = useState([]);
    const reviewGridHeaders = [
        { name: 'Simultaneous Chats', key: 'simultaneousChats' },
        { name: 'Waiting Period', key: 'chatWaitingPeriod' },
        { name: 'Timeout', key: 'chatTimeout' },
        { name: 'Timeout (Ongoing)', key: 'ongoingChatTimeout' },
        { name: 'Author', key: 'updatedByUser', headerClassName: 'historygrid-users' },
        {
            name: 'Time',
            key: 'createdTs',
            formatter: 'datetime',
            headerClassName: 'historygrid-users',
        },
        { name: 'Action', key: 'action', headerClassName: 'historygrid-ts' },
    ];
    const [historyGridData, setHistoryGridData] = useState([]);
    const historyGridHeaders = [
        { name: 'Simultaneous Chats', key: 'simultaneousChats' },
        { name: 'Waiting Period', key: 'chatWaitingPeriod' },
        { name: 'Timeout', key: 'chatTimeout' },
        { name: 'Timeout (Ongoing)', key: 'ongoingChatTimeout' },
        { name: 'Author', key: 'updatedByUser', headerClassName: 'historygrid-users' },
        { name: 'Reviewer', key: 'approvedBy', headerClassName: 'historygrid-users' },
        {
            name: 'Approve Time',
            key: 'approvedTs',
            formatter: 'datetime',
            headerClassName: 'historygrid-ts',
        },
    ];
    const liveChatConfigurationStateRef = useRef();
    const reviewDataStateRef = useRef();
    reviewDataStateRef.current = reviewGridData;
    const historyDataStateRef = useRef();
    historyDataStateRef.current = historyGridData;

    const setLiveChatConfiguration = useCallback(config => {
        liveChatConfigurationStateRef.current = config;
        setSimultaneousChats(liveChatConfigurationStateRef.current.simultaneousChats);
        setChatWaitingPeriod(liveChatConfigurationStateRef.current.chatWaitingPeriod);
        setChatTimeout(liveChatConfigurationStateRef.current.chatTimeout);
        setOngoingChatTimeout(liveChatConfigurationStateRef.current.ongoingChatTimeout);
    }, []);

    const onApproveConfig = useCallback(
        id => {
            api.approveLiveChatConfiguration(id)
                .then(res => {
                    setSuccessMessage({ success: 'Successfully approved pending configuration' });

                    setLiveChatConfiguration(res.data);
                    setReviewGridData(reviewDataStateRef.current.filter(i => i.id !== id));

                    const updatedHistoryData = [res.data, ...historyDataStateRef.current];
                    setHistoryGridData(updatedHistoryData);
                })
                .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setGeneralErrorList(errorList);
                });
        },
        [api, setLiveChatConfiguration]
    );

    const onRejectConfig = useCallback(
        id => {
            api.rejectLiveChatConfiguration(id)
                .then(() => {
                    setSuccessMessage({ success: 'Successfully rejected pending configuration' });
                    setReviewGridData(reviewDataStateRef.current.filter(i => i.id !== id));
                })
                .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setGeneralErrorList(errorList);
                });
        },
        [api]
    );

    const ApprovalPanelButtonsPair = useCallback(
        id => {
            return (
                <div className="container-fluid">
                    <div className="row">
                        <Button
                            type="button"
                            className="add-btn mr-2"
                            icon="fa fa-check"
                            onClick={() => onApproveConfig(id)}
                            label="Approve"
                        />
                        <Button
                            type="button"
                            className="delete-btn mr-2"
                            icon="fa fa-times"
                            onClick={() => onRejectConfig(id)}
                            label="Reject"
                        />
                    </div>
                </div>
            );
        },
        [onApproveConfig, onRejectConfig]
    );

    const loadCurrentConfiguration = useCallback(() => {
        api.getCurrentLiveChatConfiguration()
            .then(res => setLiveChatConfiguration(res.data))
            .catch(err => {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);

                setSimultaneousChats(0);
                setChatWaitingPeriod(0);
                setChatTimeout(0);
                setOngoingChatTimeout(0);
            });
    }, [api, setLiveChatConfiguration]);

    const loadPendingChatConfigurations = useCallback(() => {
        api.getPendingLiveChatConfigurations()
            .then(res => {
                const gridData = res.data.map(entry => ({
                    ...entry,
                    action: ApprovalPanelButtonsPair(entry.id),
                }));
                setReviewGridData(gridData);
            })
            .catch(err => {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            });
    }, [ApprovalPanelButtonsPair, api]);

    const loadConfigurationHistory = useCallback(() => {
        api.getLiveChatConfigurationHistory()
            .then(res => setHistoryGridData(res.data))
            .catch(err => {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            });
    }, [api]);

    const onSubmit = useCallback(() => {
        api.updateLiveChatConfiguration({
            simultaneousChats: simultaneousChats,
            chatWaitingPeriod: chatWaitingPeriod,
            chatTimeout: chatTimeout,
            ongoingChatTimeout: ongoingChatTimeout,
        })
            .then(res => {
                setSuccessMessage({ success: 'Successfully sent configuration for review' });
                const reviewEntry = { ...res.data, action: ApprovalPanelButtonsPair(res.data.id) };
                const updatedReviewData = [reviewEntry, ...reviewGridData];
                setReviewGridData(updatedReviewData);
            })
            .catch(err => {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            });
    }, [
        ApprovalPanelButtonsPair,
        api,
        chatTimeout,
        chatWaitingPeriod,
        ongoingChatTimeout,
        reviewGridData,
        simultaneousChats,
    ]);

    const isUpdateButtonDisabled = useCallback(() => {
        if (liveChatConfigurationStateRef.current) {
            return (
                liveChatConfigurationStateRef.current.simultaneousChats === simultaneousChats &&
                liveChatConfigurationStateRef.current.chatWaitingPeriod === chatWaitingPeriod &&
                liveChatConfigurationStateRef.current.chatTimeout === chatTimeout &&
                liveChatConfigurationStateRef.current.ongoingChatTimeout === ongoingChatTimeout
            );
        }
    }, [chatTimeout, chatWaitingPeriod, ongoingChatTimeout, simultaneousChats]);

    useEffect(() => {
        loadCurrentConfiguration();
        loadPendingChatConfigurations();
        loadConfigurationHistory();
    }, [loadConfigurationHistory, loadCurrentConfiguration, loadPendingChatConfigurations]);

    return {
        generalErrorList,
        successMessage,
        onSubmit,
        chatWaitingPeriod,
        setChatWaitingPeriod,
        chatTimeout,
        setChatTimeout,
        ongoingChatTimeout,
        setOngoingChatTimeout,
        simultaneousChats,
        setSimultaneousChats,
        isUpdateButtonDisabled,
        reviewGridHeaders,
        reviewGridData,
        historyGridHeaders,
        historyGridData,
    };
};

export default useLiveChatSettings;
