import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { FileUpload } from 'primereact/fileupload';
import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';
import GeneralMessage from '../../components/UI/GeneralMessage';
import useImportExportModel from '../../hooks/useImportExportModel';

const ImportExportModel = () => {
    const {
        successMessage,
        generalErrorList,
        downloadModel,
        uploadSpinner,
        uploadInputRef,
        onSelect,
        onUpload,
        modelFileIsSelected,
        selectModelFileError,
        onRemove,
        downloadInProgress,
    } = useImportExportModel();
    return (
        <>
            <GeneralMessage
                className="c-msg"
                successMessage={successMessage}
                errorList={generalErrorList}
            />
            <div className="container-fluid card p-4 mb-5 animated fadeIn">
                <div className="row">
                    <div className="col-3">
                        <h2 className="display-5">Export Model</h2>
                        <hr />
                        <div className="d-flex justify-content-center mt-4">
                            <div className="d-flex flex-column ">
                                <Button
                                    label="Download model"
                                    onClick={downloadModel}
                                    className="mb-3 p-p-0"
                                    disabled={downloadInProgress}
                                    style={{ minWidth: '220px', maxWidth: '300px', height: '5em' }}
                                >
                                    {downloadInProgress ? (
                                        <ProgressSpinner
                                            strokeWidth="4"
                                            className="ml-2 mr-0"
                                            style={{
                                                height: '40px',
                                                width: '40px',
                                            }}
                                        />
                                    ) : null}
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div className="col-1">
                        <Divider layout="vertical" />
                    </div>

                    <div className="col-8">
                        <h2 className="display-5">Import Model</h2>
                        <hr />

                        {uploadSpinner ? (
                            <ProgressSpinner strokeWidth="4" />
                        ) : (
                            <>
                                <div className="ie-info-label-container">
                                    <p className="ie-info-label">
                                        Select a model file for import. Only .zip files are
                                        supported. No need to train the model.
                                    </p>
                                </div>
                                <FileUpload
                                    ref={uploadInputRef}
                                    label="Select a model file for import. Only supported format is .zip"
                                    uploadHandler={onUpload}
                                    onSelect={onSelect}
                                    onRemove={onRemove}
                                    customUpload={true}
                                    accept=".zip"
                                    emptyTemplate={
                                        <p className="p-m-0">Drag and drop a model file.</p>
                                    }
                                    chooseLabel={`Choose (${modelFileIsSelected ? '1' : '0'}/1)`}
                                    uploadLabel="Upload"
                                />
                                <p className="text-danger">{selectModelFileError}</p>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ImportExportModel;
