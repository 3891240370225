import { useCallback, useEffect, useMemo, useState } from 'react';
import ApiRequests from '../http/ApiRequests';
import ConfigRespService from '../services/configResp.service';
import axiosErrorHandler from '../http/AxiosErrorHandler';
import get from 'lodash/get';
import useConfirmDialog from './useConfirmDialog';

const useApprvResp = () => {
    const confirm = useConfirmDialog();

    const [tasksForApproval, setTasksForApproval] = useState([]),
        api = useMemo(() => new ApiRequests(), []),
        responses = useMemo(() => new ConfigRespService(), []),
        tasksForApprovalHeaders = [
            { name: 'Channel', key: 'responses.0.channel' },
            { name: 'Language', key: 'responses.0.language' },
            { name: 'Name', key: 'responses.0.intent.name' },
            { name: 'Updated by', key: 'responses.0.createdBy' },
            { name: 'Update time', key: 'responses[0].createdTs', formatter: 'datetime' },
            { name: 'Status', key: 'responses.0.status' },
        ],
        [generalErrorList, setGeneralErrorList] = useState([]),
        [selectedRow, setSelectedRow] = useState(null),
        [successMessage, setSuccessMessage] = useState(null),
        approveTask = (intentId, channel, language) => {
            api.approveIntentAnswers({ intentId, channel, language })
                .then(res => {
                    setSuccessMessage({ success: 'Changes approved.' });
                    listIntentsForReview();
                })
                .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setGeneralErrorList(errorList);
                });
        },
        rejectTask = (intentId, channel, language) => {
            api.rejectIntentAnswers({ intentId, channel, language })
                .then(res => {
                    setSuccessMessage({ success: 'Changes rejected.' });
                    listIntentsForReview();
                })
                .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setGeneralErrorList(errorList);
                });
        };

    const listIntentsForReview = useCallback(() => {
        api.getIntentAnswerSearchResults({ pending: true })
            .then(res => {
                setTasksForApproval(responses.processResponsesForReview(res.data));
            })
            .catch(err => {
                setTasksForApproval([]);
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            });
    }, [api, responses]);

    useEffect(() => {
        listIntentsForReview();
    }, [listIntentsForReview]);

    const onRowExpand = data => {
        const expandRowId = {};
        expandRowId[get(data, 'data.id', 0)] = true;
        setSelectedRow(null);
        setSelectedRow(expandRowId);
    };

    const onRowCollapse = () => {
        setSelectedRow(null);
    };

    const approveAllModifications = useCallback(() => {
        api.approveAllIntentAnswers()
            .then(() => listIntentsForReview())
            .catch(err => {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            });
    }, [api, listIntentsForReview]);

    const confirmApproveAll = useCallback(() => {
        const confirmationData = {
            message: 'Are you sure you want to approve all responses?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => approveAllModifications(),
        };
        confirm(confirmationData);
    }, [approveAllModifications, confirm]);

    const rejectAllModifications = useCallback(() => {
        api.rejectAllIntentAnswers()
            .then(() => listIntentsForReview())
            .catch(err => {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            });
    }, [api, listIntentsForReview]);

    const confirmRejectAll = useCallback(() => {
        const confirmationData = {
            message: 'Are you sure you want to reject all responses?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => rejectAllModifications(),
        };
        confirm(confirmationData);
    }, [confirm, rejectAllModifications]);

    return {
        tasksForApproval,
        tasksForApprovalHeaders,
        approveTask,
        rejectTask,
        confirmApproveAll,
        confirmRejectAll,
        generalErrorList,
        successMessage,
        onRowExpand,
        onRowCollapse,
        selectedRow,
    };
};

export default useApprvResp;
