/**
 * Kindly use this file to store labels and
 * every human readable string for the application.
 */

export const labels = {
    /** common */
    NO_DATA_AVAILABLE: 'No available data.',

    /** operation result messages */
    CHANGES_ARE_SAVED: 'Successfully saved changes.',
};

export default labels;
