import orderBy from 'lodash/orderBy';
import { Button } from 'primereact/button';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ApiRequests from '../http/ApiRequests';
import axiosErrorHandler from '../http/AxiosErrorHandler';

const useForbiddenPhrases = () => {
    const api = useMemo(() => new ApiRequests(), []);
    const [generalErrorList, setGeneralErrorList] = useState([]);
    const [successMessage, setSuccessMessage] = useState(null);
    const [forbiddenPhrase, setForbiddenPhrase] = useState('');
    const [forAcceptanceGridData, setForAcceptanceGridData] = useState([]);
    const forAcceptanceGridHeaders = [
        {
            name: 'Forbidden Phrase',
            key: 'forbiddenPhrase',
        },
        { name: 'Updated By', key: 'updatedBy' },
        {
            name: 'Updated At',
            key: 'createdTs',
            formatter: 'datetime',
        },
        { name: 'Status', key: 'status' },
        { name: 'Action', key: 'action' },
    ];
    const [activePhrasesGridData, setActivePhrasesGridData] = useState([]);
    const activePhrasesGridHeaders = [
        {
            name: 'Forbidden Phrase',
            key: 'forbiddenPhrase',
        },
        { name: 'Updated By', key: 'updatedBy' },
        {
            name: 'Updated At',
            key: 'updatedTs',
            formatter: 'datetime',
        },
        { name: 'Action', key: 'action' },
    ];
    const forbiddenPhrasesStateRef = useRef();
    const forAcceptanceStateRef = useRef();
    forAcceptanceStateRef.current = forAcceptanceGridData;
    const activePhrasesStateRef = useRef();
    activePhrasesStateRef.current = activePhrasesGridData;

    const onRejectForRemoval = useCallback(
        async id =>
            api
                .rejectDeletingForbiddenPhrase(id)
                .then(res => {
                    setSuccessMessage({ success: 'Successfully rejected removal of phrase' });
                    setForAcceptanceGridData(
                        forAcceptanceStateRef.current.filter(i => i.id !== id)
                    );
                })
                .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setGeneralErrorList(errorList);
                }),
        [api]
    );

    const onApproveForRemoval = useCallback(
        async id =>
            api
                .approveDeletingForbiddenPhrase(id)
                .then(res => {
                    setSuccessMessage({ success: 'Successfully approved removal of phrase' });
                    setForAcceptanceGridData(
                        forAcceptanceStateRef.current.filter(i => i.id !== id)
                    );
                    setActivePhrasesGridData(
                        activePhrasesStateRef.current.filter(i => i.id !== id)
                    );
                })
                .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setGeneralErrorList(errorList);
                }),
        [api]
    );

    const ForRejectingPanelButtonsPair = useCallback(
        id => (
            <div className="container-fluid">
                <div className="row">
                    <Button
                        type="button"
                        className="add-btn mr-2 mb-1"
                        icon="fa fa-check"
                        onClick={() => onApproveForRemoval(id)}
                        label="Approve"
                        tooltipOptions={{ position: 'top' }}
                    />
                    <Button
                        type="button"
                        className="delete-btn mr-2 mb-1"
                        icon="fa fa-times"
                        onClick={() => onRejectForRemoval(id)}
                        label="Reject"
                        tooltipOptions={{ position: 'top' }}
                    />
                </div>
            </div>
        ),
        [onApproveForRemoval, onRejectForRemoval]
    );

    const onPutForRemoval = useCallback(
        async id => {
            api.requestToDeleteForbiddenPhrase(id)
                .then(res => {
                    setSuccessMessage({ success: 'Successfully put phrase for removal' });
                    let reviewEntry = {
                        forbiddenPhrase: res.data.phrase,
                        updatedTs: res.data.updatedTs,
                        updatedBy: res.data.updatedBy,
                        createdTs: res.data.createdTs,
                        status: <span className="text-danger">DELETED</span>,
                        action: ForRejectingPanelButtonsPair(res.data.id),
                        id: res.data.id,
                    };
                    if (
                        !forAcceptanceStateRef.current.some(
                            forRemoval => forRemoval.id === reviewEntry.id
                        )
                    ) {
                        const updatedForDeleteData = [
                            ...forAcceptanceStateRef.current,
                            reviewEntry,
                        ];
                        orderBy(updatedForDeleteData, 'status');
                        setForAcceptanceGridData(updatedForDeleteData);
                    }
                })
                .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setGeneralErrorList(errorList);
                });
        },
        [api, ForRejectingPanelButtonsPair]
    );

    const ForRemovalButton = useCallback(
        id => {
            return (
                <div className="container-fluid">
                    <div className="row">
                        <Button
                            type="button"
                            className="delete-btn mr-2"
                            icon="fa fa-trash-o"
                            onClick={() => onPutForRemoval(id)}
                            tooltip={'Remove'}
                            tooltipOptions={{ position: 'top' }}
                        />
                    </div>
                </div>
            );
        },
        [onPutForRemoval]
    );

    const onApproveForApproval = useCallback(
        async id => {
            api.approveForbiddenPhrase(id)
                .then(res => {
                    setSuccessMessage({ success: 'Successfully approved pending phrase' });
                    setForAcceptanceGridData(
                        forAcceptanceStateRef.current.filter(i => i.id !== id)
                    );
                    let reviewEntry = {
                        forbiddenPhrase: res.data.phrase,
                        updatedTs: res.data.updatedTs,
                        updatedBy: res.data.updatedBy,
                        createdTs: res.data.createdTs,
                        status: <span className="text-success">NEW</span>,
                        action: ForRemovalButton(res.data.id),
                        id: res.data.id,
                    };
                    const updatedActivePhraseData = [...activePhrasesStateRef.current, reviewEntry];
                    setActivePhrasesGridData(updatedActivePhraseData);
                })
                .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setGeneralErrorList(errorList);
                });
        },
        [api, ForRemovalButton]
    );

    const onRejectForApproval = useCallback(
        async id => {
            api.rejectForbiddenPhrase(id)
                .then(res => {
                    setSuccessMessage({ success: 'Successfully rejected pending phrase' });
                    setForAcceptanceGridData(
                        forAcceptanceStateRef.current.filter(i => i.id !== id)
                    );
                })
                .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setGeneralErrorList(errorList);
                });
        },
        [api]
    );

    const ForApprovalPanelButtonsPair = useCallback(
        id => {
            return (
                <div className="container-fluid">
                    <div className="row">
                        <Button
                            type="button"
                            className="add-btn mr-2"
                            icon="fa fa-check"
                            onClick={() => onApproveForApproval(id)}
                            label="Approve"
                            tooltipOptions={{ position: 'top' }}
                        />
                        <Button
                            type="button"
                            className="delete-btn mr-2"
                            icon="fa fa-times"
                            onClick={() => onRejectForApproval(id)}
                            label="Reject"
                            tooltipOptions={{ position: 'top' }}
                        />
                    </div>
                </div>
            );
        },
        [onApproveForApproval, onRejectForApproval]
    );

    useEffect(() => {
        async function loadCurrentPhrases() {
            try {
                const res = await api.getForbiddenPhrases();
                forbiddenPhrasesStateRef.current = res.data;
                setForbiddenPhrase('');
            } catch (err) {
                setForbiddenPhrase('');
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            }
        }
        loadCurrentPhrases();
    }, [api]);

    const setResponseData = useCallback(
        data =>
            data.map(entry => ({
                forbiddenPhrase: entry.phrase,
                updatedBy: entry.updatedBy,
                updatedTs: entry.updatedTs,
                createdTs: entry.createdTs,
                status: entry.toRemove ? (
                    <span className="text-danger">DELETED</span>
                ) : (
                    <span className="text-success">NEW</span>
                ),
                action: entry.toRemove
                    ? ForRejectingPanelButtonsPair(entry.id)
                    : ForApprovalPanelButtonsPair(entry.id),
                id: entry.id,
            })),
        [ForApprovalPanelButtonsPair, ForRejectingPanelButtonsPair]
    );

    useEffect(() => {
        function loadForApprovalPhraseContent() {
            Promise.all([api.getForbiddenPhrasesToApprove(), api.getForbiddenPhrasesToRemove()])
                .then(values => {
                    let gridOne = setResponseData(values[0].data);
                    let gridTwo = setResponseData(values[1].data);
                    let gridD = gridOne.concat(gridTwo);
                    orderBy(gridD, 'status');
                    setForAcceptanceGridData(gridD);
                })
                .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setGeneralErrorList(errorList);
                });
        }
        loadForApprovalPhraseContent();
    }, [ForApprovalPanelButtonsPair, api, setResponseData]);

    useEffect(() => {
        async function loadActivePhrasesContent() {
            try {
                const res = await api.getForbiddenPhrases();
                let gridD = res.data.map(entry => ({
                    forbiddenPhrase: entry.phrase,
                    updatedBy: entry.updatedBy,
                    updatedTs: entry.approvedTs,
                    action: ForRemovalButton(entry.id),
                    id: entry.id,
                }));
                setActivePhrasesGridData(gridD);
            } catch (err) {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            }
        }
        loadActivePhrasesContent();
    }, [ForRemovalButton, api]);

    const onForbiddenPhraseChange = useCallback(
        e => {
            setForbiddenPhrase(e.target ? e.target.value : [...forbiddenPhrase]);
        },
        [setForbiddenPhrase, forbiddenPhrase]
    );

    const onSubmit = useCallback(async () => {
        api.addForbiddenPhrase(forbiddenPhrase)
            .then(res => {
                setSuccessMessage({ success: 'Successfully added forbidden phrase for review' });
                setForbiddenPhrase('');
                let reviewEntry = {
                    forbiddenPhrase: res.data.phrase,
                    updatedTs: res.data.approvedTs,
                    updatedBy: res.data.updatedBy,
                    createdTs: res.data.createdTs,
                    status: <span className="text-success">NEW</span>,
                    action: ForApprovalPanelButtonsPair(res.data.id),
                    id: res.data.id,
                };
                const updatedReviewData = [...forAcceptanceGridData];
                updatedReviewData.unshift(reviewEntry);
                orderBy(updatedReviewData, 'status');
                setForAcceptanceGridData(updatedReviewData);
            })
            .catch(err => {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            });
    }, [api, ForApprovalPanelButtonsPair, forAcceptanceGridData, forbiddenPhrase]);

    return {
        generalErrorList,
        successMessage,
        onSubmit,
        forbiddenPhrase,
        onForbiddenPhraseChange,
        forAcceptanceGridHeaders,
        forAcceptanceGridData,
        activePhrasesGridHeaders,
        activePhrasesGridData,
    };
};

export default useForbiddenPhrases;
