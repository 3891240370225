import get from 'lodash/get';
import { Button } from 'primereact/button';
import Control from '../../components/UI/Control/Control';
import RoboadvisorService from '../../services/roboadvisor.service';
import WelcomeMessageButtonsResponse from './WelcomeMessageButtonsResponse';
import WelcomeMessageCarouselResponse from './WelcomeMessageCarouselResponse';
import PlayButton from '../configResp/PlayButton';
import { useWatch } from 'react-hook-form';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useRef } from "react";

const WelcomeMessageInteractiveResponses = ({
    index,
    registerValidationFor,
    response,
    control,
    errors,
    removeResponse,
    watch,
    namePrefix,
    searchIntent,
    intentList,
    selectedWelcomeMessage,
    appFeatures,
    clearErrors,
    setValue,
    getValues,
}) => {
    const textToSpeechRef = useRef(null);
    const watchResponseType = watch(`${namePrefix}.type`);
    const watchText = useWatch({
        control,
        name: `${namePrefix}.content.text`,
        defaultValue: response?.content?.text,
    });

    const watchTextToSpeech = useWatch({
        control,
        name: `${namePrefix}.content.alternativeContent`,
        defaultValue: response?.content?.alternativeContent,
    });

    const canSupportTextToSpeech =
        appFeatures?.textToSpeech === true &&
        watchResponseType === RoboadvisorService.responseTypes.text &&
        selectedWelcomeMessage.channel === "API";

    const messageTypeOptions = [
        { label: "Text", value: "TEXT" },
        { label: "Buttons", value: "BUTTONS" },
        { label: "Carousel", value: "CAROUSEL" },
    ];

    const wrapTextWithTagOnClick = (leftTag, rightTag) => {
        return e => {
            e.preventDefault();
            debugger;
            if (!textToSpeechRef.current) {
                return;
            }
            const start = textToSpeechRef.current.selectionStart;
            const end = textToSpeechRef.current.selectionEnd;

            if (start === undefined) {
                return;
            }
            if (end === undefined) {
                return;
            }

            // Get the current value from the form's input instead of the form's state
            // in order to avoid misalign with the cursor values.
            const content = textToSpeechRef.current.value;

            const changedContent = `${content.substring(0, start)}${leftTag}${content.substring(
                start,
                end
            )}${rightTag}${content.substring(end)}`;

            setValue(`${namePrefix}.content.alternativeContent`, changedContent);
            textToSpeechRef.current.selectionStart = start + leftTag.length;
            textToSpeechRef.current.selectionEnd = end + leftTag.length;
            textToSpeechRef.current.focus();
        };
    };

    return (
        <div className="mb-4 mt-2 container-fluid" key={index}>
            <div className="row">
                <div className="col-11 d-flex align-items-end">
                    <h4 className="mb-0">Response {index + 1}</h4>
                    {canSupportTextToSpeech ? (
                        <PlayButton
                            style={{ marginLeft: "10px" }}
                            watchText={watchText}
                            watchTextToSpeech={watchTextToSpeech}
                            language={selectedWelcomeMessage.language}
                        />
                    ) : null}
                </div>
                <div className="col-1">
                    <Button
                        label="Delete response"
                        className="delete-btn"
                        style={{ marginLeft: "-128px", minWidth: "150px" }}
                        icon="fa fa-trash-o"
                        onClick={() => {
                            removeResponse(index);
                        }}
                    />
                </div>
                <hr />
            </div>
            <div className="row">
                <div className="col-4">
                    <Control
                        error={get(errors, `${namePrefix}.type.message`, "")}
                        registerRef={registerValidationFor?.type}
                        name={`${namePrefix}.type`}
                        type="select"
                        label="Message Type"
                        value={response?.type}
                        options={messageTypeOptions}
                    />
                </div>
            </div>
            {watchResponseType === RoboadvisorService.responseTypes.text && (
                <>
                    <div className="row">
                        <div className="col-8">
                            <Control
                                error={get(errors, `${namePrefix}.content.text.message`, "")}
                                registerRef={registerValidationFor?.text}
                                name={`${namePrefix}.content.text`}
                                type="textarea"
                                label="Message Text"
                                placeholder="Enter a text and/or URL."
                                value={response?.content?.text}
                            />
                        </div>
                    </div>
                    {canSupportTextToSpeech ? (
                        <>
                            <div className="row">
                                <div className="col-8">
                                    <Control
                                        ref={textToSpeechRef}
                                        style={{ minHeight: "85px" }}
                                        error={get(
                                            errors,
                                            `${namePrefix}.content.alternativeContent.message`,
                                            ""
                                        )}
                                        registerRef={registerValidationFor?.textToSpeech}
                                        name={`${namePrefix}.content.alternativeContent`}
                                        type="textarea"
                                        label="Text-to-speech"
                                        placeholder="Enter a text."
                                        value={response?.content?.alternativeContent}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-8">
                                    <Button
                                        className="btn-info mr-2 mb-2"
                                        onClick={wrapTextWithTagOnClick(
                                            "<emphasis>",
                                            "</emphasis>"
                                        )}
                                        label="<emphasis>"
                                    />
                                    <Button
                                        className="btn-info mr-2 mb-2"
                                        onClick={wrapTextWithTagOnClick(
                                            '<say-as interpret-as="currency, telephone, verbatim, date, characters, time">',
                                            "</say-as>"
                                        )}
                                        label="<say-as>"
                                    />
                                    <Button
                                        className="btn-info mr-2 mb-2"
                                        onClick={wrapTextWithTagOnClick(
                                            '<break strength="x-weak, weak, medium, strong, x-strong"/>',
                                            ""
                                        )}
                                        label="<break>"
                                    />
                                    <Button
                                        className="btn-info mr-2 mb-2"
                                        onClick={e => {
                                            e.preventDefault();
                                            const text = getValues(
                                                `${namePrefix}.content.alternativeContent`
                                            );
                                            setValue(
                                                `${namePrefix}.content.alternativeContent`,
                                                `<speak>${text
                                                    .replaceAll("<speak>", "")
                                                    .replaceAll("</speak>", "")}</speak>`
                                            );
                                            clearErrors(`${namePrefix}.content.alternativeContent`);
                                        }}
                                        label="Use SSML"
                                    />
                                </div>
                            </div>
                        </>
                    ) : null}
                </>
            )}
            {watchResponseType === RoboadvisorService.responseTypes.buttons && (
                <WelcomeMessageButtonsResponse
                    registerValidationFor={registerValidationFor}
                    errors={errors}
                    control={control}
                    namePrefix={namePrefix}
                    searchIntent={searchIntent}
                    intentList={intentList}
                />
            )}
            {watchResponseType === RoboadvisorService.responseTypes.carousel && (
                <WelcomeMessageCarouselResponse
                    registerValidationFor={registerValidationFor}
                    errors={errors}
                    control={control}
                    namePrefix={namePrefix}
                />
            )}
            <hr />
        </div>
    );
};

const mapStateToProps = state => ({
    appFeatures: state.app.features,
});

export default connect(mapStateToProps, null)(withRouter(WelcomeMessageInteractiveResponses));
