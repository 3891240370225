import get from 'lodash/get';
import { confirmDialog } from 'primereact/confirmdialog';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import GeneralMessage from '../../components/UI/GeneralMessage';
import Grid from '../../components/UI/Grid/Grid';
import ApiRequests from '../../http/ApiRequests';
import axiosErrorHandler from '../../http/AxiosErrorHandler';
import LiveChatBanDialog from '../../modules/liveChatBan/LiveChatBanDialog';

const LiveChatBanList = () => {
    const [successMessage, setSuccessMessage] = useState(null);
    const [generalErrorList, setGeneralErrorList] = useState([]);
    const [banList, setBanList] = useState([]);
    const [api] = useState(new ApiRequests());
    const [reloadCounter, setReloadCounter] = useState(1);
    const reload = useCallback(() => setReloadCounter(Math.random()), [setReloadCounter]);
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [banForEdit, setBanForEdit] = useState(null);
    const closeEditDialog = useCallback(() => {
        setEditDialogVisible(false);
        setBanForEdit(null);
    }, [setEditDialogVisible]);
    const openEditDialog = useCallback(() => {
        setEditDialogVisible(true);
    }, [setEditDialogVisible]);

    const deleteBan = useCallback(
        id => {
            api.deleteLiveChatBan(id)
                .then(response => {
                    reload();
                    setSuccessMessage({
                        success: `You successfully deleted the live chat ban with id ${id}`,
                    });
                })
                .catch(error => {
                    const [errorList] = axiosErrorHandler(error);
                    setGeneralErrorList(errorList);
                });
        },
        [api, setSuccessMessage, setGeneralErrorList, reload]
    );

    const formatBanHours = useCallback((rowData, column) => {
        const banDuration = get(rowData, column.field, '');
        if (typeof banDuration === 'number') {
            if (banDuration === 1) {
                return banDuration + ' hour';
            } else if (banDuration > 1) {
                return banDuration + ' hours';
            } else {
                return banDuration;
            }
        }
        return banDuration;
    }, []);

    const gridHeaders = useMemo(
        () => [
            { name: 'ID', key: 'id' },
            { name: 'Duration', key: 'bannedHours', formatterFunc: formatBanHours },
            { name: 'Reason', key: 'banReason' },
            { name: 'Created By', key: 'createdBy' },
            { name: 'Created At', key: 'createdTs', formatter: 'datetime' },
            { name: 'Updated By', key: 'updatedByUser' },
            { name: 'Last Update', key: 'updatedTs', formatter: 'datetime' },
            { name: 'Session', key: 'sessionId' },
        ],
        [formatBanHours]
    );

    const confirmDeleteBan = useCallback(
        entity => {
            confirmDialog({
                message: `Are you sure you want to delete ban with id ${entity.id}?`,
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => deleteBan(entity.id),
                reject: () => {},
            });
        },
        [deleteBan]
    );

    const editBan = useCallback(
        data => {
            setBanForEdit(data);
            openEditDialog();
        },
        [openEditDialog, setBanForEdit]
    );

    useEffect(() => {
        api.getLiveChatBans()
            .then(response => {
                setBanList(response.data);
            })
            .catch(error => {
                const [errorList] = axiosErrorHandler(error);
                setGeneralErrorList(errorList);
            });
    }, [reloadCounter, api]);

    return (
        <>
            <GeneralMessage
                className="c-msg"
                successMessage={successMessage}
                errorList={generalErrorList}
            />
            <LiveChatBanDialog
                data={banForEdit}
                setGeneralErrorList={setGeneralErrorList}
                setSuccessMessage={setSuccessMessage}
                dialogVisible={editDialogVisible}
                hideDialog={closeEditDialog}
                reload={reload}
            />
            <section className="container-fluid card p-4 mb-5">
                <div className="row">
                    <div className="col-12">
                        <Grid
                            editAction={editBan}
                            deleteAction={confirmDeleteBan}
                            columns={gridHeaders}
                            data={banList}
                        />
                    </div>
                </div>
            </section>
        </>
    );
};

export default LiveChatBanList;
