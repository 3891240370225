import get from 'lodash/get';
import { Button } from 'primereact/button';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Config from '../../config/config';
import useHeader from '../../hooks/useHeader';
import useLogout from '../../hooks/useLogout';
import { initLiveChat } from '../../store/actions/liveChatActions';
import { initSuperviseLiveChat } from '../../store/actions/superviseLiveChatActions';
import { setTestIntentSidebarVisible } from '../../store/actions/testIntentAction';
import Logo from '../Logo/Logo';
import './Header.css';

const Header = ({
    currentUser,
    setSidebarVisible,
    awaitingClients,
    assignedClients,
    initLiveChat,
    initSuperviseLiveChat,
}) => {
    const hook = useHeader({
        awaitingClients,
        assignedClients,
        initLiveChat,
        currentUser,
        initSuperviseLiveChat,
    });
    const logout = useLogout();

    return (
        <header className="app-header navbar">
            <NavLink className="navbar-brand" to="/">
                <Logo width={'140'} height={'auto'} alt={'Sport Depot'} />
            </NavLink>

            <ul className="nav navbar-nav ml-auto">
                {Config.envName ? (
                    <li>
                        <span
                            className="mr-4"
                            style={{ fontSize: '1.5em', color: 'var(--danger)' }}
                        >
                            {Config.envName}
                        </span>
                    </li>
                ) : null}
                <li>
                    <Button
                        label="Test NLU Intents"
                        className="info-btn"
                        onClick={() => setSidebarVisible(true)}
                    />
                </li>
                {hook.canUseLiveChat ? (
                    <li>
                        <Button
                            label="Awaiting Clients"
                            className="awaiting-btn ml-2"
                            onClick={() => hook.onClientsButtonClick()}
                            disabled={!hook.buttonEnabled}
                        />
                    </li>
                ) : null}
                <li
                    className="navbar-text d-md-down-none"
                    style={{ marginRight: '20px', marginLeft: '20px' }}
                >
                    Welcome, {get(currentUser, 'username', '')}
                </li>
                <li className="mr-2">
                    <Button
                        className=" p-button-text p-button-secondary"
                        icon="fa fa-sign-out"
                        onClick={logout}
                        size="normal"
                        style={{ fontSize: '1.5em' }}
                    />
                </li>
            </ul>
        </header>
    );
};

const mapDispatchToProps = {
    setSidebarVisible: setTestIntentSidebarVisible,
    initLiveChat: initLiveChat,
    initSuperviseLiveChat: initSuperviseLiveChat,
};

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        awaitingClients: state.liveChat.awaitingClients,
        assignedClients: state.liveChat.assignedClients,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
