// just an abstract class that cannot be instantiated
export class AbstractLocalStorage {
    constructor() {
        if (this.constructor === AbstractLocalStorage) {
            throw new Error("Abstract classes can't be instantiated.");
        }
    }

    setLocalStorageItem = (item, value) => {
        return localStorage.setItem(item, value);
    };

    removeLocalStorageItem = item => {
        return localStorage.removeItem(item);
    };

    returnLocalStorageItem = item => {
        if (typeof window !== 'undefined') {
            return localStorage.getItem(item);
        }

        return;
    };
}

export default class AuthService extends AbstractLocalStorage {
    setUserToken = token => {
        return this.setLocalStorageItem('token', token);
    };

    getUserToken = () => {
        return this.returnLocalStorageItem('token');
    };

    removeUserToken = () => {
        return this.removeLocalStorageItem('token');
    };
}
