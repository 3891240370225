import { useEffect } from 'react';
import { connect } from 'react-redux';
import PageRootNav from '../../components/PageRootNav/PageRootNav';
import GeneralMessage from '../../components/UI/GeneralMessage';
import useUserGroups from '../../hooks/useUserGroups';
import AddNewGroup from '../../modules/userGroups/AddNewGroup';
import AllGroups from '../../modules/userGroups/AllGroups';
import Privileges from '../../modules/userGroups/Privileges';
import {
    addNewUserGroup,
    getAllUserGroups,
    grantPermissions,
    setErrorMessage,
    setSuccessMessage,
} from '../../store/actions/userGroupsActions';
import './UserGroups.css';

const UserGroups = ({
    getAllUserGroups,
    grantPermissions,
    addNewUserGroup,
    getCurrentUser,
    userGroup,
    // Success from store
    successMessage,
    // Errors from store
    errorMessage,
    setErrorMessage,
    setSuccessMessage,
}) => {
    const hook = useUserGroups({
        getAllUserGroups,
        addNewUserGroup,
        userGroup,
        grantPermissions,
        // Success from store
        successMessage,
        // Errors from store
        errorMessage,
    });

    useEffect(() => {
        return () => {
            setErrorMessage([]);
            setSuccessMessage(null);
        };
    }, [setErrorMessage, setSuccessMessage]);

    return (
        <>
            <PageRootNav label="User groups" />
            <AddNewGroup hook={hook} />
            <GeneralMessage
                className="c-msg"
                errorList={hook.generalErrorList}
                successMessage={hook.successMsg}
            />
            <div className="container-fluid mt-5">
                <div className="row">
                    <div className="col-3">
                        <AllGroups />
                    </div>
                    <div className="col-9">
                        <Privileges hook={hook} />
                    </div>
                </div>
            </div>
        </>
    );
};

const mapDispatchToProps = {
    getAllUserGroups,
    grantPermissions,
    addNewUserGroup,
    setErrorMessage,
    setSuccessMessage,
};

const mapStateToProps = state => {
    return {
        userGroup: state.userGroups.userGroup,
        // Success
        successMessage: state.userGroups.successMessage,
        // Errors
        errorMessage: state.userGroups.errorMessage,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserGroups);
