import { Button } from 'primereact/button';
import React, { useState } from 'react';
import TimePicker from '../../components/UI/Control/TimePicker';
import FormControlWrapper from '../../hoc/FormControlWrapper';
import useLiveChatWorkingHours from '../../hooks/useLiveChatWorkingHours';
import moment from 'moment';

/** data format
    {
        "date": "string",
        "dayOfWeek": "MONDAY",
        "endHour": "string",
        "id": 0,
        "startHour": "string"
    }
 */
const WorkingHoursComponent = ({
    data,
    pending,
    warning,
    readonly,
    setGeneralErrorList,
    setSuccessMessage,
    reload,
}) => {
    const {
        control,
        approve,
        reject,
        onSubmit,
        getValues,
        fieldErrorFor,
        registerValidationFor,
        deleteWorkingHours,
    } = useLiveChatWorkingHours({
        data,
        setGeneralErrorList,
        setSuccessMessage,
        reload,
        onSuccessfulSubmit: () => setEditModeEnabled(false),
    });
    const [editModeEnabled, setEditModeEnabled] = useState(false);
    const turnOnEditMode = e => {
        e.preventDefault();
        setEditModeEnabled(true);
    };
    const turnOffEditMode = () => setEditModeEnabled(false);

    const setDateOrDayToBeDisplayed = () => {
        if (data) {
            if (data.date) {
                return moment(data.date).format('DD-MM-YYYY');
            } else if (data.dayOfWeek) {
                return data.dayOfWeek;
            } else {
                return null;
            }
        }
        return null;
    };
    const dateOrDay = setDateOrDayToBeDisplayed();

    let buttonsPanel;
    if (readonly) {
        buttonsPanel = (
            <FormControlWrapper label=" ">
                <Button
                    type="button"
                    className="mr-2 delete-btn"
                    icon="fa fa-trash-o"
                    tooltip={'Delete'}
                    onClick={() => deleteWorkingHours(getValues('id'))}
                    tooltipOptions={{ position: 'top' }}
                />
            </FormControlWrapper>
        );
    } else if (pending) {
        buttonsPanel = (
            <>
                <FormControlWrapper label=" ">
                    <button
                        className="btn btn-info mr-2"
                        onClick={e => {
                            e.preventDefault();
                            if (approve) approve(getValues('id'));
                        }}
                    >
                        Approve
                    </button>
                </FormControlWrapper>
                <FormControlWrapper>
                    <button
                        className="btn btn-danger mr-2"
                        onClick={e => {
                            e.preventDefault();
                            if (reject) reject(getValues('id'));
                        }}
                    >
                        Reject
                    </button>
                </FormControlWrapper>
            </>
        );
    } else if (editModeEnabled) {
        buttonsPanel = (
            <>
                <FormControlWrapper label=" ">
                    <button className="btn btn-primary mr-2" type="submit">
                        Send for Approval
                    </button>
                </FormControlWrapper>
                <FormControlWrapper>
                    <button className="btn btn-danger mr-2" onClick={turnOffEditMode}>
                        Cancel
                    </button>
                </FormControlWrapper>
            </>
        );
    } else {
        buttonsPanel = (
            <FormControlWrapper label=" ">
                <button className="btn btn-primary mr-2" onClick={turnOnEditMode}>
                    Edit
                </button>
            </FormControlWrapper>
        );
    }

    return (
        <form noValidate onSubmit={onSubmit}>
            <div
                className={`container-fluid 
                    ${warning || data.changesArePendingApproval ? 'pending-changes-banner-bg' : ''}
                    ${pending && data.status === 'DELETED' ? 'pending-deletion-banner-bg' : ''}
                `}
            >
                <div className="row mb-2">
                    <div
                        className="col-1 col-md-2 d-flex flex-nowrap align-items-center justify-content-start"
                        style={{ minWidth: '130px' }}
                    >
                        <span>{dateOrDay}</span>
                    </div>
                    <div className="col-3">
                        <div className="d-flex flex-nowrap align-items-center justify-content-end ">
                            <TimePicker
                                name="startHour"
                                label="From"
                                control={control}
                                registerRef={registerValidationFor.startHour}
                                disabled={!editModeEnabled}
                                error={fieldErrorFor.startHour?.message}
                            />
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="d-flex flex-nowrap align-items-center justify-content-end ">
                            <TimePicker
                                name="endHour"
                                label="To"
                                control={control}
                                registerRef={registerValidationFor.endHour}
                                disabled={!editModeEnabled}
                                error={fieldErrorFor.endHour?.message}
                            />
                        </div>
                    </div>
                    <div className="col-5 col-md-4" style={{ minWidth: '300px' }}>
                        {buttonsPanel}
                    </div>
                </div>
            </div>
        </form>
    );
};

export default WorkingHoursComponent;
