import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';
import Control from '../../components/UI/Control/Control';
import GeneralMessage from '../../components/UI/GeneralMessage';

const GeneralConversationReportFilter = ({ hook }) => {
    const {
        registerValidationFor,
        onSubmit,
        onExport,
        generalErrorList,
        fieldErrorFor,
        control,
        channelOptions,
        orderCriteriaOptions,
        orderDirectionOptions,
        defaultOrderCriteria,
        defaultOrderDirection,
        stillDownload,
        operatorList,
        clearOperatorField,
    } = hook;

    const showExportButton = () => {
        if (stillDownload) {
            return <ProgressSpinner strokeWidth="4" />;
        }
        return (
            <Button
                style={{ marginTop: '8px', width: '100%' }}
                type="button"
                className="p-button-primary"
                tooltip={'Export to excel'}
                label="Export"
                tooltipOptions={{ position: 'top' }}
                onClick={onExport}
            />
        );
    };

    const formElement = (
        <form noValidate>
            <GeneralMessage errorList={generalErrorList} />
            <div className="row">
                <div className="col-3">
                    <Control
                        error={fieldErrorFor.dateFrom?.message}
                        registerRef={registerValidationFor?.dateFrom}
                        control={control}
                        name="dateFrom"
                        type="datepicker"
                        label="From date"
                        value=""
                        showTime
                    />
                </div>
                <div className="col-3">
                    <Control
                        error={fieldErrorFor.dateTo?.message}
                        registerRef={registerValidationFor?.dateTo}
                        control={control}
                        name="dateTo"
                        type="datepicker"
                        label="To date"
                        value=""
                        showTime
                    />
                </div>
                <div className="col-2">
                    <Control
                        error={fieldErrorFor.sessionID?.message}
                        registerRef={registerValidationFor?.sessionID}
                        control={control}
                        name="sessionID"
                        type="sessionID"
                        label="Session ID"
                    />
                </div>
                <div className="col-2">
                    <Control
                        error={fieldErrorFor.channel?.message}
                        registerRef={registerValidationFor?.channel}
                        control={control}
                        name="channel"
                        type="select"
                        label="Channel"
                        options={channelOptions}
                        value=""
                    />
                </div>

                <div className="col-2 d-flex align-items-center justify-content-end">
                    <Button
                        style={{ marginTop: '8px', width: '100%' }}
                        type="button"
                        className="p-button-primary"
                        tooltip={'Generate report'}
                        label="Load Report"
                        tooltipOptions={{ position: 'top' }}
                        onClick={onSubmit}
                    />
                </div>
                <div className="col-2 ">
                    <Control
                        type="select"
                        name="acceptedBy"
                        label="Operator"
                        options={operatorList}
                        error={fieldErrorFor.acceptedBy?.message}
                        registerRef={registerValidationFor.acceptedBy}
                        value={''}
                    />
                </div>
                <div className="col-1 d-flex align-items-end">
                    <Button
                        icon="fa fa-close"
                        style={{ marginBottom: '20px' }}
                        className="p-button-sm"
                        onClick={clearOperatorField}
                    />
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-3">
                    <Control
                        error={fieldErrorFor.orderCriteria?.message}
                        registerRef={registerValidationFor?.orderCriteria}
                        control={control}
                        name="orderCriteria"
                        type="select"
                        label="Sort Criteria"
                        options={orderCriteriaOptions}
                        value={defaultOrderCriteria}
                    />
                </div>
                <div className="col-3">
                    <Control
                        error={fieldErrorFor.orderDirection?.message}
                        registerRef={registerValidationFor?.orderDirection}
                        control={control}
                        name="orderDirection"
                        type="select"
                        label="Direction"
                        options={orderDirectionOptions}
                        value={defaultOrderDirection}
                    />
                </div>
                <div className="col-2 offset-4 d-flex align-items-center justify-content-end">
                    {showExportButton()}
                </div>
            </div>
        </form>
    );

    return formElement;
};

export default GeneralConversationReportFilter;
