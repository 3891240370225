import React from 'react';
import labels from '../../constants/labels';
import WorkingHoursComponent from './WorkingHoursComponent';

const PendingApprovalWorkingHours = ({ data, setGeneralErrorList, setSuccessMessage, reload }) => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <h2 className="display-5">Pending Approval Working Hours</h2>
                    <hr />
                </div>
            </div>
            {Array.isArray(data) && data.length ? (
                data.map(d => (
                    <div className="row" key={d.id}>
                        <WorkingHoursComponent
                            data={d}
                            pending
                            setGeneralErrorList={setGeneralErrorList}
                            setSuccessMessage={setSuccessMessage}
                            reload={reload}
                        />
                    </div>
                ))
            ) : (
                <span>{labels.NO_DATA_AVAILABLE}</span>
            )}
        </div>
    );
};

export default PendingApprovalWorkingHours;
