import GeneralMessage from '../../components/UI/GeneralMessage';
import Grid from '../../components/UI/Grid/Grid';

const LiveChatTemplatesReview = ({ hook }) => {
    const {
        successMessage,
        generalErrorList,
        reviewGridHeaders,
        reviewGridData,
        onApprove,
        onReject,
    } = hook;

    return (
        <div className="animated fadeIn">
            <GeneralMessage
                className="c-msg"
                successMessage={successMessage}
                errorList={generalErrorList}
            />

            <div className="container-fluid card p-4 mb-5">
                <div className="row">
                    <div className="col-12">
                        <h2 className="display-5">Review</h2>
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Grid
                            columns={reviewGridHeaders}
                            data={reviewGridData}
                            approveAction={onApprove}
                            rejectAction={onReject}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LiveChatTemplatesReview;
