import get from 'lodash/get';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ApiRequests from '../http/ApiRequests';
import axiosErrorHandler from '../http/AxiosErrorHandler';
import { validator } from '../services/validationService';

const useLiveChatAssign = ({
    currentUser,
    sessionId,
    setSuccessMessage,
    setGeneralErrorList,
    hideDialog,
    onSuccessfulSubmit,
    dialogVisible,
}) => {
    const [api] = useState(new ApiRequests());
    const [availableOperators, setAvailableOperators] = useState([]);
    const { setValue, register, handleSubmit, errors } = useForm({
        shouldUnregister: false,
    });

    const getAvailableOperators = useCallback(() => {
        if (dialogVisible) {
            api.searchForUsers(['ONLINE'])
                .then(res => {
                    const operators = get(res, 'data', [])
                        .filter(op => op.username !== currentUser.username)
                        .map(op => ({ value: op.id, label: op.username }));
                    setAvailableOperators(operators);
                })
                .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setGeneralErrorList(errorList);
                });
        }
    }, [dialogVisible, api, currentUser.username, setGeneralErrorList]);

    const registerValidationFor = {
        operator: () => register({ required: 'Please select an operator.' }),
    };

    const onFormSubmit = useCallback(
        state => {
            api.assignForwardedConversation(sessionId, state)
                .then(response => {
                    setSuccessMessage({ success: 'Successfully reassigned chat.' });
                    hideDialog();
                    if (onSuccessfulSubmit) {
                        onSuccessfulSubmit();
                    }
                })
                .catch(error => {
                    const [errorList] = axiosErrorHandler(error);
                    setGeneralErrorList(errorList);
                });
        },
        [api, hideDialog, onSuccessfulSubmit, sessionId, setGeneralErrorList, setSuccessMessage]
    );

    useEffect(() => {
        getAvailableOperators();
        setValue('operator', '');
    }, [getAvailableOperators, setValue]);

    return {
        availableOperators,
        register,
        registerValidationFor,
        fieldErrorFor: errors,
        onSubmit: handleSubmit(
            onFormSubmit,
            validator.extractErrorsFromInvalidForm(setGeneralErrorList)
        ),
    };
};
export default useLiveChatAssign;
