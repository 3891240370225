import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React from 'react';
import RoboadvisorService from '../../services/roboadvisor.service';

const PendingPredefinedMessageGrid = props => {
    const dateTimeFormatter = (rowData, column) => {
        return RoboadvisorService.formatDateTime(
            rowData[column.field],
            RoboadvisorService.dateTimeMomentFormatSeconds,
        );
    };

    const renderColumns = columns => {
        const dynamicColumns = [];
        for (const element of columns) {
            let body = null;
            if (element.formatter === 'datetime') {
                body = dateTimeFormatter;
            } else if (typeof element.formatterFunc === 'function') {
                body = element.formatterFunc;
            }
            dynamicColumns.push(
                <Column
                    key={element.key}
                    field={element.key}
                    header={element.name}
                    style={{ textOverflow: 'ellipsis' }}
                    body={body}
                />,
            );
        }
        dynamicColumns.push(
            <Column
                key='Actions'
                header='Actions'
                body={expansionActionTemplate}
                className='actionBtns'
                style={{ textAlign: 'center', width: '150px' }}
                field='actionsColumn' // must have 'field' in order to produce proper column reordering
            />,
        );

        return dynamicColumns;
    };

    const expansionActionTemplate = rowData => {
        return (
            <React.Fragment>
                <Button
                    type='button'
                    icon={PrimeIcons.CHECK}
                    tooltip={'Approve'}
                    tooltipOptions={{ position: 'top' }}
                    className='p-button-success p-button-rounded p-button-text'
                    onClick={() => props.approveAction(rowData)}
                />
                <Button
                    type='button'
                    icon={PrimeIcons.TRASH}
                    tooltip={'Reject'}
                    tooltipOptions={{ position: 'top' }}
                    className='p-button-danger p-button-rounded p-button-text'
                    onClick={() => props.rejectAction(rowData)}
                />
            </React.Fragment>
        );
    };

    return (
        <div className='card p-4'>
            <h4>Awaiting</h4>
            <DataTable
                value={props.data.pendingMessages}
                resizableColumns={true}
                emptyMessage={'No results'}
            >
                {renderColumns(props.columns)}
            </DataTable>
        </div>
    );
};

export default PendingPredefinedMessageGrid;
