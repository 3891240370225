import Grid from '../../components/UI/Grid/Grid';
import usePredefinedMessageData from '../../hooks/usePredefinedMessageData';
import PredefinedMessageEditItem from '../../components/PredefinedMessageEditItem/PredefinedMessageEditItem';
import GeneralMessage from '../../components/UI/GeneralMessage';
import React, { useEffect, useState } from 'react';
import PendingPredefinedMessageGrid from './PendingPredefinedMessageGrid';
import './PredefinedMessageGrid.css';

const PredefinedMessagesGrid = () => {
    const hook = usePredefinedMessageData(),
        {
            predefinedMessages,
            predefinedGridHeaders,
            pendingGridHeaders,
            selectedRow,
            selectedRowLanguage,
            selectedRowMessage,
            selectedRowAlternativeContent,
            selectedRowDescription,
            editClickHandler,
            approveClickHandler,
            rejectClickHandler,
            editMessageHandler,
            editAlternativeContentHandler,
            editDescriptionHandler,
            onSubmit,
            closeEditDialog,
            onRowToggle,
            successMessage,
            generalErrorList,
            fieldErrorFor,
            registerValidationFor,
            visibleEdit,
            saveButtonDisabled,
            getValues, 
            setValue, 
            clearErrors
        } = hook,
        [isPendingChanges, setIsPendingChanges] = useState(false);

    useEffect(() => {
        let pendingChanges = false;
        for (const element of predefinedMessages) {
            if (element.pendingMessages.length) {
                pendingChanges = true;
                break;
            }
        }
        setIsPendingChanges(pendingChanges);
    }, [predefinedMessages]);

    const rowExpansionTemplate = data => {
        return (
            <PendingPredefinedMessageGrid
                data={data}
                approveAction={approveClickHandler}
                rejectAction={rejectClickHandler}
                columns={pendingGridHeaders}
            />
        );
    };

    return (
        <>
            <GeneralMessage
                className='c-msg'
                successMessage={successMessage}
                errorList={generalErrorList}
            />
            {isPendingChanges && (
                <div className={'pending-changes-banner'}>There are pending changes</div>
            )}
            <div className='container-fluid card p-4 mb-5'>
                <div className='row'>
                    <div className='col-12'>
                        <Grid
                            rowExpansionTemplate={rowExpansionTemplate}
                            columns={predefinedGridHeaders}
                            data={predefinedMessages}
                            editAction={editClickHandler}
                            onRowToggle={onRowToggle}
                            selectedRow={selectedRow}
                            expandDataKey='id'
                            expandTable={true}
                            hover={true}
                        />
                    </div>
                </div>
            </div>

            <PredefinedMessageEditItem
                visibleEditDialog={visibleEdit}
                closeEditDialog={closeEditDialog}
                saveEdits={onSubmit}
                language={selectedRowLanguage}
                message={selectedRowMessage}
                alternativeContent={selectedRowAlternativeContent}
                description={selectedRowDescription}
                disabled={saveButtonDisabled}
                fieldErrorFor={fieldErrorFor}
                registerValidationFor={registerValidationFor}
                onDescriptionChange={editDescriptionHandler}
                onMessageChange={editMessageHandler}
                onAlternativeContentChange={editAlternativeContentHandler}
                getValues={getValues}
                setValue={setValue}
                clearErrors={clearErrors}
            />
        </>
    );
};

export default PredefinedMessagesGrid;
