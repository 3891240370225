import React from 'react';
import { connect } from 'react-redux';
import SuperviseLiveChatWindow from './SuperviseLiveChatWindow';

const SuperviseLiveChatWindowPanel = ({ conversations, selectedConversationId }) => {
    const currentConversation = conversations.find(conv => conv.data.id === selectedConversationId);
    const messages = currentConversation?.messages;
    const sessionId = currentConversation?.data.session;
    const operatorAvatar = currentConversation?.operator?.avatar;
    const username = currentConversation?.operator?.username;
    const nickname = currentConversation?.operator?.nickname;
    const operatorName = username
        ? `${username} ${nickname ? '(' + nickname + ')' : ''}`
        : 'Operator';
    const profileColor = currentConversation?.profileColor;
    return (
        <>
            {selectedConversationId ? (
                <>
                    <div
                        className="d-flex pb-1 justify-content-between"
                        style={{
                            borderBottom: '1px solid #cccccc',
                            height: '60px',
                        }}
                    >
                        <div className="d-flex flex-row align-items-center ml-2">
                            <div
                                className="d-flex justify-content-center align-items-center mr-1"
                                style={{
                                    minWidth: '40px',
                                    minHeight: '40px',
                                    borderRadius: '50%',
                                    backgroundColor: 'lightGray',
                                }}
                            >
                                <i
                                    className="fa fa-user"
                                    style={{
                                        fontSize: '30px',
                                        color: profileColor ? profileColor : '#000',
                                        display: 'inline-block',
                                    }}
                                ></i>
                            </div>
                            <div className="mr-1">
                                {operatorAvatar ? (
                                    <img
                                        src={operatorAvatar}
                                        alt=""
                                        style={{
                                            display: 'inline-block',
                                            width: '40px',
                                            height: '40px',
                                            borderRadius: '50%',
                                        }}
                                    />
                                ) : (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            fontSize: '1.5rem',
                                            color: 'white',
                                            backgroundColor: 'brown',
                                            borderRadius: '50%',
                                            width: '40px',
                                            height: '40px',
                                        }}
                                    >
                                        <span>{operatorName[0].toUpperCase()}</span>
                                    </div>
                                )}
                            </div>
                            <div className="d-flex flex-column ml-2">
                                <span
                                    className="live-chat-card-client-name"
                                    style={{ fontStyle: 'italic', color: '#b1b1b1' }}
                                >
                                    {operatorName}
                                </span>
                                <span
                                    className="live-chat-card-client-name"
                                    style={{ fontStyle: 'italic' }}
                                >
                                    {sessionId}
                                </span>
                            </div>
                        </div>
                    </div>
                    <SuperviseLiveChatWindow
                        conversationID={selectedConversationId}
                        messages={messages}
                        sessionId={sessionId}
                    />
                </>
            ) : (
                <p className="m-2">No selected conversation.</p>
            )}
        </>
    );
};

const mapStateToProps = state => {
    return {
        conversations: state.superviseLiveChat.conversations,
        selectedConversationId: state.superviseLiveChat.selectedConversationId,
    };
};

export default connect(mapStateToProps, null)(SuperviseLiveChatWindowPanel);
