import { canUser } from '../permissions/permissions';
import { routes } from '../routes/Routes';

const menuItemType = {
    TITLE: 'TITLE',
    LINK: 'LINK',
};

export const appMenuItems = [
    {
        label: `Bot Training`,
        icon: `fa fa-android`,
        key: 'home',
        type: menuItemType.TITLE,
        items: [
            {
                label: `Manage Intents and Sample Questions`,
                key: routes.addInt.path,
                type: menuItemType.LINK,
                checkPermissions: canUser.viewPageAddInt,
            },
            {
                label: `Manage Synonyms`,
                key: routes.addSyn.path,
                type: menuItemType.LINK,
                checkPermissions: canUser.viewPageAddSyn,
            },
            {
                label: `Review & Train`,
                key: routes.apprvTrain.path,
                type: menuItemType.LINK,
                checkPermissions: canUser.viewPageApprvTrain,
            },
            {
                label: `Analyze Training Data`,
                key: routes.analyzeTrainingData.path,
                type: menuItemType.LINK,
                checkPermissions: canUser.viewPageAnalyzeTrainingData,
            },
        ],
    },
    {
        label: `Messages`,
        icon: `fa fa-envelope`,
        key: 'messages',
        type: menuItemType.TITLE,
        items: [
            {
                label: `Predefined Messages`,
                key: routes.pdfMessage.path,
                type: menuItemType.LINK,
                checkPermissions: canUser.viewPagePdfMessage,
            },
            {
                label: `Bot Responses`,
                key: 'botResponses',
                has3rdLvl: true,
                type: menuItemType.TITLE,
                items: [
                    {
                        label: `Manage`,
                        key: routes.configResp.path,
                        is3rdLevel: true,
                        type: menuItemType.LINK,
                        checkPermissions: canUser.viewPageConfigResp,
                    },
                    {
                        label: `Approve`,
                        key: routes.apprvResp.path,
                        is3rdLevel: true,
                        type: menuItemType.LINK,
                        checkPermissions: canUser.viewPageApprvResp,
                    },
                    {
                        label: `Analyze Responses`,
                        key: routes.analyzeAnswers.path,
                        is3rdLevel: true,
                        type: menuItemType.LINK,
                        checkPermissions: canUser.viewPageAnalyzeResponses,
                    },
                ],
            },
            {
                label: `Welcome Messages`,
                key: 'welcomeMessages',
                has3rdLvl: true,
                type: menuItemType.TITLE,
                items: [
                    {
                        label: `Manage`,
                        key: routes.welcomeMessages.path,
                        is3rdLevel: true,
                        type: menuItemType.LINK,
                        checkPermissions: canUser.viewPageWelcomeMessages,
                    },
                    {
                        label: `Approve`,
                        key: routes.welcomeMessagesApproval.path,
                        is3rdLevel: true,
                        type: menuItemType.LINK,
                        checkPermissions: canUser.viewPageWelcomeMessagesApproval,
                    },
                ],
            },
        ],
    },
    {
        label: `Settings`,
        icon: `fa fa-cogs`,
        key: 'botSettings',
        type: menuItemType.TITLE,
        items: [
            {
                label: `General Configuration`,
                key: routes.botRespSettings.path,
                type: menuItemType.LINK,
                checkPermissions: canUser.viewPageBotRespSettings,
            },
            {
                label: `Multilingual Phrases`,
                key: routes.liveChatMultilingualPhrases.path,
                type: menuItemType.LINK,
                checkPermissions: canUser.viewPageLiveChatMultilingualPhrases,
            },
            {
                label: `Locations & Coordinates`,
                key: 'locationsCoordinates',
                has3rdLvl: true,
                type: menuItemType.TITLE,
                items: [
                    {
                        label: `Manage`,
                        key: routes.locationCoordinates.path,
                        is3rdLevel: true,
                        type: menuItemType.LINK,
                        checkPermissions: canUser.viewPageLocationCoordinates,
                    },
                    {
                        label: `Approve`,
                        key: routes.locationCoordinatesApproval.path,
                        is3rdLevel: true,
                        type: menuItemType.LINK,
                        checkPermissions: canUser.viewPageLocationCoordinatesApproval,
                    },
                ],
            },
            // {
            //     label: `Tariff`,
            //     key: 'tariff',
            //     has3rdLvl: true,
            //     type: menuItemType.TITLE,
            //     items: [
            //         {
            //             label: `Upload`,
            //             key: routes.tariff.path,
            //             is3rdLevel: true,
            //             type: menuItemType.LINK,
            //             checkPermissions: canUser.viewPageTariff,
            //         },
            //         {
            //             label: `Approve`,
            //             key: routes.tariffApproval.path,
            //             is3rdLevel: true,
            //             type: menuItemType.LINK,
            //             checkPermissions: canUser.viewPageTariffApproval,
            //         },
            //     ],
            // },
            {
                label: `Import/Export`,
                key: routes.importExport.path,
                type: menuItemType.LINK,
                checkPermissions: canUser.viewPageImportExport,
            },
        ],
    },
    {
        label: `Reporting`,
        icon: `fa fa-bars`,
        key: 'reporting',
        type: menuItemType.TITLE,
        items: [
            {
                label: `Conversation report`,
                key: routes.cnvrstnReport.path,
                type: menuItemType.LINK,
                checkPermissions: canUser.viewPageCnvrstnReport,
            },
            {
                label: `Intent report`,
                key: routes.intentReport.path,
                type: menuItemType.LINK,
                checkPermissions: canUser.viewPageIntentReport,
            },
            {
                label: `Summary report`,
                key: routes.smmryReport.path,
                type: menuItemType.LINK,
                checkPermissions: canUser.viewPageSmmryReport,
            },
        ],
    },
    // {
    //     label: `Audit`,
    //     icon: `pi pi-fw pi-pencil`,
    //     key: routes.auditTrails.path,
    //     type: menuItemType.LINK,
    //     checkPermissions: canUser.viewPageAuditTrails,
    // },
    {
        label: `User groups`,
        icon: `fa fa-users`,
        key: routes.userGroups.path,
        type: menuItemType.LINK,
        checkPermissions: canUser.viewPageUserGroups,
    },
    {
        label: `Live Chat`,
        icon: `fa fa-comments`,
        key: 'chat',
        type: menuItemType.TITLE,
        items: [
            {
                label: `Live Chat`,
                key: routes.liveChat.path,
                type: menuItemType.LINK,
                checkPermissions: canUser.viewPageLiveChat,
            },
            {
                label: `My Summary`,
                key: routes.liveChatOperatorDashboard.path,
                type: menuItemType.LINK,
                checkPermissions: canUser.viewPageOperatorDashboard,
            },
            {
                label: `Live Chat Monitoring`,
                key: routes.liveChatSuperviseLiveChat.path,
                type: menuItemType.LINK,
                checkPermissions: canUser.viewPageSuperviseLiveChat,
            },
        ],
    },
    {
        label: `Live Chat Management`,
        icon: `fa fa-sliders`,
        key: 'management',
        type: menuItemType.TITLE,
        items: [
            {
                label: `Configuration`,
                key: routes.liveChatConfiguration.path,
                type: menuItemType.LINK,
                checkPermissions: canUser.viewPageLiveChatConfiguration,
            },
            {
                label: `Working Hours`,
                key: routes.liveChatWorkingHours.path,
                type: menuItemType.LINK,
                checkPermissions: canUser.viewPageLiveChatWorkingHours,
            },
            {
                label: `Forbidden Phrases`,
                key: routes.liveChatForbiddenPhrases.path,
                type: menuItemType.LINK,
                checkPermissions: canUser.viewPageLiveChatForbiddenPhrases,
            },
            {
                label: `Templates`,
                key: routes.liveChatTemplates.path,
                type: menuItemType.LINK,
                checkPermissions: canUser.viewPageLiveChatTemplates,
            },
            {
                label: `Active Bans`,
                key: routes.liveChatBanList.path,
                type: menuItemType.LINK,
                checkPermissions: canUser.viewPageBanList,
            },
        ],
    },
    {
        label: `Live Chat Dashboard`,
        icon: `pi pi-chart-bar`,
        key: routes.liveChatDashboard.path,
        type: menuItemType.LINK,
        checkPermissions: canUser.viewPageDashboard,
    },
    {
        label: `User`,
        icon: `fa fa-user`,
        key: routes.profile.path,
        type: menuItemType.LINK,
        checkPermissions: canUser.viewPageProfile,
    },
    {
        label: `Sign out`,
        icon: `fa fa-sign-out`,
        key: 'signOut',
        type: menuItemType.LINK,
        checkPermissions: canUser.yesSheCan,
        command: props => {
            if (props.logout) {
                props.logout();
            }
        },
    },
];
Object.freeze(appMenuItems);

const removeMenuItemsWithoutPermissions = (menuItems, userPermissions) => {
    const filteredMenuItems = [];
    menuItems.forEach(menuItem => {
        const menuItemCopy = { ...menuItem };
        if (menuItemCopy.type === menuItemType.TITLE) {
            filteredMenuItems.push(menuItemCopy);
            menuItemCopy.items = removeMenuItemsWithoutPermissions(
                menuItemCopy.items,
                userPermissions
            );
        } else if (
            menuItemCopy.type === menuItemType.LINK &&
            typeof menuItemCopy.checkPermissions === 'function'
        ) {
            if (menuItemCopy.checkPermissions(userPermissions)) {
                filteredMenuItems.push(menuItemCopy);
            }
        }
    });
    return filteredMenuItems;
};

const removeTitleTypeMenuItemsWithoutSubmenus = menuItems => {
    const filteredMenuItems = [];
    menuItems.forEach(menuItem => {
        if (menuItem.type === menuItemType.TITLE && Array.isArray(menuItem.items)) {
            const items = getNonEmptyItems(menuItem);
            if (items.length) {
                filteredMenuItems.push(menuItem);
            }
        } else {
            filteredMenuItems.push(menuItem);
        }
    });
    return filteredMenuItems;
};

const getNonEmptyItems = menuItem => {
    if (Array.isArray(menuItem.items) && menuItem.items.length) {
        const filteredMenuItems = [];
        menuItem.items.forEach(item => {
            if (item.type === menuItemType.TITLE) {
                const items = getNonEmptyItems(item);
                if (items.length) {
                    filteredMenuItems.push(item);
                }
            } else {
                filteredMenuItems.push(item);
            }
        });
        return filteredMenuItems;
    } else {
        return [];
    }
};

export const getAppMenuItems = userPermissions => {
    const allowedMenuItems = removeMenuItemsWithoutPermissions(appMenuItems, userPermissions);
    const menuWithoutEmptySubmenus = removeTitleTypeMenuItemsWithoutSubmenus(allowedMenuItems);
    return menuWithoutEmptySubmenus;
};
