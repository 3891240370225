import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useHistory } from 'react-router';
import './PageHeader.css';

const PageHeader = props => {
    const path = useHistory()?.location?.pathname;
    const menuItemLabels = getMenuItemByPath(props.menuItems, path, props.label);

    let breadcrumbs = [];
    menuItemLabels?.reduceRight(
        (_, item, index) =>
            breadcrumbs.push(
                <Breadcrumb.Item active key={index}>
                    {item}
                </Breadcrumb.Item>
            ),
        null
    );

    return (
        <>
            <Breadcrumb>{breadcrumbs}</Breadcrumb>
        </>
    );
};

function getMenuItemByPath(menuItems = [], path, defaultLabel, builtMenuPath = []) {
    for (const i in menuItems) {
        if (path === menuItems[i].key) {
            builtMenuPath.push(defaultLabel?.length > 0 ? defaultLabel : menuItems[i].label);
            return builtMenuPath;
        }

        if (menuItems[i].items?.length > 0) {
            getMenuItemByPath(menuItems[i].items, path, defaultLabel, builtMenuPath);
            if (builtMenuPath.length > 0) {
                builtMenuPath.push(menuItems[i].label);
                return builtMenuPath;
            }
        }
    }
}

export default PageHeader;
