import React from 'react';
import useTemplates from '../../hooks/useTemplates';
import LiveChatTemplateForm from '../../modules/liveChatTemplates/LiveChatTemplateForm';
import LiveChatTemplatesReview from '../../modules/liveChatTemplates/LiveChatTemplatesReview';
import LiveChatActiveTemplates from '../../modules/liveChatTemplates/LiveChatActiveTemplates';
import LiveChatTemplateEdit from '../../components/LiveChat/LiveChatTemplateEdit';

const LiveChatTemplates = () => {
    const hook = useTemplates();

    return (
        <>
            <LiveChatTemplateForm hook={hook} />
            <LiveChatTemplatesReview hook={hook} />
            <LiveChatActiveTemplates hook={hook} />
            <LiveChatTemplateEdit hook={hook} />
        </>
    );
};

export default LiveChatTemplates;
