import get from 'lodash/get';
import { connect } from 'react-redux';
import PersmissionsForm from './PermissionsForm';

const Privileges = ({ userGroup, hook }) => {
    return userGroup ? (
        <>
            <h3 className="display-5 mb-5">{get(userGroup, 'name', '')}</h3>
            <PersmissionsForm hook={hook} />
        </>
    ) : (
        <h3 className="display-5">No group selected</h3>
    );
};

const mapStateToProps = state => {
    return {
        userGroup: state.userGroups.userGroup,
    };
};

export default connect(mapStateToProps)(Privileges);
