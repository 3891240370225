import get from 'lodash/get';

export const sortByDateCompareFunction = (comparedPropertyName, desc = false) => {
    return (firstElement, secondElement) => {
        // Dates in Unix time are measured in seconds elapsed since 00:00:00 UTC on 1 January 1970
        const firstDateInSeconds = new Date(firstElement[comparedPropertyName]).getTime();
        const secondDateInSeconds = new Date(secondElement[comparedPropertyName]).getTime();
        if (desc) {
            if (firstDateInSeconds > secondDateInSeconds) {
                return -1;
            } else if (firstDateInSeconds < secondDateInSeconds) {
                return 1;
            } else {
                return 0;
            }
        } else {
            if (secondDateInSeconds > firstDateInSeconds) {
                return -1;
            } else if (secondDateInSeconds < firstDateInSeconds) {
                return 1;
            } else {
                return 0;
            }
        }
    };
};

export const sortByNumberProperty = (comparedPropertyName, desc = false) => {
    return (firstElement, secondElement) => {
        const firstElementProperty = get(firstElement, comparedPropertyName, 0);
        const secondElementProperty = get(secondElement, comparedPropertyName, 0);
        if (desc) {
            if (firstElementProperty > secondElementProperty) {
                return -1;
            } else if (firstElementProperty < secondElementProperty) {
                return 1;
            } else {
                return 0;
            }
        } else {
            if (secondElementProperty > firstElementProperty) {
                return -1;
            } else if (secondElementProperty < firstElementProperty) {
                return 1;
            } else {
                return 0;
            }
        }
    };
};
