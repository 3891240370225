import { confirmDialog } from 'primereact/confirmdialog';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import ViewInMapsButton from '../components/LocationCoordinates/ViewInMapsButton';
import FlagCounter from '../components/UI/FlagCounter';
import useConfirmDialog from '../hooks/useConfirmDialog';
import ApiRequests from '../http/ApiRequests';
import axiosErrorHandler from '../http/AxiosErrorHandler';
import { routes } from '../routes/Routes';

const useLocationCoordinates = () => {
    const api = useMemo(() => new ApiRequests(), []);
    const { control } = useForm({});
    const [generalErrorList, setGeneralErrorList] = useState([]);
    const [successMessage, setSuccessMessage] = useState(null);
    const [locationList, setLocationList] = useState([]);
    const [pendingLocationList, setPendingLocationList] = useState([]);
    const [uploadSpinner, setUploadSpinner] = useState(false);
    const [locationForEdit, setLocationForEdit] = useState(null);
    const [newLocationDialogIsOpen, setNewLocationDialogIsOpen] = useState(false);
    const gridHeaders = [
        { name: 'Location', key: 'locationName', headerClassName: 'coordinates-location-name' },
        { name: 'Latitude', key: 'latitude', headerClassName: 'coordinates-lat-lon' },
        { name: 'Longitude', key: 'longitude', headerClassName: 'coordinates-lat-lon' },
        { name: 'View', key: 'view', headerClassName: 'coordinates-view-maps' },
        { name: 'Author', key: 'createdBy', headerClassName: 'coordinates-user' },
        { name: 'Reviewer', key: 'approvedBy', headerClassName: 'coordinates-user' },
    ];
    const confirm = useConfirmDialog();
    const history = useHistory();

    const locationsAreEqual = useCallback((pendingLocation, location) => {
        if (pendingLocation.townCoordinatesId === location.id) {
            return true;
        } else {
            return false;
        }
    }, []);

    const addFlagForPendingChanges = useCallback(
        (approvedLocationList, pendingLocation) => {
            const location = approvedLocationList.find(loc =>
                locationsAreEqual(pendingLocation, loc)
            );
            if (location) {
                if (pendingLocation.status === 'UPDATED') {
                    location.hasPendingUpdate = true;
                }
                if (pendingLocation.status === 'DELETED') {
                    location.hasPendingRemoval = true;
                }
                location.locationName = (
                    <div className="d-flex flex-row">
                        {location.name}
                        {location.hasPendingRemoval ? (
                            <FlagCounter
                                tooltipLabel="This locations is awaiting approval to be removed"
                                containerClassNames="ml-1 text-danger"
                            />
                        ) : null}
                        {location.hasPendingUpdate ? (
                            <FlagCounter
                                tooltipLabel="This location has one or more changes awaiting approval"
                                containerClassNames="ml-1 text-warning"
                            />
                        ) : null}
                    </div>
                );
            }
        },
        [locationsAreEqual]
    );

    useEffect(() => {
        function loadLocationsGridData() {
            Promise.all([api.getApprovedCoordinates(), api.getPendingCoordinates()])
                .then(values => {
                    const approvedLocationList = values[0].data.map(entry => ({
                        ...entry,
                        locationName: entry.name,
                        view: <ViewInMapsButton props={entry} />,
                        hasPendingUpdate: false,
                        hasPendingRemoval: false,
                    }));
                    const pendingGridD = values[1].data;

                    pendingGridD.forEach(pendingLoc =>
                        addFlagForPendingChanges(approvedLocationList, pendingLoc)
                    );
                    setLocationList(approvedLocationList);
                    setPendingLocationList(pendingGridD);
                })
                .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setGeneralErrorList(errorList);
                });
        }
        loadLocationsGridData();
    }, [api, addFlagForPendingChanges]);

    const uploadHandler = ({ files }) => {
        if (files.length > 1) {
            setGeneralErrorList(['Only 1 file can be uploaded at a time']);
            return;
        }
        const [file] = files;
        const fileReader = new FileReader();
        fileReader.onload = () => {
            confirmUploadFileContent(file);
        };
        fileReader.readAsDataURL(file);
    };

    const configureLocationCoordinates = async coordinatesFile => {
        setUploadSpinner(true);
        let formData = new FormData();
        formData.append('file', coordinatesFile, coordinatesFile.name);
        try {
            await api.configureCoordinates(formData);
            setUploadSpinner(false);
            setSuccessMessage({ success: 'Successfully configured location coordinates' });
            const timer = setTimeout(() => {
                history.push(routes.locationCoordinatesApproval.path);
            }, 1000);
            return () => clearTimeout(timer);
        } catch (err) {
            const [errorList] = axiosErrorHandler(err);
            setGeneralErrorList(errorList);
        }
    };

    const confirmUploadFileContent = coordinatesFile => {
        const confirmationData = {
            message: `Are you sure you want upload this file?`,
            header: 'Confirmation',
            icon: 'fa fa-question-circle-o',
            accept: () => configureLocationCoordinates(coordinatesFile),
        };
        confirm(confirmationData);
    };

    const confirmDeleteCoordinates = rowData => {
        confirmDialog({
            message: `Are you sure you want to delete Location name ${rowData.name}?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteCoordinate(rowData.id),
            reject: () => {},
        });
    };

    const deleteCoordinate = useCallback(
        id => {
            api.deleteCoordinate(id)
                .then(response => {
                    const deletedLocation = response.data;
                    addFlagForPendingChanges(locationList, deletedLocation);
                    setPendingLocationList([...pendingLocationList, deletedLocation]);
                })
                .then(setSuccessMessage({ success: 'Successfully put location for removal' }))
                .catch(error => {
                    axiosErrorHandler(error);
                });
        },
        [api, locationList, setSuccessMessage, addFlagForPendingChanges, pendingLocationList]
    );

    const onEditLocation = useCallback(rowData => {
        setLocationForEdit({ ...rowData });
    }, []);

    const onCancelEditLocation = useCallback(() => {
        setLocationForEdit(null);
    }, []);

    const onEditLocationSubmit = useCallback(
        pendingLocation => {
            if (pendingLocation) {
                addFlagForPendingChanges(locationList, pendingLocation);
                setPendingLocationList([...pendingLocationList, pendingLocation]);
            }
            setSuccessMessage({ success: 'Location changes successfully submitted.' });
            setLocationForEdit(null);
        },
        [addFlagForPendingChanges, locationList, pendingLocationList]
    );

    const onCancelNewLocation = useCallback(
        () => setNewLocationDialogIsOpen(false),
        [setNewLocationDialogIsOpen]
    );
    const onNewLocation = useCallback(
        () => setNewLocationDialogIsOpen(true),
        [setNewLocationDialogIsOpen]
    );
    const onNewLocationSubmit = useCallback(
        pendingLocation => {
            if (pendingLocation) {
                addFlagForPendingChanges(locationList, pendingLocation);
                setPendingLocationList([...pendingLocationList, pendingLocation]);
            }
            setSuccessMessage({ success: 'New location created.' });
            setNewLocationDialogIsOpen(false);
        },
        [pendingLocationList, addFlagForPendingChanges, locationList]
    );
    const pendingLocationCount = useMemo(() => {
        const newLocations = pendingLocationList.filter(loc => loc.status === 'NEW');
        const updatedLocations = pendingLocationList.filter(loc => loc.status === 'UPDATED');
        const deletedLocations = pendingLocationList.filter(loc => loc.status === 'DELETED');
        return {
            new: newLocations ? newLocations.length : 0,
            updated: updatedLocations ? updatedLocations.length : 0,
            deleted: deletedLocations ? deletedLocations.length : 0,
        };
    }, [pendingLocationList]);

    return {
        generalErrorList,
        successMessage,
        uploadSpinner,
        gridHeaders,
        locationList,
        control,
        uploadHandler,
        confirmDeleteCoordinates,
        onEditLocation,
        onEditLocationSubmit,
        onCancelEditLocation,
        locationForEdit,
        newLocationDialogIsOpen,
        onCancelNewLocation,
        onNewLocation,
        onNewLocationSubmit,
        pendingLocationCount,
    };
};

export default useLocationCoordinates;
