import PageRootNav from '../components/PageRootNav/PageRootNav';
import PredefinedMessagesGrid from '../modules/predefinedMessage/PredefinedMessageGrid';

const PredefinedMessage = () => {
    return (
        <>
            <PageRootNav label="Predefined Messages" />
            <PredefinedMessagesGrid />
        </>
    );
};

export default PredefinedMessage;
