import get from 'lodash/get';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { connect } from 'react-redux';
import GeneralMessage from '../../components/UI/GeneralMessage';
import { formatLabelFromValue, permissions } from '../../permissions/permissions';
import { changePermission } from '../../store/actions/userGroupsActions';

const AddUserGroupForm = ({ hook, userGroup, changePermission }) => {
    const { onSavePermissions, generalErrorList } = hook;

    return (
        <form
            noValidate
            className="animated fadeIn"
            id="userGroupPermissions"
            onSubmit={onSavePermissions}
        >
            <GeneralMessage className="c-msg" errorList={generalErrorList} />
            {Object.values(permissions).map((permission, index) => (
                <div
                    key={index}
                    className={`p-1 mb-1 d-inline-flex flex-nowrap flex-row align-items-end justify-content-start w-50`}
                >
                    <Checkbox
                        onChange={() => changePermission(userGroup, permission)}
                        checked={get(userGroup, 'permissions', []).includes(permission)}
                        inputId={permission}
                        className="mr-1 mb-2"
                    />
                    <label className={` checkbox-label text-capitalize`} htmlFor={permission}>
                        {formatLabelFromValue(permission)}
                    </label>
                </div>
            ))}

            <Button
                type="submit"
                className="add-btn"
                label="Save"
                tooltip={'Save'}
                tooltipOptions={{ position: 'top' }}
            />
        </form>
    );
};

const mapStateToProps = state => {
    return {
        userGroup: state.userGroups.userGroup,
    };
};

const mapDispatchToProps = {
    changePermission,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserGroupForm);
