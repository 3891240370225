import AddIntent from '../pages/AddIntent/AddIntent';
import AddSynonyms from '../pages/AddSynonyms/AddSynonyms';
import ApproveTraining from '../pages/ApproveTraining/ApproveTraining';
import ApprvResp from '../pages/ApprvResp';
import AuditTrails from '../pages/AuditTrails';
import ChatBotSettings from '../pages/BotSettings/ChatBotSettings';
import ConfigResp from '../pages/ConfigResp';
import ConversRepData from '../pages/ConversRepData';
import Login from '../pages/Login';
import SummaryReport from '../pages/SummaryReport/SummaryReport';
import WelcomeMessage from '../pages/WelcomeMessages/WelcomeMessage';
import WelcomeMessagesApproval from '../pages/WelcomeMessagesApproval/WelcomeMessagesApproval';
import UserGroups from '../pages/UserGroups/UserGroups';
import LocationCoordinates from '../pages/LocationCoordinates/LocationCoordinates';
import LocationCoordinatesApproval from '../pages/LocationCoordinates/LocationCoordinatesApproval';
import PredefinedMessage from '../pages/PredefinedMessage';
import Tariff from '../pages/Tariff/Tariff';
import TariffApproval from '../pages/Tariff/TariffApproval';
import ImportExport from '../pages/ImportExport/ImportExport';
import IntentReport from '../pages/IntentReport/IntentReport';
import LiveChat from '../pages/LiveChat/LiveChat';
import Profile from '../pages/Profile/Profile';
import LiveChatConfiguration from '../pages/LiveChatSettings/LiveChatConfiguration';
import LiveChatWorkingHours from '../pages/LiveChatSettings/LiveChatWorkingHours';
import ForbiddenPhrasses from '../pages/ForbiddenPhrases/ForbiddenPhrases';
import MultilingualPhrasses from '../pages/MultilingualPhrases/MultilingualPhrases';
import LiveChatTemplates from '../pages/LiveChatTemplates/LiveChatTemplates';
import { canUser } from '../permissions/permissions';
import LiveChatBanList from '../pages/LiveChatBanList/LiveChatBanList';
import LiveChatDashboard from '../pages/LiveChatDashboard/LiveChatDashboard';
import LiveChatOperatorDashboard from '../pages/LiveChatOperatorDashboard/LiveChatOperatorDashboard';
import LiveChatSuperviseLiveChat from '../pages/LiveChatSuperviseLiveChat/LiveChatSuperviseLiveChat';
import AnalyzeTrainingData from '../pages/AnalyzeTrainingData/AnalyzeTrainingData';
import AnalyzeResponses from '../pages/AnalyzeResponses.js/AnalyzeResponses';

export const allRoutes = {
    base: {
        path: '/',
        key: 'home',
        component: AddIntent,
    },
    login: {
        path: '/login',
        key: 'login',
        component: Login,
        public: true,
    },
};

export const routes = {
    base: { path: '/', key: 'home', component: SummaryReport },
    addInt: {
        path: '/intents-examples',
        key: 'addInt',
        component: AddIntent,
        checkPermissions: canUser.viewPageAddInt,
    },
    addSyn: {
        path: '/synonyms',
        key: 'addSyn',
        component: AddSynonyms,
        checkPermissions: canUser.viewPageAddSyn,
    },
    apprvTrain: {
        path: '/training',
        key: 'apprvTrain',
        component: ApproveTraining,
        checkPermissions: canUser.viewPageApprvTrain,
    },
    analyzeTrainingData: {
        path: '/analyze-training-data',
        key: 'analyzeTrainingData',
        component: AnalyzeTrainingData,
        checkPermissions: canUser.viewPageAnalyzeTrainingData,
    },
    configResp: {
        path: '/responses',
        key: 'cofigResp',
        component: ConfigResp,
        checkPermissions: canUser.viewPageConfigResp,
    },
    apprvResp: {
        path: '/approve-responses',
        key: 'apprvResp',
        component: ApprvResp,
        checkPermissions: canUser.viewPageApprvResp,
    },
    analyzeAnswers: {
        path: '/analyze-responses',
        key: 'analyzeResponses',
        component: AnalyzeResponses,
        checkPermissions: canUser.viewPageAnalyzeResponses,
    },
    botRespSettings: {
        path: '/configuration',
        key: 'botRespSettings',
        component: ChatBotSettings,
        checkPermissions: canUser.viewPageBotRespSettings,
    },
    // auditTrails: {
    //     path: '/audit',
    //     key: 'auditTrails',
    //     component: AuditTrails,
    //     checkPermissions: canUser.viewPageAuditTrails,
    // },
    userGroups: {
        path: '/user-groups',
        key: 'userGroups',
        component: UserGroups,
        checkPermissions: canUser.viewPageUserGroups,
    },
    cnvrstnReport: {
        path: '/conversation-report',
        key: 'cnvrstnReport',
        component: ConversRepData,
        checkPermissions: canUser.viewPageCnvrstnReport,
    },
    smmryReport: {
        path: '/summary-report',
        key: 'smmryReport',
        component: SummaryReport,
        checkPermissions: canUser.viewPageSmmryReport,
    },
    welcomeMessages: {
        path: '/welcome-messages',
        key: 'welcomeMessages',
        component: WelcomeMessage,
        checkPermissions: canUser.viewPageWelcomeMessages,
    },
    welcomeMessagesApproval: {
        path: '/welcome-messages-approval',
        key: 'welcomeMessagesApproval',
        component: WelcomeMessagesApproval,
        checkPermissions: canUser.viewPageWelcomeMessagesApproval,
    },
    locationCoordinates: {
        path: '/locations',
        key: 'locations',
        component: LocationCoordinates,
        checkPermissions: canUser.viewPageLocationCoordinates,
    },
    locationCoordinatesApproval: {
        path: '/locations-approval',
        key: 'locations-approval',
        component: LocationCoordinatesApproval,
        checkPermissions: canUser.viewPageLocationCoordinatesApproval,
    },
    pdfMessage: {
        path: '/predefined-responses',
        key: 'pdfMessage',
        component: PredefinedMessage,
        checkPermissions: canUser.viewPagePdfMessage,
    },
    // tariff: {
    //     path: '/tariff',
    //     key: 'tariff',
    //     component: Tariff,
    //     checkPermissions: canUser.viewPageTariff,
    // },
    // tariffApproval: {
    //     path: '/tariff-approval',
    //     key: 'tariffApproval',
    //     component: TariffApproval,
    //     checkPermissions: canUser.viewPageTariffApproval,
    // },
    importExport: {
        path: '/import-export',
        key: 'importExport',
        component: ImportExport,
        checkPermissions: canUser.viewPageImportExport,
    },
    intentReport: {
        path: '/intents-report',
        key: 'intentReport',
        component: IntentReport,
        checkPermissions: canUser.viewPageIntentReport,
    },
    liveChat: {
        path: '/live-chat',
        key: 'chat',
        component: LiveChat,
        checkPermissions: canUser.viewPageLiveChat,
    },
    liveChatConfiguration: {
        path: '/live-chat-configuration',
        key: 'chat-configuration',
        component: LiveChatConfiguration,
        checkPermissions: canUser.viewPageLiveChatConfiguration,
    },
    liveChatWorkingHours: {
        path: '/live-chat-working-hours',
        key: 'chat-working-hours',
        component: LiveChatWorkingHours,
        checkPermissions: canUser.viewPageLiveChatWorkingHours,
    },
    liveChatForbiddenPhrases: {
        path: '/live-chat-operator-forbidden-phrases',
        key: 'forbidden-phrases',
        component: ForbiddenPhrasses,
        checkPermissions: canUser.viewPageLiveChatForbiddenPhrases,
    },
    liveChatMultilingualPhrases: {
        path: '/live-chat-operator-multilingual-phrases',
        key: 'multilingual-phrases',
        component: MultilingualPhrasses,
        checkPermissions: canUser.viewPageLiveChatMultilingualPhrases,
    },
    liveChatTemplates: {
        path: '/live-chat-templates',
        key: 'templates',
        component: LiveChatTemplates,
        checkPermissions: canUser.viewPageLiveChatTemplates,
    },
    liveChatBanList: {
        path: '/live-chat-ban-list',
        key: 'banList',
        component: LiveChatBanList,
        checkPermissions: canUser.viewPageBanList,
    },
    liveChatDashboard: {
        path: '/live-chat-dashboard',
        key: 'dashboard',
        component: LiveChatDashboard,
        checkPermissions: canUser.viewPageDashboard,
    },
    liveChatOperatorDashboard: {
        path: '/my-live-chat-summary',
        key: 'operatorDashboard',
        component: LiveChatOperatorDashboard,
        checkPermissions: canUser.viewPageOperatorDashboard,
    },
    liveChatSuperviseLiveChat: {
        path: '/live-chat-monitoring',
        key: 'superviseLiveChat',
        component: LiveChatSuperviseLiveChat,
        checkPermissions: canUser.viewPageSuperviseLiveChat,
    },
    profile: {
        path: '/profile',
        key: 'profile',
        component: Profile,
        checkPermissions: canUser.viewPageProfile,
    },
};
