import { Checkbox } from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';
import React from 'react';
import { Controller } from 'react-hook-form';

const CheckboxControlled = ({
    error,
    label,
    info,
    labelClass,
    defaultValue,
    name,
    control,
    registerRef,
    beforeOnChange,
    disabled,
}) => {
    return (
        <Controller
            defaultValue={defaultValue ? defaultValue : false}
            control={control}
            name={name}
            rules={registerRef ? registerRef() : null}
            render={({ onChange, onBlur, value, name }) => (
                <>
                    <div className="d-flex flex-nowrap flex-row align-items-end justify-content-start">
                        <Checkbox
                            inputId={name}
                            className="mr-2 mb-2"
                            onBlur={onBlur}
                            onChange={e => {
                                if (beforeOnChange) {
                                    beforeOnChange(e);
                                }
                                onChange(e.target.checked);
                            }}
                            checked={value || false}
                            value={value}
                            name={name}
                            disabled={disabled}
                        />
                        <label
                            className={`checkbox-label ${labelClass ? labelClass : ''} ${
                                disabled ? 'input-disabled' : ''
                            }`}
                            htmlFor={name}
                        >
                            {label}&nbsp;
                            {info ? (
                                <>
                                    <span data-pr-tooltip={info} className="label-info">
                                        <i className="fa fa-info" aria-hidden="true"></i>
                                    </span>
                                    <Tooltip target={`.label-info`} />
                                </>
                            ) : null}
                        </label>
                    </div>
                    {error ? <p className="error-message">{error}</p> : null}
                </>
            )}
        />
    );
};

export default CheckboxControlled;
