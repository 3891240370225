import get from 'lodash/get';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import ApiRequests from '../http/ApiRequests';
import axiosErrorHandler from '../http/AxiosErrorHandler';
import conversRepFilterSchema from '../schemas/conversRepFilterSchema';
import ReportsService from '../services/reports.service';
import validationService from '../services/validationService';

const useGeneralConversationReport = ({ reloadCounter, includeForwarded }) => {
    const [api] = useState(new ApiRequests());
    const reportService = new ReportsService();
    const filterFields = {
        acceptedBy: 'acceptedBy',
        dateFrom: 'dateFrom',
        dateTo: 'dateTo',
        sessionID: 'sessionID',
        channel: 'channel',
        startsAfter: 'startsAfter',
        endsBefore: 'endsBefore',
        orderDirection: 'orderDirection',
        orderCriteria: 'orderCriteria',
        begin: 'begin',
        end: 'end',
        includeForwarded: 'includeForwarded',
    };
    const messageSenderValues = useMemo(
        () => ({
            CUSTOMER: 'Customer',
            CHATBOT: '--',
        }),
        []
    );
    const { register, handleSubmit, errors, control, setValue } = useForm();
    const validator = validationService();
    const initialGridMeta = useMemo(
        () => ({
            limit: 10,
            offset: 0,
            total: 0,
        }),
        []
    );
    const [generalErrorList, setGeneralErrorList] = useState([]);
    const [conversationGridData, setConversationGridData] = useState([]);
    const [gridMeta, setGridMeta] = useState({ ...initialGridMeta });
    const [gridFilter, setGridFilter] = useState({});
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [messagesGridData, setmessagesGridData] = useState([]);
    const [stillDownload, setStillDownload] = useState(false);
    const [operatorList, setOperatorList] = useState([]);
    const conversationGridHeaders = [
        { name: 'Conv. ID', key: 'convId', filter: false },
        { name: 'Session ID', key: 'sessionId', filter: false },
        { name: 'Operator', key: 'acceptedBy', filter: false },
        { name: 'Channel', key: 'channel', filter: false },
        { name: 'Start Time', key: 'startTime', formatter: 'datetime', filter: false },
        { name: 'End Time', key: 'endTime', formatter: 'datetime', filter: false },
        { name: 'Duration', key: 'duration', filter: false },
    ];
    const messagesGridHeaders = [
        { name: 'Conv. ID', key: 'convId', filter: false },
        { name: 'Session ID', key: 'sessionId', filter: false },
        { name: 'Time', key: 'messageTimestamp', formatter: 'datetime', filter: false },
        { name: 'Chatbot/Operator', key: 'botCustomer', filter: false },
        { name: 'Message/Action', key: 'content', filter: false },
    ];
    const channelOptions = [
        { label: 'All', value: '' },
        { label: 'Viber', value: 'VIBER' },
        { label: 'Messenger', value: 'MESSENGER' },
        { label: 'Web', value: 'API' },
    ];
    const orderCriteriaOptions = [
        { label: 'Start Timestamp', value: 'START_TS' },
        { label: 'End Timestamp', value: 'END_TS' },
    ];
    const defaultOrderCriteria = orderCriteriaOptions[0].value;
    const orderDirectionOptions = [
        { label: 'Ascending', value: 'ASCENDING' },
        { label: 'Descending', value: 'DESCENDING' },
    ];
    const defaultOrderDirection = orderDirectionOptions[0].value;
    const onSubmit = data => {
        const filters = {};

        if (
            data[filterFields.dateFrom] &&
            data[filterFields.dateTo] &&
            moment(data[filterFields.dateTo]).isBefore(data[filterFields.dateFrom])
        ) {
            setGeneralErrorList([validator.errorMessages.INCORRECT_SEARCH_PERIOD]);
            return;
        }

        if (data[filterFields.dateFrom])
            filters[filterFields.startsAfter] = moment(data[filterFields.dateFrom]).utc().format();
        if (data[filterFields.dateTo])
            filters[filterFields.endsBefore] = moment(data[filterFields.dateTo]).utc().format();
        if (data[filterFields.sessionID])
            filters[filterFields.sessionID] = data[filterFields.sessionID];
        if (data[filterFields.channel]) filters[filterFields.channel] = data[filterFields.channel];
        if (data[filterFields.acceptedBy])
            filters[filterFields.acceptedBy] = data[filterFields.acceptedBy];

        setGridFilter(filters);

        getConversations(filters, initialGridMeta);
        setSelectedConversation(null);
        setmessagesGridData([]);
    };
    const onExport = data => {
        const requestData = {};
        if (includeForwarded) {
            requestData[filterFields.includeForwarded] = includeForwarded;
        }
        if (
            data[filterFields.dateFrom] &&
            data[filterFields.dateTo] &&
            moment(data[filterFields.dateTo]).isBefore(data[filterFields.dateFrom])
        ) {
            setGeneralErrorList([validator.errorMessages.INCORRECT_SEARCH_PERIOD]);
            return;
        }
        if (data[filterFields.dateFrom])
            requestData[filterFields.begin] = moment(data[filterFields.dateFrom]).utc().format();
        if (data[filterFields.dateTo])
            requestData[filterFields.end] = moment(data[filterFields.dateTo]).utc().format();
        if (data[filterFields.sessionID])
            requestData[filterFields.sessionID] = data[filterFields.sessionID];
        if (data[filterFields.channel])
            requestData[filterFields.channel] = data[filterFields.channel];
        if (data[filterFields.orderCriteria])
            requestData[filterFields.orderCriteria] = data[filterFields.orderCriteria];
        if (data[filterFields.orderDirection])
            requestData[filterFields.orderDirection] = data[filterFields.orderDirection];
        if (data[filterFields.acceptedBy])
            requestData[filterFields.acceptedBy] = data[filterFields.acceptedBy];

        setStillDownload(true);
        api.downloadConversationReportAsExcel(requestData)
            .then(response =>
                reportService.downloadBlobFileWithLink(
                    response,
                    'conversation_reports.xlsx',
                    setStillDownload
                )
            )
            .catch(err => {
                reportService.handleBlobError(err, setGeneralErrorList, setStillDownload);
                setStillDownload(false);
            });
    };
    const getConversations = useCallback(
        (filter, meta) => {
            api.getConversations({ ...filter, forwarded: true, ...meta })
                .then(res => {
                    setConversationGridData(processData(get(res, 'data.conversations', [])));
                    setGridMeta({
                        limit: get(res, 'data.limit', 5),
                        offset: get(res, 'data.offset', meta.offset),
                        total: get(res, 'data.total', 0),
                    });
                })
                .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setGeneralErrorList(errorList);
                    setmessagesGridData([]);
                });
        },
        [api]
    );
    const onGridPage = event => {
        setSelectedConversation(null);
        getConversations(gridFilter, {
            limit: event.rows,
            offset: event.first,
            page: event.page + 1,
        });
    };
    const processData = data => {
        const calcDuration = (start, end) => {
            start = moment(start);
            end = moment(end);
            var hrs = moment.utc(end.diff(start)).format('HH');
            var min = moment.utc(end.diff(start)).format('mm');
            var sec = moment.utc(end.diff(start)).format('ss');
            return [hrs, min, sec].join(':');
        };

        return data.map(item => ({
            convId: item.id,
            sessionId: item.session,
            acceptedBy: item.acceptedBy,
            channel: item.channel === 'API' ? 'WEB' : item.channel,
            startTime: item.startTs,
            endTime: item.endTs,
            duration: calcDuration(item.startTs, item.endTs),
            chatbotMsg: item.chatBotMessagesCount,
            customerMsg: item.clientMessagesCount,
            totalMsg: item.totalMessagesCount,
            averageResponseTime: item.averageResponseTime + 's',
            engaged: item.engaged,
            finished: item.finished,
            rejected: item.rejected,
            lbInteractions: 'lbInteractions',
            userSatisfaction: item.rating === null ? '--' : item.rating !== 0 ? item.rating : 0,
        }));
    };

    const getUsers = useCallback(() => {
        api.searchForUsers()
            .then(response => {
                if (Array.isArray(response.data) && response.data.length) {
                    setOperatorList(
                        response.data.map(user => ({ label: user.username, value: user.username }))
                    );
                }
            })
            .catch(error => {
                const [errorList] = axiosErrorHandler(error);
                setGeneralErrorList(errorList);
            });
    }, [api]);

    const getConversationMessages = useCallback(
        async conversationId => {
            try {
                const messagesData = await api.getConversationMessages(conversationId);
                return messagesData.data;
            } catch (err) {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            }
        },
        [api, setGeneralErrorList]
    );

    useEffect(() => {
        const initialGridFilter = {};
        getConversations(initialGridFilter, initialGridMeta);
        getUsers();
    }, [getConversations, initialGridMeta, getUsers]);

    useEffect(() => {
        if (selectedConversation) {
            getConversationMessages(selectedConversation.convId)
                .then(response => {
                    if (Array.isArray(response.data) && response.data.length) {
                        const messages = response.data.map(msg => ({
                            convId: selectedConversation.convId,
                            sessionId: selectedConversation.sessionId,
                            channel:
                                selectedConversation.channel === 'API'
                                    ? 'WEB'
                                    : selectedConversation.channel,
                            messageTimestamp: moment(msg.messageTs).format('YYYY-MM-DD HH:mm:ss'),
                            botCustomer: msg.customerId
                                ? messageSenderValues.CUSTOMER
                                : msg.sentByUser
                                ? msg.sentByUser
                                : messageSenderValues.CHATBOT,
                            content:
                                msg.type === 'TEXT' || msg.type === 'REQUEST_LOCATION'
                                    ? msg.content
                                    : msg.type + ': ' + msg.content.substring(0, 100) + '...',
                            responseTime: msg.responseTime ? msg.responseTime + 'ms' : '',
                            intent: msg.recognizedIntent ? msg.recognizedIntent.name : '',
                            confidence: msg.confidence ? msg.confidence.toFixed(4) : '',
                        }));
                        setmessagesGridData(messages);
                    }
                })
                .catch(error => {
                    const [errorList] = axiosErrorHandler(error);
                    setGeneralErrorList(errorList);
                });
        } else {
            setmessagesGridData([]);
        }
    }, [
        reloadCounter,
        getConversationMessages,
        getConversations,
        getUsers,
        gridFilter,
        initialGridMeta,
        messageSenderValues,
        selectedConversation,
    ]);

    const changeSelectedConversation = async conversation => {
        try {
            setSelectedConversation(conversation);
            if (selectedConversation === conversation) {
                setSelectedConversation(null);
                setmessagesGridData([]);
            } else {
                let messages = await getConversationMessages(conversation.convId);
                messages = messages.map(msg => ({
                    convId: conversation.convId,
                    sessionId: conversation.sessionId,
                    channel: conversation.channel === 'API' ? 'WEB' : conversation.channel,
                    messageTimestamp: moment(msg.messageTs).format('YYYY-MM-DD HH:mm:ss'),
                    botCustomer: msg.customerId
                        ? messageSenderValues.CUSTOMER
                        : msg.sentByUser
                        ? msg.sentByUser
                        : messageSenderValues.CHATBOT,
                    content:
                        msg.type === 'TEXT' || msg.type === 'REQUEST_LOCATION'
                            ? msg.content
                            : msg.type + ': ' + msg.content.substring(0, 100) + '...',
                    responseTime: msg.responseTime ? msg.responseTime + 'ms' : '',
                    intent: msg.recognizedIntent ? msg.recognizedIntent.name : '',
                    confidence: msg.confidence ? msg.confidence.toFixed(4) : '',
                }));
                setmessagesGridData(messages);
            }
        } catch (err) {
            const [errorList] = axiosErrorHandler(err);
            setGeneralErrorList(errorList);
        }
    };

    const clearOperatorField = useCallback(
        e => {
            e.preventDefault();
            setValue('acceptedBy', null);
        },
        [setValue]
    );

    return {
        registerValidationFor: conversRepFilterSchema(register),
        channelOptions,
        conversationGridHeaders,
        conversationGridData,
        gridMeta,
        generalErrorList,
        onGridPage,
        messagesGridHeaders,
        messagesGridData,
        fieldErrorFor: errors,
        control,
        onSubmit: handleSubmit(
            onSubmit,
            validator.extractErrorsFromInvalidForm(setGeneralErrorList)
        ),
        selectedConversation,
        changeSelectedConversation,
        orderCriteriaOptions,
        defaultOrderCriteria,
        orderDirectionOptions,
        defaultOrderDirection,
        stillDownload,
        onExport: handleSubmit(
            onExport,
            validator.extractErrorsFromInvalidForm(setGeneralErrorList)
        ),
        operatorList,
        clearOperatorField,
    };
};

export default useGeneralConversationReport;
