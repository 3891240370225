/**
 * Kindly use this file for all constant values.
 * This excludes labels, titles and translations.
 */

export const WEEK_DAYS = {
    MONDAY: 'Monday',
    TUESDAY: 'Tuesday',
    WEDNESDAY: 'Wednesday',
    THURSDAY: 'Thursday',
    FRIDAY: 'Friday',
    SATURDAY: 'Saturday',
    SUNDAY: 'Sunday',
};

export const COLORS = [
    '#5C3D2E',
    '#B85C38',
    '#86340A',
    '#E8D0B3',
    '#2A0944',
    '#3A6351',
    '#A12568',
    '#FEC260',
    '#464660',
    '#368B85',
    '#3B185F',
    '#00541A',
    '#00303F',
    '#2D2424',
];
