import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import BlinkingIcon from '../../components/UI/BlinkingIcon';
import LiveChatCard from './LiveChatCard';
import { setSelectedConversationId } from '../../store/actions/liveChatActions';

const ActiveChatsPanel = ({ conversations, selectedConversationId, setSelectedConversationId }) => {
    const checkForUnreadMessages = useCallback(
        () => conversations.some(conv => conv.unreadMessagesCount > 0),
        [conversations]
    );
    const onSwitchBetweenActiveChats = useCallback(
        id => () => {
            setSelectedConversationId(id);
        },
        [setSelectedConversationId]
    );

    return (
        <>
            <div
                className="d-flex justify-content-between flex-row align-items-center p-2"
                style={{ borderBottom: '1px solid #cccccc', height: '50px' }}
            >
                <h2 className="display-5" style={{ marginBottom: 0 }}>
                    Active Chats
                </h2>
                <span className="pt-1 d-flex">
                    {checkForUnreadMessages() ? <BlinkingIcon /> : null}
                </span>
            </div>
            <div style={{ minHeight: 'calc(40% - 50px)', overflowY: 'auto' }}>
                <div className="d-flex flex-column mb-2">
                    {Array.isArray(conversations) && conversations.length ? (
                        conversations.map((conv, index) => (
                            <LiveChatCard
                                key={index}
                                index={index}
                                conversation={conv}
                                conversationId={conv.id}
                                onClick={onSwitchBetweenActiveChats(conv.id)}
                                selectedActiveChatId={selectedConversationId}
                                sessionId={conv.session}
                                language={conv.language}
                                channel={conv.channel}
                                unreadMessagesCount={conv.unreadMessagesCount}
                                profileColor={conv.profileColor}
                            />
                        ))
                    ) : (
                        <p className="ml-2 mt-1">No active chats.</p>
                    )}
                </div>
            </div>
        </>
    );
};

const mapStateToProps = state => {
    return {
        conversations: state.liveChat.conversations,
        selectedConversationId: state.liveChat.selectedConversationId,
    };
};

const mapDispatchToProps = {
    setSelectedConversationId: setSelectedConversationId,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveChatsPanel);
