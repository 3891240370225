import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import Control from '../../components/UI/Control/Control';
import GeneralMessage from '../../components/UI/GeneralMessage';

const ProfileConfigPanel = ({ hook }) => {
    const {
        onSubmit,
        successMessage,
        generalErrorList,
        control,
        nickname,
        onAvatarChanged,
        isUpdateButtonDisabled,
        avatar,
        onNicknameChanged,
    } = hook;

    const formElement = (
        <div className="animated fadeIn">
            <GeneralMessage
                className="c-msg"
                successMessage={successMessage}
                errorList={generalErrorList}
            />
            <form noValidate id="profileInfoForm">
                <div className="container-fluid card p-4 mb-5 animated fadeIn">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="display-5">Configuration</h2>
                            <hr />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            <Control
                                name="nickname"
                                type="text"
                                control={control}
                                label="Nickname"
                                info="The name that will display in live console"
                                value={nickname}
                                onChange={e => {
                                    onNicknameChanged(e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-2">
                            <Control
                                name="avatar"
                                type="text"
                                label="Avatar URL"
                                control={control}
                                info="Should be a public link"
                                value={avatar}
                                onChange={e => {
                                    onAvatarChanged(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            <Tooltip target=".disabled-button-wrapper" position="top" />
                            <span
                                className="disabled-button-wrapper"
                                data-pr-tooltip="Confirm"
                                style={{ display: 'inline-block' }}
                            >
                                <Button
                                    type="button"
                                    className="add-btn"
                                    icon="fa fa-check"
                                    label="UPDATE"
                                    onClick={() => onSubmit()}
                                    disabled={isUpdateButtonDisabled()}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );

    return formElement;
};

export default ProfileConfigPanel;
