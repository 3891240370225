import { Tooltip } from 'primereact/tooltip';
import { InputText } from 'primereact/inputtext';
import styles from './TextInput.module.css';

const TextInput = props => {
    return (
        <div className={styles.wrapper}>
            {props.label ? (
                <label className={styles.label} htmlFor="">
                    {props.label}&nbsp;
                    {props.info ? (
                        <>
                            <span data-pr-tooltip={props.info} className="label-info">
                                <i className="fa fa-info" aria-hidden="true"></i>
                            </span>
                            <Tooltip target=".label-info" />
                        </>
                    ) : null}
                </label>
            ) : null}
            {props.error ? <p className="error-message">{props.error}</p> : null}
            <InputText className={styles.inputtext} value={props.value} onChange={props.onChange} />
        </div>
    );
};

export default TextInput;
