import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import throttle from 'lodash/throttle';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

dayjs.extend(duration);

const InactivityTimer = ({
    inactivityTimeoutMs = 7200000, // 2 hours per request from sportdepot
    showDialogDurationMs = 60000, // 1 minute
    dialogText,
    onInactivity,
    onClearInactivity,
}) => {
    const [showDialog, setShowDialog] = useState(false);
    const inactivityTimeoutId = useRef(-1);
    const countdownIntervalIdRef = useRef(-1);
    const countdownCountRef = useRef(dayjs.duration(showDialogDurationMs, 'ms'));
    const inactivityDetectedFlag = useRef(false);
    const [displaySeconds, setDisplaySeconds] = useState(
        dayjs.duration(showDialogDurationMs, 'ms')
    );
    const [resetCounter, setResetCounter] = useState(0);

    const content = useMemo(() => {
        if (dialogText && typeof dialogText === 'string') {
            return dialogText;
        } else {
            // useMemo instead of default prop value because later this text will come from translations.
            return 'This is an inactivity warning. Please press the button below before your session expires.';
        }
    }, [dialogText]);

    const resetTimer = useMemo(
        () =>
            throttle(() => {
                setResetCounter(value => value + 1);
            }, 1000),
        []
    );

    const onButtonClick = useCallback(() => {
        setShowDialog(false);
        if (typeof onClearInactivity === 'function') {
            onClearInactivity();
        }
    }, [onClearInactivity]);

    // reset inactivity timer on activity event
    useEffect(() => {
        if (window && resetTimer) {
            window.addEventListener('keydown', resetTimer);
            window.addEventListener('click', resetTimer);
            resetTimer();
        }

        return () => {
            window.removeEventListener('keydown', resetTimer);
            window.removeEventListener('click', resetTimer);
        };
    }, [resetTimer]);

    // reset inactivity timer on dialog close
    useEffect(() => {
        if (!showDialog) {
            clearTimeout(inactivityTimeoutId.current);
            inactivityTimeoutId.current = setTimeout(() => {
                setShowDialog(true);
            }, inactivityTimeoutMs);
        }

        return () => {
            clearTimeout(inactivityTimeoutId.current);
        };
    }, [resetCounter, showDialog, inactivityTimeoutMs]);

    // manage the dialog's countdown timer
    useEffect(() => {
        if (showDialog) {
            countdownIntervalIdRef.current = setInterval(() => {
                if (countdownCountRef.current.as('s') === 0) {
                    countdownCountRef.current = dayjs.duration(showDialogDurationMs, 'ms');
                    clearInterval(countdownIntervalIdRef.current);
                    setShowDialog(false);
                    inactivityDetectedFlag.current = true;
                } else {
                    countdownCountRef.current = countdownCountRef.current.subtract(1, 's');
                }
                setDisplaySeconds(countdownCountRef.current);
            }, 1000);
        } else {
            clearInterval(countdownIntervalIdRef.current);
            countdownCountRef.current = dayjs.duration(showDialogDurationMs, 'ms');
            setDisplaySeconds(countdownCountRef.current);
        }

        return () => {
            clearInterval(countdownIntervalIdRef.current);
        };
    }, [showDialog, onInactivity, showDialogDurationMs]);

    // additional useEffect is requried because if we call onInactivity
    // in the setInterval's callback and it simultaniously updates showDialog state and
    // changes the page we get warning that we try to update a component (setDialog state)
    // that was destroyed
    useEffect(() => {
        if (!showDialog && inactivityDetectedFlag.current === true) {
            inactivityDetectedFlag.current = false;
            if (typeof onInactivity === 'function') {
                onInactivity();
            }
        }
    }, [showDialog, onInactivity]);

    return (
        <Dialog
            header="Inactivity Warning"
            visible={showDialog}
            style={{ width: '50vw' }}
            breakpoints={{ '960px': '75vw', '640px': '100vw' }}
            closable={false}
            draggable={false}
            resizable={false}
        >
            <div className="d-flex flex-column">
                <div style={{ textAlign: 'center', fontWeight: 600 }}>{content}</div>
                <div className="my-2" style={{ textAlign: 'center', fontWeight: 600 }}>
                    {displaySeconds.format('mm:ss')}
                </div>
                <div className="d-flex justify-content-center mt-4">
                    <Button label="Ok" style={{ minWidth: '120px' }} onClick={onButtonClick} />
                </div>
            </div>
        </Dialog>
    );
};

export default InactivityTimer;
