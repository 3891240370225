const validationService = () => {
    const errorMessages = {
        INVALID_FIELDS: 'Validating one or more fields failed. The fields are colored red.',
        MISSING_REQUIRED_FIELDS: `One or more of the fields are not filled correctly and have been colored red.`,
        MISSING_EMAIL: `Please, enter an email.`,
        MISSING_PASSWORD: `Please, enter a password`,
        MISSING_PASSWORD_CONFIRMATION: `Please, confirm your password`,
        MISSING_NAME: `Please, enter a username`,
        MISSING_USER_GROUP_NAME: `Please, enter a name of the user group`,
        WRONG_EMAIL_FORMAT: `Invalid email format.`,
        EMAIL_TOO_LONG: `Maximum length of 100 symbols`,
        PASSWORD_TOO_SHORT: `Password has to be at least 6 symbols`,
        MISSING_LANG: 'Please, select a language',
        MISSING_CHANNEL: 'Please, select a channel',
        MISSING_INENT: 'Please, select an intent',
        MISSING_TYPE: 'Please, select a type',
        MISSING_CONTENT: 'Please, fill some content',
        MISSING_LABEL: 'Please, fill a label',
        MISSING_VALUE: 'Please, fill a value',
        MISSING_TITLE: 'Please, fill a title',
        MISSING_IMAGE: 'Please, fill a image',
        MISSING_URL: 'Please, fill a valid URL',
        MISSING_SUBTITLE: 'Please, fill a subtitle',
        DATE_FILTER_ERROR: 'After date cannot be greater than before date.',
        INCORRECT_SEARCH_PERIOD: 'Start of search period is greated than end of search period.',
    };

    const validationRules = {
        EMAIL_PATTERN: '(.+)@(.+){1,}.(.+){2,}',
    };

    const isEmpty = (...args) => {
        let isEmpty = false;
        args.forEach(arg => {
            if (!arg) {
                isEmpty = true;
            }
        });
        return isEmpty;
    };

    const validateEmail = email => {
        // Validate email format
        if (!/(.+)@(.+){1,}\.(.+){2,}/.test(email) && !isEmpty(email)) {
            return [false, errorMessages.WRONG_EMAIL_FORMAT];
        }
        // Validate email length
        if (email.length > 100) {
            return [false, errorMessages.EMAIL_TOO_LONG];
        }

        return [true, ''];
    };

    const validatePassword = password => {
        // Validate password min length
        if (password.length < 6 && !isEmpty(password)) {
            return [false, errorMessages.PASSWORD_TOO_SHORT];
        }
        // Validate password max length
        if (password.length > 20 && !isEmpty(password)) {
            return [false, errorMessages.PASSWORD_TOO_LONG];
        }

        return [true, ''];
    };

    const validatePasswordConfirmation = (password, passwordConfirmation) => {
        // Validate password confirmation
        if (password !== passwordConfirmation) {
            return [false, errorMessages.PASSWORD_CONFIRMATION_MISSMATCH];
        }
        return [true, ''];
    };

    const extractErrorsFromInvalidForm = (setErrorsFunction, setSuccess) => {
        if (setErrorsFunction) {
            return errors => {
                let errorList = [];

                let errorFields = Object.keys(errors);
                if (errorFields.length > 0) {
                    if (setSuccess) {
                        setSuccess(false);
                    }
                    const errorTypeList = errorFields.map(errorField => errors[errorField].type);
                    if (errorTypeList.includes('required')) {
                        errorList.push(errorMessages.MISSING_REQUIRED_FIELDS);
                    }

                    if (errorTypeList.filter(errorType => errorType !== 'required').length > 0) {
                        errorList.push(errorMessages.INVALID_FIELDS);
                    }
                }

                setErrorsFunction(errorList);
            };
        }
    };

    return {
        errorMessages,
        isEmpty,
        validateEmail,
        validatePassword,
        validatePasswordConfirmation,
        extractErrorsFromInvalidForm,
        validationRules,
    };
};

// Write the logic one more time directly exported as an object to avoid calling method on every rerender.
// This object will be used in the future, but the old method is kept in order not to break previous implementations.

export const validator = {
    errorMessages: {
        INVALID_FIELDS: 'Validating one or more fields failed. The fields are colored red.',
        MISSING_REQUIRED_FIELDS: `One or more of the fields are not filled correctly and have been colored red.`,
        MISSING_EMAIL: `Please, enter an email.`,
        MISSING_PASSWORD: `Please, enter a password`,
        MISSING_PASSWORD_CONFIRMATION: `Please, confirm your password`,
        MISSING_NAME: `Please, enter a username`,
        MISSING_USER_GROUP_NAME: `Please, enter a name of the user group`,
        WRONG_EMAIL_FORMAT: `Invalid email format.`,
        EMAIL_TOO_LONG: `Maximum length of 100 symbols`,
        PASSWORD_TOO_SHORT: `Password has to be at least 6 symbols`,
        MISSING_LANG: 'Please, select a language',
        MISSING_CHANNEL: 'Please, select a channel',
        MISSING_INENT: 'Please, select an intent',
        MISSING_TYPE: 'Please, select a type',
        MISSING_CONTENT: 'Please, fill some content',
        ONLY_WHITE_SPACE_CONTENT: 'Please, add some text.',
        MISSING_LABEL: 'Please, fill a label',
        MISSING_VALUE: 'Please, fill a value',
        MISSING_TITLE: 'Please, fill a title',
        MISSING_IMAGE: 'Please, fill a image',
        MISSING_URL: 'Please, fill a valid URL',
        MISSING_METADATA: 'Please, fill in metadata',
        WRONG_METADATA_FORMAT: 'Metadata should have the format "/xxxx#XX", e.g. "/intent_name#EN"',
        MISSING_SUBTITLE: 'Please, fill a subtitle',
        DATE_FILTER_ERROR: 'After date cannot be greater than before date.',
        INCORRECT_SEARCH_PERIOD: 'Start of search period is greated than end of search period.',
        MISSING_OPENING_TIME: 'Enter opening time.',
        MISSING_CLOSING_TIME: 'Enter closing time.',
        MISSING_DAY_OF_WEEK: 'Select day of the week.',
        MISSING_DATE: 'Pick a date.',
    },

    validationRules: {
        EMAIL_PATTERN: '(.+)@(.+){1,}.(.+){2,}',
    },

    isEmpty: (...args) => {
        let isEmpty = false;
        args.forEach(arg => {
            if (!arg) {
                isEmpty = true;
            }
        });
        return isEmpty;
    },

    validateEmail: email => {
        // Validate email format
        if (!/(.+)@(.+){1,}\.(.+){2,}/.test(email) && !validator.isEmpty(email)) {
            return [false, validator.errorMessages.WRONG_EMAIL_FORMAT];
        }
        // Validate email length
        if (email.length > 100) {
            return [false, validator.errorMessages.EMAIL_TOO_LONG];
        }

        return [true, ''];
    },

    validatePassword: password => {
        // Validate password min length
        if (password.length < 6 && !validator.isEmpty(password)) {
            return [false, validator.errorMessages.PASSWORD_TOO_SHORT];
        }
        // Validate password max length
        if (password.length > 20 && !validator.isEmpty(password)) {
            return [false, validator.errorMessages.PASSWORD_TOO_LONG];
        }

        return [true, ''];
    },

    validatePasswordConfirmation: (password, passwordConfirmation) => {
        // Validate password confirmation
        if (password !== passwordConfirmation) {
            return [false, validator.errorMessages.PASSWORD_CONFIRMATION_MISSMATCH];
        }
        return [true, ''];
    },

    extractErrorsFromInvalidForm: (setErrorsFunction, setSuccess) => {
        if (setErrorsFunction) {
            return errors => {
                let errorList = [];

                let errorFields = Object.keys(errors);
                if (errorFields.length > 0) {
                    if (setSuccess) {
                        setSuccess(false);
                    }
                    const errorTypeList = errorFields.map(errorField => errors[errorField].type);
                    if (errorTypeList.includes('required')) {
                        errorList.push(validator.errorMessages.MISSING_REQUIRED_FIELDS);
                    }

                    if (errorTypeList.filter(errorType => errorType !== 'required').length > 0) {
                        errorList.push(validator.errorMessages.INVALID_FIELDS);
                    }
                }

                setErrorsFunction(errorList);
            };
        }
    },
};

export default validationService;
