import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import ApiRequests from '../http/ApiRequests';
import axiosErrorHandler from '../http/AxiosErrorHandler';
import welcomeMessageSchema from '../schemas/welcomeMessageSchema';
import useConfirmDialog from './useConfirmDialog';

const useWelcomeMessage = props => {
    const api = useMemo(() => new ApiRequests(), []);
    const [welcomeMessages, setWelcomeMessages] = useState([]);
    const [selectedWelcomeMessage, setSelectedWelcomeMessage] = useState();
    const [apiErrors, setApiErrors] = useState([]);
    const [successMessage, setSuccessMessage] = useState(null);
    const { register, errors, getValues, setValue } = useForm();
    const confirm = useConfirmDialog();
    const [dialogIsVisible, setDialogIsVisible] = useState(false);
    const [editDialogIsVisible, setEditDialogIsVisible] = useState(false);
    const closeDialog = useCallback(() => setDialogIsVisible(false), [setDialogIsVisible]);
    const closeEditDialog = useCallback(
        () => setEditDialogIsVisible(false),
        [setEditDialogIsVisible]
    );
    const openEditDialog = useCallback(() => {
        setEditDialogIsVisible(true);
    }, [setEditDialogIsVisible]);
    const [changeSelectedWelcomeMessageCounter, setChangeSelectedWelcomeMessageCounter] =
        useState();
    const updateSelectedWelcomeMessageCounter = useCallback(
        () => setChangeSelectedWelcomeMessageCounter(Math.random()),
        [setChangeSelectedWelcomeMessageCounter]
    );
    const [reloadCounter, setReloadCounter] = useState(1);
    const reload = useCallback(() => setReloadCounter(Math.random()), [setReloadCounter]);
    const [gridHeaders] = useState([
        { name: 'Default', key: 'default', formatter: 'booleanWithIcons' },
        { name: 'Language', key: 'language' },
        { name: 'Channel', key: 'channel' },
        { name: 'Page', key: 'pageId' },
    ]);
    const onEditAction = useCallback(
        row => {
            setSelectedWelcomeMessage(welcomeMessages.find(message => message.id === row.id));
            updateSelectedWelcomeMessageCounter();
        },
        [setSelectedWelcomeMessage, updateSelectedWelcomeMessageCounter, welcomeMessages]
    );
    const getWelcomeMessages = useCallback(
        data => {
            api.getWelcomeMessages({
                channel: data.channel ? data.channel : null,
                language: data.language ? data.language : null,
                pending: false,
            })
                .then(response => {
                    setSelectedWelcomeMessage(null);
                    setWelcomeMessages(response.data);
                })
                .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setApiErrors(errorList);
                });
        },
        [api, setApiErrors]
    );

    const onFilter = useCallback(
        e => {
            e.preventDefault();
            e.stopPropagation();
            getWelcomeMessages({
                channel: getValues('channel'),
                language: getValues('language'),
                pending: false,
            });
        },
        [getWelcomeMessages, getValues]
    );

    const onClearFilter = useCallback(
        e => {
            e.preventDefault();
            e.stopPropagation();
            setValue('channel', '');
            setValue('language', '');
            getWelcomeMessages({
                channel: null,
                language: null,
                pending: false,
            });
        },
        [getWelcomeMessages, setValue]
    );

    useEffect(() => {
        getWelcomeMessages({ pending: false });
    }, [getWelcomeMessages, reloadCounter]);

    useEffect(() => {
        if (selectedWelcomeMessage && openEditDialog) {
            openEditDialog();
        }
    }, [selectedWelcomeMessage, openEditDialog, changeSelectedWelcomeMessageCounter]);

    const onAddNew = useCallback(
        e => {
            e.preventDefault();
            e.stopPropagation();
            setDialogIsVisible(true);
        },
        [setDialogIsVisible]
    );

    const langOptions = [
        { label: 'English', value: 'EN' },
        { label: 'Bulgarian', value: 'BG' },
    ];
    const channelOptions = [
        { label: 'Viber', value: 'VIBER' },
        { label: 'Messenger', value: 'MESSENGER' },
        { label: 'Web', value: 'API' },
    ];

    const deleteWelcomeMessage = async welcomeMessage => {
        if (!welcomeMessage) return;
        const acceptFunc = () => {
            api.deleteWelcomeMessage(welcomeMessage.id)
                .then(() => {
                    reload();
                    setSuccessMessage({ success: 'Welcome message set for delete.' });
                })
                .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setApiErrors(errorList);
                });
        };
        const conformProps = {
            message:
                'Are you sure you want to delete selected welcome message? If approved this will delete all other pending requests connected to this welcome message!',
            header: 'Delete welcome message',
            icon: 'fa fa-trash-o',
            accept: () => acceptFunc(),
        };
        confirm(conformProps);
    };

    return {
        welcomeMessages,
        selectedWelcomeMessage,
        langOptions,
        channelOptions,
        errors,
        registerValidationFor: welcomeMessageSchema(register),
        deleteWelcomeMessage,
        generalErrorList: apiErrors,
        onFilter,
        onClearFilter,
        gridHeaders,
        onAddNew,
        dialogIsVisible,
        closeDialog,
        editDialogIsVisible,
        closeEditDialog,
        onEditAction,
        reload,
        successMessage,
    };
};

export default useWelcomeMessage;
