import { permissions } from '../permissions/permissions';

const permissionsSchema = register => {
    const fieldsObj = {};

    Object.values(permissions).forEach(permission => {
        fieldsObj[permission] = register();
    });

    return fieldsObj;
};

export default permissionsSchema;
