import { useCallback, useEffect, useMemo, useState } from 'react';
import ApiRequests from '../http/ApiRequests';
import axiosErrorHandler from '../http/AxiosErrorHandler';
import get from 'lodash/get';
import { sortByNumberProperty } from '../utils/sortUtils';

const useWelcomeMessagesApproval = props => {
    const api = useMemo(() => new ApiRequests(), []);

    const [welcomeMessages, setWelcomeMessages] = useState([]);
    const [generalErrorList, setGeneralErrorList] = useState([]);
    const [successMessage, setSuccessMessage] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const tasksForApprovalHeaders = [
        { name: 'Channel', key: 'channel' },
        { name: 'Default', key: 'default' },
        { name: 'Language', key: 'language' },
        { name: 'Page ID', key: 'pageId' },
        { name: 'Status', key: 'status' },
    ];

    const listWelcomeMessagesForReview = useCallback(() => {
        api.getWelcomeMessages({ pending: true })
            .then(res => {
                const sortedMessages = res.data?.sort(sortByNumberProperty('id', true));
                setWelcomeMessages([...sortedMessages]);
            })
            .catch(err => {
                setWelcomeMessages([]);
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            });
    }, [api]);

    const approveWelcomeMessage = id => {
        api.approveWelcomeMessage(id)
            .then(() => {
                setSuccessMessage({ success: 'Changes approved.' });
                listWelcomeMessagesForReview();
            })
            .catch(err => {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            });
    };

    const rejectWelcomeMessage = id => {
        api.rejectWelcomeMessage(id)
            .then(() => {
                setSuccessMessage({ success: 'Changes rejected.' });
                listWelcomeMessagesForReview();
            })
            .catch(err => {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            });
    };

    useEffect(() => {
        listWelcomeMessagesForReview();
    }, [listWelcomeMessagesForReview]);

    const onRowExpand = data => {
        const expandRowId = {};
        expandRowId[get(data, 'data.id', 0)] = true;
        setSelectedRow(null);
        setSelectedRow(expandRowId);
    };

    const onRowCollapse = () => {
        setSelectedRow(null);
    };

    return {
        welcomeMessages,
        generalErrorList,
        successMessage,
        onRowExpand,
        onRowCollapse,
        selectedRow,
        approveWelcomeMessage,
        rejectWelcomeMessage,
        tasksForApprovalHeaders,
        approveTask: approveWelcomeMessage,
        rejectTask: rejectWelcomeMessage,
    };
};

export default useWelcomeMessagesApproval;
