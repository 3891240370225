import { Button } from 'primereact/button';
import CheckboxControlled from '../../components/UI/Control/CheckboxControlled';
import Control from '../../components/UI/Control/Control';
import GeneralMessage from '../../components/UI/GeneralMessage';
import useWelcomeMessageForm from '../../hooks/useWelcomeMessageForm';
import RoboadvisorService from '../../services/roboadvisor.service';
import WelcomeMessageInteractiveResponses from '../welcomeMessageResp/WelcomeMessageInteractiveResponses';

const WelcomeMessageForm = ({ selectedWelcomeMessage, closeDialog, reload }) => {
    const {
        registerValidationFor,
        errors,
        control,
        langOptions,
        channelOptions,
        watch,
        getValues,
        setValue,
        append,
        fields,
        trigger,
        reset,
        remove,
        unregister,
        onSubmit,
        successMessage,
        generalErrorList,
        searchIntent,
        intentList,
        clearErrors,
    } = useWelcomeMessageForm({ data: selectedWelcomeMessage, closeDialog, reload });
    const watchChannel = watch("channel");

    return (
        <>
            <GeneralMessage errorList={generalErrorList} successMessage={successMessage} />

            <div className="row">
                <div className="col-4">
                    <Control
                        error={errors.language?.message}
                        registerRef={registerValidationFor?.language}
                        name="language"
                        type="select"
                        label="Language"
                        value={selectedWelcomeMessage?.language}
                        options={langOptions}
                    />
                </div>
                <div className="col-4">
                    <Control
                        error={errors.channel?.message}
                        registerRef={registerValidationFor?.channel}
                        name="channel"
                        type="select"
                        label="Channel"
                        options={channelOptions}
                        value={selectedWelcomeMessage?.channel}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <CheckboxControlled
                        error={errors.default?.message}
                        registerRef={registerValidationFor?.default}
                        name="default"
                        type="checkbox"
                        inLabel
                        control={control}
                        label="Default welcome message"
                        value={selectedWelcomeMessage?.default}
                    />
                </div>
                {watchChannel === "API" ? (
                    <div className="col-12">
                        <Control
                            error={errors.pageId?.message}
                            registerRef={registerValidationFor?.pageId}
                            name="pageId"
                            type="text"
                            label="Page"
                            value={selectedWelcomeMessage?.pageId}
                        />
                    </div>
                ) : null}
                {Array.isArray(fields) && fields.length
                    ? fields.map((response, index) => (
                          <WelcomeMessageInteractiveResponses
                              key={response.id}
                              namePrefix={`responses[${index}]`}
                              index={index}
                              registerValidationFor={registerValidationFor}
                              response={response}
                              control={control}
                              errors={errors}
                              getValues={getValues}
                              setValue={setValue}
                              watch={watch}
                              reset={reset}
                              removeResponse={remove}
                              selectedWelcomeMessage={selectedWelcomeMessage}
                              unregister={unregister}
                              searchIntent={searchIntent}
                              intentList={intentList}
                              clearErrors={clearErrors}
                          />
                      ))
                    : null}
            </div>

            <section>
                <Button
                    type="button"
                    className="add-btn"
                    onClick={() =>
                        trigger(["channel", "language"]).then(fieldsAreValid => {
                            if (fieldsAreValid) {
                                append({
                                    type: RoboadvisorService.responseTypes.text,
                                    content: {
                                        buttons: [],
                                        carousel: [],
                                        text: "",
                                        alternativeContent: "",
                                    },
                                });
                            }
                        })
                    }
                    icon="fa fa-plus-circle"
                    label="ADD NEW RESPONSE"
                />
            </section>
            <hr />
            <div className="d-flex flex-row justify-content-start my-4">
                <Button className="mr-2 p-button-primary" label="Confirm" onClick={onSubmit} />
                <Button className="p-button-secondary" label="Cancel" onClick={closeDialog} />
            </div>
        </>
    );
};

export default WelcomeMessageForm;
