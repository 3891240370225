import { Button } from 'primereact/button';
import React from 'react';
import BotTrainingApprovalItem from '../../components/BotTrainingApprovalItem/BotTrainingApprovalItem';
import PageRootNav from '../../components/PageRootNav/PageRootNav';
import GeneralMessage from '../../components/UI/GeneralMessage';
import useApproveTraining from '../../hooks/useApproveTraining';
import BotTrainingStatusPanel from '../../modules/botTrainingStatus/BotTrainingStatusPanel';
import ModelApprovalPanel from '../../modules/modelApproval/ModelApprovalPanel';
import './ApproveTraining.css';

const ApproveTraining = props => {
    const {
        dataToApproveReject,
        modelsToApproveReject,
        nowElementsList,
        futureElementsList,
        generalErrorList,
        successMessage,
        toglePreview,
        confirmApproveModel,
        confirmRejectModel,
        confirmApproveModification,
        confirmRejectModification,
        confirmApproveAllModifications,
        confirmRejectAllModifications,
        confirmStartBotTraining,
        trainingEnabled,
        trainingInProgress,
        trainingElapsedTime,
        getLastTrainingTime,
        lastTrainingExecutor,
        lastTrainingDuration,
        currentlyLoadedModel,
        loadedModelApprovedBy,
        loadedModelApprovedTs,
        getIntentStatusDetailed,
        status,
        modelApproveSpinner,
    } = useApproveTraining(props);

    return (
        <>
            <PageRootNav label="Awaiting approval" />
            <GeneralMessage
                className="c-msg"
                successMessage={successMessage}
                errorList={generalErrorList}
            />
            <h2 className="display-5 mb-4">List of approval tasks</h2>
            {modelsToApproveReject.length > 0 ? (
                <ModelApprovalPanel
                    modelApproveSpinner={modelApproveSpinner}
                    modelData={modelsToApproveReject[0]}
                    confirmApprove={confirmApproveModel}
                    confirmReject={confirmRejectModel}
                />
            ) : (
                ''
            )}

            {dataToApproveReject.length > 0 ? (
                <div style={{ marginBottom: 10 }}>
                    <Button
                        type="button"
                        className="add-btn mr-2"
                        label={'Approve All'}
                        onClick={confirmApproveAllModifications}
                    />
                    <Button
                        type="button"
                        className="delete-btn mr-2"
                        label={'Reject All'}
                        onClick={confirmRejectAllModifications}
                    />
                </div>
            ) : (
                ''
            )}

            {dataToApproveReject.map((val, i) => (
                <BotTrainingApprovalItem
                    key={i}
                    index={i}
                    change={val}
                    nowElementsList={nowElementsList}
                    futureElementsList={futureElementsList}
                    toglePreview={toglePreview}
                    approveModification={confirmApproveModification}
                    rejectModification={confirmRejectModification}
                    getIntentStatusMessage={getIntentStatusDetailed}
                    changeStatus={status}
                />
            ))}
            <hr />
            <BotTrainingStatusPanel
                confirmStartBotTraining={confirmStartBotTraining}
                trainingEnabled={trainingEnabled}
                trainingInProgress={trainingInProgress}
                trainingElapsedTime={trainingElapsedTime}
                getLastTrainingTime={getLastTrainingTime}
                lastTrainingExecutor={lastTrainingExecutor}
                lastTrainingDuration={lastTrainingDuration}
                currentlyLoadedModel={currentlyLoadedModel}
                loadedModelApprovedBy={loadedModelApprovedBy}
                loadedModelApprovedTs={loadedModelApprovedTs}
            />
        </>
    );
};

export default ApproveTraining;
