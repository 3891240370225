import * as React from 'react';
import logo from '../../images/brand/logo.png';

const Logo = props => {
    return (
        <img
            className="navbar-brand-full"
            src={logo}
            width={props.width}
            height={props.height}
            alt={props.alt}
        />
    );
};

export default Logo;
