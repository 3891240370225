import ApprovalTasksList from '../modules/apprvResp/ApprovalTasksList';

const ApprvResp = () => {
    return (
        <>
            <h1 className="mb-5 display-4">Awaiting approval</h1>
            <ApprovalTasksList />
        </>
    );
};

export default ApprvResp;
