import React from 'react';

const TileTimeFormat = ({ time }) => {
    return (
        <div className="d-flex flex-wrap flex-column w-100">
            {time.hours ? (
                <div className="d-flex align-items-stretch w-100">
                    <span
                        className="value"
                        style={{
                            marginRight: '1px',
                            marginLeft: '5px',
                            width: '50%',
                            textAlign: 'right',
                        }}
                    >
                        {time.hours}
                    </span>
                    <span className="unit" style={{ width: '50%', textAlign: 'left' }}>
                        {time.hoursUnit}
                    </span>
                </div>
            ) : null}
            {time.minutes ? (
                <div className="d-flex align-items-stretch">
                    <span
                        className="value"
                        style={{
                            marginRight: '1px',
                            marginLeft: '5px',
                            width: '50%',
                            textAlign: 'right',
                        }}
                    >
                        {time.minutes}
                    </span>
                    <span className="unit" style={{ width: '50%', textAlign: 'left' }}>
                        {time.minutesUnit}
                    </span>
                </div>
            ) : null}
            {time.seconds || (!time.hours && !time.minutes && !time.milliseconds) ? (
                <div className="d-flex align-items-stretch">
                    <span
                        className="value"
                        style={{
                            marginRight: '1px',
                            marginLeft: '5px',
                            width: '50%',
                            textAlign: 'right',
                        }}
                    >
                        {time.seconds}
                    </span>
                    <span className="unit" style={{ width: '50%', textAlign: 'left' }}>
                        {time.secondsUnit}
                    </span>
                </div>
            ) : null}
            {time.milliseconds ? (
                <div className="d-flex align-items-stretch">
                    <span
                        className="value"
                        style={{
                            marginRight: '1px',
                            marginLeft: '5px',
                            width: '50%',
                            textAlign: 'right',
                        }}
                    >
                        {time.milliseconds}
                    </span>
                    <span className="unit" style={{ width: '50%', textAlign: 'left' }}>
                        {time.millisecondsUnit}
                    </span>
                </div>
            ) : null}
        </div>
    );
};

export default TileTimeFormat;
