import PageRootNav from '../../components/PageRootNav/PageRootNav';
import IntentReportGrid from '../../modules/intentReportData/IntentReportGrid';

const IntentReport = () =>{
    return (
        <>
            <PageRootNav label='Intent Reports'/>
            <IntentReportGrid/>
        </>
    );
};

export default IntentReport;
