import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ApiRequests from '../http/ApiRequests';
import { allRoutes } from '../routes/Routes';
import { clearCurrentUser } from '../store/actions/authActions';

const useLogout = (afterLogoutAction, beforeLogout) => {
    const [api] = useState(new ApiRequests());
    const dispatch = useDispatch();
    const history = useHistory();
    const logout = useCallback(() => {
        if (typeof beforeLogout === 'function') {
            beforeLogout();
        }
        dispatch(clearCurrentUser());
        history.replace(allRoutes.login.path);
        setTimeout(() => {
            api.logOut()
                .catch(err => {
                    console.error('Failed destroy the session!', err);
                })
                .finally(() => {
                    if (typeof afterLogoutAction === 'function') {
                        // USE WITH CAUTION!!!
                        // After the route changes all previos components will be destroyed
                        afterLogoutAction();
                    }
                });
        }, 1000);
    }, [history, dispatch, api, afterLogoutAction, beforeLogout]);

    return logout;
};

export default useLogout;
