import { FileUpload } from 'primereact/fileupload';
import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';
import GeneralMessage from '../../components/UI/GeneralMessage';

const LocationCoordinatesConfigPanel = ({ hook }) => {
    const { uploadHandler, successMessage, uploadSpinner, generalErrorList } = hook;

    const uploadPanelContent = () => {
        if (uploadSpinner) {
            return <ProgressSpinner strokeWidth="4" />;
        } else {
            return (
                <FileUpload
                    uploadHandler={uploadHandler}
                    customUpload={true}
                    label="Select a text file in CSV format. Expected order of fields is [LOCATION, LATITUDE, LONGITUDE]"
                    accept=".csv,.txt"
                    emptyTemplate={<p className="p-m-0">Drag and drop files.</p>}
                />
            );
        }
    };

    const formElement = (
        <div className="animated fadeIn">
            <GeneralMessage
                className="c-msg"
                successMessage={successMessage}
                errorList={generalErrorList}
            />
            <form noValidate id="locationCoordinatesForm" tooltip="test">
                <div className="container-fluid card p-4 mb-5 animated fadeIn">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="display-5">Configuration</h2>
                            <hr />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">{uploadPanelContent()}</div>
                    </div>
                </div>
            </form>
        </div>
    );

    return formElement;
};

export default LocationCoordinatesConfigPanel;
