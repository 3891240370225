import RoboadvisorService from "../services/roboadvisor.service";
const useBotTrainingItem = props => {
    let params = {
        listLabel: 'List of intents',
        newLabel: props.createEventStarted ? 'Add intent' : 'Edit Intent',
        newAddLabel: props.createEventStarted ? 'New intent:' : `Rename ${props.selectedElementValue} to:`,
        placeholder: 'Intent name',
        hint: <p className="hint">Hint: no spaces, no special symbols, Cyrillic or Latin</p>,
        disableAddlBtn:
            props.inputValue.trim() === '' ||
            /[^0-9a-zA-Zа-яА-Я_]/.test(props.inputValue.trim()) ||
            props.inputValue.includes(' '),
        infoTitle:  <h6 className="display-6 mb-2">Intent info</h6>,    
        selected: props.selectedIntent ? 
            `Created: ${RoboadvisorService.formatDateTime(props.selectedIntent.uploadedTs, RoboadvisorService.dateMomentFormat)} by ${props.selectedIntent.uploadedBy}` : "",
        updated: props.selectedIntent && props.selectedIntent.updatedTs ? 
            `Last updated: ${RoboadvisorService.formatDateTime(props.selectedIntent.updatedTs, RoboadvisorService.dateMomentFormat)} by ${props.selectedIntent.updatedBy} `: ""    
    };
    if (props.createEventStarted) {
        params.selected = "";
        params.updated = "";
        params.infoTitle = "";
    }
    if (props.type === 'question') {
        params.listLabel = 'List of sample question for the selected intent:';
        params.newLabel = 'Add sample question';
        params.newAddLabel = 'New question:';
        params.placeholder = 'Sample question text';
        params.hint = null;
        params.disableAddlBtn = props.inputValue.trim() === '';
    }
    if (props.type === 'keyWord') {
        params.listLabel = 'List of existing synonyms:';
        params.newLabel = ' Add synonym';
        params.newAddLabel = 'New synonym:';
        params.placeholder = 'Synonym text';
        params.hint = null;
        params.disableAddlBtn = props.inputValue.trim() === '';
    }
    if (props.type === 'synonym') {
        params.listLabel = 'List of examples for the selected synonym:';
        params.newLabel = 'Add examples';
        params.newAddLabel = 'New examples:';
        params.placeholder = 'Example text';
        params.hint = null;
        params.disableAddlBtn = props.inputValue.trim() === '';
    }
    return { params };
};

export default useBotTrainingItem;
