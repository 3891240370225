import { Dialog } from 'primereact/dialog';
import React from 'react';
import WelcomeMessageForm from '../welcomeMessageForm/WelcomeMessageForm';

const WelcomeMessageDialog = ({ selectedWelcomeMessage, dialogIsVisible, closeDialog, reload }) => {
    return (
        <Dialog
            visible={dialogIsVisible}
            style={{ width: '90%', maxHeight: '80%', marginTop: '100px', maxWidth: '1200px' }}
            position="top"
            header={`Welcome Message`}
            onHide={closeDialog}
        >
            <WelcomeMessageForm
                selectedWelcomeMessage={selectedWelcomeMessage}
                closeDialog={closeDialog}
                reload={reload}
            />
        </Dialog>
    );
};

export default WelcomeMessageDialog;
