import ApiRequests from '../http/ApiRequests';
import { useForm } from 'react-hook-form';
import validationService from '../services/validationService';
import { useState } from 'react';
import axiosErrorHandler from '../http/AxiosErrorHandler';
import get from 'lodash/get';

const useTextProcess = props => {
    const api = new ApiRequests();
    const { register, handleSubmit, errors } = useForm();
    const validator = validationService();
    const [generalErrorList, setGeneralErrorList] = useState([]);
    const [entities, setEntities] = useState([]);
    const [intentRanging, setIntentRanking] = useState([]);

    const intentGridHeader = [
        { name: 'Intent name', key: 'name' },
        { name: 'Confidence', key: 'confidence' },
    ];

    const entityGridHeaders = [
        { name: 'Entity', key: 'entity' },
        { name: 'Start', key: 'start' },
        { name: 'End', key: 'end' },
        { name: 'Value', key: 'value' },
    ];

    const onSubmit = formData => {
        api.sendTextToProcess(formData)
            .then(res => {
                processData(get(res, 'data', []));
            })
            .catch(err => {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            });
    };

    const processData = data => {
        if (data.entities && data.entities.length) {
            const entities = data.entities.map(e => ({
                entity: e.entity,
                start: e.start,
                end: e.end,
                value: e.value,
            }));
            setEntities(entities);
        } else {
            setEntities([]);
        }

        if (data.intent_ranking && data.intent_ranking.length) {
            const intentRanking = data.intent_ranking.map(intent => ({
                name: intent.name,
                confidence: intent.confidence,
            }));
            setIntentRanking(intentRanking);
        } else {
            setIntentRanking([]);
        }
    };

    const registerValidationFor = {
        text: () =>
            register({
                required: validator.errorMessages.MISSING_CONTENT,
            }),
    };

    const disablePanel = () => {
        setEntities([]);
        setIntentRanking([]);
    };

    return {
        onSubmit: handleSubmit(
            onSubmit,
            validator.extractErrorsFromInvalidForm(setGeneralErrorList)
        ),
        fieldErrorFor: errors,
        registerValidationFor,
        disablePanel,
        intentGridHeader,
        entityGridHeaders,
        entities,
        intentRanging,
        generalErrorList,
    };
};

export default useTextProcess;
