import { Button } from 'primereact/button';
import React from 'react';
import { get } from 'react-hook-form';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GeneralMessage from "../../components/UI/GeneralMessage";
import Grid from "../../components/UI/Grid/Grid";
import useWelcomeMessagesApproval from "../../hooks/useWelcomeMessagesApproval";
import RoboadvisorService from "../../services/roboadvisor.service";
import PlayButton from "../configResp/PlayButton";

const MessageResponseBeforeReduxConnect = ({ welcomeMessage, language, channel, appFeatures }) => {
    const canSupportTextToSpeech =
        appFeatures?.textToSpeech === true &&
        welcomeMessage.type === RoboadvisorService.responseTypes.text &&
        channel === "API";

    return (
        <div className="col-12">
            <p>
                <strong>
                    Message type:{" "}
                    {get(welcomeMessage, "type", RoboadvisorService.responseTypes.text)}
                </strong>
                {canSupportTextToSpeech ? (
                    <PlayButton
                        style={{ marginLeft: "10px" }}
                        watchText={get(welcomeMessage, "content.text", "")}
                        watchTextToSpeech={get(welcomeMessage, "content.alternativeContent", "")}
                        language={language}
                    />
                ) : null}
            </p>
            <p className="mb-0">
                <strong>Text and/or URL:</strong>
            </p>
            <p>{get(welcomeMessage, "content.text", "")}</p>
            {canSupportTextToSpeech ? (
                <>
                    <p className="mb-0">
                        <strong>Text-to-speech:</strong>
                    </p>
                    <p>
                        {get(welcomeMessage, "content.alternativeContent", "")
                            ? get(welcomeMessage, "content.alternativeContent", "")
                            : "-"}
                    </p>
                </>
            ) : null}
            <hr />
        </div>
    );
};

const mapStateToProps = state => ({
    appFeatures: state.app.features,
});

const MessageResponse = connect(
    mapStateToProps,
    null
)(withRouter(MessageResponseBeforeReduxConnect));

const ButtonsResponse = ({ welcomeMessage }) => {
    return (
        <div className="col-12">
            <p>
                <strong>
                    Message type:{' '}
                    {get(welcomeMessage, 'type', RoboadvisorService.responseTypes.text)}
                </strong>
            </p>
            {get(welcomeMessage, 'content.buttons', []).map((item, index) => {
                return (
                    <div className="container-fluid" key={index}>
                        <div className="row">
                            <div className="col-12">
                                <p className="mb-0">
                                    <strong>Button {index + 1}:</strong>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <p>Label: {get(item, 'text', '')}</p>
                            </div>
                            <div className="col-4">
                                <p>Value: {get(item, 'value', '')}</p>
                            </div>
                            <div className="col-4">
                                <p>Metadata: {get(item, 'metadata', '')}</p>
                            </div>
                        </div>
                    </div>
                );
            })}
            <hr />
        </div>
    );
};

const CarouselResponse = ({ welcomeMessage }) => {
    return (
        <div className="col-12">
            <p>
                <strong>
                    Message type:{' '}
                    {get(welcomeMessage, 'type', RoboadvisorService.responseTypes.carousel)}
                </strong>
            </p>
            {get(welcomeMessage, 'content.carousel', []).map((item, index) => {
                return (
                    <div className="container-fluid" key={index}>
                        <div className="row">
                            <div className="col-12">
                                <p className="mb-0">
                                    <strong>Slide {index + 1}:</strong>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2">
                                <p>Title: {get(item, 'title', '')}</p>
                            </div>
                            <div className="col-2">
                                <p>Subtitle: {get(item, 'subtitle', '')}</p>
                            </div>
                            <div className="col-3">
                                <p>Image resource: {get(item, 'image', '')}</p>
                            </div>
                            <div className="col-3">
                                <p>URL: {get(item, 'url', '')}</p>
                            </div>
                            <div className="col-2">
                                <p>Metadata: {get(item, 'metadata', '')}</p>
                            </div>
                        </div>
                    </div>
                );
            })}
            <hr />
        </div>
    );
};

const Responses = ({ data, language, channel }) => {
    return data.map((welcomeMessage, index) => {
        const responseType = get(welcomeMessage, 'type', RoboadvisorService.responseTypes.text);
        let formattedResponse = null;

        switch (responseType) {
            case RoboadvisorService.responseTypes.text:
                formattedResponse = (
                    <MessageResponse
                        welcomeMessage={welcomeMessage}
                        language={language}
                        channel={channel}
                    />
                );
                break;
            case RoboadvisorService.responseTypes.buttons:
                formattedResponse = <ButtonsResponse welcomeMessage={welcomeMessage} />;
                break;
            case RoboadvisorService.responseTypes.carousel:
                formattedResponse = <CarouselResponse welcomeMessage={welcomeMessage} />;
                break;
            default:
                formattedResponse = <MessageResponse />;
                break;
        }

        return (
            <div className="row" key={index}>
                {formattedResponse}
            </div>
        );
    });
};

const TaskView = ({ welcomeMessage, onApprove, onReject }) => {
    return (
        <div className="animated card p-4 fadeIn container-fluid">
            <div className="row">
                <div className="col-12">
                    <h3 className="display-6 mb-4">
                        Review panel for {get(welcomeMessage, 'responses[0].intent.name', '')}
                    </h3>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                {welcomeMessage.pageId ? (
                                    <p>
                                        Welcome Message set for page:{' '}
                                        <strong>{welcomeMessage.pageId}.</strong>
                                    </p>
                                ) : (
                                    <p>
                                        Welcome Message is set for <strong>any</strong> page.
                                    </p>
                                )}
                                {welcomeMessage.default ? (
                                    <p>
                                        Welcome Message set <strong>default</strong> (this will
                                        change default message!!)
                                    </p>
                                ) : (
                                    <p>
                                        Welcome Message is <strong>not</strong> set default.
                                    </p>
                                )}

                                <p>
                                    Channel:{' '}
                                    <strong>
                                        {welcomeMessage.channel === 'API'
                                            ? 'Web'
                                            : welcomeMessage.channel}
                                    </strong>
                                </p>
                                <p>
                                    Language: <strong>{welcomeMessage.language}</strong>
                                </p>

                                <label>Bot responses in order of appearance:</label>
                                <hr />
                                <div className="cotainer-fluid">
                                    <Responses
                                        data={get(welcomeMessage, 'responses', [])}
                                        language={welcomeMessage.language}
                                        channel={welcomeMessage.channel}
                                    />

                                    <Button
                                        type="button"
                                        className="add-btn mr-2"
                                        icon="fa fa-check"
                                        onClick={() => onApprove(get(welcomeMessage, 'id', '-1'))}
                                        label="Approve"
                                        tooltipOptions={{ position: 'top' }}
                                    />

                                    <Button
                                        type="button"
                                        className="delete-btn"
                                        icon="fa fa-ban"
                                        onClick={() => onReject(get(welcomeMessage, 'id', '-1'))}
                                        label="Reject"
                                        tooltipOptions={{ position: 'top' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const getRowExpansionTemplate = ({ data, approveTask, rejectTask }) => {
    return (
        <TaskView
            welcomeMessage={data}
            onApprove={() => approveTask(get(data, 'id', 0))}
            onReject={() => rejectTask(get(data, 'id', 0))}
        />
    );
};

export const WelcomeMessageApprove = () => {
    const {
        welcomeMessages,
        successMessage,
        generalErrorList,
        onRowExpand,
        onRowCollapse,
        selectedRow,
        tasksForApprovalHeaders,
        approveTask,
        rejectTask,
    } = useWelcomeMessagesApproval();

    return (
        <div className="container-fluid animated fadeIn">
            <div className="row">
                <div className="col-12">
                    {welcomeMessages.length ? (
                        <>
                            <GeneralMessage
                                className="c-msg"
                                successMessage={successMessage}
                                errorList={generalErrorList}
                            />
                            <h2 className="display-5 mb-4">List of approval tasks:</h2>
                            <Grid
                                rowExpansionTemplate={data =>
                                    getRowExpansionTemplate({
                                        data,
                                        approveTask,
                                        rejectTask,
                                    })
                                }
                                columns={tasksForApprovalHeaders}
                                data={welcomeMessages}
                                onRowExpand={onRowExpand}
                                onRowCollapse={onRowCollapse}
                                selectedRow={selectedRow}
                                expandDataKey="id"
                                expandTable={true}
                            />
                        </>
                    ) : (
                        <h2 className="display-5">List is empty</h2>
                    )}
                </div>
            </div>
        </div>
    );
};
