import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Control from '../../components/UI/Control/Control';
import GeneralMessage from '../../components/UI/GeneralMessage';
import HoverText from '../../components/UI/HoverText/HoverText';
import ListBox from '../../components/UI/ListBox/ListBox';
import ApiRequests from '../../http/ApiRequests';
import Grid from '../../components/UI/Grid/Grid';
import axiosErrorHandler from '../../http/AxiosErrorHandler';

const AnalyzeResponses = () => {
    const [api] = useState(new ApiRequests());
    const [successMessage, setSuccessMessage] = useState(null);
    const [generalErrorList, setGeneralErrorList] = useState([]);
    const [intentsWithoutAnswers, setIntentsWithoutAnswers] = useState([]);
    const [wrongMetadataAnswers, setWrongMetadataAnswers] = useState([]);
    const [filterTextIntents, setFilterTextIntents] = useState('');
    const { register, watch } = useForm({
        mode: 'onChange',
        defaultValues: { language: 'BG' },
    });

    const langOptions = [
        { label: 'All Languages', value: 'all' },
        { label: 'Bulgarian', value: 'BG' },
        { label: 'English', value: 'EN' },
    ];

    const loadIntentsWithoutAnswers = useCallback(
        lang =>
            api
                .getIntentsWithoutAnswers(lang)
                .then(response => {
                    if (response.data) {
                        setIntentsWithoutAnswers(
                            response.data.map(intent => ({
                                value: intent,
                                label: intent,
                            }))
                        );
                    }
                })
                .catch(error => {
                    const [errorList] = axiosErrorHandler(error);
                    setGeneralErrorList(errorList);
                }),
        [api]
    );

    const loadButtonAnswersWithWrongMetadata = useCallback(
        () =>
            api
                .getWrongMetadataButtons()
                .then(response => {
                    if (response.data) {
                        setWrongMetadataAnswers(
                            response.data.map(answer => ({
                                intentName: answer.intentName,
                                language: answer.language,
                                channel: answer.channel,
                                invalidMetadata: answer.invalidMetadata,
                            }))
                        );
                    }
                })
                .catch(error => {
                    const [errorList] = axiosErrorHandler(error);
                    setGeneralErrorList(errorList);
                }),
        [api]
    );

    const itemTemplate = useCallback(option => {
        if (option && option.label) {
            return (
                <HoverText positionX="right" content={<i class="pi pi-copy"></i>}>
                    <div className="py-1 px-2 text-break">{option.label}</div>
                </HoverText>
            );
        } else {
            return null;
        }
    }, []);

    const copyItemToClipboard = useCallback(
        e => {
            if (e.value && navigator) {
                navigator.clipboard.writeText(e.value);
                setSuccessMessage({
                    success: `Intent ${e.value} copied to clipboard.`,
                });
            }
        },
        [setSuccessMessage]
    );

    const onFilterValueChange = useCallback(
        e => setFilterTextIntents(e.value),
        [setFilterTextIntents]
    );

    const watchLanguage = watch('language');

    useEffect(() => {
        Promise.all([
            loadIntentsWithoutAnswers(watchLanguage),
            loadButtonAnswersWithWrongMetadata(),
        ]).then(setFilterTextIntents(''));
    }, [
        watchLanguage,
        loadIntentsWithoutAnswers,
        setFilterTextIntents,
        loadButtonAnswersWithWrongMetadata,
    ]);

    const gridHeaders = [
        { name: 'Intent', key: 'intentName' },
        { name: 'Channel', key: 'channel' },
        { name: 'Language', key: 'language' },
        { name: 'Incorrect Metadata Format', key: 'invalidMetadata' },
    ];

    return (
        <>
            <GeneralMessage
                className="c-msg"
                successMessage={successMessage}
                errorList={generalErrorList}
            />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-auto">
                        <div className="d-flex flex-column">
                            <h3 className="mb-4">Button Responses with incorrect intent: </h3>
                            <Grid columns={gridHeaders} data={wrongMetadataAnswers} />
                        </div>
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="d-flex flex-column mt-5">
                            <h3 className="mb-2">Intents Without Responses: </h3>
                            <div className="d-flex justify-content-start mt-2">
                                <Control
                                    name="language"
                                    registerRef={register}
                                    type="select"
                                    label="Language"
                                    value={''}
                                    options={langOptions}
                                    info="Display intents without responses in a particular language."
                                />
                            </div>
                            <ListBox
                                filter
                                filterBy="label"
                                filterValue={filterTextIntents}
                                style={{ overflow: 'auto' }}
                                listStyle={{ height: '40vh' }}
                                options={intentsWithoutAnswers}
                                onChange={copyItemToClipboard}
                                onFilterValueChange={onFilterValueChange}
                                itemTemplate={itemTemplate}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AnalyzeResponses;
