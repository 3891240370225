import * as React from 'react';
import { VER_NUM } from '../../config/version';
import './Footer.css';
import dayjs from 'dayjs';

const Footer = props => {
    return (
        <footer className={`app-footer ${props.sidebarVisible ? '' : 'expand'}`}>
            <div className={`shrinkText ${props.sidebarVisible ? 'shrinkTextSidebarVisible' : ''}`}>
                <a href='https://www.sportdepot.bg/' target='blank'>
                    Sport Depot&nbsp;
                </a>
                <span>&copy; ${dayjs().year()} Melinda.</span>&nbsp;
            </div>
            <div className={`shrinkText ${props.sidebarVisible ? 'shrinkTextSidebarVisible' : ''}`}>
                {VER_NUM.length === 0 ? null : <span className='ver_num'>Ver. # {VER_NUM}</span>}
            </div>
        </footer>
    );
};

export default Footer;
