import React, { useEffect, useRef } from 'react';
import ChatFeed from '../../components/UI/ChatFeed/ChatFeed';
import MessagePosition from '../../components/UI/ChatFeed/messagePosition';

const SuperviseLiveChatWindow = ({ conversationID, messages, overwriteChatInput, sessionId }) => {
    const chatInputRef = useRef();
    const chatMessages =
        Array.isArray(messages) && messages?.length
            ? messages
                  .filter(msg => msg.type === 'TEXT')
                  .map(msg => {
                      let messagePosition = MessagePosition.CENTER;
                      let senderName = '';
                      if (msg.userMessage === false && !msg.nickname) {
                          senderName = 'System';
                      } else if (msg.customerId) {
                          messagePosition = MessagePosition.LEFT;
                          senderName = `Client ${
                              sessionId.length < 8 ? sessionId : sessionId.substring(0, 7) + '...'
                          }`;
                          // operator or bot message
                      } else if (msg.sentByUser || (!msg.customerId && !msg.sentByUser)) {
                          messagePosition = MessagePosition.RIGHT;
                          if (!msg.customerId && !msg.sentByUser && !msg.nickname) {
                              senderName = 'chatbot';
                          } else {
                              senderName = msg.nickname
                                  ? msg.nickname
                                  : msg.sentByUser ?? 'Operator';
                          }
                      }

                      return { ...msg, messagePosition, senderName };
                  })
            : [];

    useEffect(() => {
        if (chatInputRef?.current && conversationID) {
            chatInputRef.current.value = '';
        }
    }, [chatInputRef, conversationID]);

    useEffect(() => {
        if (overwriteChatInput && chatInputRef.current) {
            chatInputRef.current.value = overwriteChatInput;
        }
    }, [overwriteChatInput]);

    return (
        <ChatFeed
            messages={chatMessages}
            chatFeedContainerStyle={{ height: '100%', padding: '5px 10px' }}
        />
    );
};

export default SuperviseLiveChatWindow;
