import get from 'lodash/get';
import { connect } from 'react-redux';
import { setUserGroup, toggleAddNewUserGroupSidebar } from '../../store/actions/userGroupsActions';

const AllGroups = ({ allGrops, userGroup, setUserGroup }) => {
    return (
        <ul className="permissions-list">
            {allGrops.map((item, index) => (
                <li
                    key={index}
                    className={get(userGroup, 'id', -1) === get(item, 'id', -1) ? 'active' : ''}
                >
                    <button onClick={() => setUserGroup(item)}>{get(item, 'name', '')}</button>
                </li>
            ))}
        </ul>
    );
};

const mapDispatchToProps = {
    setUserGroup,
    toggleSidebar: toggleAddNewUserGroupSidebar,
};

const mapStateToProps = state => {
    return {
        allGrops: state.userGroups.allUserGroups,
        userGroup: state.userGroups.userGroup,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllGroups);
