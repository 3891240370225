import get from "lodash/get";
import ApiRequests from "../../http/ApiRequests";

//Action Types
export const SET_APP_FEATURES = "SET_APP_FEATURES";

export const setAppFeatures = payload => ({
    type: SET_APP_FEATURES,
    payload,
});

export const getAppFeatures = () => {
    const api = new ApiRequests();

    return dispatch => {
        api.getAppFeatures()
            .then(res => {
                dispatch(setAppFeatures(get(res, "data")));
            })
            .catch(err => {
                // const [errorList] = axiosErrorHandler(err);
                // dispatch(setErrorMessage(errorList));
                // TODO error not handled here!!!
            });
    };
};
