import { Button } from 'primereact/button';
import Control from '../../components/UI/Control/Control';
import GeneralMessage from '../../components/UI/GeneralMessage';

const LiveChatTemplateForm = ({ hook }) => {
    const {
        generalErrorList,
        successMessage,
        onSubmit,
        control,
        name,
        onNameChange,
        description,
        onDescriptionChange,
        content,
        onContentChange,
    } = hook;

    const formElement = (
        <div className="animated fadeIn">
            <GeneralMessage
                className="c-msg"
                successMessage={successMessage}
                errorList={generalErrorList}
            />
            <form noValidate id="profileInfoForm">
                <div className="container-fluid card p-4 mb-5 animated fadeIn">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="display-5">Add live chat template</h2>
                            <hr />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <Control
                                name="templateName"
                                type="text"
                                control={control}
                                label="Template name"
                                info="The name of the live chat template"
                                value={name}
                                onChange={e => {
                                    onNameChange(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <Control
                                name="templateDescription"
                                type="text"
                                control={control}
                                label="Template description"
                                info="The description of the live chat template"
                                value={description}
                                onChange={e => {
                                    onDescriptionChange(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <Control
                                name="templateContent"
                                type="textarea"
                                control={control}
                                label="Template content"
                                info="The content of the live chat template"
                                value={content}
                                onChange={e => {
                                    onContentChange(e.target.value);
                                }}
                                style={{ minHeight: 'unset' }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            <Button
                                style={{ marginTop: '8px' }}
                                type="button"
                                className="add-btn"
                                icon="fa fa-plus"
                                tooltip={'Add phrase'}
                                label="Add"
                                tooltipOptions={{ position: 'top' }}
                                onClick={() => onSubmit()}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );

    return formElement;
};

export default LiveChatTemplateForm;
