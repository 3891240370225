import { useCallback } from 'react';
import BotTrainingItem from '../../components/BotTrainingItem/BotTrainingItem';
import PageRootNav from '../../components/PageRootNav/PageRootNav';
import GeneralMessage from '../../components/UI/GeneralMessage';
import useAddSynonyms from '../../hooks/useAddSynonyms';
import get from 'lodash/get';

const AddSynonyms = () => {
    const {
        keywordList,
        synonymList,
        selectedKeywordId,
        selectedSynonymId,
        inputKeywordName,
        inputSynonymContent,
        addKeywordSectionVisible,
        addSynonymSectionVisible,
        addKeywordSectionRef,
        addSynonymSectionRef,
        generalErrorList,
        successMessage,
        changeSelectedKeywordHandler,
        changeSelectedSynonymHandler,
        changeNewKeywordNameHandler,
        changeNewSynonymContentHandler,
        addKeywordHandler,
        addSynonymHandler,
        delKeywordHandler,
        delSynonymHandler,
        displayHideAddSectionHandler,
    } = useAddSynonyms();

    const getOptionClassName = option => {
        const status = get(option, 'status', '');
        return `${status.toLowerCase()} ${option.selected ? 'selected' : ''}`;
    };

    const itemTemplate = useCallback(option => {
        if (option && option.label) {
            return (
                <div
                    id="pppp"
                    className={getOptionClassName(option)}
                    style={{
                        padding: '0.3rem 0.3rem',
                        wordWrap: 'break-word',
                    }}
                >
                    {option.label}
                </div>
            );
        } else {
            return null;
        }
    }, []);
    return (
        <>
            <PageRootNav label="Add synonyms" />
            <GeneralMessage
                className="c-msg"
                successMessage={successMessage}
                errorList={generalErrorList}
            />
            <div className="container-fluid">
                <BotTrainingItem
                    type="keyWord"
                    listItems={keywordList}
                    changeSelection={changeSelectedKeywordHandler}
                    addItem={addKeywordHandler}
                    deleteItem={delKeywordHandler}
                    inputValue={inputKeywordName}
                    changeInputValue={changeNewKeywordNameHandler}
                    disabledDelBtn={!selectedKeywordId}
                    visible={addKeywordSectionVisible}
                    toggleVisibility={displayHideAddSectionHandler}
                    addSectionRef={addKeywordSectionRef}
                    createEventStarted={true}
                    itemTemplate={itemTemplate}
                />
                <BotTrainingItem
                    type="synonym"
                    listItems={synonymList}
                    changeSelection={changeSelectedSynonymHandler}
                    addItem={addSynonymHandler}
                    deleteItem={delSynonymHandler}
                    inputValue={inputSynonymContent}
                    changeInputValue={changeNewSynonymContentHandler}
                    disabledButton={!selectedSynonymId}
                    disabledAddButton={!selectedKeywordId}
                    visible={addSynonymSectionVisible}
                    toggleVisibility={displayHideAddSectionHandler}
                    addSectionRef={addSynonymSectionRef}
                    disableAddInput={!selectedKeywordId}
                    createEventStarted={true}
                    itemTemplate={itemTemplate}
                />
            </div>
        </>
    );
};

export default AddSynonyms;
