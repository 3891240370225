import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { ListBox } from 'primereact/listbox';
import React from 'react';
import useBotTrainingItem from '../../hooks/useBotTrainingItem';
import TextSearchInput from '../UI/Control/TextSearchInput';
import './BotTrainingItem.css';

const BotTrainingItem = props => {
    const { params } = useBotTrainingItem(props);

    const showIntentSpecificElements = () => {
        if (props.disableItem && props.editItem) {
            return (
                <>
                    <Button
                        type="button"
                        className="mr-2 edit-btn"
                        onClick={() => props.toggleVisibility(props.type, false)}
                        disabled={props.disabledButton}
                        icon={
                            props.visible && props.editEventStarted ? 'fa fa-minus' : 'fa fa-pencil'
                        }
                        tooltip={props.visible && props.editEventStarted ? 'Remove' : 'Edit'}
                        tooltipOptions={{ position: 'top' }}
                    />
                    <InputSwitch
                        disabled={props.disabledButton}
                        checked={props.checked}
                        onChange={() => props.disableItem()}
                    />
                </>
            );
        }
        return null;
    };

    return (
        <>
            <div className="row">
                <div className="col-5 d-flex justify-content-center align-items-center botTrainItem-list-container">
                    <div className="form-control-block w-100">
                        {props.search ? (
                            <TextSearchInput
                                loading={props.searchLoading}
                                searchCallback={props.search}
                                placeholder={props.searchPlaceholder}
                            />
                        ) : null}
                        <label className="mt-2">{params.listLabel}</label>
                        <ListBox
                            style={{ overflow: 'auto' }}
                            listStyle={{ height: '40vh', padding: '0.3rem' }}
                            listClassName="intent-list"
                            options={props.listItems}
                            onChange={event => props.changeSelection(event.target.value)}
                            itemTemplate={props.itemTemplate}
                        />
                    </div>
                </div>
                <div
                    className={`col-2 d-flex flex-column ${
                        props.showMultipleButton
                            ? 'justify-content-center'
                            : 'justify-content-start'
                    }`}
                    style={{ minHeight: '100%' }}
                >
                    {props.showMultipleButton ? (
                        <Button
                            className="mb-2"
                            label="Enable/disable multiple intents"
                            onClick={props.hook ? props.hook.openDialog : null}
                        />
                    ) : null}
                    <div
                        className={`d-flex justify-content-center ${
                            props.showMultipleButton ? 'align-items-center' : 'align-items-top'
                        }`}
                        style={{ marginTop: props.showMultipleButton ? '20px' : '80px' }}
                    >
                        <Button
                            type="button"
                            className="info-btn mr-2"
                            onClick={() => props.toggleVisibility(props.type, true)}
                            disabled={props.disabledAddButton ? props.disabledAddButton : false}
                            icon={
                                props.visible && props.createEventStarted
                                    ? 'fa fa-minus'
                                    : 'fa fa-plus'
                            }
                            tooltip={props.visible && props.createEventStarted ? 'Remove' : 'Add'}
                            tooltipOptions={{ position: 'top' }}
                        />
                        <Button
                            type="button"
                            className="mr-2 delete-btn"
                            onClick={() => props.deleteItem()}
                            disabled={props.disabledButton}
                            icon="fa fa-trash-o"
                            tooltip={'Delete'}
                            tooltipOptions={{ position: 'top' }}
                        />
                        {showIntentSpecificElements()}
                    </div>
                </div>

                <div
                    className="col-5 animated fadeIn card p-4 new-botTrainItem__invisible"
                    ref={props.addSectionRef}
                >
                    <h6 className="display-6 mb-2">{params.newLabel}</h6>
                    <div className="form-control-block">
                        <label>{params.newAddLabel}</label>
                        <input
                            ref={props.editInputRef}
                            className="form-control mb-2"
                            type="text"
                            onChange={event => {
                                props.changeInputValue(event.target.value);
                            }}
                            onKeyPress={e => {
                                if (e.key === 'Enter') {
                                    const action = props.createEventStarted
                                        ? props.addItem
                                        : props.editItem;
                                    action();
                                }
                            }}
                            value={props.inputValue ? props.inputValue : ''}
                            placeholder={params.placeholder}
                            disabled={props.disableAddInput}
                        ></input>
                        <Button
                            type="button"
                            className="add-btn"
                            onClick={
                                props.createEventStarted
                                    ? () => props.addItem()
                                    : () => props.editItem()
                            }
                            disabled={params.disableAddlBtn}
                            icon={props.createEventStarted ? 'fa fa-plus' : 'fa fa-check'}
                            label={props.createEventStarted ? 'Add' : 'Update'}
                            tooltipOptions={{ position: 'top' }}
                        />
                    </div>
                    {params.hint}
                    <div className="form-control-block-info-content mt-4">
                        {params.infoTitle}
                        {params.selected}
                        <br />
                        {params.updated}
                    </div>
                </div>
            </div>
        </>
    );
};

export default BotTrainingItem;
