import { Button } from 'primereact/button';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import ApiRequests from '../http/ApiRequests';
import axiosErrorHandler from '../http/AxiosErrorHandler';
import { canUser } from '../permissions/permissions';

const useProfileConfig = ({ userPermissions }) => {
    const api = useMemo(() => new ApiRequests(), []);
    const { control } = useForm({});
    const [generalErrorList, setGeneralErrorList] = useState([]);
    const [successMessage, setSuccessMessage] = useState(null);
    const [nickname, setNickname] = useState('');
    const [avatar, setAvatar] = useState('');
    const [userId, setUserId] = useState(null);
    const [reviewGridData, setReviewGridData] = useState([]);
    const reviewGridHeaders = [
        { name: 'Avatar URL', key: 'avatar', headerClassName: 'reviewgrid-responses' },
        { name: 'Nickname', key: 'nickname', headerClassName: 'reviewgrid-confidence' },
        { name: 'Updated By', key: 'updatedBy', headerClassName: 'reviewgrid-forwarding' },
        { name: 'Updated At', key: 'updatedTs', headerClassName: 'reviewgrid-forwarding' },
        { name: 'Action', key: 'action', headerClassName: 'reviewgrid-actions' },
    ];

    const [historyGridData, setHistoryGridData] = useState([]);
    const historyGridHeaders = [
        { name: 'Avatar URL', key: 'avatar', headerClassName: 'historygrid-responses' },
        { name: 'Nickname', key: 'nickname', headerClassName: 'historygrid-confidence' },
        { name: 'Updated By', key: 'updatedBy', headerClassName: 'reviewgrid-forwarding' },
        { name: 'Updated At', key: 'updatedTs', headerClassName: 'reviewgrid-forwarding' },
        { name: 'Username', key: 'username', headerClassName: 'reviewgrid-forwarding' },
    ];
    const profileConfigurationStateRef = useRef();
    const reviewDataStateRef = useRef();
    reviewDataStateRef.current = reviewGridData;
    const historyDataStateRef = useRef();
    historyDataStateRef.current = historyGridData;

    const onApproveConfig = useCallback(
        async id => {
            api.approveUserUpdate(id)
                .then(res => {
                    setSuccessMessage({ success: 'Successfully approved pending configuration' });
                    setReviewGridData(reviewDataStateRef.current.filter(i => i.id !== id));
                })
                .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setGeneralErrorList(errorList);
                });
        },
        [api]
    );

    const onRejectConfig = useCallback(
        async id => {
            api.rejectUserUpdate(id)
                .then(() => {
                    setSuccessMessage({ success: 'Successfully rejected pending configuration' });
                    setReviewGridData(reviewDataStateRef.current.filter(i => i.id !== id));
                })
                .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setGeneralErrorList(errorList);
                });
        },
        [api]
    );

    const ApprovalPanelButtonsPair = useCallback(
        id => {
            return (
                <div className="container-fluid">
                    <div className="row">
                        <Button
                            type="button"
                            className="add-btn mr-2"
                            icon="fa fa-check"
                            onClick={() => onApproveConfig(id)}
                            label="Approve"
                            tooltipOptions={{ position: 'top' }}
                        />
                        <Button
                            type="button"
                            className="delete-btn mr-2"
                            icon="fa fa-times"
                            onClick={() => onRejectConfig(id)}
                            label="Reject"
                            tooltipOptions={{ position: 'top' }}
                        />
                    </div>
                </div>
            );
        },
        [onApproveConfig, onRejectConfig]
    );

    useEffect(() => {
        async function loadCurrentProfile() {
            try {
                const res = await api.getCurrentUser();
                profileConfigurationStateRef.current = res.data;
                setNickname(res.data.nickname);
                setAvatar(res.data.avatar);
                setUserId(res.data.id);
            } catch (err) {
                setNickname('');
                setAvatar('');
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            }
        }
        loadCurrentProfile();
    }, [api]);

    useEffect(() => {
        async function loadApprovalPanelContent() {
            try {
                const res = await api.getPendingUserChanges();
                let gridD = res.data.map(entry => ({
                    nickname: entry.nickname,
                    avatar: entry.avatar,
                    updatedBy: entry.updatedBy,
                    updatedTs: entry.updatedTs,
                    action: ApprovalPanelButtonsPair(entry.id),
                    id: entry.id,
                }));
                setReviewGridData(gridD);
            } catch (err) {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            }
        }
        if (canUser.approveProfileChanges(userPermissions)) {
            loadApprovalPanelContent();
        }
    }, [ApprovalPanelButtonsPair, api, userPermissions]);

    useEffect(() => {
        async function loadHistoryPanelContent() {
            try {
                const res = await api.getPendingUserChanges();
                let gridD = res.data.map(entry => ({
                    nickname: entry.nickname,
                    avatar: entry.avatar,
                    updatedBy: entry.updatedBy,
                    updatedTs: entry.updatedTs,
                    action: ApprovalPanelButtonsPair(entry.id),
                    id: entry.id,
                }));
                setHistoryGridData(gridD);
            } catch (err) {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            }
        }
        if (canUser.approveProfileChanges(userPermissions)) {
            loadHistoryPanelContent();
        }
    }, [ApprovalPanelButtonsPair, api, userPermissions]);

    const onSubmit = async () => {
        api.updateUserInfo(userId, {
            avatar: avatar,
            nickname: nickname,
        })
            .then(res => {
                setSuccessMessage({ success: 'Successfully sent profile changes for review' });
                setNickname(profileConfigurationStateRef.current.nickname);
                setAvatar(profileConfigurationStateRef.current.avatar);
                let reviewEntry = {
                    nickname: res.data.nickname,
                    avatar: res.data.avatar,
                    username: res.data.username,
                    timestamp: res.data.timestamp,
                    updatedBy: res.data.updatedBy,
                    updatedTs: res.data.updatedTs,
                    action: ApprovalPanelButtonsPair(res.data.id),
                    id: res.data.id,
                };
                const updatedReviewData = [...reviewGridData, reviewEntry];
                setReviewGridData(updatedReviewData);
            })
            .catch(err => {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            });
    };

    const isUpdateButtonDisabled = () => {
        if (profileConfigurationStateRef.current) {
            return (
                profileConfigurationStateRef.current.nickname === nickname &&
                profileConfigurationStateRef.current.avatar === avatar
            );
        }
    };

    const onNicknameChanged = userNickname => {
        setNickname(userNickname);
    };

    const onAvatarChanged = userAvatar => {
        setAvatar(userAvatar);
    };

    return {
        generalErrorList,
        successMessage,
        onSubmit,
        control,
        nickname,
        onNicknameChanged,
        avatar,
        onAvatarChanged,
        isUpdateButtonDisabled,
        reviewGridHeaders,
        reviewGridData,
        historyGridHeaders,
        historyGridData,
    };
};

export default useProfileConfig;
