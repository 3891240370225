import get from 'lodash/get';
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import React, { useCallback, useRef } from "react";
import { Controller, useFieldArray, useWatch } from "react-hook-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Control from "../../components/UI/Control/Control";
import FormControl from "../../components/UI/Control/FormControl";
import Label from "../../components/UI/Control/Label";
import FormButtonWrapper from "../../hoc/FormButtonWrapper";
import RoboadvisorService from "../../services/roboadvisor.service";
import PlayButton from "./PlayButton";

const BotResponse = ({
    index,
    registerValidationFor,
    response,
    control,
    errors,
    removeResponse,
    namePrefix,
    data,
    searchIntent,
    intentList,
    appFeatures,
    setValue,
    getValues,
    clearErrors,
}) => {
    const messageTypeOptions = [
        { label: "Text", value: "TEXT" },
        { label: "Buttons", value: "BUTTONS" },
        { label: "Carousel", value: "CAROUSEL" },
    ];

    const textToSpeechRef = useRef(null);

    const onRemoveResponse = useCallback(() => {
        removeResponse(index);
    }, [removeResponse, index]);

    const responseTypeWatch = useWatch({
        control,
        name: `${namePrefix}.type`,
        defaultValue:
            data && data.answers[index]?.type
                ? data.answers[index].type
                : RoboadvisorService.responseTypes.text,
    });

    const watchText = useWatch({
        control,
        name: `${namePrefix}.content`,
        defaultValue: data.answers[index]?.content,
    });

    const watchTextToSpeech = useWatch({
        control,
        name: `${namePrefix}.alternativeContent`,
        defaultValue: data.answers[index]?.alternativeContent,
    });
    const canSupportTextToSpeech =
        appFeatures?.textToSpeech === true &&
        responseTypeWatch === RoboadvisorService.responseTypes.text &&
        data.channel === "API";

    const {
        fields: buttonFields,
        append: appendButtonField,
        remove: removeButtonField,
    } = useFieldArray({
        control,
        name: `${namePrefix}.buttons`,
    });

    const {
        fields: carouselFields,
        append: appendCarouselField,
        remove: removeCarouselField,
    } = useFieldArray({
        control,
        name: `${namePrefix}.carousel`,
    });

    const wrapTextWithTagOnClick = (leftTag, rightTag) => {
        return e => {
            e.preventDefault();
            if (!textToSpeechRef.current) {
                return;
            }

            const start = textToSpeechRef.current.selectionStart;
            const end = textToSpeechRef.current.selectionEnd;

            if (start === undefined) {
                return;
            }
            if (end === undefined) {
                return;
            }

            // Get the current value from the form's input instead of the form's state
            // in order to avoid misalign with the cursor values.
            const content = textToSpeechRef.current.value;

            const changedContent = `${content.substring(0, start)}${leftTag}${content.substring(
                start,
                end
            )}${rightTag}${content.substring(end)}`;

            setValue(`${namePrefix}.alternativeContent`, changedContent);
            textToSpeechRef.current.selectionStart = start + leftTag.length;
            textToSpeechRef.current.selectionEnd = end + leftTag.length;
            textToSpeechRef.current.focus();
        };
    };

    return (
        <div className="mb-4 mt-2 container-fluid">
            <div className="row">
                <div className="col-11 d-flex align-items-end">
                    <h4 className="mb-0">Response {index + 1} configuration:</h4>
                    {canSupportTextToSpeech ? (
                        <PlayButton
                            style={{ marginLeft: "10px" }}
                            watchText={watchText}
                            watchTextToSpeech={watchTextToSpeech}
                            language={data.language}
                        />
                    ) : null}
                </div>
                <div className="col-1">
                    <Button
                        label="Delete response"
                        className="delete-btn"
                        style={{ marginLeft: "-128px", minWidth: "150px" }}
                        icon="fa fa-trash-o"
                        onClick={onRemoveResponse}
                    />
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-4">
                    <Control
                        error={get(errors, `${namePrefix}.type.message`, "")}
                        registerRef={registerValidationFor?.type}
                        name={`${namePrefix}.type`}
                        type="select"
                        label="Message Type"
                        value={response?.type}
                        options={messageTypeOptions}
                    />
                </div>
            </div>
            {responseTypeWatch === RoboadvisorService.responseTypes.text && (
                <>
                    <div className="row">
                        <div className="col-8">
                            <Control
                                style={{ minHeight: "85px" }}
                                error={get(errors, `${namePrefix}.content.message`, "")}
                                registerRef={registerValidationFor?.text}
                                name={`${namePrefix}.content`}
                                type="textarea"
                                label="Message Text"
                                placeholder="Enter a text and/or URL."
                                value={response?.content}
                            />
                        </div>
                    </div>
                    {canSupportTextToSpeech ? (
                        <>
                            <div className="row">
                                <div className="col-8">
                                    <Control
                                        ref={textToSpeechRef}
                                        style={{ minHeight: "85px" }}
                                        error={get(
                                            errors,
                                            `${namePrefix}.alternativeContent.message`,
                                            ""
                                        )}
                                        registerRef={registerValidationFor?.textToSpeech}
                                        name={`${namePrefix}.alternativeContent`}
                                        type="textarea"
                                        label="Text-to-speech"
                                        placeholder="Enter a text."
                                        value={response?.alternativeContent}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-8">
                                    <Button
                                        className="btn-info mr-2 mb-2"
                                        onClick={wrapTextWithTagOnClick(
                                            "<emphasis>",
                                            "</emphasis>"
                                        )}
                                        label="<emphasis>"
                                    />
                                    <Button
                                        className="btn-info mr-2 mb-2"
                                        onClick={wrapTextWithTagOnClick(
                                            '<say-as interpret-as="currency, telephone, verbatim, date, characters, time">',
                                            "</say-as>"
                                        )}
                                        label="<say-as>"
                                    />
                                    <Button
                                        className="btn-info mr-2 mb-2"
                                        onClick={wrapTextWithTagOnClick(
                                            '<break strength="x-weak, weak, medium, strong, x-strong"/>',
                                            ""
                                        )}
                                        label="<break>"
                                    />
                                    <Button
                                        className="btn-info mr-2 mb-2"
                                        onClick={e => {
                                            e.preventDefault();
                                            const text = getValues(
                                                `${namePrefix}.alternativeContent`
                                            );
                                            setValue(
                                                `${namePrefix}.alternativeContent`,
                                                `<speak>${text
                                                    .replaceAll("<speak>", "")
                                                    .replaceAll("</speak>", "")}</speak>`
                                            );
                                            clearErrors(`${namePrefix}.alternativeContent`);
                                        }}
                                        label="Use SSML"
                                    />
                                </div>
                            </div>
                        </>
                    ) : null}
                </>
            )}
            {responseTypeWatch === RoboadvisorService.responseTypes.buttons && (
                <>
                    <div className="row">
                        <div className="col-12">
                            {Array.isArray(buttonFields) && buttonFields.length
                                ? buttonFields.map((field, index) => (
                                      <div className="row" key={field.id}>
                                          <div className="col-3">
                                              <Control
                                                  error={get(
                                                      errors,
                                                      `${namePrefix}.buttons[${index}].text.message`,
                                                      ""
                                                  )}
                                                  registerRef={registerValidationFor?.text}
                                                  name={`${namePrefix}.buttons[${index}].text`}
                                                  type="text"
                                                  label="Label *"
                                                  value={field?.text}
                                                  placeholder="Button label (text)"
                                              />
                                          </div>
                                          <div className="col-4">
                                              <Control
                                                  error={get(
                                                      errors,
                                                      `${namePrefix}.buttons[${index}].value.message`,
                                                      ""
                                                  )}
                                                  registerRef={registerValidationFor?.value}
                                                  name={`${namePrefix}.buttons[${index}].value`}
                                                  type="text"
                                                  label="Value"
                                                  value={field?.value}
                                                  placeholder="Button value (text)"
                                              />
                                          </div>
                                          <div className="col-4">
                                              <Controller
                                                  defaultValue={field?.metadata}
                                                  control={control}
                                                  name={`${namePrefix}.buttons[${index}].metadata`}
                                                  rules={registerValidationFor?.buttonMetadata()}
                                                  render={({ onChange, onBlur, value, name }) => {
                                                      const error = get(
                                                          errors,
                                                          `${namePrefix}.buttons[${index}].metadata.message`,
                                                          ""
                                                      );
                                                      return (
                                                          <FormControl>
                                                              <Label value="Metadata *">
                                                                  <span
                                                                      data-pr-tooltip="Only active intents. No spaces. Should end with #EN or #BG according to the language"
                                                                      className="label-info ml-2"
                                                                  >
                                                                      <i
                                                                          className="fa fa-info"
                                                                          aria-hidden="true"
                                                                      ></i>
                                                                  </span>
                                                                  <Tooltip target={`.label-info`} />
                                                                  <AutoComplete
                                                                      className="d-block"
                                                                      inputClassName={`form-control ${
                                                                          error
                                                                              ? " invalid-input"
                                                                              : ""
                                                                      }`}
                                                                      placeholder="/intent_name#BG"
                                                                      value={value}
                                                                      suggestions={intentList}
                                                                      field="name"
                                                                      completeMethod={searchIntent}
                                                                      onChange={e => {
                                                                          const newValue = e.value;
                                                                          onChange(
                                                                              typeof newValue ===
                                                                                  "object"
                                                                                  ? newValue.name
                                                                                  : newValue
                                                                          );
                                                                      }}
                                                                  />
                                                                  {error ? (
                                                                      <p className="error-message">
                                                                          {error}
                                                                      </p>
                                                                  ) : null}
                                                              </Label>
                                                          </FormControl>
                                                      );
                                                  }}
                                              />
                                          </div>
                                          <div className="col-1">
                                              <FormButtonWrapper>
                                                  <Button
                                                      className="delete-btn d-block"
                                                      onClick={() => {
                                                          removeButtonField(index);
                                                      }}
                                                      icon="fa fa-trash-o"
                                                  />
                                              </FormButtonWrapper>
                                          </div>
                                      </div>
                                  ))
                                : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-11"></div>
                        <div className="col-1">
                            <Button
                                className="add-btn"
                                style={{ marginLeft: "-112px", minWidth: "150px" }}
                                onClick={e => {
                                    e.preventDefault();
                                    appendButtonField({ metadata: "", text: "", value: "" });
                                }}
                                icon="fa fa-plus-circle"
                                label="Add button"
                            />
                        </div>
                    </div>
                </>
            )}
            {responseTypeWatch === RoboadvisorService.responseTypes.carousel && (
                <>
                    <div className="row">
                        <div className="col-12">
                            {Array.isArray(carouselFields) && carouselFields.length
                                ? carouselFields.map((field, index) => (
                                      <div className="row mb-2" key={field.id}>
                                          <div className="col-2">
                                              <Control
                                                  error={get(
                                                      errors,
                                                      `${namePrefix}.carousel[${index}].title.message`,
                                                      ""
                                                  )}
                                                  registerRef={registerValidationFor?.title}
                                                  name={`${namePrefix}.carousel[${index}].title`}
                                                  type="text"
                                                  label="Title *"
                                                  value={field?.title}
                                                  placeholder="Title text"
                                              />
                                          </div>
                                          <div className="col-2">
                                              <Control
                                                  error={get(
                                                      errors,
                                                      `${namePrefix}.carousel[${index}].subtitle.message`,
                                                      ""
                                                  )}
                                                  registerRef={registerValidationFor?.subtitle}
                                                  name={`${namePrefix}.carousel[${index}].subtitle`}
                                                  type="text"
                                                  label="Subtitle"
                                                  value={field?.subtitle}
                                                  placeholder="Subtitle text"
                                              />
                                          </div>
                                          <div className="col-3">
                                              <Control
                                                  error={get(
                                                      errors,
                                                      `${namePrefix}.carousel[${index}].image.message`,
                                                      ""
                                                  )}
                                                  registerRef={registerValidationFor?.image}
                                                  name={`${namePrefix}.carousel[${index}].image`}
                                                  type="text"
                                                  label="Image resource *"
                                                  info="The image has to be either a JPG or PNG file, 237x165 or equal"
                                                  value={field?.image}
                                                  placeholder="External URL"
                                              />
                                          </div>
                                          <div className="col-2">
                                              <Control
                                                  error={get(
                                                      errors,
                                                      `${namePrefix}.carousel[${index}].url.message`,
                                                      ""
                                                  )}
                                                  registerRef={registerValidationFor?.url}
                                                  name={`${namePrefix}.carousel[${index}].url`}
                                                  type="text"
                                                  label="URL *"
                                                  value={field?.url}
                                                  placeholder="URL address"
                                              />
                                          </div>
                                          <div className="col-2">
                                              <Control
                                                  error={get(
                                                      errors,
                                                      `${namePrefix}.carousel[${index}].metadata.message`,
                                                      ""
                                                  )}
                                                  registerRef={registerValidationFor?.metadata}
                                                  name={`${namePrefix}.carousel[${index}].metadata`}
                                                  type="text"
                                                  label="Metadata"
                                                  value={field?.metadata}
                                                  placeholder="Metadata (text)"
                                              />
                                          </div>
                                          <div className="col-1">
                                              <FormButtonWrapper>
                                                  <Button
                                                      className="delete-btn"
                                                      onClick={e => {
                                                          removeCarouselField(index);
                                                      }}
                                                      icon="fa fa-trash-o"
                                                      tooltipOptions={{ position: "top" }}
                                                  />
                                              </FormButtonWrapper>
                                          </div>
                                      </div>
                                  ))
                                : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-11"></div>
                        <div className="col-1">
                            <Button
                                className="add-btn"
                                style={{ marginLeft: "-112px", minWidth: "150px" }}
                                onClick={e => {
                                    e.preventDefault();
                                    appendCarouselField({
                                        image: "",
                                        metadata: "",
                                        subtitle: "",
                                        title: "",
                                        url: "",
                                    });
                                }}
                                icon="fa fa-plus-circle"
                                label="Add slide"
                            />
                        </div>
                    </div>
                </>
            )}
            <hr />
        </div>
    );
};

const mapStateToProps = state => ({
    appFeatures: state.app.features,
});

export default connect(mapStateToProps, null)(withRouter(BotResponse));
