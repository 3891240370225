import { useCallback, useRef, useState } from 'react';
import ApiRequests from '../http/ApiRequests';
import axiosErrorHandler from '../http/AxiosErrorHandler';

const useImportExport = () => {
    const [api] = useState(new ApiRequests());
    const uploadInputRef = useRef();

    const [generalErrorList, setGeneralErrorList] = useState([]);
    const [successMessage, setSuccessMessage] = useState(null);
    const [uploadSpinner, setUploadSpinner] = useState(false);
    const [showExamples, setShowExamples] = useState(false);
    const [selectFileErrorMessage, setSelectFileErrorMessage] = useState('');
    const onUpload = useCallback(
        event => {
            const formData = new FormData();
            event.files.forEach(file => {
                formData.append('files', file);
            });
            setUploadSpinner(true);
            api.uploadDataFiles(formData)
                .then(() => setSuccessMessage({ success: 'Successfully imported data.' }))
                .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setGeneralErrorList(errorList);
                })
                .finally(() => {
                    setUploadSpinner(false);
                    setSelectFileErrorMessage('');
                });
        },
        [api]
    );

    const onSelect = useCallback(e => {
        const regexYml = /\.yml$/;
        const regexJson = /\.json$/;
        const selectedFileList = e.files;
        let foundFilesWithWrongTypes = false;
        for (let index = 0; index < selectedFileList.length; index++) {
            const file = selectedFileList[index];
            if (
                typeof file.name === 'string' &&
                !(file.name.match(regexYml) || file.name.match(regexJson))
            ) {
                foundFilesWithWrongTypes = true;
                break;
            }
        }
        if (foundFilesWithWrongTypes) {
            setSelectFileErrorMessage('Only .yml and .json files are supported.');
            if (uploadInputRef.current && uploadInputRef.current.clear) {
                uploadInputRef.current.clear();
            }
        } else {
            setSelectFileErrorMessage('');
        }
    }, []);

    const toggleShowExamples = useCallback(() => {
        setShowExamples(!showExamples);
    }, [showExamples]);

    const downloadFile = useCallback(response => {
        const filename = response.headers['content-disposition'].split('=')[1];
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }, []);

    const downloadNlu = useCallback(() => {
        api.downloadNluData()
            .then(response => downloadFile(response))
            .catch(err => {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            });
    }, [api, downloadFile]);

    const downloadResponses = useCallback(() => {
        api.downloadResponseData()
            .then(response => {
                response.data = JSON.stringify(response.data);
                downloadFile(response);
            })
            .catch(err => {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            });
    }, [api, downloadFile]);

    const downloadModel = useCallback(() => {
        api.downloadModelData()
            .then(response => downloadFile(response))
            .catch(err => {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            });
    }, [api, downloadFile]);

    return {
        successMessage,
        generalErrorList,
        uploadSpinner,
        onUpload,
        downloadNlu,
        downloadResponses,
        downloadModel,
        showExamples,
        toggleShowExamples,
        onSelect,
        uploadInputRef,
        selectFileErrorMessage,
    };
};

export default useImportExport;
