import { COLORS } from '../../constants/constants';
import ApiRequests from '../../http/ApiRequests';
import axiosErrorHandler from '../../http/AxiosErrorHandler';
import { sortByDateCompareFunction } from '../../utils/sortUtils';
import { convertObjectArrayToObject } from '../../utils/dataTypeConversionUtils';

const refreshActiveChatsInterval = 30000; // 30s
const superviseChatMessagesPollingInterval = 5000; // 5 sec

export const SET_SUPERVISE_CHAT_GENERAL_ERROR_LIST = 'SET_SUPERVISE_CHAT_GENERAL_ERROR_LIST';
export const SET_SUPERVISE_CHAT_WS_CLIENT = 'SET_SUPERVISE_CHAT_WS_CLIENT';
export const SET_SUPERVISE_CHAT_CONVERSATIONS = 'SET_SUPERVISE_CHAT_CONVERSATIONS';
export const SET_OPERATOR_LIST = 'SET_OPERATOR_LIST';
export const INCREMENT_SUPERVISE_CHAT_PROFILE_COLOR_COUNTER =
    'INCREMENT_SUPERVISE_CHAT_PROFILE_COLOR_COUNTER';
export const SET_SUPERVISE_CHAT_INCOMMING_MESSAGE = 'SET_SUPERVISE_CHAT_INCOMMING_MESSAGE';
export const SET_SUPERVISE_CHAT_SELECTED_CONVERSATION_ID =
    'SET_SUPERVISE_CHAT_SELECTED_CONVERSATION_ID';
export const SET_REFRESH_ACTIVE_CHATS_INTERVAL_ID = 'SET_REFRESH_ACTIVE_CHATS_INTERVAL_ID';
export const SET_SUPERVISE_CHAT_MESSAGE_POLLING_INTERVAL_ID =
    'SET_SUPERVISE_CHAT_MESSAGE_POLLING_INTERVAL_ID';
export const SET_SUPERVISE_CHAT_LAST_POLLING_STAMP = 'SET_SUPERVISE_CHAT_LAST_POLLING_STAMP';

export const setGeneralErrorList = payload => ({
    type: SET_SUPERVISE_CHAT_GENERAL_ERROR_LIST,
    payload,
});

export const setSuperviseChatWsClient = payload => ({
    type: SET_SUPERVISE_CHAT_WS_CLIENT,
    payload,
});

export const setConversations = payload => ({
    type: SET_SUPERVISE_CHAT_CONVERSATIONS,
    payload,
});

export const setOperatorList = payload => ({
    type: SET_OPERATOR_LIST,
    payload,
});

export const incrementProfileColorCounter = payload => ({
    type: INCREMENT_SUPERVISE_CHAT_PROFILE_COLOR_COUNTER,
    payload,
});

export const setIncommingMessage = payload => ({
    type: SET_SUPERVISE_CHAT_INCOMMING_MESSAGE,
    payload,
});

export const setSelectedConversationId = payload => ({
    type: SET_SUPERVISE_CHAT_SELECTED_CONVERSATION_ID,
    payload,
});

export const setRefreshActiveChatsIntervalId = payload => ({
    type: SET_REFRESH_ACTIVE_CHATS_INTERVAL_ID,
    payload,
});

export const setSuperviseChatMessagePollingIntervalId = payload => ({
    type: SET_SUPERVISE_CHAT_MESSAGE_POLLING_INTERVAL_ID,
    payload,
});

export const setSuperviseChatLastPollingTimestamp = payload => ({
    type: SET_SUPERVISE_CHAT_LAST_POLLING_STAMP,
    payload,
});

export const initSuperviseLiveChat = () => {
    const api = new ApiRequests();
    return dispatch => {
        Promise.all([
            api.searchForUsers(),
            api.getConversations({ accepted: true, finished: false }),
        ])
            .then(([usersResponse, conversationsResponse]) => {
                if (Array.isArray(usersResponse.data) && usersResponse.data.length) {
                    const operatorList = usersResponse.data;
                    dispatch(setOperatorList(operatorList));
                    dispatch(initRefreshActiveChatsInterval());
                    dispatch(initRefreshChatMessagesInterval());
                    if (
                        Array.isArray(conversationsResponse.data?.conversations) &&
                        conversationsResponse.data?.conversations.length
                    ) {
                        const conversationsFromBackend = conversationsResponse.data.conversations;
                        dispatch(updateConversations(conversationsFromBackend, operatorList));
                    }
                }
            })
            .catch(error => {
                const [errorList] = axiosErrorHandler(error);
                dispatch(setGeneralErrorList(errorList));
            });
    };
};

export const disableSuperviseLiveChat = () => {
    return dispatch => {
        dispatch(setSuperviseChatWsClient(null));
        dispatch(setConversations([]));
        dispatch(clearRefreshActiveChatsInterval());
        dispatch(clearRefreshChatMessagesInterval());
    };
};

export const updateConversations = (conversationsFromBackend, operatorList) => {
    const api = new ApiRequests();
    return (dispatch, getState) => {
        const state = getState();
        // remove the finished conversations
        const conversations = [...state.superviseLiveChat.conversations].filter(conv =>
            conversationsFromBackend.some(convBackend => convBackend.id === conv.data.id)
        );
        let profileColorCounter = state.superviseLiveChat.profileColorCounter;

        conversationsFromBackend.forEach(convBackend => {
            let currentConversation = conversations.find(conv => conv.data.id === convBackend.id);
            if (!currentConversation) {
                const newConversation = {
                    data: convBackend,
                    unreadMessagesCount: convBackend.totalMessagesCount,
                    profileColor: COLORS[profileColorCounter % 10],
                    operator: operatorList.find(
                        operator => operator.username === convBackend.acceptedBy
                    ),
                    isSupervised: false,
                    timestampToMessageMap: null,
                };
                profileColorCounter++;
                dispatch(incrementProfileColorCounter());
                conversations.push(newConversation);
            } else if (currentConversation.operator.username !== convBackend.acceptedBy) {
                currentConversation.operator = operatorList.find(
                    operator => operator.username === convBackend.acceptedBy
                );
            }
        });

        // set conversations here and after getting messages because
        // if conversations is [] there will be no messages
        dispatch(setConversations(conversations));
        const conversationsWithoutMessages = conversations.filter(conv => !conv.messages);
        if (conversationsWithoutMessages.length) {
            const promisesList = conversationsWithoutMessages.map(conv => {
                return api.getConversationMessages(conv.data.id);
            });
            Promise.all(promisesList)
                .then(responseList => {
                    responseList.forEach((response, index) => {
                        const conversation = conversations.find(
                            conv => conv.data.id === conversationsWithoutMessages[index].data.id
                        );
                        if (conversation) {
                            conversation.messages = [...response.data];
                            conversation.timestampToMessageMap = convertObjectArrayToObject(
                                response.data,
                                'messageTs'
                            );
                        }
                    });
                    dispatch(setConversations(conversations));
                })
                .catch(error => {
                    const [errorList] = axiosErrorHandler(error);
                    dispatch(setGeneralErrorList(errorList));
                });
        }
    };
};

export const initRefreshActiveChatsInterval = () => {
    return (dispatch, getState) => {
        const state = getState();
        const existingIntervalId = state.superviseLiveChat.refreshActiveChatsIntervalId;
        if (existingIntervalId === -1) {
            const intervalId = setInterval(
                () => dispatch(refreshActiveChats()),
                refreshActiveChatsInterval
            );
            dispatch(setRefreshActiveChatsIntervalId(intervalId));
        }
    };
};

export const clearRefreshActiveChatsInterval = () => {
    return (dispatch, getState) => {
        const state = getState();
        const existingIntervalId = state.superviseLiveChat.refreshActiveChatsIntervalId;
        clearInterval(existingIntervalId);
        dispatch(setRefreshActiveChatsIntervalId(-1));
    };
};

export const refreshActiveChats = () => {
    const api = new ApiRequests();
    return (dispatch, getState) => {
        const state = getState();
        const operatorList = state.superviseLiveChat.operatorList;
        api.getConversations({ accepted: true, finished: false })
            .then(conversations => {
                if (Array.isArray(conversations.data?.conversations)) {
                    const conversationsFromBackend = conversations.data.conversations;
                    dispatch(updateConversations(conversationsFromBackend, operatorList));
                }
            })
            .catch(error => {
                const [errorList] = axiosErrorHandler(error);
                dispatch(setGeneralErrorList(errorList));
            });
    };
};

export const initRefreshChatMessagesInterval = () => {
    return (dispatch, getState) => {
        const state = getState();
        const existingIntervalId = state.superviseLiveChat.superviseChatMessagePollingIntervalId;
        if (existingIntervalId === -1) {
            const intervalId = setInterval(
                () => dispatch(refreshChatMessages()),
                superviseChatMessagesPollingInterval
            );
            dispatch(setSuperviseChatMessagePollingIntervalId(intervalId));
        }
    };
};

export const clearRefreshChatMessagesInterval = () => {
    return (dispatch, getState) => {
        const state = getState();
        const existingIntervalId = state.superviseLiveChat.superviseChatMessagePollingIntervalId;
        clearInterval(existingIntervalId);
        dispatch(setSuperviseChatMessagePollingIntervalId(-1));
    };
};

export const refreshChatMessages = () => {
    const api = new ApiRequests();
    return (dispatch, getState) => {
        const storeState = getState();
        if (storeState) {
            const conversations = storeState.superviseLiveChat.conversations;
            const lastPollingTimestamp = storeState.superviseLiveChat.lastPollingTimestamp;

            api.getLiveChatMessages({
                sessionIds: conversations.map(conv => conv.data.session),
                timestamp: lastPollingTimestamp,
            })
                .then(response => {
                    if (response.data) {
                        if (response.data.timestamp) {
                            dispatch(setSuperviseChatLastPollingTimestamp(response.data.timestamp));
                        }
                        if (
                            response.data.conversations &&
                            Object.keys(response.data.conversations).length
                        ) {
                            const newMessagesGroupedByConversation = response.data.conversations;
                            const selectedConversationId =
                                storeState.superviseLiveChat.selectedConversationId;

                            conversations.forEach(conv => {
                                const newMessages =
                                    newMessagesGroupedByConversation[conv.data.id]?.messages;
                                if (Array.isArray(newMessages) && newMessages.length) {
                                    conv.messages.push(
                                        ...newMessages
                                            .filter(m => !conv.timestampToMessageMap[m.messageTs])
                                            .map(message => ({
                                                ...message,
                                                content: message.message,
                                                customerId: message.userMessage
                                                    ? conv.data.session
                                                    : '',
                                                chatId: conv.data.id,
                                                type: 'TEXT',
                                                messageTs: message.messageTs,
                                            }))
                                    );
                                    conv.timestampToMessageMap = convertObjectArrayToObject(
                                        newMessages,
                                        'messageTs',
                                        conv.timestampToMessageMap
                                    );
                                    if (conv.data.id !== selectedConversationId) {
                                        conv.unreadMessagesCount += newMessages.length;
                                    }
                                    conv.messages.sort(
                                        sortByDateCompareFunction('messageTs', false)
                                    );
                                }
                            });
                            dispatch(setConversations([...conversations]));
                        }
                    }
                })
                .catch(error => {
                    const [errorList] = axiosErrorHandler(error);
                    dispatch(setGeneralErrorList(errorList));
                });
        }
    };
};
