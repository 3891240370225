import ApiRequests from '../http/ApiRequests';
import { useState, useEffect, useRef, useMemo } from 'react';
import useConfirmDialog from '../hooks/useConfirmDialog';
import axiosErrorHandler from '../http/AxiosErrorHandler';

const useAddSynonyms = props => {
    const api = useMemo(() => new ApiRequests(), []);
    const [keywordList, setKeywordList] = useState([]);
    const [synonymList, setSynonymList] = useState([]);
    const [inputKeywordName, setInputKeywordName] = useState('');
    const [inputSynonymContent, setinputSynonymContent] = useState('');
    const [selectedKeywordId, setSelectedKeywordId] = useState('');
    const [selectedSynonymId, setSelectedSynonymId] = useState('');

    const [addKeywordSectionVisible, toggleaddKeywordSectionVisible] = useState(false);
    const [addSynonymSectionVisible, toggleaddSynonymSectionVisible] = useState(false);

    const [generalErrorList, setGeneralErrorList] = useState([]),
        [successMessage, setSuccessMessage] = useState(null);

    const addKeywordSectionRef = useRef();
    const addSynonymSectionRef = useRef();

    const confirm = useConfirmDialog();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await api.getKeywordList();
                const keywords_list = res.data.map(kw => ({
                    value: kw.id,
                    label: kw.name,
                    status: kw.status,
                }));
                setKeywordList(keywords_list);
            } catch (err) {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            }
        };

        fetchData();
    }, [api]);

    const changeSelectedKeywordHandler = async id => {
        try {
            setSelectedKeywordId(id);
            setSelectedSynonymId('');
            const res = await api.getKeywordSynonymList(id);
            const intentSynonymList = res.data.map(e => ({
                value: e.id,
                label: e.content,
                status: e.status,
            }));
            setSynonymList(intentSynonymList);
            setKeywordList(
                keywordList.map(keyword => ({
                    ...keyword,
                    selected: keyword.value === id ? true : false,
                }))
            );
        } catch (err) {
            const [errorList] = axiosErrorHandler(err);
            setGeneralErrorList(errorList);
        }
    };

    const displayHideAddSectionHandler = botItemType => {
        if (botItemType === 'keyWord') {
            toggleaddKeywordSectionVisible(!addKeywordSectionVisible);
            addKeywordSectionRef.current.classList.toggle('new-botTrainItem__invisible');
        } else {
            toggleaddSynonymSectionVisible(!addSynonymSectionVisible);
            addSynonymSectionRef.current.classList.toggle('new-botTrainItem__invisible');
        }
    };

    const changeSelectedSynonymHandler = id => {
        setSelectedSynonymId(id);
        setSynonymList(
            synonymList.map(synonym => ({
                ...synonym,
                selected: synonym.value === id ? true : false,
            }))
        );
    };

    const changeNewKeywordNameHandler = newKeywordNameValue => {
        setInputKeywordName(newKeywordNameValue);
    };

    const changeNewSynonymContentHandler = newSynonymContentValue => {
        setinputSynonymContent(newSynonymContentValue);
    };

    const addKeywordHandler = async () => {
        const acceptFunc = async () => {
            const data = { name: inputKeywordName };
            try {
                const res = await api.addKeyword(data);
                const newKeywordList = [
                    { value: res.data.id, label: res.data.name, status: 'NEW' },
                    ...keywordList,
                ];
                setKeywordList(newKeywordList);
                setInputKeywordName('');
                setSuccessMessage({ success: 'Keyword successfully added.' });
            } catch (err) {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            }
        };
        const conformProps = {
            message: 'Are you sure you want to add new keyword?',
            header: 'Add new keyword',
            icon: 'pi pi-plus-circle',
            accept: () => acceptFunc(),
            reject: () => setInputKeywordName(''),
        };
        confirm(conformProps);
    };

    const delKeywordHandler = async () => {
        if (!selectedKeywordId) return;
        const acceptFunc = async () => {
            try {
                await api.delKeyword(selectedKeywordId);
                changeListItemStatus(keywordList, parseInt(selectedKeywordId), 'DELETED');
                setSelectedKeywordId('');
                setSuccessMessage({ success: 'Keyword successfully deleted.' });
            } catch (err) {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            }
        };
        const conformProps = {
            message: 'Are you sure you want to delete selected keyword?',
            header: 'Delete keyword',
            icon: 'fa fa-trash-o',
            accept: () => acceptFunc(),
        };
        confirm(conformProps);
    };

    const addSynonymHandler = async () => {
        const acceptFunc = async () => {
            const data = {
                content: inputSynonymContent,
                synonym: {
                    id: parseInt(selectedKeywordId),
                },
            };
            try {
                const res = await api.addKeywordSynonym(data);
                const newsynonymList = [
                    { value: res.data.id, label: res.data.content, status: 'NEW' },
                    ...synonymList,
                ];
                setSynonymList(newsynonymList);
                setinputSynonymContent('');
                setSuccessMessage({ success: 'Synonym successfully added.' });
            } catch (err) {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            }
        };
        const conformProps = {
            message: 'Are you sure you want to add new synonym  to selected keyword?',
            header: 'Add new sample question',
            icon: 'pi pi-plus-circle',
            accept: () => acceptFunc(),
            reject: () => setinputSynonymContent(''),
        };
        confirm(conformProps);
    };

    const delSynonymHandler = async () => {
        if (!selectedSynonymId) return;
        const acceptFunc = async () => {
            try {
                await api.delSynonym(selectedSynonymId);
                changeListItemStatus(synonymList, parseInt(selectedSynonymId), 'DELETED');
                setinputSynonymContent('');
                setSuccessMessage({ success: 'Example successfully deleted.' });
            } catch (err) {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            }
        };
        const conformProps = {
            message: 'Are you sure you want to delete selected example?',
            header: 'Delete example',
            icon: 'fa fa-trash-o',
            accept: () => acceptFunc(),
        };
        confirm(conformProps);
    };

    const changeListItemStatus = (itemsList, selectedId, status) => {
        const updatedItem = itemsList.find(i => i.value === selectedId);
        if (updatedItem) {
            updatedItem.status = status;
        }
    };

    return {
        keywordList,
        synonymList,
        selectedKeywordId,
        selectedSynonymId,
        inputKeywordName,
        inputSynonymContent,
        addKeywordSectionVisible,
        addSynonymSectionVisible,
        addKeywordSectionRef,
        addSynonymSectionRef,
        generalErrorList,
        successMessage,
        changeSelectedKeywordHandler,
        changeSelectedSynonymHandler,
        changeNewKeywordNameHandler,
        changeNewSynonymContentHandler,
        addKeywordHandler,
        addSynonymHandler,
        delKeywordHandler,
        delSynonymHandler,
        displayHideAddSectionHandler,
    };
};

export default useAddSynonyms;
