import * as moment from 'moment';

export default class RoboadvisorService {
    // Default time formats
    static datepickerFormat = 'yy-mm-dd'; // Use for datepicker only
    static dateMomentFormat = 'DD.MM.YYYY';
    static dateFormatAmCharts = 'MM/dd/YY';
    static timeMomentFormatSeconds = 'hh:mm:ss a';
    static timeMomentFormatMinutes = 'hh:mm a';
    static dateTimeMomentFormatMinutes = 'YYYY-MM-DD HH:mm';
    static dateTimeMomentFormatSeconds = 'YYYY-MM-DD HH:mm:ss ';
    static nameValidationPattern = /^([a-zA-Z0-9])[a-zA-Z0-9 ',. -]+[a-zA-Z0-9]$/;

    static responseTypes = {
        text: 'TEXT',
        carousel: 'CAROUSEL',
        buttons: 'BUTTONS',
    };
    static entityTypes = [
        'INTENT',
        'INTENT_ANSWER',
        'INTENT_EXAMPLE',
        'SYNONYM',
        'SYNONYM_EXAMPLE',
        'CONFIGURATION',
        'TARIFF',
        'USER_GROUP',
        'GROUP_PERMISSION',
        'PREDEFINED_MESSAGE',
        'TOWN_COORDINATES',
        'WELCOME_MESSAGE',
        'WELCOME_MESSAGE_ANSWER',
        'NLU_MODEL',
    ];
    static deepCopy(aObject) {
        if (!aObject) {
            return aObject;
        }

        let v;
        let bObject = Array.isArray(aObject) ? [] : {};
        for (const k in aObject) {
            v = aObject[k];
            bObject[k] = typeof v === 'object' ? this.deepCopy(v) : v;
        }

        return bObject;
    }

    static checkFieldValidity = (value, rules, eventType) => {
        let isValid = true;

        if (
            value != null &&
            typeof value !== 'undefined' &&
            !(value instanceof File || value instanceof Date || Array.isArray(value))
        ) {
            if (rules.required && (eventType === 'onChange' || eventType === 'onBlur')) {
                isValid = value.toString().trim() !== '' && isValid;
            }

            if (rules.pattern && value.trim() !== '' && eventType === 'onBlur') {
                isValid = rules.pattern.test(value) && isValid;
            }
        } else if (value != null && typeof value !== 'undefined' && value instanceof Date) {
            if (rules.futureDateValidation) {
                isValid = RoboadvisorService.checkForFutureDate(value);
            }
        } else if (value != null && typeof value !== 'undefined' && Array.isArray(value)) {
            if (rules.required) {
                isValid = value.length > 0 && isValid;
            }
        } else if (!value && rules.required) {
            isValid = false;
        }

        return isValid;
    };

    static checkFormValidity = formFields => {
        let formIsValid = true;
        const dateIsValid = true;

        for (const inputIdentifier in formFields) {
            if (formFields[inputIdentifier].valid !== undefined) {
                formIsValid = RoboadvisorService.checkFormValidityFields(
                    formFields[inputIdentifier],
                    dateIsValid,
                    formIsValid
                );
            } else if (Array.isArray(formFields[inputIdentifier])) {
                for (const fieldset of formFields[inputIdentifier]) {
                    if (fieldset) {
                        for (const item of fieldset) {
                            if (item.valid !== undefined) {
                                formIsValid = RoboadvisorService.checkFormValidityFields(
                                    item,
                                    dateIsValid,
                                    formIsValid
                                );
                            }
                        }
                    }
                }
            }
        }

        return formIsValid;
    };

    static checkFormValidityFields(field, dateIsValid, formIsValid) {
        let validByPattern = true;
        const dateCheck = field.value instanceof Date;

        if (!dateCheck && field.validation.pattern && field.value.toString().trim() !== '') {
            // Testing the value of the input if it has a regex pattern
            validByPattern = field.validation.pattern.test(field.value);
        } else if (dateCheck) {
            if (field.validation.futureDateValidation) {
                dateIsValid = RoboadvisorService.checkForFutureDate(field.value);
            }
        }
        return field.valid && formIsValid && validByPattern && dateIsValid;
    }

    static checkForFutureDate(dateTime) {
        const currentTime = moment();
        const datePickerTime = moment(dateTime);
        return !moment(datePickerTime).isAfter(currentTime);
    }

    static formatDateTime(dateTime, formatTemplate) {
        return dateTime ? moment(dateTime).local().format(formatTemplate) : '-';
    }
}
