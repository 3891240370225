import * as constants from '../constants/constants';

export const getWeekDayString = weekDay =>
    constants.WEEK_DAYS[weekDay] ? constants.WEEK_DAYS[weekDay] : '';

export const getWeekdaysForSelectControl = () =>
    Object.keys(constants.WEEK_DAYS).map(key => ({ value: key, label: constants.WEEK_DAYS[key] }));

// "18:19:02" expected format
export const parseTime = timeString => {
    // Creates a Date Object using the clients current time
    let d = new Date();

    if (typeof timeString === 'string') {
        let [hours, minutes, seconds] = timeString.split(':');

        d.setHours(+hours); // Set the hours, using implicit type coercion
        d.setMinutes(minutes); // You can pass Number or String. It doesn't really matter
        d.setSeconds(seconds);
        // If needed, adjust date and time zone
        return d;
    } else {
        return null;
    }
};

export const toLocalTime = dateObject => {
    if (dateObject && typeof dateObject.getMonth === 'function') {
        // all the units are padded to match the format XX
        const hours = ('0' + dateObject.getHours()).slice(-2);
        const minutes = ('0' + dateObject.getMinutes()).slice(-2);
        const seconds = ('0' + dateObject.getSeconds()).slice(-2);
        return `${hours}:${minutes}:${seconds}`;
    } else {
        return '';
    }
};

export const secondsToTimeSpan = secondsInput => {
    const fullTimePeriodInSeconds = parseInt(secondsInput);
    let hours = 0;
    let minutes = 0;
    let seconds = 0;
    let milliseconds = 0;

    hours = parseInt(fullTimePeriodInSeconds / 3600);
    const hoursString = `${hours <= 0 ? '' : hours === 1 ? hours + ' hour' : hours + ' hours'}`;
    const minutesSeconds = fullTimePeriodInSeconds - hours * 3600;

    minutes = parseInt(minutesSeconds / 60);
    const minutesString = `${
        minutes <= 0 ? '' : minutes === 1 ? minutes + ' minute' : minutes + ' minutes'
    }`;

    const secondsOnly = minutesSeconds - minutes * 60;

    seconds = secondsOnly;
    const secondsString = `${
        seconds <= 0 ? '' : seconds === 1 ? seconds + ' second' : seconds + ' seconds'
    }`;

    const doubleNumberParts = secondsInput.toFixed(3).split('.');
    if (
        doubleNumberParts.length === 2 &&
        doubleNumberParts[0] === '0' &&
        doubleNumberParts[1] !== '000'
    ) {
        milliseconds = parseInt(doubleNumberParts[1]);
    }

    const formattedTime = `${hoursString ? hoursString + ' ' : ''}${
        minutesString ? minutesString + ' ' : ''
    }${secondsString}`;
    return {
        hours,
        hoursUnit: hours === 1 ? 'hour' : 'hours',
        minutes,
        minutesUnit: minutes === 1 ? 'minute' : 'minutes',
        seconds,
        secondsUnit: seconds === 1 ? 'second' : 'seconds',
        milliseconds,
        millisecondsUnit: milliseconds === 1 ? 'millisecond' : 'milliseconds',
        formattedTime,
    };
};
