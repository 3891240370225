import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Control from '../../components/UI/Control/Control';
import GeneralMessage from '../../components/UI/GeneralMessage';

const ConversRepExportDialog = ({ hook }) => {
    const {
        registerValidationFor,
        onSubmit,
        onExport,
        generalErrorList,
        fieldErrorFor,
        channelOptions,
        orderCriteriaOptions,
        orderDirectionOptions,
        defaultOrderCriteria,
        defaultOrderDirection,
        stillDownload,
        showConfirmExportDialog,
        closeConfirmExportDialog,
        getValues,
    } = hook;
    const {
        setValue,
        control,
        getValues: getV,
        reset,
        register,
    } = useForm({ shouldUnregister: false });

    useEffect(() => {
        if (showConfirmExportDialog) {
            const formValues = getValues();

            if (formValues.dateFrom) {
                setValue('dateFrom', formValues.dateFrom);
            }
            if (formValues.dateTo) {
                setValue('dateTo', formValues.dateTo);
            }
            if (formValues.sessionID) {
                setValue('sessionID', formValues.sessionID);
            }
            setValue('channel', channelOptions.find(o => o.value === formValues.channel)?.label);
            if (formValues.orderCriteria) {
                setValue('orderCriteria', formValues.orderCriteria);
            }
            if (formValues.orderDirection) {
                setValue('orderDirection', formValues.orderDirection);
            }
        } else {
            reset();
        }
    }, [showConfirmExportDialog]);
    return (
        <Dialog
            visible={showConfirmExportDialog}
            onHide={closeConfirmExportDialog}
            header="Export Conversation Report"
            footer={
                <div className="d-flex flex-row justify-content-start">
                    <Button
                        type="button"
                        className="add-btn"
                        tooltip={'Export to excel'}
                        label="Export"
                        tooltipOptions={{ position: 'top' }}
                        onClick={onExport}
                    />
                    <Button
                        type="button"
                        className="info-btn"
                        label="Cancel"
                        onClick={closeConfirmExportDialog}
                    />
                </div>
            }
        >
            <form className="container-fluid " noValidate>
                <p>
                    <b>Following filters will be applied to the conversation report: </b>
                </p>
                <GeneralMessage errorList={generalErrorList} />
                <div className="row">
                    <div className="col-3">
                        <Control
                            error={fieldErrorFor.dateFrom?.message}
                            registerRef={registerValidationFor?.dateFrom}
                            control={control}
                            name="dateFrom"
                            type="datepicker"
                            label="From date"
                            value={getV('dateFrom')}
                            showTime
                            disabled
                        />
                    </div>
                    <div className="col-3">
                        <Control
                            error={fieldErrorFor.dateTo?.message}
                            registerRef={registerValidationFor?.dateTo}
                            control={control}
                            name="dateTo"
                            type="datepicker"
                            label="To date"
                            value=""
                            showTime
                            disabled
                        />
                    </div>
                    <div className="col-2">
                        <Control
                            error={fieldErrorFor.sessionID?.message}
                            registerRef={register}
                            control={control}
                            name="sessionID"
                            type="text"
                            label="Session ID"
                            disabled
                        />
                    </div>
                    <div className="col-2">
                        <Control
                            error={fieldErrorFor.channel?.message}
                            registerRef={register}
                            control={control}
                            name="channel"
                            type="text"
                            label="Channel"
                            disabled
                        />
                    </div>
                    <div className="col-2 d-flex align-items-center justify-content-end"></div>
                </div>
                <div className="row">
                    <div className="col-3">
                        <Control
                            error={fieldErrorFor.orderCriteria?.message}
                            registerRef={register}
                            control={control}
                            name="orderCriteria"
                            type="select"
                            label="Sort Criteria"
                            options={orderCriteriaOptions}
                            disabled
                        />
                    </div>
                    <div className="col-3">
                        <Control
                            error={fieldErrorFor.orderDirection?.message}
                            registerRef={register}
                            control={control}
                            name="orderDirection"
                            type="select"
                            label="Direction"
                            options={orderDirectionOptions}
                            disabled
                        />
                    </div>
                    <div className="col-2 offset-4 d-flex align-items-center justify-content-end"></div>
                </div>
            </form>
        </Dialog>
    );
};

export default ConversRepExportDialog;
