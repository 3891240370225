import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { canUser } from '../permissions/permissions';
import { routes } from '../routes/Routes';

const useHeader = ({ awaitingClients, assignedClients, initLiveChat, currentUser, initSuperviseLiveChat }) => {
    const [buttonEnabled, setButtonEnabled] = useState(false);
    const history = useHistory();
    const [canUseLiveChat, setCanUseLiveChat] = useState(false);
    const onClientsButtonClick = useCallback(() => {
        history.push(routes.liveChat.path);
        setButtonEnabled(false);
    }, [setButtonEnabled, history]);

    useEffect(() => {
        if ((Array.isArray(awaitingClients) && awaitingClients.length) ||
            (Array.isArray(assignedClients) && assignedClients.length)) {
            setButtonEnabled(true);
        } else {
            setButtonEnabled(false);
        }
        return () => {
            setButtonEnabled(false);
        };
    }, [awaitingClients, assignedClients]);

    useEffect(() => {
        if (initLiveChat && currentUser) {
            setCanUseLiveChat(canUser.useLiveChat(currentUser.permissions));
            if (canUser.useLiveChat(currentUser.permissions)) {
                initLiveChat();
            }
            if (canUser.superviseLiveChat(currentUser.permissions)) {
                initSuperviseLiveChat();
            }
        }
    }, [initLiveChat, currentUser, initSuperviseLiveChat]);

    return {
        buttonEnabled,
        onClientsButtonClick,
        canUseLiveChat,
    };
};

export default useHeader;
