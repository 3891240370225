import React, { useCallback, useEffect, useRef } from 'react';

const BlinkingIcon = ({ props }) => {
    const notificationIconRef = useRef();
    const blinkFuntion = useCallback(
        () =>
            setInterval(() => {
                if (notificationIconRef.current) {
                    if (notificationIconRef.current.style.color === 'red') {
                        notificationIconRef.current.style.color = 'white';
                    } else {
                        notificationIconRef.current.style.color = 'red';
                    }
                }
            }, 3000),
        [notificationIconRef]
    );

    useEffect(() => {
        const intervalId = blinkFuntion();
        return () => clearInterval(intervalId);
    }, [blinkFuntion]);

    return (
        <i
            ref={notificationIconRef}
            className="pi pi-exclamation-circle"
            style={{
                fontSize: '1.2rem',
                color: 'red',
                fontWeight: 600,
                transition: 'color 3s ease-out',
            }}
            {...props}
        />
    );
};

export default BlinkingIcon;
