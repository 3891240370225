import 'font-awesome/css/font-awesome.min.css';
import * as React from "react";
import { connect } from "react-redux";
import { Route, Switch, useLocation, withRouter } from "react-router-dom";
import "./App.css";
import AppLayout from "./layouts/appLayout";
import { allRoutes } from "./routes/Routes";
import { getAppFeatures } from "./store/actions/appActions";
import { getCurrentUser } from "./store/actions/authActions";
// import NotFound                     from './views/NotFound/NotFound';

const App = ({ getCurrentUser, currentUser, getAppFeatures }) => {
    const location = useLocation();

    React.useEffect(() => {
        if (location.pathname !== "/login") {
            getCurrentUser();
        }
    }, [location, getCurrentUser]);

    React.useEffect(() => {
        // get app features on app load in case the user is logged in
        getAppFeatures();
    }, []);

    return (
        <React.Fragment>
            <Switch>
                {currentUser ? (
                    <Route path={allRoutes.base.path} component={AppLayout} />
                ) : (
                    <>
                        {/* key causes component rerender for different routes that are using one component */}
                        <Route
                            path={allRoutes.login.path}
                            component={allRoutes.login.component}
                            key={allRoutes.login.key}
                            exact={true}
                        />
                    </>
                )}

                {/* <Route path={allRoutes.notFound.path} component={NotFound} /> */}
            </Switch>
        </React.Fragment>
    );
};

const mapDispatchToProps = { getCurrentUser, getAppFeatures };

const mapStateToProps = state => ({
    currentUser: state.auth.currentUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
