import { useCallback, useEffect, useState } from 'react';
import ApiRequests from '../http/ApiRequests';
import axiosErrorHandler from '../http/AxiosErrorHandler';

const useDisableIntentDialog = ({
    setGeneralErrorList, // if error window is in a parent component
    setSuccessMessage, // if success message comes from outside
}) => {
    const [api] = useState(new ApiRequests());
    const [intentList, setIntentList] = useState([]);
    const [enabledIntentList, setEnabledIntentList] = useState([]);
    const [disabledIntentList, setDisabledIntentList] = useState([]);
    const [selectedEnabledIntentList, setSelectedEnabledIntentList] = useState([]);
    const [selectedDisabledIntentList, setSelectedDisabledIntentList] = useState([]);
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const closeDialog = useCallback(() => setDialogIsOpen(false), [setDialogIsOpen]);

    const clearSelectedEnabledIntentList = useCallback(
        () => setSelectedEnabledIntentList([]),
        [setSelectedEnabledIntentList]
    );

    const clearSelectedDisaledIntentList = useCallback(
        () => setSelectedDisabledIntentList([]),
        [setSelectedDisabledIntentList]
    );

    const loadAllIntents = useCallback(() => {
        api.getIntentList()
            .then(response => {
                if (response.data) {
                    setIntentList(response.data);
                }
            })
            .catch(error => {
                const [errorList] = axiosErrorHandler(error);
                setGeneralErrorList(errorList);
            });
    }, [api, setGeneralErrorList]);

    const openDialog = useCallback(() => {
        loadAllIntents();
        setDialogIsOpen(true);
    }, [setDialogIsOpen, loadAllIntents]);

    const onSelectedEnabledIntent = useCallback(e => {
        setSelectedEnabledIntentList(e.value.filter(intent => intent.status === 'APPROVED'));
    }, []);
    const onSelectedDisabledIntent = useCallback(e => {
        setSelectedDisabledIntentList(e.value.filter(intent => intent.status === 'APPROVED'));
    }, []);

    const changeIntentListStatus = useCallback(
        async (intentList, enableIntent) => {
            try {
                const apiCalls = intentList.map(intent =>
                    api.updateIntentEnableStatus(intent.id, enableIntent)
                );
                await Promise.all(apiCalls);
                const successMessage = `${apiCalls.length} ${
                    apiCalls.length === 1 ? 'intent' : 'intents'
                } successfully ${
                    enableIntent ? 'enabled' : 'disabled'
                }. Change is awaiting for review.`;
                loadAllIntents();
                setSuccessMessage({ success: successMessage });
            } catch (err) {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            } finally {
                clearSelectedEnabledIntentList();
                clearSelectedDisaledIntentList();
            }
        },
        [
            api,
            setGeneralErrorList,
            setSuccessMessage,
            clearSelectedDisaledIntentList,
            clearSelectedEnabledIntentList,
            loadAllIntents,
        ]
    );

    const onEnableSelectedIntents = useCallback(() => {
        if (Array.isArray(selectedDisabledIntentList) && selectedDisabledIntentList.length === 0) {
            setGeneralErrorList(['No selected intent.']);
            return;
        }
        changeIntentListStatus(selectedDisabledIntentList, true);
    }, [changeIntentListStatus, selectedDisabledIntentList, setGeneralErrorList]);
    const onDisableSelectedIntents = useCallback(() => {
        if (Array.isArray(selectedEnabledIntentList) && selectedEnabledIntentList.length === 0) {
            setGeneralErrorList(['No selected intent.']);
            return;
        }
        changeIntentListStatus(selectedEnabledIntentList, false);
    }, [changeIntentListStatus, selectedEnabledIntentList, setGeneralErrorList]);

    useEffect(() => {
        if (Array.isArray(intentList) && intentList.length) {
            setEnabledIntentList(intentList.filter(intent => intent.enabled === true));
            setDisabledIntentList(intentList.filter(intent => intent.enabled === false));
        }
    }, [intentList]);
    useEffect(() => {
        loadAllIntents();
    }, [loadAllIntents]);

    return {
        enabledIntentList,
        disabledIntentList,
        openDialog,
        closeDialog,
        dialogIsOpen,
        selectedEnabledIntentList,
        selectedDisabledIntentList,
        onSelectedEnabledIntent,
        onSelectedDisabledIntent,
        onEnableSelectedIntents,
        onDisableSelectedIntents,
        clearSelectedEnabledIntentList,
        clearSelectedDisaledIntentList,
    };
};
export default useDisableIntentDialog;
