import PageRootNav from '../../components/PageRootNav/PageRootNav';
import GeneralMessage from '../../components/UI/GeneralMessage';
import useSummaryReport from '../../hooks/useSummaryReport';
import ReportData from '../../modules/summaryReport/ReportData';
import SummaryReportFilter from '../../modules/summaryReport/SummaryReportFilter';
import './SummaryReport.css';

const SummaryReport = () => {
    const hook = useSummaryReport();
    const { generalErrorList } = hook;

    return (
        <>
            <PageRootNav label="Summary report" />
            <SummaryReportFilter hook={hook} />
            <GeneralMessage errorList={generalErrorList} />
            <ReportData hook={hook} />
        </>
    );
};

export default SummaryReport;
