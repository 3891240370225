import Grid from '../../components/UI/Grid/Grid';
import useConversRepData from '../../hooks/useConversRepData';
import ConversRepExportDialog from './ConversRepExportDialog';
import ConversRepGridFilter from './ConversRepGridFilter';

const ConversRepGrid = () => {
    const hook = useConversRepData(),
        {
            conversationGridHeaders,
            conversationGridData,
            gridMeta,
            messagesGridHeaders,
            messagesGridData,
            onGridPage,
            selectedConversation,
            changeSelectedConversation,
            closeConfirmExportDialog,
        } = hook;
    return (
        <>
            <ConversRepExportDialog hook={hook} onHide={closeConfirmExportDialog} />
            <ConversRepGridFilter hook={hook} />
            <section className="container-fluid card p-4 mb-5">
                <div className="row">
                    <div className="col-12">
                        <Grid
                            selection={selectedConversation}
                            changeSelectedConversation={changeSelectedConversation}
                            selectionMode="single"
                            paginator={true}
                            lazy={true}
                            total={gridMeta.total}
                            rows={gridMeta.limit}
                            first={gridMeta.offset}
                            onPage={onGridPage}
                            columns={conversationGridHeaders}
                            data={conversationGridData}
                        />
                    </div>
                </div>
            </section>

            {messagesGridData.length && messagesGridData.length > 0 ? (
                <section className="container-fluid card p-4 mb-5">
                    <div className="row">
                        <div className="col-12">
                            <Grid
                                paginator={false}
                                lazy={true}
                                columns={messagesGridHeaders}
                                data={messagesGridData}
                            />
                        </div>
                    </div>
                </section>
            ) : null}
        </>
    );
};

export default ConversRepGrid;
