import React, { useCallback, useEffect, useState } from 'react';
import GeneralMessage from '../../components/UI/GeneralMessage';
import HoverText from '../../components/UI/HoverText/HoverText';
import ListBox from '../../components/UI/ListBox/ListBox';
import ApiRequests from '../../http/ApiRequests';
import axiosErrorHandler from '../../http/AxiosErrorHandler';
import styles from './AnalyzeTrainingData.module.css';

const AnalyzeTrainingData = () => {
    const [api] = useState(new ApiRequests());
    const [successMessage, setSuccessMessage] = useState(null);
    const [generalErrorList, setGeneralErrorList] = useState([]);
    const [intentsWithOneExample, setIntentsWithOneExample] = useState([]);
    const [intentsWithoutExamples, setIntentsWithoutExamples] = useState([]);
    const [intentsWithDuplicateExamples, setIntentsWithDuplicateExamples] = useState([]);

    const loadIntentsWithoutExamples = useCallback(
        () =>
            api
                .getIntentsWithoutExamples()
                .then(response => {
                    if (response.data) {
                        setIntentsWithoutExamples(
                            response.data.map(intent => ({
                                value: intent,
                                label: intent,
                            }))
                        );
                    }
                })
                .catch(error => {
                    const [errorList] = axiosErrorHandler(error);
                    setGeneralErrorList(errorList);
                }),
        [api]
    );
    const loadIntentsWithOneExample = useCallback(
        () =>
            api
                .getIntentsWithOneExample()
                .then(response => {
                    if (response.data) {
                        setIntentsWithOneExample(
                            response.data.map(intent => ({
                                value: intent,
                                label: intent,
                            }))
                        );
                    }
                })
                .catch(error => {
                    const [errorList] = axiosErrorHandler(error);
                    setGeneralErrorList(errorList);
                }),
        [api]
    );
    const loadIntentsWithDuplicateExamples = useCallback(
        () =>
            api
                .getIntentsWithDuplicateExamples()
                .then(response => {
                    if (response.data) {
                        setIntentsWithDuplicateExamples(
                            response.data.map(exampleIntentsPair => ({
                                ...exampleIntentsPair,
                                label: `${
                                    exampleIntentsPair.example
                                } - ${exampleIntentsPair.intents?.join(', ')}`,
                            }))
                        );
                    }
                })
                .catch(error => {
                    const [errorList] = axiosErrorHandler(error);
                    setGeneralErrorList(errorList);
                }),
        [api]
    );

    const itemTemplate = useCallback(option => {
        if (option && option.label) {
            return (
                <HoverText positionX="right" content={<i class="pi pi-copy"></i>}>
                    <div
                        className={styles.listBoxItem}
                        style={{
                            padding: '0.3rem 0.7rem',
                            wordWrap: 'break-word',
                        }}
                    >
                        {option.label}
                    </div>
                </HoverText>
            );
        } else {
            return null;
        }
    }, []);

    const copyIntentToClipboard = useCallback(
        e => {
            if (e.value && navigator) {
                navigator.clipboard.writeText(e.value);
                setSuccessMessage({
                    success: `Intent ${e.value} copied to clipboard.`,
                });
            }
        },
        [setSuccessMessage]
    );
    const copyComplexIntentToClipboard = useCallback(
        e => {
            if (e.value && e.value.label && navigator) {
                navigator.clipboard.writeText(e.value.label);
                setSuccessMessage({
                    success: `Example-intents pair ${e.value.label} copied to clipboard.`,
                });
            }
        },
        [setSuccessMessage]
    );

    useEffect(() => {
        loadIntentsWithoutExamples();
        loadIntentsWithOneExample();
        loadIntentsWithDuplicateExamples();
    }, [loadIntentsWithoutExamples, loadIntentsWithOneExample, loadIntentsWithDuplicateExamples]);

    return (
        <>
            <GeneralMessage
                className="c-msg"
                successMessage={successMessage}
                errorList={generalErrorList}
            />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-6">
                        <div className="d-flex flex-column">
                            <div className="d-flex flex-row justify-content-between mb-1">
                                <h3 className="mb-2">Intents Without Examples: </h3>
                            </div>
                            <ListBox
                                filter
                                style={{ overflow: 'auto' }}
                                listStyle={{ height: '40vh' }}
                                options={intentsWithoutExamples}
                                onChange={copyIntentToClipboard}
                                itemTemplate={itemTemplate}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="d-flex flex-column">
                            <div className="d-flex flex-row justify-content-between mb-1">
                                <h3 className="mb-2">Intents With One Example: </h3>
                            </div>

                            <ListBox
                                filter
                                style={{ overflow: 'auto' }}
                                listStyle={{ height: '40vh' }}
                                options={intentsWithOneExample}
                                onChange={copyIntentToClipboard}
                                itemTemplate={itemTemplate}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="d-flex flex-column">
                            <div className="d-flex flex-row justify-content-between mb-1 mt-4">
                                <h3 className="mb-2 ">Examples Used by Multiple Intents: </h3>
                            </div>
                            <ListBox
                                filter
                                style={{ overflow: 'auto' }}
                                listStyle={{ height: '40vh' }}
                                options={intentsWithDuplicateExamples}
                                onChange={copyComplexIntentToClipboard}
                                itemTemplate={itemTemplate}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AnalyzeTrainingData;
