import { Button } from 'primereact/button';
import { useCallback, useEffect, useMemo, useState } from 'react';
import GeneralMessage from '../../components/UI/GeneralMessage';
import ApiRequests from '../../http/ApiRequests';
import axiosErrorHandler from '../../http/AxiosErrorHandler';

const PlayButton = ({ watchText, watchTextToSpeech, language, ...props }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isPlayingAudio, setIsPlayingAudio] = useState();
    const [audioFile, setAudioFile] = useState();
    const [audioFileNeedsUpdate, setAudioFileNeedsUpdate] = useState(true);
    const [generalErrorList, setGeneralErrorList] = useState([]);
    const api = useMemo(() => new ApiRequests(), []);

    const onAudioEnd = useCallback(() => {
        setIsPlayingAudio(false);
    }, []);

    const onClick = async e => {
        e.preventDefault();
        if (isPlayingAudio) {
            audioFile.pause();
            audioFile.currentTime = 0;
            setIsPlayingAudio(false);
            return;
        }

        if (audioFileNeedsUpdate) {
            try {
                setIsLoading(true);
                const response = await api.getIntentAnswerAudioTrack(
                    watchTextToSpeech || watchText,
                    language
                );
                const blob = new Blob([response.data]);
                const downloadUrl = window.URL.createObjectURL(blob);
                const newAudioApi = new Audio(downloadUrl);
                setAudioFile(newAudioApi);
                setAudioFileNeedsUpdate(false);
                newAudioApi.play();
                newAudioApi.onended = onAudioEnd;
                setIsPlayingAudio(true);
            } catch (error) {
                const [errorList] = axiosErrorHandler(error);
                setGeneralErrorList(errorList);
            } finally {
                setIsLoading(false);
            }
            return;
        }

        audioFile.play();
        setIsPlayingAudio(true);
    };

    useEffect(() => {
        // if text-to-speech field is in use don't watch the text field
        if (watchTextToSpeech) return;
        setAudioFileNeedsUpdate(true);
    }, [watchText]);

    useEffect(() => {
        setAudioFileNeedsUpdate(true);
    }, [watchTextToSpeech]);

    return (
        <>
            <GeneralMessage className="c-msg" errorList={generalErrorList} />
            <Button
                type="button"
                label="SPEAK IT"
                className="p-button-info"
                disabled={!watchText && !watchTextToSpeech}
                icon={`fa ${isPlayingAudio ? 'fa-pause' : 'fa-play'}`}
                loading={isLoading}
                onClick={onClick}
                {...props}
            />
        </>
    );
};

export default PlayButton;
