import Config from '../config/config';

export default class ServerApis {
    constructor() {
        this.origin = Config.apiEndpoint;
        this.adminEndpoint = Config.apiEndpointAdmin;
        this.searchEndpoint = bTItem => `${this.adminEndpoint}${bTItem}/search`;
    }
    logIn = () => `${this.origin}auth/login`;
    logOut = () => `${this.origin}auth/logout`;
    userInfo = () => `${this.origin}auth/me`;

    getAuditData = () => `${this.adminEndpoint}audit`;

    getIntentList = () => `${this.adminEndpoint}intent/all`;
    intentAnswerSearch = () => `${this.adminEndpoint}intent-answer/search`;
    addIntent = () => `${this.adminEndpoint}intent`;
    approveIntentAnswers = () => `${this.adminEndpoint}intent-answer/approve`;
    rejectIntentAnswers = () => `${this.adminEndpoint}intent-answer/reject`;
    approveAllIntentAnswers = () => `${this.adminEndpoint}intent-answer/approve/all`;
    rejectAllIntentAnswers = () => `${this.adminEndpoint}intent-answer/reject/all`;
    editIntentAnswers = () => `${this.adminEndpoint}intent-answer`;
    deleteIntentAnswer = id =>
        `${this.adminEndpoint}intent-answer/delete?channel=${id?.channel}&intentId=${id?.intentId}&language=${id?.language}`;
    getIntentAnswer = id => `${this.adminEndpoint}intent-answer/${id}`;
    getTextToSpeechAudioTrack = () => `${this.adminEndpoint}text-to-speech`;
    getIntentExampleList = intentId => `${this.adminEndpoint}intent/${intentId}/examples`;
    updateIntent = intentId => `${this.adminEndpoint}intent/${intentId}`;
    searchIntent = () => `${this.adminEndpoint}intent/search`;
    changeIntentEnableStatus = (intentId, enableStatus) =>
        `${this.adminEndpoint}intent/${intentId}/enable?enabled=${enableStatus}`;
    delIntent = intentId => `${this.adminEndpoint}intent/${intentId}`;
    approveIntent = intentId => `${this.adminEndpoint}intent/${intentId}/approve`;
    rejectIntent = intentId => `${this.adminEndpoint}intent/${intentId}/reject`;
    approveAllIntents = () => `${this.adminEndpoint}intent/approve/all`;
    rejectAllIntents = () => `${this.adminEndpoint}intent/reject/all`;

    addIntentExample = () => `${this.adminEndpoint}intent-example`;
    delExample = exampleId => `${this.adminEndpoint}intent-example/${exampleId}`;
    approveExample = exampleId => `${this.adminEndpoint}intent-example/${exampleId}/approve`;
    rejectExample = exampleId => `${this.adminEndpoint}intent-example/${exampleId}/reject`;
    approveAllExamples = () => `${this.adminEndpoint}intent-example/approve/all`;
    rejectAllExamples = () => `${this.adminEndpoint}intent-example/reject/all`;

    getKeywordList = () => `${this.adminEndpoint}synonym/all`;
    addKeyword = () => `${this.adminEndpoint}synonym`;
    delKeyword = KeywordId => `${this.adminEndpoint}synonym/${KeywordId}`;
    approveKeyword = KeywordId => `${this.adminEndpoint}synonym/${KeywordId}/approve`;
    rejectKeyword = KeywordId => `${this.adminEndpoint}synonym/${KeywordId}/reject`;
    approveAllKeywords = () => `${this.adminEndpoint}synonym/approve/all`;
    rejectAllKeywords = () => `${this.adminEndpoint}synonym/reject/all`;

    getKeywordSynonymList = KeywordId => `${this.adminEndpoint}synonym/${KeywordId}/examples`;
    addKeywordSynonym = () => `${this.adminEndpoint}synonym-example`;
    delSynonym = synonymId => `${this.adminEndpoint}synonym-example/${synonymId}`;
    approveSynonym = synonymId => `${this.adminEndpoint}synonym-example/${synonymId}/approve`;
    rejectSynonym = synonymId => `${this.adminEndpoint}synonym-example/${synonymId}/reject`;
    approveAllSynonyms = () => `${this.adminEndpoint}synonym-example/approve/all`;
    rejectAllSynonyms = () => `${this.adminEndpoint}synonym-example/reject/all`;

    getUserGroups = () => `${this.adminEndpoint}groups`;
    createUserGroup = () => `${this.adminEndpoint}groups`;
    grantPermissions = id => `${this.adminEndpoint}groups/${id}/grant-permissions`;
    deleteUserGroup = id => `${this.adminEndpoint}groups/${id}`;

    getAveragesReport = () => `${this.origin}reports/average-stats`;
    getTotalStats = () => `${this.origin}reports/totals-stats`;

    getNotApprovedInt = () => this.searchEndpoint("intent");
    getNotApprovedSQ = () => this.searchEndpoint("intent-example");
    getNotApprovedKw = () => this.searchEndpoint("synonym");
    getNotApprovedSyn = () => this.searchEndpoint("synonym-example");

    startBotTraining = () => `${this.origin}model`;
    getBotTrainingStatus = () => `${this.origin}model/status`;

    getConversations = () => `${this.origin}conversation/search`;
    getConversationMessages = conversationID =>
        `${this.origin}conversation/${conversationID}/messages`;

    getWelcomeMessages = () => `${this.origin}client/welcome-message/search`;
    welcomeMessage = id => `${this.origin}client/welcome-message/${id}`;
    welcomeMessages = () => `${this.origin}client/welcome-message`;
    approveWelcomeMessage = id => `${this.origin}client/welcome-message/${id}/approve`;
    rejectWelcomeMessage = id => `${this.origin}client/welcome-message/${id}/reject`;

    getCurrentBotConfiguration = () => `${this.adminEndpoint}properties`;
    updateBotConfiguration = () => `${this.adminEndpoint}properties`;
    getPendingBotConfigurations = () => `${this.adminEndpoint}properties/pending`;
    approveBotConfiguration = id => `${this.adminEndpoint}properties/${id}/approve`;
    rejectBotConfiguration = id => `${this.adminEndpoint}properties/${id}/reject`;
    getBotConfigurationHistory = () => `${this.adminEndpoint}properties/history`;

    getAppFeatures = () => `${this.adminEndpoint}features`;

    createLocation = () => `${this.adminEndpoint}coordinates`;
    updateLocation = id => `${this.adminEndpoint}coordinates/${id}`;
    deleteCoordinates = id => `${this.adminEndpoint}coordinates/${id}`;
    getApprovedCoordinates = () => `${this.adminEndpoint}coordinates`;
    getPendingCoordinates = () => `${this.adminEndpoint}coordinates/pending`;
    reviewPendingCoordinates = approve => `${this.adminEndpoint}coordinates/pending/${approve}/all`;
    configureCoordinates = () => `${this.adminEndpoint}coordinates/bulk`;
    approvePendingLocation = id => `${this.adminEndpoint}coordinates/pending/${id}/approve`;
    rejectPendingLocation = id => `${this.adminEndpoint}coordinates/pending/${id}/reject`;

    getPredefinedMessages = () => `${this.adminEndpoint}predefined-messages`;
    editPredefinedMessage = () => `${this.adminEndpoint}predefined-messages`;
    approvePendingPredefinedMessage = pendingId =>
        `${this.adminEndpoint}predefined-messages/${pendingId}`;
    deletePendingPredefinedMessage = pendingId =>
        `${this.adminEndpoint}predefined-messages/${pendingId}`;

    processText = () => `${this.origin}v1/classify`;

    getLastApprovedTariff = () => `${this.adminEndpoint}tariff`;
    uploadTariff = () => `${this.adminEndpoint}tariff`;
    getAllPendingTariffData = () => `${this.adminEndpoint}tariff/pending`;
    approvePendingTariff = id => `${this.adminEndpoint}tariff/pending/${id}`;
    rejectPendingTariff = id => `${this.adminEndpoint}tariff/pending/${id}`;

    uploadDataFiles = () => `${this.origin}data/import`;
    downloadNluData = () => `${this.origin}data/export`;
    downloadResponseData = () => `${this.origin}data/export-responses`;
    downloadModelData = () => `${this.origin}data/export-model`;

    getPendingModels = () => `${this.origin}model/pending`;
    approvePendingModel = id => `${this.origin}model/${id}/approve`;
    rejectPendingModel = id => `${this.origin}model/${id}/reject`;

    fetchIntentReport = () => `${this.origin}reports/intents`;
    getIntentMessages = intentId => `${this.origin}reports/intents/${intentId}`;
    downloadSummaryReport = () => `${this.origin}reports/summary-stats-export`;

    getForwardedUnacceptedConversations = () => `${this.origin}conversation/forwarded`;
    getAssignedConversations = () => `${this.origin}conversation/assigned/me`;
    assignForwardedConversation = (sessionId, userId) =>
        `${this.origin}conversation/${sessionId}/assign/${userId}`;
    acceptForwardedConversation = id => `${this.origin}conversation/${id}/accept`;
    endConversation = id => `${this.origin}conversation/${id}/end`;
    getLiveChatMessages = () => `${this.origin}conversation/live-messages`;
    sendLiveChatMessage = () => `${this.origin}chat`;

    downloadConversationReport = () => `${this.origin}reports/conversations-export`;

    getCurrentUserInfo = () => `${this.origin}user/`;
    createUser = () => `${this.origin}user/`;
    getUserById = id => `${this.origin}user/${id}`;
    getUsersByPeriod = () => `${this.origin}user/by-period`;
    updateUserInfo = id => `${this.origin}user/${id}`;
    approveUserInfoUpdate = id => `${this.origin}user/${id}/approve`;
    rejectUserInfoUpdate = id => `${this.origin}user/${id}/reject`;
    getAllPendingUsers = () => `${this.origin}user/pending`;
    searchForUser = () => `${this.origin}user/search`;
    getUserStatusHistory = () => `${this.origin}user/status-history`;

    getCurrentLiveChatConfiguration = () => `${this.origin}live-chat/configuration`;
    updateLiveChatConfiguration = () => `${this.origin}live-chat/configuration`;
    getPendingLiveChatConfigurations = () => `${this.origin}live-chat/configuration/pending`;
    approveLiveChatConfiguration = id => `${this.origin}live-chat/configuration/${id}/approve`;
    rejectLiveChatConfiguration = id => `${this.origin}live-chat/configuration/${id}/reject`;
    getLiveChatConfigurationHistory = () => `${this.origin}live-chat/configuration/history`;

    getAllApprovedLiveChatWorkingHours = () => `${this.origin}working-hours/all`;
    getAllPendingLiveChatWorkingHours = () => `${this.origin}working-hours/pending`;
    createLiveChatWorkingHours = () => `${this.origin}working-hours`;
    getLiveChatWorkingHours = id => `${this.origin}working-hours/${id}`;
    updateLiveChatWorkingHours = id => `${this.origin}working-hours/${id}`;
    deleteLiveChatWorkingHours = id => `${this.origin}working-hours/${id}`;
    approveLiveChatWorkingHours = id => `${this.origin}working-hours/${id}/approve`;
    rejectLiveChatWorkingHours = id => `${this.origin}working-hours/${id}/reject`;

    forbiddenPhrases = () => `${this.origin}profanity`;
    approveForbiddenPhrase = id => `${this.origin}profanity/${id}/approve-create`;
    rejectForbiddenPhrase = id => `${this.origin}profanity/${id}/reject-create`;
    approveDeletingForbiddenPhrase = id => `${this.origin}profanity/${id}/approve-delete`;
    rejectDeletingForbiddenPhrase = id => `${this.origin}profanity/${id}/reject-delete`;
    requestDeletingForbiddenPhrase = id => `${this.origin}profanity/${id}/request-delete`;
    phrasesToAprrove = () => `${this.origin}profanity/to-approve`;
    phrasesToRemove = () => `${this.origin}profanity/to-remove`;

    multilingualPhrases = () => `${this.origin}multilingual`;
    approveMultilingualPhrase = id => `${this.origin}multilingual/${id}/approve-create`;
    rejectMultilingualPhrase = id => `${this.origin}multilingual/${id}/reject-create`;
    approveDeletingMultilingualPhrase = id => `${this.origin}multilingual/${id}/approve-delete`;
    rejectDeletingMultilingualPhrase = id => `${this.origin}multilingual/${id}/reject-delete`;
    requestDeletingMultilingualPhrase = id => `${this.origin}multilingual/${id}/request-delete`;
    multilingualPhrasesToAprrove = () => `${this.origin}multilingual/to-approve`;
    multilingualPhrasesToRemove = () => `${this.origin}multilingual/to-remove`;

    liveChatTemplates = () => `${this.origin}live-chat/template`;
    liveChatTemplate = id => `${this.origin}live-chat/template/${id}`;
    pendingLiveChatTemplates = () => `${this.origin}live-chat/template/pending`;
    approvePendingLiveChatTemplate = id => `${this.origin}live-chat/template/pending/${id}/approve`;
    rejectPendingLiveChatTemplate = id => `${this.origin}live-chat/template/pending/${id}/reject`;
    searchLiveChatTemplates = data => `${this.origin}live-chat/template/search?search=${data}`;

    getLiveChatBans = () => `${this.origin}live-chat/bans`;
    createLiveChatBan = () => `${this.origin}live-chat/bans`;
    updateLiveChatBan = id => `${this.origin}live-chat/bans/${id}`;
    deleteLiveChatBan = id => `${this.origin}live-chat/bans/${id}`;

    getLiveChatReportForSupervisor = () => `${this.origin}live-chat/reports`;
    exportLiveChatReportForSupervisor = () => `${this.origin}live-chat/reports/export`;
    getLiveChatReportForOperator = () => `${this.origin}live-chat/reports/me`;
    exportLiveChatReportForOperator = () => `${this.origin}live-chat/reports/me/export`;
    getLiveChatSummaryReportForAllOperators = () => `${this.origin}live-chat/reports/summary`;
    exportLiveChatSummaryReportForAllOperators = () =>
        `${this.origin}live-chat/reports/summary/export`;

    pingServer = () => `${this.origin}ping`;

    getIntentsWithoutAnswers = lang =>
        `${this.origin}training-data-analysis/intent-answers-analysis${
            lang && lang !== "all" ? `?language=${lang}` : ""
        }`;
    getWrongMetadataButtons = () =>
        `${this.origin}training-data-analysis/intent-answers-metadata-analysis`;
    getIntentsWithOneExample = () => `${this.origin}training-data-analysis/intents-one-example`;
    getIntentsWithoutExamples = () => `${this.origin}training-data-analysis/intents-no-examples`;
    getIntentsWithDuplicateExamples = () =>
        `${this.origin}training-data-analysis/intents-duplicated-examples`;
    getSynonymsWithoutExamples = () =>
        `${this.origin}training-data-analysis/synonym-examples-analysis`;
}
