import { COLORS } from '../../constants/constants';
import ApiRequests from '../../http/ApiRequests';
import axiosErrorHandler from '../../http/AxiosErrorHandler';
import { convertObjectArrayToObject } from '../../utils/dataTypeConversionUtils';
import { SendingStatus } from '../../utils/liveChatUtils';
import { sortByDateCompareFunction } from '../../utils/sortUtils';

const inactivityTimeoutInMs = 7200000; // 2 h
const liveChatMessagesPollingInterval = 5000; // 5 sec
//Action Types
export const SET_INCOMMING_MESSAGE = 'SET_INCOMMING_MESSAGE';
export const SET_SELECTED_CONVERSATION_ID = 'SET_SELECTED_CONVERSATION_ID';
export const SET_CONVERSATION_WINDOWS = 'SET_CONVERSATION_WINDOWS';
export const INCREMENT_PROFILE_COLOR_COUNTER = 'SET_PROFILE_COLOR_COUNTER';
export const SET_LIVE_CHAT_ERROR_LIST = 'SET_LIVE_CHAT_ERROR_LIST';
export const SET_CONVERSATIONS = 'SET_CONVERSATIONS';
export const SET_WS_CLIENT_FOR_AWAITING_CONVERSATIONS = 'SET_WS_CLIENT_FOR_AWAITING_CONVERSATIONS';
export const SET_AWAITING_CLIENTS = 'SET_AWAITING_CLIENTS';
export const SET_WS_CLIENT_FOR_ASSIGNED_CONVERSATIONS = 'SET_WS_CLIENT_FOR_FORWARDED_CONVERSATIONS';
export const SET_ASSIGNED_CLIENTS = 'SET_FORWARDED_CLIENTS';
export const INCREMENT_NOTIFICATION_COUNTER = 'INCREMENT_NOTIFICATION_COUNTER';
export const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE';
export const SET_TEMPLATE = 'SET_TEMPLATE';
export const SET_WS_CLIENT = 'SET_WS_CLIENT';
export const INCREMENT_NOTIFICATION_COUNTER_WITH_CONVERSATION_ID =
    'INCREMENT_NOTIFICATION_COUNTER_WITH_CONVERSATION_ID';
export const SET_INACTIVITY_TIMER_ID = 'SET_INACTIVITY_TIMER_ID';
export const SET_INACTIVITY_DIALOG_IS_VISIBLE = 'SET_INACTIVITY_DIALOG_IS_VISIBLE';
export const SET_LIVE_CHAT_MESSAGE_POLLING_INTERVAL_ID =
    'SET_LIVE_CHAT_MESSAGE_POLLING_INTERVAL_ID';
export const SET_LAST_POLLING_STAMP = 'SET_LAST_POLLING_STAMP';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const SET_MESSAGE_STATUS = 'SET_MESSAGE_STATUS';

export const setIncommingMessage = payload => ({
    type: SET_INCOMMING_MESSAGE,
    payload,
});

export const setSelectedConversationId = payload => ({
    type: SET_SELECTED_CONVERSATION_ID,
    payload,
});

export const incrementProfileColorCounter = payload => ({
    type: INCREMENT_PROFILE_COLOR_COUNTER,
    payload,
});

export const setGeneralErrorList = payload => ({
    type: SET_LIVE_CHAT_ERROR_LIST,
    payload,
});

export const setConversations = payload => ({
    type: SET_CONVERSATIONS,
    payload,
});

export const setWsClientForAwaitingConversations = payload => ({
    type: SET_WS_CLIENT_FOR_AWAITING_CONVERSATIONS,
    payload,
});

export const setAwaitingClients = payload => ({
    type: SET_AWAITING_CLIENTS,
    payload,
});

export const setWsClientForAssignedConversations = payload => ({
    type: SET_WS_CLIENT_FOR_ASSIGNED_CONVERSATIONS,
    payload,
});

export const setAssignedClients = payload => ({
    type: SET_ASSIGNED_CLIENTS,
    payload,
});

export const incrementNotificationCounter = payload => ({
    type: INCREMENT_NOTIFICATION_COUNTER,
    payload,
});

export const incrementNotificationCounterWithConversationID = payload => ({
    type: INCREMENT_NOTIFICATION_COUNTER_WITH_CONVERSATION_ID,
    payload,
});

export const setSuccessMessage = payload => ({
    type: SET_SUCCESS_MESSAGE,
    payload,
});

export const setTemplate = payload => ({
    type: SET_TEMPLATE,
    payload,
});

export const setWsClient = payload => ({
    type: SET_WS_CLIENT,
    payload,
});

export const setInactivityTimerId = payload => ({
    type: SET_INACTIVITY_TIMER_ID,
    payload,
});

export const setInactivityDialogIsVisible = payload => ({
    type: SET_INACTIVITY_DIALOG_IS_VISIBLE,
    payload,
});

export const setLiveChatMessagePollingIntervalId = payload => ({
    type: SET_LIVE_CHAT_MESSAGE_POLLING_INTERVAL_ID,
    payload,
});

export const setLastPollingTimestamp = payload => ({
    type: SET_LAST_POLLING_STAMP,
    payload,
});

export const removeMessage = payload => ({
    type: REMOVE_MESSAGE,
    payload,
});
export const setMessageStatus = payload => ({
    type: SET_MESSAGE_STATUS,
    payload,
});

export const updateConversations = conversationsFromBackend => {
    const api = new ApiRequests();
    return (dispatch, getState) => {
        const state = getState();

        const conversations = [...state.liveChat.conversations];
        let profileColorCounter = state.liveChat.profileColorCounter;

        conversationsFromBackend.forEach(convBackend => {
            if (!conversations.some(conv => conv.id === convBackend.id)) {
                const newConversation = {
                    id: convBackend.id,
                    session: convBackend.session,
                    channel: convBackend.channel,
                    language: convBackend.language,
                    totalMessagesCount: convBackend.totalMessagesCount,
                    unreadMessagesCount: 0,
                    profileColor: COLORS[profileColorCounter % 10],
                    timestampToMessageMap: null,
                    conversationEnded: false,
                };
                dispatch(incrementProfileColorCounter());
                profileColorCounter++;
                conversations.push(newConversation);
            }
        });

        const conversationsWithoutMessages = conversations.filter(conv => !conv.messages);
        if (conversationsWithoutMessages.length) {
            const promisesList = conversationsWithoutMessages.map(conv => {
                return api.getConversationMessages(conv.id);
            });
            Promise.all(promisesList)
                .then(responseList => {
                    responseList.forEach((response, index) => {
                        const conversation = conversations.find(
                            conv => conv.id === conversationsWithoutMessages[index].id
                        );
                        if (conversation) {
                            conversation.messages = [...response.data];
                            conversation.timestampToMessageMap = convertObjectArrayToObject(
                                response.data,
                                'messageTs'
                            );
                        }
                    });
                    dispatch(setConversations(conversations));
                })
                .catch(error => {
                    const [errorList] = axiosErrorHandler(error);
                    dispatch(setGeneralErrorList(errorList));
                });
        }
    };
};

export const initLiveChat = () => {
    const api = new ApiRequests();
    return dispatch => {
        api.userInfo()
            .then(response => {
                const username = response.data?.username;
                Promise.all([
                    api.getForwardedUnacceptedConversations(),
                    api.getConversations({ accepted: true, acceptedBy: username, finished: false }),
                    api.getAssignedConversations(),
                ])
                    .then(
                        ([
                            unacceptedConversationsResponse,
                            acceptedConversationsResponse,
                            assignedConversationsResponse,
                        ]) => {
                            dispatch(
                                setAwaitingClients(
                                    unacceptedConversationsResponse.data?.sort(
                                        sortByDateCompareFunction('startTs', false)
                                    )
                                )
                            );
                            dispatch(
                                updateConversations(
                                    acceptedConversationsResponse.data?.conversations
                                )
                            );
                            dispatch(
                                setAssignedClients(
                                    assignedConversationsResponse.data?.sort(
                                        sortByDateCompareFunction('startTs', false)
                                    )
                                )
                            );

                            dispatch(initRefreshChatMessagesInterval());
                        }
                    )
                    .catch(error => {
                        const [errorList] = axiosErrorHandler(error);
                        dispatch(setGeneralErrorList(errorList));
                    });
            })
            .catch(error => {
                const [errorList] = axiosErrorHandler(error);
                dispatch(setGeneralErrorList(errorList));
            });
    };
};

export const disableLiveChat = () => {
    return dispatch => {
        dispatch(setConversations([]));
        dispatch(setWsClientForAwaitingConversations(null));
        dispatch(setWsClientForAssignedConversations(null));
        dispatch(clearLiveChatMessagePollingInterval());
    };
};

export const sendMessage = (sessionId, messageText, convId) => {
    const api = new ApiRequests();
    return (dispatch, getState) => {
        const state = getState();
        const conversation = state.liveChat.conversations.find(conv => conv.id === convId);
        const conversationId = conversation?.id;
        const outgoingMessageId = Math.floor(Math.random() * 10000000) + 1;
        api.sendLiveChatMessage({ sessionId, message: messageText })
            .catch(error => {
                const [errorList] = axiosErrorHandler(error);
                dispatch(setGeneralErrorList(errorList));
                dispatch(
                    setMessageStatus({
                        conversationId: convId,
                        messageId: outgoingMessageId,
                        sendingStatus: SendingStatus.FAILED,
                    })
                );
            })
            .then(response => {
                // check for error messages (e.g. after message with curse words)
                if (response.data?.messages?.length) {
                    response.data.messages.forEach(message => {
                        dispatch(
                            setIncommingMessage({
                                ...message,
                                messageText: message.message,
                                sessionId: false,
                                chatId: conversationId,
                                // set this to designate the message as a system message
                                userMessage: false,
                            })
                        );
                    });
                }
            });
        dispatch(
            setIncommingMessage({
                id: outgoingMessageId,
                messageText: messageText,
                sessionId: false,
                chatId: conversationId,
                sentByUser: true,
                sendingStatus: SendingStatus.AWAITING,
            })
        );
        // if the operator is sending messages he/she is active
        dispatch(resetInactivityTimer());
    };
};

export const acceptConversation = id => {
    const api = new ApiRequests();
    return (dispatch, getState) => {
        const state = getState();
        const awaitingClients = state.liveChat.awaitingClients;
        const assignedClients = state.liveChat.assignedClients;
        const username = state.auth.currentUser?.username;
        api.acceptForwardedConversation(id)
            .then(response => {
                dispatch(
                    setSuccessMessage({
                        success: `You successfully took a conversation with Client ${id}`,
                    })
                );

                api.getConversations({ accepted: true, acceptedBy: username, finished: false })
                    .then(acceptedConversationsResponse => {
                        dispatch(
                            updateConversations(acceptedConversationsResponse.data?.conversations)
                        );

                        dispatch(setSelectedConversationId(id));
                        dispatch(
                            setAwaitingClients(
                                awaitingClients.filter(client => client.conversationID !== id)
                            )
                        );
                        dispatch(
                            setAssignedClients(
                                assignedClients.filter(client => client.conversationID !== id)
                            )
                        );
                    })
                    .catch(error => {
                        const [errorList] = axiosErrorHandler(error);
                        dispatch(setGeneralErrorList(errorList));
                    });
            })
            .catch(error => {
                const [errorList] = axiosErrorHandler(error);
                dispatch(setGeneralErrorList(errorList));
                // if there's an issue taking this conversation, load all pending conversations again
                api.getForwardedUnacceptedConversations()
                    .then(response => {
                        dispatch(
                            setAwaitingClients(
                                response.data?.sort(sortByDateCompareFunction('startTs', false))
                            )
                        );
                    })
                    .catch(error => {
                        const [errorList] = axiosErrorHandler(error);
                        dispatch(setGeneralErrorList(errorList));
                    });
            });
    };
};

export const endConversation = id => {
    const api = new ApiRequests();
    return (dispatch, getState) => {
        const state = getState();
        const conversations = state.liveChat.conversations;
        api.endConversation(id)
            .then(() => {
                const conversationsAfterRemove = conversations.filter(conv => conv.id !== id);
                dispatch(setConversations(conversationsAfterRemove));
                const newSelectedConversationId = conversationsAfterRemove.length
                    ? conversationsAfterRemove[0].id
                    : 0;
                dispatch(setSelectedConversationId(newSelectedConversationId));
            })
            .catch(error => {
                const [errorList] = axiosErrorHandler(error);
                dispatch(setGeneralErrorList(errorList));
            });
    };
};

export const initInactivityTimer = () => {
    return (dispatch, getState) => {
        const state = getState();
        if (!state.liveChat.inactivityTimerId) {
            const timerId = setTimeout(
                () => dispatch(setInactivityDialogIsVisible(true)),
                inactivityTimeoutInMs
            );
            dispatch(setInactivityTimerId(timerId));
        }
    };
};

export const resetInactivityTimer = () => {
    return (dispatch, getState) => {
        const state = getState();
        if (state.liveChat.inactivityTimerId) {
            clearTimeout(state.liveChat.inactivityTimerId);
            const timerId = setTimeout(
                () => dispatch(setInactivityDialogIsVisible(true)),
                inactivityTimeoutInMs
            );
            dispatch(setInactivityTimerId(timerId));
        }
    };
};

export const clearInactivityTimer = () => {
    return (dispatch, getState) => {
        const state = getState();
        clearTimeout(state.liveChat.inactivityTimerId);
        dispatch(setInactivityTimerId(0));
    };
};

export const clearSelectedConversation = () => {
    return dispatch => {
        dispatch(setSelectedConversationId(0));
    };
};

export const keepSessionAlive = () => {
    const api = new ApiRequests();
    return dispatch => {
        api.pingServer();
    };
};

export const clearLiveChatMessagePollingInterval = () => {
    return (dispatch, getState) => {
        const state = getState();
        const liveChatMessagePollingIntervalId = state.liveChat.liveChatMessagePollingIntervalId;
        clearInterval(liveChatMessagePollingIntervalId);
        dispatch(setLiveChatMessagePollingIntervalId(-1));
    };
};

export const clearOverwrite = () => {
    return dispatch => {
        dispatch(setTemplate(null));
    };
};

export const initRefreshChatMessagesInterval = () => {
    return (dispatch, getState) => {
        const state = getState();
        const pollingIntervalId = state.liveChat.liveChatMessagePollingIntervalId;

        if (pollingIntervalId === -1) {
            const intervalId = setInterval(() => {
                dispatch(refreshConversations());
                dispatch(refreshLiveChatMessages());
            }, liveChatMessagesPollingInterval);

            dispatch(setLiveChatMessagePollingIntervalId(intervalId));
        }
    };
};

export const refreshConversations = () => {
    const api = new ApiRequests();
    return dispatch => {
        Promise.all([api.getForwardedUnacceptedConversations(), api.getAssignedConversations()])
            .then(([unacceptedConversations, assignedConversations]) => {
                dispatch(
                    setAwaitingClients(
                        unacceptedConversations.data?.sort(
                            sortByDateCompareFunction('startTs', false)
                        )
                    )
                );
                dispatch(
                    setAssignedClients(
                        assignedConversations.data?.sort(
                            sortByDateCompareFunction('startTs', false)
                        )
                    )
                );
                if (assignedConversations.data?.length || unacceptedConversations.data?.length) {
                    dispatch(incrementNotificationCounter());
                }
            })
            .catch(error => {
                const [errorList] = axiosErrorHandler(error);
                dispatch(setGeneralErrorList(errorList));
            });
    };
};

export const refreshLiveChatMessages = () => {
    const api = new ApiRequests();
    return (dispatch, getState) => {
        const storeState = getState();
        if (storeState) {
            const conversations = storeState.liveChat.conversations;
            const lastPollingTimestamp = storeState.liveChat.lastPollingTimestamp;
            if (Array.isArray(conversations) && conversations.length) {
                api.getLiveChatMessages({
                    messageSender: ['CLIENT', 'SYSTEM', 'ADMIN'],
                    sessionIds: conversations.map(conv => conv.session),
                    timestamp: lastPollingTimestamp,
                })
                    .then(response => {
                        if (response.data) {
                            if (response.data.timestamp) {
                                dispatch(setLastPollingTimestamp(response.data.timestamp));
                            }

                            if (response.data.conversations) {
                                const newMessagesGroupedByConversation =
                                    response.data.conversations;
                                const selectedConversationId =
                                    storeState.liveChat.selectedConversationId;
                                let gotNewMessagesFlag = false;
                                conversations.forEach(conv => {
                                    const newMessages =
                                        newMessagesGroupedByConversation[conv.id]?.messages;
                                    const action =
                                        newMessagesGroupedByConversation[conv.id]?.action;
                                    if (Array.isArray(newMessages) && newMessages.length) {
                                        gotNewMessagesFlag = true;
                                        conv.messages.push(
                                            ...newMessages
                                                .filter(
                                                    m =>
                                                        !conv.timestampToMessageMap[m.messageTs] &&
                                                        !m.nickname
                                                )
                                                .map(message => ({
                                                    ...message,
                                                    content: message.message,
                                                    customerId: message.userMessage
                                                        ? conv.session
                                                        : '',
                                                    chatId: conv.id,
                                                    type: 'TEXT',
                                                    messageTs: message.messageTs,
                                                }))
                                        );
                                        // check for operator messages and set their timestamp
                                        newMessages
                                            .filter(m => m.nickname)
                                            .forEach(m => {
                                                const existingMessage = conv.messages.find(
                                                    message =>
                                                        message.content === m.message &&
                                                        !message.messageTs
                                                );
                                                if (existingMessage) {
                                                    existingMessage.messageTs = m.messageTs;
                                                    existingMessage.sendingStatus =
                                                        SendingStatus.SUCCESS;
                                                    gotNewMessagesFlag = true;
                                                }
                                            });
                                        conv.timestampToMessageMap = convertObjectArrayToObject(
                                            newMessages,
                                            'messageTs',
                                            conv.timestampToMessageMap
                                        );
                                        if (conv.id !== selectedConversationId) {
                                            conv.unreadMessagesCount += newMessages.length;
                                            if (newMessages.length > 0) {
                                                dispatch(incrementNotificationCounter());
                                            }
                                        }
                                        conv.messages.sort(
                                            sortByDateCompareFunction('messageTs', false)
                                        );
                                    }
                                    if (action === 'DISCONNECT') {
                                        conv.conversationEnded = true;
                                    }
                                });
                                if (gotNewMessagesFlag) {
                                    dispatch(setConversations([...conversations]));
                                }
                            }
                        }
                    })
                    .catch(error => {
                        const [errorList] = axiosErrorHandler(error);
                        dispatch(setGeneralErrorList(errorList));
                    });
            }
        }
    };
};
