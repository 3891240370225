import { Dialog } from 'primereact/dialog';
import React, { useCallback } from 'react';
import Control from '../../components/UI/Control/Control';
import useLiveChatAssign from '../../hooks/useLiveChatAssign';
import { connect } from 'react-redux';

const LiveChatAssignDialog = ({
    currentUser,
    sessionId,
    setSuccessMessage,
    setGeneralErrorList,
    dialogVisible,
    hideDialog,
    onSuccessfulSubmit,
}) => {
    const { availableOperators, fieldErrorFor, onSubmit, registerValidationFor } =
        useLiveChatAssign({
            currentUser,
            sessionId,
            setSuccessMessage,
            setGeneralErrorList,
            hideDialog,
            onSuccessfulSubmit,
            dialogVisible,
        });

    const cancelOnClick = useCallback(
        e => {
            e.preventDefault();
            hideDialog();
        },
        [hideDialog]
    );

    return (
        <Dialog
            header="Assign conversation"
            visible={dialogVisible}
            style={{ width: '20vw', minWidth: '300px' }}
            breakpoints={{ '960px': '30vw', '640px': '40vw' }}
            onHide={hideDialog}
        >
            <form noValidate onSubmit={onSubmit}>
                <div className="container-fluid" style={{ maxWidth: '400px' }}>
                    <div className="row">
                        <div className="col-12">
                            <Control
                                error={fieldErrorFor.operator?.message}
                                registerRef={registerValidationFor.operator}
                                label="Operator"
                                type="select"
                                options={availableOperators}
                                name="operator"
                                value=""
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 d-flex justify-content-end mt-4">
                        <button className="btn btn-primary mr-2" type="submit">
                            Assign
                        </button>
                        <button className="btn btn-danger mr-2" onClick={cancelOnClick}>
                            Cancel
                        </button>
                    </div>
                </div>
            </form>
        </Dialog>
    );
};

const mapStateToProps = state => ({
    currentUser: state.auth.currentUser,
});

export default connect(mapStateToProps, null)(LiveChatAssignDialog);
