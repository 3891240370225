import { Dialog } from 'primereact/dialog';
import React from 'react';
import Grid from '../../components/UI/Grid/Grid';
import moment from 'moment';

const WorkingHoursHistoryDialog = ({
    total,
    rows,
    first,
    onPage,
    history,
    dialogVisible,
    hideDialog,
}) => {
    const workingHoursGridHeaders = [
        { name: 'Date', key: 'date', filter: false },
        { name: 'From', key: 'startHour', filter: false },
        { name: 'To', key: 'endHour', filter: false },
        { name: 'Approved By', key: 'approvedBy', filter: false },
        { name: 'Approval date', key: 'approvalDate', formatter: 'datetime', filter: false },
    ];
    let workingHoursGridData;

    if (history) {
        workingHoursGridData = history.workingHours.map(item => ({
            date: moment(item.date).format('DD-MM-YYYY'),
            startHour: item.startHour ? item.startHour.substring(0, 5) : 'Closed',
            endHour: item.endHour ? item.endHour.substring(0, 5) : 'Closed',
            approvedBy: item.approvedBy,
            approvalDate: item.approvedTs,
        }));
    }

    return (
        <Dialog
            header="History"
            visible={dialogVisible}
            style={{ width: '75vw' }}
            breakpoints={{ '1400px': '75vw', '1000px': '100vw' }}
            onHide={hideDialog}
            contentStyle={{ overflow: 'visible' }}
        >
            <Grid
                paginator={true}
                lazy={true}
                rows={rows}
                first={first}
                total={total}
                onPage={onPage}
                columns={workingHoursGridHeaders}
                data={workingHoursGridData}
            />
        </Dialog>
    );
};

export default WorkingHoursHistoryDialog;
