import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { routes } from './Routes';

const ProtectedRoute = ({ currentUser, component: Component, checkPermissions, ...rest }) => {
    const permissions =
        currentUser && Array.isArray(currentUser.permissions) ? currentUser.permissions : [];
    return (
        <Route
            {...rest}
            render={props => {
                // only if there's a permission function and it fails a redirect follows
                if (checkPermissions && !checkPermissions(permissions)) {
                    return <Redirect to={{ pathname: routes.base.path }} />;
                } else {
                    return <Component {...props} />;
                }
            }}
        />
    );
};

const mapStateToProps = state => ({
    currentUser: state.auth.currentUser,
});

export default connect(mapStateToProps, null)(ProtectedRoute);
