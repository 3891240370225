import React from 'react';
import useMultilingualPhrases from '../../hooks/useMultilingualPhrases';
import MultilingualPhraseForm from '../../modules/multilingualPhrases/MultilingualPhraseForm';
import PhrasesForApprovalPanel from '../../modules/multilingualPhrases/PhrasesForApprovalPanel';
import ActivePhrasesPanel from '../../modules/multilingualPhrases/ActivePhrasesPanel';

const MultilingualPhrasses = () => {
    const hook = useMultilingualPhrases();

    return (
        <>
            <MultilingualPhraseForm hook={hook} />
            <PhrasesForApprovalPanel hook={hook} />
            <ActivePhrasesPanel hook={hook} />
        </>
    );
};

export default MultilingualPhrasses;
