import { SET_APP_FEATURES } from "../actions/appActions";

const INIT_STATE = {
    features: {
        textToSpeech: false,
    },
};

const appReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_APP_FEATURES:
            return Object.assign({}, state, {
                features: action.payload,
            });

        default:
            return { ...state };
    }
};

export default appReducer;
