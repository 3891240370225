import get from 'lodash/get';
import { Button } from 'primereact/button';
import React from 'react';
import './BotTrainingApprovalItem.css';

const BotTrainingApprovalItem = props => {
    const intentStatusFormatted = (intStatus, message) => {
        let status = null;
        if (intStatus && intStatus === props.changeStatus.DELETED) {
            status = <b className="label-intent-deleted">{message ? message : 'Deleted'}</b>;
        } else if (intStatus && intStatus === props.changeStatus.NEW) {
            status = <b className="label-intent-new">{message ? message : 'Created'}</b>;
        } else if (
            intStatus &&
            (intStatus === props.changeStatus.UPDATED || intStatus === props.changeStatus.APPROVED)
        ) {
            status = <b className="label-intent-updated">{message ? message : 'Updated'}</b>;
        }
        return status;
    };

    const showChangeStatusDetailed = () => {
        const change = get(props, 'change', '');
        const futureElementsList = get(props, 'futureElementsList', '');
        const nowElementsList = get(props, 'nowElementsList', '');
        const intentStatusMessage = props.getIntentStatusMessage(
            change,
            futureElementsList,
            nowElementsList
        );
        if (intentStatusMessage) {
            return (
                <div className="row mb-2">
                    <div className="col-12">
                        <h3 className="display-5">
                            {intentStatusFormatted(
                                intentStatusMessage.status,
                                intentStatusMessage.message
                            )}
                        </h3>
                    </div>
                </div>
            );
        }
        return null;
    };

    return (
        <div className="container-fluid">
            <div className="row mb-2">
                <div className="col-6 d-flex align-items-center justify-content-start">
                    <label>
                        {intentStatusFormatted(props.change.status)} &nbsp;
                        {props.change.type === 'intent'
                            ? 'intent/sample questions for intent '
                            : 'keyword/synonyms for keyword '}
                        <b>{props.change.name}</b> &nbsp;
                    </label>
                </div>
                <div className="col-4 d-flex align-items-center justify-content-start">
                    <Button
                        type="button"
                        className="info-btn"
                        onClick={() => props.toglePreview(props.index, props.change)}
                        label="Review"
                        tooltipOptions={{ position: 'top' }}
                    />
                </div>
            </div>
            {props.change.preview ? (
                <div className="container-fluid card p-4 mb-5 animated fadeIn">
                    <div className="row">
                        <div className="col-12">
                            <h5 className="display-6">{`Review panel for ${props.change.type} ${props.change.name}`}</h5>
                        </div>
                    </div>

                    {showChangeStatusDetailed()}

                    <div className="row mb-2">
                        {/* {props.nowElementsList && props.nowElementsList.length ? ( */}
                        <div className="col-6 d-flex flex-column">
                            <label>
                                {`Current list of
                                ${
                                    props.change.type === 'intent'
                                        ? ' sample questions '
                                        : ' synonyms '
                                }
                                for ${props.change.type} ${props.change.name}`}
                            </label>
                            <div className="intent-change-box">
                                {props.nowElementsList.map(el => (
                                    <p key={el.id} className={get(el, 'status', '').toLowerCase()}>
                                        {el.content}
                                    </p>
                                ))}
                            </div>
                        </div>
                        {/* ) : null} */}

                        {/* {props.futureElementsList && props.futureElementsList.length ? ( */}
                        <div className="col-6 d-flex flex-column">
                            <label>
                                {`Modified list of
                                ${
                                    props.change.type === 'intent'
                                        ? ' sample questions '
                                        : ' synonyms '
                                }
                                for ${props.change.type} ${props.change.name}`}
                            </label>
                            <div className="intent-change-box">
                                {props.futureElementsList.map(el => (
                                    <p key={el.id} className={get(el, 'status', '').toLowerCase()}>
                                        {el.content}
                                    </p>
                                ))}
                            </div>
                        </div>
                        {/* ) : null} */}
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Button
                                type="button"
                                className="add-btn mr-2"
                                label="Approve"
                                tooltipOptions={{ position: 'top' }}
                                onClick={() => props.approveModification(props.change)}
                            />
                            <Button
                                type="button"
                                className="delete-btn"
                                label="Reject"
                                tooltipOptions={{ position: 'top' }}
                                onClick={() => props.rejectModification(props.change)}
                            />
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default BotTrainingApprovalItem;
