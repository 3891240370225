import { useCallback, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import ApiRequests from '../http/ApiRequests';
import axiosErrorHandler from '../http/AxiosErrorHandler';
import { validator } from '../services/validationService';
import { trimStringsRecursively } from '../utils/formUtils';
import { validateSsmlWrapperTag } from "../utils/validateUtils";

const useWelcomeMessageForm = ({ data, closeDialog, reload }) => {
    const api = useMemo(() => new ApiRequests(), []);
    const [selectedWelcomeMessage, setSelectedWelcomeMessage] = useState();
    const [apiErrors, setApiErrors] = useState([]);
    const [successMessage, setSuccessMessage] = useState(null);
    const [intentList, setIntentList] = useState([]);

    const {
        register,
        handleSubmit,
        errors,
        watch,
        control,
        getValues,
        reset,
        setValue,
        trigger,
        unregister,
        clearErrors,
    } = useForm({
        defaultValues: {
            pageId: data?.pageId,
            language: data?.language,
            channel: data?.channel,
            default: data?.default,
            responses: data
                ? data?.responses.map(resp => ({
                      id: resp.id,
                      content: {
                          buttons: Array.isArray(resp.content.buttons)
                              ? resp.content.buttons.map(button => ({
                                    metadata: button.metadata,
                                    text: button.text,
                                    value: button.value,
                                }))
                              : [],
                          carousel: Array.isArray(resp.content.carousel)
                              ? resp.content.carousel.map(car => ({
                                    image: car.image,
                                    metadata: car.metadata,
                                    subtitle: car.subtitle,
                                    title: car.title,
                                    url: car.url,
                                }))
                              : [],
                          text: resp.content.text ? resp.content.text : "",
                          alternativeContent: resp.content.alternativeContent
                              ? resp.content.alternativeContent
                              : "",
                      },
                      type: resp.type,
                  }))
                : [
                      {
                          type: "TEXT",
                          content: [
                              {
                                  buttons: [],
                                  carousel: [],
                                  text: "",
                                  alternativeContent: "",
                              },
                          ],
                      },
                  ],
        },
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "responses",
    });

    const [gridHeaders] = useState([
        { name: "Default", key: "default", formatter: "booleanWithIcons" },
        { name: "Language", key: "language" },
        { name: "Channel", key: "channel" },
        { name: "Page", key: "pageId" },
    ]);

    const noWhiteSpaceValidate = value =>
        value.trim() !== "" || validator.errorMessages.ONLY_WHITE_SPACE_CONTENT;

    const registerValidationFor = {
        language: () => register({ required: "Select a language." }),
        channel: () => register({ required: "Select a channel." }),
        type: () =>
            register({
                required: validator.errorMessages.MISSING_TYPE,
            }),
        content: () =>
            register({
                required: validator.errorMessages.MISSING_CONTENT,
                validate: noWhiteSpaceValidate,
            }),
        text: () =>
            register({
                required: "Enter a message.",
                validate: noWhiteSpaceValidate,
            }),
        textToSpeech: () =>
            register({
                validate: validateSsmlWrapperTag,
            }),
        value: () => register(),
        title: () =>
            register({
                required: validator.errorMessages.MISSING_TITLE,
                validate: noWhiteSpaceValidate,
            }),
        subtitle: () => register(),
        image: () =>
            register({
                required: validator.errorMessages.MISSING_IMAGE,
            }),
        pageId: () => register(),
        buttonMetadata: () => ({
            required: validator.errorMessages.MISSING_METADATA,
            validate: value => {
                if (value.length > 1 && value.substring(1).trim() === "") {
                    return validator.errorMessages.ONLY_WHITE_SPACE_CONTENT;
                }
                if (!value.match(/^\/[a-zа-я0-9_]+#[A-Z]{2}$/)) {
                    return validator.errorMessages.WRONG_METADATA_FORMAT;
                }
                return true;
            },
        }),
        metadata: () => register(),
        welcomeMessage: () => register(),
        url: () =>
            register({
                required: validator.errorMessages.MISSING_URL,
                validate: noWhiteSpaceValidate,
            }),
        default: () => register(),
    };

    const langOptions = [
        { label: "English", value: "EN" },
        { label: "Bulgarian", value: "BG" },
    ];
    const channelOptions = [
        { label: "Viber", value: "VIBER" },
        { label: "Messenger", value: "MESSENGER" },
        { label: "Web", value: "API" },
    ];

    const onSubmit = data => {
        if (!data.responses) {
            setApiErrors(["Add at least one response."]);
            return;
        }

        trimStringsRecursively(data);
        api.createOrUpdateWelcomeMessage(data)
            .then(() => {
                closeDialog();
                reload();
                setSuccessMessage({ success: "Successfully sent responses for review" });
            })
            .catch(err => {
                const [errorList] = axiosErrorHandler(err);
                setApiErrors(errorList);
            });
    };

    const searchIntent = useCallback(
        e => {
            // if the search string does not start with '/' do not show suggestions
            if (!e.query.startsWith("/")) {
                setIntentList([]);
                return;
            }
            const language = getValues("language");
            api.searchIntent({
                approved: true,
                name: e.query.replace("/", "").trim(),
            })
                .then(response => {
                    setIntentList(
                        response.data.map(i => ({ name: "/" + i.name + "#" + language }))
                    );
                })
                .catch(error => {
                    const [errorList] = axiosErrorHandler(error);
                    setApiErrors(errorList);
                });
        },
        [api, getValues]
    );

    return {
        onSubmit: handleSubmit(onSubmit),
        registerValidationFor,
        errors,
        watch,
        langOptions,
        channelOptions,
        selectedWelcomeMessage,
        setSelectedWelcomeMessage,
        getValues,
        setValue,
        apiErrors,
        control,
        successMessage,
        generalErrorList: apiErrors,
        gridHeaders,
        append,
        fields,
        remove,
        trigger,
        reset,
        unregister,
        searchIntent,
        intentList,
        clearErrors,
    };
};

export default useWelcomeMessageForm;
