import get from 'lodash/get';
import ApiRequests from '../../http/ApiRequests';
import RoboadvisorService from '../../services/roboadvisor.service';
import axiosErrorHandler from '../../http/AxiosErrorHandler';

//Action Types
export const SHOW_ADD_NEW_USER_GROUP_SIDEBAR = 'SHOW_ADD_NEW_USER_GROUP_SIDEBAR';
export const CHANGE_USER_GROUP_PERMISSION = 'CHANGE_USER_GROUP_PERMISSION';
export const ADD_USER_GROUP_TO_STATE = 'ADD_USER_GROUP_TO_STATE';
export const SET_USER_GROUPS = 'SET_USER_GROUPS';
export const SET_USER_GROUP = 'SET_USER_GROUP';

// Success
export const SUCCESS_MESSAGE = 'SUCCESS_MESSAGE';

// Errors
export const ERROR_MESSAGE = 'ERROR_MESSAGE';

//Action Creator
export const toggleAddNewUserGroupSidebar = opened => ({
    type: SHOW_ADD_NEW_USER_GROUP_SIDEBAR,
    payload: opened,
});

export const setUserGroups = payload => ({
    type: SET_USER_GROUPS,
    payload,
});

export const setUserGroup = payload => ({
    type: SET_USER_GROUP,
    payload,
});

export const addUserGroupToState = payload => ({
    type: ADD_USER_GROUP_TO_STATE,
    payload,
});

// Success action creators
export const setSuccessMessage = payload => ({
    type: SUCCESS_MESSAGE,
    payload,
});

// Error action creators
export const setErrorMessage = payload => ({
    type: ERROR_MESSAGE,
    payload,
});

export const changePermission = (userGroup, permission) => {
    const payload = RoboadvisorService.deepCopy(userGroup);

    if (payload.permissions && payload.permissions.includes(permission)) {
        const index = payload.permissions.indexOf(permission);
        payload.permissions.splice(index, 1);
    } else {
        payload.permissions.push(permission);
    }

    return {
        type: CHANGE_USER_GROUP_PERMISSION,
        payload,
    };
};

export const getAllUserGroups = () => {
    const api = new ApiRequests();

    return dispatch => {
        api.getUserGroups()
            .then(res => {
                dispatch(setUserGroups(get(res, 'data', [])));
            })
            .catch(err => {
                const [errorList] = axiosErrorHandler(err);
                dispatch(setErrorMessage(errorList));
            });
    };
};

export const addNewUserGroup = data => {
    const api = new ApiRequests();

    return dispatch => {
        api.createUserGroup(data)
            .then(res => {
                const newGroup = res.data;
                newGroup.permissions = [];
                dispatch(setSuccessMessage({ success: 'A new user group successfully created' }));
                dispatch(toggleAddNewUserGroupSidebar(false));
                dispatch(addUserGroupToState(newGroup));
                dispatch(setUserGroup(newGroup));
            })
            .catch(err => {
                const [errorList] = axiosErrorHandler(err);
                dispatch(setErrorMessage(errorList));
            });
    };
};

export const grantPermissions = (userGroupId, permissions) => {
    const api = new ApiRequests();

    return dispatch => {
        api.grantPermissions(userGroupId, permissions)
            .then(res => {
                dispatch(setSuccessMessage({ success: 'Permissions to the group granted' }));
                dispatch(getAllUserGroups());
            })
            .catch(err => {
                const [errorList] = axiosErrorHandler(err);
                dispatch(setErrorMessage(errorList));
            });
    };
};

export const deleteUserGroup = id => {
    const api = new ApiRequests();

    return dispatch => {
        api.deleteUserGroup(id)
            .then(res => {
                dispatch(setSuccessMessage({ success: 'User group successfully deleted' }));
                dispatch(getAllUserGroups());
                dispatch(setUserGroup(null));
            })
            .catch(err => {
                const [errorList] = axiosErrorHandler(err);
                dispatch(setErrorMessage(errorList));
            });
    };
};
