import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import GeneralMessage from '../../components/UI/GeneralMessage';
import ActiveChatsPanel from '../../modules/liveChat/ActiveChatsPanel';
import AssignedClientsPanel from '../../modules/liveChat/AssignedClientsPanel';
import AwaitingClientsPanel from '../../modules/liveChat/AwaitingClientsPanel';
import LiveChatTemplatePanel from '../../modules/liveChat/LiveChatTemplatePanel';
import LiveChatWindowPanel from '../../modules/liveChat/LiveChatWindowPanel';
import {
    clearInactivityTimer,
    clearSelectedConversation,
    initInactivityTimer,
    setSuccessMessage,
    setGeneralErrorList,
} from '../../store/actions/liveChatActions';

const LiveChat = ({
    generalErrorList,
    successMessage,
    initInactivityTimer,
    clearInactivityTimer,
    clearSelectedConversation,
    setSuccessMessage,
    setGeneralErrorList,
}) => {
    useEffect(() => {
        initInactivityTimer();
        return () => {
            clearInactivityTimer();
            clearSelectedConversation();
            setSuccessMessage(null);
            setGeneralErrorList([]);
        };
    }, [
        clearInactivityTimer,
        initInactivityTimer,
        setSuccessMessage,
        setGeneralErrorList,
        clearSelectedConversation,
    ]);

    return (
        <>
            <div
                className="animated fadeIn"
                style={{ height: 'calc(100vh - 200px)', minHeight: '600px', overflow: 'hidden' }}
            >
                <GeneralMessage
                    className="c-msg"
                    successMessage={successMessage}
                    errorList={generalErrorList}
                />
                <div className="d-flex flex-row" style={{ height: '100%' }}>
                    <div
                        className="d-flex flex-column "
                        style={{ border: '1px solid #cccccc', flex: '1 0 150px' }}
                    >
                        <ActiveChatsPanel />
                        <AwaitingClientsPanel />
                        <AssignedClientsPanel />
                    </div>
                    <div
                        className="d-flex flex-column"
                        style={{ border: '1px solid #cccccc', flex: '3 0 320px' }}
                    >
                        <LiveChatWindowPanel />
                    </div>
                    <div
                        className="pt-2"
                        style={{ border: '1px solid #cccccc', flex: '1 0 150px' }}
                    >
                        <LiveChatTemplatePanel />
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = state => {
    return {
        generalErrorList: state.liveChat.errorList,
        successMessage: state.liveChat.successMessage,
    };
};

const mapDispatchToProps = {
    initInactivityTimer: initInactivityTimer,
    clearInactivityTimer: clearInactivityTimer,
    clearSelectedConversation: clearSelectedConversation,
    setGeneralErrorList: setGeneralErrorList,
    setSuccessMessage: setSuccessMessage,
};
export default connect(mapStateToProps, mapDispatchToProps)(LiveChat);
