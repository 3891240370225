import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Control from "../../components/UI/Control/Control";
import GeneralMessage from "../../components/UI/GeneralMessage";
import useFormLogin from "../../hooks/useFormLogin";
import melindaLogo from "../../images/melinda_logo.png";
import { getAppFeatures } from "../../store/actions/appActions";
import "./Login.css";
import LoginFormWarningMessage from "./LoginFormWarningMessage";

const LoginForm = props => {
    const { registerValidationFor, onSubmit, generalErrorList, fieldErrorFor, getWarningMessage } =
        useFormLogin({
            getCartData: props.getCartData,
            onSuccess: props.onSuccess,
            getAppFeatures: props.getAppFeatures,
        });

    return (
        <form noValidate id="loginForm" onSubmit={onSubmit}>
            <div className="text-center">
                <img src={melindaLogo} alt="Melinda" />
            </div>
            <p className="text-center login-text">Powered by Melinda®</p>
            <GeneralMessage className="c-msg" errorList={generalErrorList} />
            <Control
                elementConfig={{ autoFocus: true }}
                error={fieldErrorFor.username?.message}
                registerRef={registerValidationFor.username}
                name="username"
                type="email" //needed for saving username in browser
                placeholder="Username *"
                id="username"
            />
            <Control
                error={fieldErrorFor.password?.message}
                registerRef={registerValidationFor.password}
                name="password"
                type="password"
                placeholder="Password *"
                id="password"
            />
            <LoginFormWarningMessage message={getWarningMessage()} />
            <div className="row">
                <div className={"col-6"}>
                    <button className="btn btn-primary px-4" type="submit">
                        Login
                    </button>
                </div>
            </div>
        </form>
    );
};

const mapDispatchToProps = { getAppFeatures };

export default connect(null, mapDispatchToProps)(withRouter(LoginForm));
