import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import GeneralMessage from '../../components/UI/GeneralMessage';
import SupervisedChatsPanel from '../../modules/liveChatSuperviseLiveChat/SupervisedChatsPanel';
import SuperviseLiveChatWindowPanel from '../../modules/liveChatSuperviseLiveChat/SuperviseLiveChatWindowPanel';
import { setGeneralErrorList } from '../../store/actions/superviseLiveChatActions';

const LiveChatSuperviseLiveChat = ({
    generalErrorList,
    selectedConversationId,
    setGeneralErrorList,
}) => {
    useEffect(() => {
        return () => {
            setGeneralErrorList([]);
        };
    }, [setGeneralErrorList]);

    return (
        <div
            className="animated fadeIn d-flex flex-row"
            style={{ height: 'calc(100vh - 200px)', minHeight: '600px' }}
        >
            <GeneralMessage className="c-msg" errorList={generalErrorList} />
            <div style={{ border: '1px solid #cccccd', flex: '5 0 200px', overflow: 'hidden' }}>
                <SupervisedChatsPanel />
            </div>
            <div
                className="d-flex flex-column"
                style={{ border: '1px solid #cccccd', flex: '12 0 400px' }}
            >
                <SuperviseLiveChatWindowPanel />
            </div>
            <div style={{ flex: '20 0 1px' }}></div>
        </div>
    );
};

const mapStateToProps = state => ({
    generalErrorList: state.superviseLiveChat.generalErrorList,
    selectedConversationId: state.superviseLiveChat.selectedConversationId,
});

const mapDispatchToProps = {
    setGeneralErrorList: setGeneralErrorList,
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveChatSuperviseLiveChat);
