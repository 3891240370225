const summaryReportFilterSchema = register => {
    return {
        begin: () => register(),
        end: () => register(),
        intent: () => register(),
        channel: () => register(),
        reportCategory: () => register(),
        groupBy: () => register(),
    };
};

export default summaryReportFilterSchema;
