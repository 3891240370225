import GeneralMessage from '../../components/UI/GeneralMessage';
import Grid from '../../components/UI/Grid/Grid';

const LiveChatActiveTemplates = ({ hook }) => {
    const {
        successMessage,
        generalErrorList,
        activeTemplatesGridData,
        activeTemplatesGridHeaders,
        editClickHandler,
        onPutForRemoval,
    } = hook;

    const element = (
        <div className="animated fadeIn">
            <GeneralMessage
                className="c-msg"
                successMessage={successMessage}
                errorList={generalErrorList}
            />

            <div className="container-fluid card p-4 mb-5">
                <div className="row">
                    <div className="col-12">
                        <h2 className="display-5">Active templates</h2>
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Grid
                            columns={activeTemplatesGridHeaders}
                            data={activeTemplatesGridData}
                            editAction={editClickHandler}
                            deleteAction={onPutForRemoval}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
    return element;
};

export default LiveChatActiveTemplates;
