import React from 'react';
import { connect } from 'react-redux';
import { acceptConversation } from '../../store/actions/liveChatActions';
import LiveChatCard from './LiveChatCard';

const AwaitingClientsPanel = ({ awaitingClients, acceptConversation }) => {
    return (
        <>
            <div
                className="d-flex justify-content-between flex-row align-items-center p-2"
                style={{ borderBottom: '1px solid #cccccc', height: '50px' }}
            >
                <h2 className="display-5" style={{ marginBottom: 0 }}>
                    Queue
                </h2>
            </div>
            <div style={{ minHeight: 'calc(20% - 50px)', overflowY: 'auto' }}>
                {Array.isArray(awaitingClients) && awaitingClients.length ? (
                    <div className="d-flex flex-column">
                        <LiveChatCard
                            sessionId={awaitingClients[0].sessionID}
                            conversationId={awaitingClients[0].conversationID}
                            onClick={() => acceptConversation(awaitingClients[0].conversationID)}
                        />
                        {awaitingClients.length - 1 > 0 ? (
                            <p className="ml-2">{`And ${
                                awaitingClients.length - 1
                            } more conversations in queue.`}</p>
                        ) : null}
                    </div>
                ) : (
                    <p className="ml-2 mt-1">No pending conversations.</p>
                )}
            </div>
        </>
    );
};

const mapStateToProps = state => {
    return {
        awaitingClients: state.liveChat.awaitingClients,
    };
};

const mapDispatchToProps = {
    acceptConversation: acceptConversation,
};

export default connect(mapStateToProps, mapDispatchToProps)(AwaitingClientsPanel);
