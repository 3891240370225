import get from 'lodash/get';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import permissionsSchema from '../schemas/permissionsSchema';
import userGroupSchema from '../schemas/userGroupSchema';
import validationService from '../services/validationService';

const useUserGroups = ({
    getAllUserGroups,
    addNewUserGroup,
    userGroup,
    grantPermissions,
    // success
    successMessage,
    // error
    errorMessage,
}) => {
    const { register, handleSubmit, errors, control } = useForm(),
        [generalErrorList, setGeneralErrorList] = useState([]),
        validator = validationService(),
        [successMsg, setSuccessMsg] = useState(null),
        onAddUserGroupSubmit = data => {
            addNewUserGroup({ name: get(data, 'groupName', '') });
        },
        onSavePermissions = data => {
            grantPermissions(get(userGroup, 'id', -1), {
                permissions: get(userGroup, 'permissions', []),
            });
        };

    useEffect(() => {
        getAllUserGroups();
    }, [getAllUserGroups]);

    // Success handling from store
    useEffect(() => {
        setSuccessMsg(successMessage);
    }, [successMessage]);

    // Error handling from store
    useEffect(() => {
        setGeneralErrorList(errorMessage);
    }, [errorMessage]);

    return {
        onAddUserGroupSubmit: handleSubmit(
            onAddUserGroupSubmit,
            validator.extractErrorsFromInvalidForm(setGeneralErrorList)
        ),
        onSavePermissions: handleSubmit(
            onSavePermissions,
            validator.extractErrorsFromInvalidForm(setGeneralErrorList)
        ),
        registerValidationFor: userGroupSchema(register),
        registerValidationForPermissions: permissionsSchema(register),
        fieldErrorFor: errors,
        generalErrorList,
        successMsg,
        control,
    };
};

export default useUserGroups;
