import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import GeneralMessage from '../../components/UI/GeneralMessage';
import ApiRequests from '../../http/ApiRequests';
import axiosErrorHandler from '../../http/AxiosErrorHandler';
import DashboardTile from '../../modules/liveChatDashboard/DashboardTile';
import { formatOperatorReportData } from '../../reports/reports';

const LiveChatOperatorDashboard = ({ currentUser }) => {
    const [generalErrorList, setGeneralErrorList] = useState([]);
    const [api] = useState(new ApiRequests());
    const [reportData, setReportData] = useState(null);
    const [dataPeriod, setDataPeriod] = useState(null);
    const startOfTheDay = useMemo(() => {
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        return date.toISOString();
    }, []);
    const filter = useMemo(
        () => ({
            period: {
                begin: startOfTheDay,
                end: new Date().toISOString(),
            },
            username: currentUser?.username,
        }),
        [currentUser, startOfTheDay]
    );

    useEffect(() => {
        api.getLiveChatReportForOperator(filter)
            .then(response => {
                if (response.data) {
                    const d = response.data;
                    setDataPeriod(d.period);
                    const formattedDataList = formatOperatorReportData(d);
                    setReportData(formattedDataList);
                }
            })
            .catch(error => {
                const [errorList] = axiosErrorHandler(error);
                setGeneralErrorList(errorList);
            });
    }, [api, filter]);

    return (
        <>
            <GeneralMessage className="c-msg" errorList={generalErrorList} />
            <div className="container-fluid  animated fadeIn card p-4">
                {dataPeriod && Array.isArray(reportData) && reportData.length ? (
                    <>
                        <div className="row">
                            <div className="col-12">
                                <h2 className="display-6 mb-4" style={{ fontWeight: 600 }}>
                                    Live chat report for today:
                                </h2>
                            </div>
                        </div>
                        <div className="row">
                            {reportData.map((data, index) => (
                                <DashboardTile
                                    key={index}
                                    label={data.label}
                                    value={data.value}
                                    unit={data.unit}
                                />
                            ))}
                        </div>
                    </>
                ) : null}
            </div>
        </>
    );
};

const mapStateToProps = state => ({
    currentUser: state.auth.currentUser,
});
export default connect(mapStateToProps, null)(LiveChatOperatorDashboard);
