import {
    INCREMENT_NOTIFICATION_COUNTER,
    INCREMENT_PROFILE_COLOR_COUNTER,
    SET_AWAITING_CLIENTS,
    SET_CONVERSATIONS,
    SET_INCOMMING_MESSAGE,
    SET_SELECTED_CONVERSATION_ID,
    SET_SUCCESS_MESSAGE,
    SET_TEMPLATE,
    SET_WS_CLIENT,
    SET_WS_CLIENT_FOR_AWAITING_CONVERSATIONS,
    INCREMENT_NOTIFICATION_COUNTER_WITH_CONVERSATION_ID,
    SET_LIVE_CHAT_ERROR_LIST,
    SET_INACTIVITY_TIMER_ID,
    SET_INACTIVITY_DIALOG_IS_VISIBLE,
    SET_WS_CLIENT_FOR_ASSIGNED_CONVERSATIONS,
    SET_ASSIGNED_CLIENTS,
    SET_LIVE_CHAT_MESSAGE_POLLING_INTERVAL_ID,
    SET_LAST_POLLING_STAMP,
    REMOVE_MESSAGE,
    SET_MESSAGE_STATUS,
} from '../actions/liveChatActions';

const INIT_STATE = {
    conversations: [],
    profileColorCounter: 0,
    selectedConversationId: 0,
    newMessageArrived: 0,
    notificationCounter: 0,
    wsClientForAwaitingClients: null,
    wsClientForAssignedClients: null,
    awaitingClients: [],
    assignedClients: [],
    template: null,
    wsClient: null,
    errorList: [],
    successMessage: null, //{success: string}
    inactivityTimerId: 0,
    inactivityDialogIsVisible: false,
    liveChatMessagePollingIntervalId: -1,
    lastPollingTimestamp: new Date().toISOString(),
};

const liveChatReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_LIVE_CHAT_ERROR_LIST:
            return {
                ...state,
                errorList: action.payload,
            };
        case SET_SELECTED_CONVERSATION_ID:
            const newConversations = [...state.conversations];
            // clear unread messages for this conversation
            newConversations.some(conv => {
                if (conv.id === action.payload) {
                    conv.unreadMessagesCount = 0;
                    return true;
                } else {
                    return false;
                }
            });
            return {
                ...state,
                conversations: newConversations,
                selectedConversationId: action.payload,
            };
        case INCREMENT_PROFILE_COLOR_COUNTER:
            return {
                ...state,
                profileColorCounter: action.payload
                    ? action.payload
                    : state.profileColorCounter + 1,
            };
        case SET_CONVERSATIONS:
            if (
                Array.isArray(state.conversations) &&
                state.conversations.length &&
                Array.isArray(action.payload) &&
                action.payload.length === 0
            ) {
                state.conversations.forEach(conv => {
                    if (conv.wsClient) {
                        conv.wsClient.deactivate();
                    }
                });
            }
            return { ...state, conversations: action.payload };
        case SET_SUCCESS_MESSAGE:
            return { ...state, successMessage: action.payload };
        case SET_TEMPLATE:
            // init new object on each change to cause new render even if
            // the same template was used twice in a row
            return { ...state, template: { ...action.payload } };
        case SET_WS_CLIENT_FOR_AWAITING_CONVERSATIONS:
            if (state.wsClientForAwaitingClients) {
                state.wsClientForAwaitingClients.deactivate();
            }
            return { ...state, wsClientForAwaitingClients: action.payload };
        case SET_WS_CLIENT_FOR_ASSIGNED_CONVERSATIONS:
            if (state.wsClientForAssignedClients) {
                state.wsClientForAssignedClients.deactivate();
            }
            return { ...state, wsClientForAssignedClients: action.payload };
        case SET_WS_CLIENT:
            if (state.wsClient) {
                state.wsClient.deactivate();
            }
            return { ...state, wsClient: action.payload };
        case SET_AWAITING_CLIENTS:
            return { ...state, awaitingClients: action.payload };
        case SET_ASSIGNED_CLIENTS:
            return { ...state, assignedClients: action.payload };
        case INCREMENT_NOTIFICATION_COUNTER:
            return { ...state, notificationCounter: state.notificationCounter + 1 };
        case INCREMENT_NOTIFICATION_COUNTER_WITH_CONVERSATION_ID:
            if (state.selectedConversationId === action.payload) {
                return { ...state };
            } else {
                return { ...state, notificationCounter: state.notificationCounter + 1 };
            }
        case SET_INCOMMING_MESSAGE:
            const conversationsWithNewMessage = [...state.conversations];
            conversationsWithNewMessage.forEach(chatW => {
                if (chatW.id === action.payload.chatId) {
                    chatW.messages.push({
                        ...action.payload,
                        id: action.payload.id,
                        content: action.payload.messageText,
                        customerId: action.payload.sessionId,
                        type: 'TEXT',
                        messageTs: action.payload.messageTs,
                    });
                    if (state.selectedConversationId !== chatW.id) {
                        chatW.unreadMessagesCount += 1;
                    }
                }
            });
            return {
                ...state,
                conversations: conversationsWithNewMessage,
            };
        case REMOVE_MESSAGE:
            const conversations = [...state.conversations];
            const conversationId = action.payload.conversationId;
            const messageId = action.payload.messageId;
            conversations.forEach(chatW => {
                if (chatW.id === conversationId) {
                    chatW.messages = chatW.messages.filter(message => message.id !== messageId);
                }
            });
            return {
                ...state,
                conversations: conversations,
            };
        case SET_MESSAGE_STATUS:
            const _conversations = [...state.conversations];
            const _conversationId = action.payload.conversationId;
            const _messageId = action.payload.messageId;
            const sendingStatus = action.payload.sendingStatus;
            const conversation = _conversations.find(conv => conv.id === _conversationId);
            if (conversation) {
                const message = conversation.messages.find(message => message.id === _messageId);
                if (message) {
                    message.sendingStatus = sendingStatus;
                }
            }
            return {
                ...state,
                conversations: _conversations,
            };
        case SET_INACTIVITY_TIMER_ID:
            return { ...state, inactivityTimerId: action.payload };
        case SET_INACTIVITY_DIALOG_IS_VISIBLE:
            return { ...state, inactivityDialogIsVisible: action.payload };
        case SET_LIVE_CHAT_MESSAGE_POLLING_INTERVAL_ID:
            return { ...state, liveChatMessagePollingIntervalId: action.payload };
        case SET_LAST_POLLING_STAMP:
            return { ...state, lastPollingTimestamp: action.payload };
        default:
            return { ...state };
    }
};

export default liveChatReducer;
