import { Button } from 'primereact/button';
import Control from '../../components/UI/Control/Control';
import GeneralMessage from '../../components/UI/GeneralMessage';

const AddUserGroupForm = ({ hook }) => {
    const { onAddUserGroupSubmit, generalErrorList, registerValidationFor, fieldErrorFor } = hook;

    return (
        <form noValidate id="userGroupForm" onSubmit={onAddUserGroupSubmit}>
            <GeneralMessage className="c-msg" errorList={generalErrorList} />
            <Control
                error={fieldErrorFor.groupName?.message}
                registerRef={registerValidationFor.groupName}
                name="groupName"
                type="text"
                placeholder="Group name *"
                id="groupName"
            />

            <Button
                type="submit"
                className="add-btn"
                icon="fa fa-plus"
                label="Add"
                tooltipOptions={{ position: 'top' }}
            />
        </form>
    );
};

export default AddUserGroupForm;
