import PageRootNav from '../components/PageRootNav/PageRootNav';
import ConversRepGrid from '../modules/conversRepData/ConversRepGrid';

const ConversRepData = () => {
    return (
        <>
            <PageRootNav label='Conversation Reports'/>
            <ConversRepGrid />
        </>
    );
};

export default ConversRepData;
