import SideNav, { NavIcon, NavItem, NavText } from '@trendmicro/react-sidenav';
// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import flatten from 'lodash/flatten';
import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import useLogout from '../../hooks/useLogout';
import { getAppMenuItems } from '../../menu/appMenu';
import { routes } from '../../routes/Routes';
import { setSidebarOpened } from '../../store/actions/sidebarActions';
import './Sidebar.css';

const Sidebar = ({ currentUser, ...props }) => {
    const history = useHistory();
    const logout = useLogout();
    const navItemClicked = useCallback(
        item => () => {
            if (item.command) item.command({ logout });
        },
        [logout]
    );

    const buildMenu = useCallback(
        item => {
            const nestedMenuItems = item.items?.map(subItem =>
                subItem.items ? [subItem, ...subItem.items] : subItem
            );
            const flattenedSubitems = flatten(nestedMenuItems);
            return (
                <NavItem eventKey={item.key} key={item.key} onClick={navItemClicked(item)}>
                    {item.icon ? (
                        <NavIcon>
                            <i className={item.icon} style={{ fontSize: '1.75em' }} />
                        </NavIcon>
                    ) : null}

                    <NavText>{item.label}</NavText>
                    {flattenedSubitems
                        ? flattenedSubitems.map(subitem => (
                              <NavItem
                                  eventKey={subitem.key}
                                  key={subitem.key}
                                  disabled={subitem.has3rdLvl ? true : false}
                                  onClick={navItemClicked(item)}
                                  navitemClassName={
                                      subitem.has3rdLvl
                                          ? 'menu-header'
                                          : subitem.is3rdLevel
                                          ? 'third-level-nav-item'
                                          : ''
                                  }
                              >
                                  <NavText>{subitem.label}</NavText>
                              </NavItem>
                          ))
                        : null}
                </NavItem>
            );
        },
        [navItemClicked]
    );

    const menuItems = useMemo(() => getAppMenuItems(currentUser.permissions), [currentUser]);
    const menuBuilder = useMemo(() => menuItems.map(buildMenu), [menuItems, buildMenu]);
    const getAllRoutesKeys = () => {
        const allKeys = [];
        for (const key in routes) {
            if (Object.hasOwnProperty.call(routes, key)) {
                allKeys.push(routes[key].path);
            }
        }
        return allKeys;
    };
    const selectMenuItem = selected => {
        const allRoutesPaths = getAllRoutesKeys();
        if (allRoutesPaths.includes(selected)) props.history.replace(selected);
    };

    return (
        <div className="app-sidebar">
            <SideNav
                onToggle={opened => {
                    props.setSidebarOpened(opened);
                }}
                onSelect={selected => selectMenuItem(selected)}
                expanded={props.sidebarOpened}
            >
                <SideNav.Toggle />
                <SideNav.Nav
                    className={props.sidebarOpened ? 'scrollable-sidebar' : ''}
                    selected={history?.location?.pathname}
                >
                    {menuBuilder}
                </SideNav.Nav>
            </SideNav>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        sidebarOpened: state.sidebar.sidebarOpened,
        currentUser: state.auth.currentUser,
    };
};

const mapDispatchToProps = {
    setSidebarOpened: setSidebarOpened,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));
