import Tile from './Tile';
import RoboadvisorService from '../../services/roboadvisor.service';

const ReportData = ({ hook }) => {
    const { reportData } = hook;
    
    return reportData ? (
        <div className="container-fluid animated fadeIn card p-4">
            <div className="row">
                <div className="col-12">
                    <h2 className="display-6 mb-4">
                        Summary report from{' '}
                        {RoboadvisorService.formatDateTime(reportData.period.begin, RoboadvisorService.dateTimeMomentFormatMinutes)} to&nbsp;
                        {RoboadvisorService.formatDateTime(reportData.period.end, RoboadvisorService.dateTimeMomentFormatMinutes)} as per the
                        selected categories:
                    </h2>
                </div>
            </div>
            <div className="row">
                {Object.keys(reportData).map((key, index) =>
                    key !== 'period' ? (
                        <Tile key={index} type={key} value={reportData[key]} />
                    ) : null
                )}
            </div>
        </div>
    ) : null;
};

export default ReportData;
