import { Button } from 'primereact/button';
import TextInput from '../../components/UI/Control/TextInput';
import GeneralMessage from '../../components/UI/GeneralMessage';

const MultilingualPhraseForm = ({ hook }) => {
    const {
        generalErrorList,
        successMessage,
        onSubmit,
        multilingualPhrase,
        onMultilingualPhraseChange,
    } = hook;

    const formElement = (
        <div className="animated fadeIn">
            <GeneralMessage
                className="c-msg"
                successMessage={successMessage}
                errorList={generalErrorList}
            />
            <form
                noValidate
                id="profileInfoForm"
                onSubmit={e => {
                    e.preventDefault();
                    onSubmit();
                }}
            >
                <div className="container-fluid card p-4 mb-5 animated fadeIn">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="display-5">Add multilingual phrase</h2>
                            <hr />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            <TextInput
                                label="Multilingual Phrase"
                                info="Case-insensitive user phrase not subject to language recognition. Should be entered in Latin alphabet."
                                value={multilingualPhrase}
                                onChange={onMultilingualPhraseChange}
                            />
                        </div>
                        <div className="col-2 d-flex align-items-center">
                            <Button
                                style={{ marginTop: '8px' }}
                                type="button"
                                className="add-btn"
                                icon="fa fa-plus"
                                tooltip={'Add phrase'}
                                label="Add"
                                disabled={!multilingualPhrase}
                                tooltipOptions={{ position: 'top' }}
                                onClick={onSubmit}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );

    return formElement;
};

export default MultilingualPhraseForm;
