import get from 'lodash/get';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import ApiRequests from '../http/ApiRequests';
import axiosErrorHandler from '../http/AxiosErrorHandler';
import intentReportFilterSchema from '../schemas/intentReportFilterSchema';
import validationService from '../services/validationService';

const useIntentReport = () => {
    const api = useMemo(() => new ApiRequests(), []);
    const validator = validationService();

    const filterFields = useMemo(
        () => ({
            dateFrom: 'dateFrom',
            dateTo: 'dateTo',
            name: 'name',
            direction: 'direction',
            begin: 'begin',
            end: 'end',
        }),
        []
    );

    const initialGridMeta = useMemo(
        () => ({
            limit: 10,
            offset: 0,
            total: 0,
        }),
        []
    );

    const orderOptions = [
        { label: 'Ascending', value: 'ASCENDING' },
        { label: 'Descending', value: 'DESCENDING' },
    ];

    const defaultOrderDirection = orderOptions[0].value;

    const intentGridHeaders = [
        { name: 'Intent Name', key: 'intentName', filter: false },
        { name: 'Average Confidence', key: 'averageConfidence', filter: false },
        { name: 'Total Count', key: 'totalCount', filter: false },
    ];

    const messagesGridHeaders = [
        { name: 'Timestamp', key: 'timestamp', filter: false, formatter: 'datetime' },
        { name: 'Confidence', key: 'confidence', filter: false },
        { name: 'Message', key: 'message', filter: false },
    ];

    const initialGridFilter = useMemo(
        () => ({
            begin: moment(moment().subtract(7, 'days').startOf('day')._d).utc().format(),
            direction: defaultOrderDirection,
        }),
        [defaultOrderDirection]
    );

    const [intentGridData, setIntentGridData] = useState([]);
    const [gridFilter, setGridFilter] = useState({});
    const [gridMeta, setGridMeta] = useState({ ...initialGridMeta });
    const [generalErrorList, setGeneralErrorList] = useState([]);
    const [selectedIntent, setSelectedIntent] = useState(null);
    const [messagesGridData, setMessagesGridData] = useState([]);
    const { register, control, handleSubmit, setValue, errors, getValues } = useForm();

    const showInitialStartDate = useCallback(() => {
        if (initialGridFilter.begin) {
            const startDate = moment(initialGridFilter.begin).startOf('day')._d;
            setValue(filterFields.dateFrom, startDate);
        }
    }, [filterFields, setValue, initialGridFilter]);

    const getIntentReport = useCallback(
        (filter, meta) => {
            api.fetchIntentReport({ ...filter, ...meta })
                .then(res => {
                    setIntentGridData(processData(get(res, 'data.intents', [])));
                    setGridMeta({
                        limit: get(res, 'data.limit', 5),
                        offset: get(res, 'data.offset', meta.offset),
                        total: get(res, 'data.total', 0),
                    });
                    setGridFilter(filter);
                })
                .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setGeneralErrorList(errorList);
                });
        },
        [api]
    );

    const processData = data => {
        return data.map(item => ({
            intentName: item.intentName,
            averageConfidence: item.averageConfidence,
            totalCount: item.totalCount,
            id: item.intentID,
        }));
    };

    const onGridPage = event => {
        getIntentReport(gridFilter, {
            limit: event.rows,
            offset: event.first,
            page: event.page + 1,
        });
    };

    const onSubmit = data => {
        const filters = {};
        if (
            data[filterFields.dateFrom] &&
            data[filterFields.dateTo] &&
            moment(data[filterFields.dateTo]).isBefore(data[filterFields.dateFrom])
        ) {
            setGeneralErrorList([validator.errorMessages.INCORRECT_SEARCH_PERIOD]);
            return;
        }

        if (data[filterFields.dateFrom]) {
            filters[filterFields.begin] = moment(data[filterFields.dateFrom]).utc().format();
        }
        if (data[filterFields.dateTo]) {
            filters[filterFields.end] = moment(data[filterFields.dateTo]).utc().format();
        }
        if (data[filterFields.name]) {
            filters[filterFields.name] = data[filterFields.name];
        }
        if (data[filterFields.direction]) {
            filters[filterFields.direction] = data[filterFields.direction];
        }

        getIntentReport(filters, initialGridMeta);
    };

    const changeSelectedIntent = useCallback(
        intent => {
            setSelectedIntent(intent);
            if (selectedIntent === intent) {
                setSelectedIntent(null);
                setMessagesGridData([]);
            } else {
                api.getIntentMessages({
                    id: intent.id,
                    begin: getValues('dateFrom'),
                    end: getValues('dateTo'),
                })
                    .then(response => {
                        if (response && Array.isArray(response.data?.entries)) {
                            setMessagesGridData(response.data.entries);
                        }
                    })
                    .catch(error => {
                        const [errorList] = axiosErrorHandler(error);
                        setGeneralErrorList(errorList);
                    });
            }
        },
        [api, getValues, selectedIntent]
    );

    useEffect(() => {
        showInitialStartDate();
        getIntentReport(initialGridFilter, initialGridMeta);
    }, [getIntentReport, showInitialStartDate, initialGridFilter, initialGridMeta]);

    return {
        gridMeta,
        intentGridData,
        intentGridHeaders,
        defaultOrderDirection,
        onGridPage,
        onSubmit: handleSubmit(
            onSubmit,
            validator.extractErrorsFromInvalidForm(setGeneralErrorList)
        ),
        registerValidationFor: intentReportFilterSchema(register),
        fieldErrorFor: errors,
        control,
        orderOptions,
        generalErrorList,
        selectedIntent,
        changeSelectedIntent,
        messagesGridData,
        messagesGridHeaders,
    };
};

export default useIntentReport;
