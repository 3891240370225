import { Button } from 'primereact/button';

const ViewInMapsButton = ({ props }) => {
    const googleMapsUrlPattern = 'https://www.google.com/maps/search/?api=1&query=';
    const coordinatesUrl = (lat, lon) => {
        return `${googleMapsUrlPattern}${lat},${lon}`;
    };

    return (
        <Button
            type="button"
            icon="fa fa-map-marker"
            tooltip={'View in Google Maps'}
            onClick={() => window.open(coordinatesUrl(props.latitude, props.longitude), '_blank')}
            tooltipOptions={{ position: 'top' }}
        />
    );
};

export default ViewInMapsButton;
