import get from 'lodash/get';
import ApiRequests from '../../http/ApiRequests';
import { disableLiveChat } from './liveChatActions';
import { disableSuperviseLiveChat } from './superviseLiveChatActions';
// import axiosErrorHandler from '../../http/AxiosErrorHandler';

//Action Types
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

// Success
export const SUCCESS_MESSAGE = 'SUCCESS_MESSAGE';

// Errors
export const ERROR_MESSAGE = 'ERROR_MESSAGE';

export const setCurrentUser = payload => ({
    type: SET_CURRENT_USER,
    payload,
});

export const getCurrentUser = () => {
    const api = new ApiRequests();

    return dispatch => {
        api.userInfo()
            .then(res => {
                dispatch(setCurrentUser(get(res, 'data')));
            })
            .catch(err => {
                // const [errorList] = axiosErrorHandler(err);
                // dispatch(setErrorMessage(errorList));
                // TODO error not handled here!!!
            });
    };
};

export const clearCurrentUser = () => {
    return dispatch => {
        dispatch(setCurrentUser(null));
        dispatch(disableLiveChat());
        dispatch(disableSuperviseLiveChat());
    };
};
