import { Button } from 'primereact/button';
import CheckboxControlled from '../../components/UI/Control/CheckboxControlled';
import GeneralMessage from '../../components/UI/GeneralMessage';
import useBotResponseForm from '../../hooks/useBotResponseForm';
import BotResponse from './BotResponse';

const BotResponseForm = ({ selectedAnswer, setGeneralErrorList }) => {
    const {
        registerValidationFor,
        errors,
        control,
        intentName,
        fields,
        remove,
        onSubmit,
        successMessage,
        generalErrorList,
        deleteWholeAnswer,
        addNewResponse,
        formattedData,
        isDirty,
        searchIntent,
        intentList,
        setValue,
        getValues,
        clearErrors,
    } = useBotResponseForm({ data: selectedAnswer, setGeneralErrorList });

    return (
        <form noValidate>
            <GeneralMessage errorList={generalErrorList} successMessage={successMessage} />

            <div className="row">
                <div className="col-12 d-flex flex-row ">
                    <h2 className="display-5 mr-4">
                        Responses for intent <b>{intentName}</b>
                    </h2>
                    <Button
                        type="button"
                        onClick={deleteWholeAnswer}
                        className="p-button delete-btn"
                        icon="fa fa-trash-o"
                        label="Delete all responses"
                        disabled={Array.isArray(fields) && fields.length === 0}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <input type="hidden" name="intentId" ref={registerValidationFor.intentId()} />
                    <input type="hidden" name="channel" ref={registerValidationFor.channel()} />
                    <input type="hidden" name="language" ref={registerValidationFor.language()} />
                    <CheckboxControlled
                        name="lastAnswer"
                        inLabel
                        control={control}
                        label="Append rating messages"
                        defaultValue={false}
                    />
                </div>
                <div className="col-12">
                    <CheckboxControlled
                        name="requestLiveChat"
                        inLabel
                        control={control}
                        label="Add Request Live Chat messages"
                        defaultValue={false}
                    />
                </div>

                {fields.map((response, index) => (
                    <BotResponse
                        key={response.fieldId}
                        namePrefix={`answers[${index}]`}
                        index={index}
                        registerValidationFor={registerValidationFor}
                        response={response}
                        control={control}
                        errors={errors}
                        removeResponse={remove}
                        data={formattedData}
                        searchIntent={searchIntent}
                        intentList={intentList}
                        setValue={setValue}
                        getValues={getValues}
                        clearErrors={clearErrors}
                    />
                ))}
            </div>

            <section>
                <Button
                    type="button"
                    className="add-btn"
                    onClick={addNewResponse}
                    icon="fa fa-plus-circle"
                    label="ADD NEW RESPONSE"
                    tooltipOptions={{ position: "top" }}
                />
            </section>
            <hr style={{ marginBottom: "70px" }} />
            <div
                className="d-flex flex-row justify-content-center"
                style={{
                    position: "fixed",
                    bottom: "20px",
                    right: "15px",
                    width: "90%",
                }}
            >
                <div
                    className="my-4 py-2 px-4"
                    style={{
                        backgroundColor: "#dedede",
                        width: "500px",
                        boxShadow: "0px -7px 17px -5px #666666",
                    }}
                >
                    <Button
                        className="mr-2 p-button-primary"
                        style={{ minWidth: "200px", width: "100%" }}
                        label="Save Changes"
                        onClick={onSubmit}
                        disabled={!isDirty}
                    />
                </div>
            </div>
        </form>
    );
};

export default BotResponseForm;
