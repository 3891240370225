import { Button } from "primereact/button";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Control from "../../components/UI/Control/Control";
import GeneralMessage from "../../components/UI/GeneralMessage";

const BotResponseConfigPanel = ({ hook, appFeatures }) => {
    const {
        onSubmit,
        successMessage,
        generalErrorList,
        control,
        confidence,
        onConfidenceChanged,
        botResponsesOn,
        onEnabledStateChanged,
        isUpdateButtonDisabled,
        forwarding,
        onForwardingChanged,
        textToSpeech,
        onTextToSpeechChanged,
    } = hook;

    const canSupportTextToSpeech = appFeatures?.textToSpeech === true;

    const formElement = (
        <div className="animated fadeIn">
            <GeneralMessage
                className="c-msg"
                successMessage={successMessage}
                errorList={generalErrorList}
            />
            <form noValidate id="botSettingsForm">
                <div className="container-fluid card p-4 mb-5 animated fadeIn">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="display-5">Configuration</h2>
                            <hr />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            <Control
                                name="respstate"
                                type="inputswitch"
                                labelClass="d-block"
                                control={control}
                                label="Responses ON / OFF"
                                value={botResponsesOn}
                                onChange={e => {
                                    onEnabledStateChanged(e.value);
                                }}
                            />
                        </div>
                        {canSupportTextToSpeech ? (
                            <div className="col-2">
                                <Control
                                    name="texttospeech"
                                    type="inputswitch"
                                    labelClass="d-block"
                                    control={control}
                                    label="Text-to-speech ON / OFF"
                                    value={textToSpeech}
                                    onChange={e => {
                                        onTextToSpeechChanged(e.value);
                                    }}
                                />
                            </div>
                        ) : null}
                        <div className="col-2">
                            <Control
                                name="confidence"
                                type="number"
                                label="Confidence Threshold"
                                info="Value should be a number between 0 and 1"
                                value={confidence}
                                elementConfig={{ value: confidence }}
                                step={0.05}
                                min={0}
                                max={1}
                                onChange={e => {
                                    onConfidenceChanged(e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-2">
                            <Control
                                name="forwardingThreshold"
                                type="number"
                                label="Forwarding Threshold"
                                info="Value should be a number between 0 and 10"
                                value={forwarding}
                                elementConfig={{ value: forwarding }}
                                min={0}
                                max={10}
                                onChange={e => {
                                    onForwardingChanged(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            <Button
                                type="button"
                                className="add-btn"
                                icon="fa fa-check"
                                tooltip={"Confirm"}
                                label="UPDATE"
                                onClick={() => onSubmit()}
                                disabled={isUpdateButtonDisabled()}
                                tooltipOptions={{ position: "top" }}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );

    return formElement;
};

const mapStateToProps = state => ({
    appFeatures: state.app.features,
});

export default connect(mapStateToProps, null)(withRouter(BotResponseConfigPanel));
