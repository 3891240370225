import get from 'lodash/get';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { ListBox } from 'primereact/listbox';
import { useCallback } from 'react';
import BotTrainingItem from '../../components/BotTrainingItem/BotTrainingItem';
import PageRootNav from '../../components/PageRootNav/PageRootNav';
import CheckboxControlled from '../../components/UI/Control/CheckboxControlled';
import TextSearchInput from '../../components/UI/Control/TextSearchInput';
import GeneralMessage from '../../components/UI/GeneralMessage';
import useAddIntent from '../../hooks/useAddIntent';
import useDisableIntentDialog from '../../hooks/useDisableIntentDialog';
import RoboadvisorService from '../../services/roboadvisor.service';
import './AddIntent.css';
import DisableIntentDialog from './DisableIntentDialog';

const AddIntent = () => {
    const {
        questionList,
        selectedIntentId,
        selectedQuestionId,
        selectedIntentName,
        selectedIntentItem,
        inputIntentName,
        inputExampleContent,
        addIntentSectionVisible,
        addQuestionSectionVisible,
        createIntentClicked,
        editIntentClicked,
        intentEnabled,
        addIntentSectionRef,
        addQuestionSectionRef,
        generalErrorList,
        successMessage,
        changeSelectedIntentHandler,
        changeSelectedQuestionHandler,
        changeNewIntentNameHandler,
        changeNewExampleContentHandler,
        addIntentHandler,
        addExampleHandler,
        delIntentHandler,
        delExampleHandler,
        displayHideAddSectionHandler,
        disableIntentHandler,
        editIntentNameHandler,
        searchIntent,
        intentSearchLoading,
        editQuestionInputRef,
        setGeneralErrorList,
        setSuccessMessage,
        reload,
        getValues,
        sortOptions,
        sortedIntentList,
        sortForm,
        onSortFormChange,
        watchSortBy,
    } = useAddIntent();

    const getOptionClassName = option => {
        const status = get(option, 'status', '');
        const enableStatus = get(option, 'enabledStatus', true);
        if (status === 'APPROVED' && !enableStatus) {
            return `disabled ${option.selected ? 'selected' : ''}`;
        }
        return `${status.toLowerCase()} ${option.selected ? 'selected' : ''}`;
    };
    const hook = useDisableIntentDialog({
        setGeneralErrorList,
        setSuccessMessage,
    });

    const itemTemplate = useCallback(option => {
        if (option && option.label) {
            return (
                <div
                    id="pppp"
                    className={getOptionClassName(option)}
                    style={{
                        padding: '0.3rem 0.3rem',
                        wordWrap: 'break-word',
                    }}
                >
                    {option.label}
                </div>
            );
        } else {
            return null;
        }
    }, []);
    return (
        <>
            <PageRootNav label="Add intents and sample questions" />
            <GeneralMessage
                className="c-msg"
                successMessage={successMessage}
                errorList={generalErrorList}
            />
            <div className="container-fluid">
                <DisableIntentDialog hook={hook} reload={reload} />
                <div className="row">
                    <div className="col-5 d-flex justify-content-center align-items-start botTrainItem-list-container">
                        <div className="form-control-block w-100">
                            {searchIntent ? (
                                <TextSearchInput
                                    loading={intentSearchLoading}
                                    searchCallback={searchIntent}
                                    placeholder="Search intent..."
                                />
                            ) : null}
                            <label className="mt-2">List of intents</label>
                            <ListBox
                                style={{ overflow: 'auto' }}
                                listStyle={{ height: '40vh', padding: '0.3rem' }}
                                listClassName="intent-list"
                                options={sortedIntentList}
                                onChange={event => changeSelectedIntentHandler(event.target.value)}
                                itemTemplate={itemTemplate}
                                value={selectedIntentItem}
                            />
                        </div>
                    </div>
                    <div
                        className="col-3 d-flex flex-column justify-content-between"
                        style={{ minHeight: '100%' }}
                    >
                        <div className="d-flex flex-row">
                            <select
                                className="form-control trunkate mr-3"
                                onChange={onSortFormChange}
                                ref={sortForm.register}
                                name="sortBy"
                                defaultValue={getValues('label')}
                            >
                                {sortOptions.map(option => (
                                    <option value={option.value} key={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>

                            <CheckboxControlled
                                name="sortOrderIsDesc"
                                control={sortForm.control}
                                label="Descending"
                                beforeOnChange={onSortFormChange}
                                disabled={!watchSortBy}
                            />
                        </div>
                        <div className="d-flex flex-column" style={{ flex: '0.6' }}>
                            <Button
                                label="Enable/disable multiple intents"
                                onClick={hook ? hook.openDialog : null}
                            />
                            <div
                                className="d-flex justify-content-center align-items-center"
                                style={{ marginTop: '20px' }}
                            >
                                <Button
                                    type="button"
                                    className="info-btn mr-2"
                                    onClick={() => displayHideAddSectionHandler('intent', true)}
                                    icon={
                                        addIntentSectionVisible && createIntentClicked
                                            ? 'fa fa-minus'
                                            : 'fa fa-plus'
                                    }
                                    tooltip={
                                        addIntentSectionVisible && createIntentClicked
                                            ? 'Remove'
                                            : 'Add'
                                    }
                                    tooltipOptions={{ position: 'top' }}
                                />
                                <Button
                                    type="button"
                                    className="mr-2 delete-btn"
                                    onClick={delIntentHandler}
                                    disabled={!selectedIntentId}
                                    icon="fa fa-trash-o"
                                    tooltip={'Delete'}
                                    tooltipOptions={{ position: 'top' }}
                                />
                                <Button
                                    type="button"
                                    className="mr-2 edit-btn"
                                    onClick={() => displayHideAddSectionHandler('intent', false)}
                                    disabled={!selectedIntentId}
                                    icon={
                                        addIntentSectionVisible && editIntentClicked
                                            ? 'fa fa-minus'
                                            : 'fa fa-pencil'
                                    }
                                    tooltip={
                                        addIntentSectionVisible && editIntentClicked
                                            ? 'Remove'
                                            : 'Edit'
                                    }
                                    tooltipOptions={{ position: 'top' }}
                                />

                                <InputSwitch
                                    disabled={!selectedIntentId}
                                    checked={intentEnabled}
                                    onChange={disableIntentHandler}
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        className="col-4 animated fadeIn card p-4 new-botTrainItem__invisible"
                        ref={addIntentSectionRef}
                    >
                        <h6 className="display-6 mb-2">
                            {createIntentClicked ? 'Add intent' : 'Edit Intent'}
                        </h6>
                        <div className="form-control-block">
                            <label>
                                {createIntentClicked
                                    ? 'New intent:'
                                    : `Rename ${selectedIntentName} to:`}
                            </label>
                            <input
                                className="form-control mb-2"
                                type="text"
                                onChange={event => {
                                    changeNewIntentNameHandler(event.target.value);
                                }}
                                onKeyPress={e => {
                                    if (e.key === 'Enter') {
                                        const action = createIntentClicked
                                            ? addIntentHandler
                                            : editIntentNameHandler;
                                        action();
                                    }
                                }}
                                value={inputIntentName ? inputIntentName : ''}
                                placeholder="Intent name"
                            ></input>
                            <Button
                                type="button"
                                className="add-btn"
                                onClick={
                                    createIntentClicked ? addIntentHandler : editIntentNameHandler
                                }
                                disabled={
                                    inputIntentName === '' ||
                                    !/^[a-zа-я][a-zа-я0-9_]*$/.test(inputIntentName)
                                }
                                icon={createIntentClicked ? 'fa fa-plus' : 'fa fa-check'}
                                label={createIntentClicked ? 'Add' : 'Update'}
                                tooltipOptions={{ position: 'top' }}
                            />
                        </div>
                        <p className="hint">
                            Hint: no spaces, no special symbols, Cyrillic or Latin
                        </p>
                        {editIntentClicked ? (
                            <div className="form-control-block-info-content mt-4">
                                <h6 className="display-6 mb-2">Intent info</h6>
                                {selectedIntentItem && selectedIntentItem.uploadedTs
                                    ? `Created: ${RoboadvisorService.formatDateTime(
                                          selectedIntentItem.uploadedTs,
                                          RoboadvisorService.dateMomentFormat
                                      )} by ${selectedIntentItem.uploadedBy}`
                                    : ''}
                                <br />
                                {selectedIntentItem && selectedIntentItem.updatedTs
                                    ? `Last updated: ${RoboadvisorService.formatDateTime(
                                          selectedIntentItem.updatedTs,
                                          RoboadvisorService.dateMomentFormat
                                      )} by ${selectedIntentItem.updatedBy} `
                                    : ''}
                            </div>
                        ) : null}
                    </div>
                </div>
                <hr />
                <BotTrainingItem
                    type="question"
                    listItems={questionList}
                    changeSelection={changeSelectedQuestionHandler}
                    addItem={addExampleHandler}
                    deleteItem={delExampleHandler}
                    inputValue={inputExampleContent}
                    changeInputValue={changeNewExampleContentHandler}
                    disabledButton={!selectedQuestionId}
                    disabledAddButton={!selectedIntentId}
                    visible={addQuestionSectionVisible}
                    toggleVisibility={displayHideAddSectionHandler}
                    addSectionRef={addQuestionSectionRef}
                    createEventStarted={true}
                    editInputRef={editQuestionInputRef}
                    itemTemplate={itemTemplate}
                />
            </div>
        </>
    );
};

export default AddIntent;
