import React, { useCallback, useMemo, useState } from 'react';
import styles from './HoverText.module.css';

// position: left, top, right, bottom, center
// content can be text or jsx
const HoverText = ({ children, positionX, positionY, content, hoverItemStyle }) => {
    const [activeHover, setActiveHover] = useState(false);
    const onMouseEnter = useCallback(() => setActiveHover(true), [setActiveHover]);
    const onMouseLeave = useCallback(() => setActiveHover(false), [setActiveHover]);

    const hoverText = useMemo(() => {
        let positionXClassName = '';
        let marginXClassName = '';
        let positionYClassName = '';
        let marginYClassName = '';
        switch (positionX) {
            case 'left':
                positionXClassName = 'justify-content-start';
                marginXClassName = 'ml-1';
                break;
            case 'right':
                positionXClassName = 'justify-content-end';
                marginXClassName = 'mr-1';
                break;
            case 'center':
            default:
                positionXClassName = 'justify-content-center';
                marginXClassName = '';
                break;
        }

        switch (positionY) {
            case 'top':
                positionYClassName = 'align-items-start';
                marginYClassName = 'mt-1';
                break;
            case 'bottom':
                positionYClassName = 'align-items-end';
                marginYClassName = 'mb-1';
                break;
            case 'center':
            default:
                positionYClassName = 'align-items-center';
                marginYClassName = '';
                break;
        }

        return (
            <div
                className={`${activeHover ? styles.textHover : styles.textHidden} ${
                    styles.overlay
                } d-flex ${positionYClassName} ${positionXClassName}`}
            >
                <span
                    className={`${styles.hoverStyle} ${marginYClassName} ${marginXClassName}`}
                    style={hoverItemStyle}
                >
                    {content ? content : ''}
                </span>
            </div>
        );
    }, [content, activeHover, hoverItemStyle, positionX, positionY]);
    return (
        <div className={styles.container} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {hoverText}
            {children}
        </div>
    );
};

export default HoverText;
