import React, { useCallback, useEffect, useRef } from 'react';
import Control from '../../components/UI/Control/Control';

const RefreshInterval = ({ reload, intervalOptions }) => {
    const refreshIntervalIdRef = useRef(0);
    const setNewInterval = useCallback(
        intervalValue => {
            if (refreshIntervalIdRef.current) {
                clearInterval(refreshIntervalIdRef.current);
            }
            refreshIntervalIdRef.current = setInterval(() => {
                if (reload) {
                    reload();
                }
            }, intervalValue * 1000);
        },
        [refreshIntervalIdRef, reload]
    );

    const onIntervalChange = useCallback(e => setNewInterval(e.target.value), [setNewInterval]);

    useEffect(() => {
        if (
            !refreshIntervalIdRef.current &&
            Array.isArray(intervalOptions) &&
            intervalOptions.length
        ) {
            setNewInterval(intervalOptions[0].value);
        }
    }, [intervalOptions, refreshIntervalIdRef, setNewInterval]);

    return (
        <div className="d-flex flex-row justify-content-start align-items-stretch">
            <Control
                label="Refresh Interval"
                type="select"
                options={intervalOptions}
                defaultValue={
                    Array.isArray(intervalOptions) && intervalOptions.length
                        ? intervalOptions[0]
                        : ''
                }
                onChange={onIntervalChange}
            />
        </div>
    );
};

export default RefreshInterval;
