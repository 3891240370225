import { Button } from 'primereact/button';
import React, { useCallback, useState } from 'react';
import ApiRequests from '../../http/ApiRequests';

const LocationApproveActionPanel = ({ id, onSuccessfulSubmit, onError, gridData }) => {
    const [api] = useState(new ApiRequests());
    const onApprove = useCallback(
        () =>
            api
                .approvePendingLocation(id)
                .then(() => onSuccessfulSubmit(id, true, gridData))
                .catch(error => onError(error)),
        [id, onSuccessfulSubmit, onError, api, gridData]
    );
    const onReject = useCallback(
        () =>
            api
                .rejectPendingLocation(id)
                .then(() => onSuccessfulSubmit(id, false, gridData))
                .catch(error => onError(error)),
        [id, onSuccessfulSubmit, onError, api, gridData]
    );

    return (
        <>
            <Button
                type="button"
                className="add-btn mr-2 mb-2"
                icon="fa fa-check"
                onClick={onApprove}
                label="Approve"
            />
            <Button
                type="button"
                className="delete-btn mr-2 mb-2"
                icon="fa fa-times"
                onClick={onReject}
                label="Reject"
            />
        </>
    );
};

export default LocationApproveActionPanel;
