import moment from 'moment';
import { Button } from 'primereact/button';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Control from '../../components/UI/Control/Control';
import Grid from '../../components/UI/Grid/Grid';
import ApiRequests from '../../http/ApiRequests';
import axiosErrorHandler from '../../http/AxiosErrorHandler';
import { formatOperatorReportDataForSupervisor } from '../../reports/reports';
import RoboadvisorService from '../../services/roboadvisor.service';
import { validator } from '../../services/validationService';
import { downloadBlobFileWithLink } from '../../utils/fileUtils';
import DashboardTile from './DashboardTile';

const OperatorSpecificReport = ({ preselectedOperator, reloadCounter, setGeneralErrorList }) => {
    const [api] = useState(new ApiRequests());
    const [reportData, setReportData] = useState([]);
    const [dataPeriod, setDataPeriod] = useState(null);
    const [statusEntries, setStatusEntries] = useState([]);
    const [operatorOptions, setOperatorOptions] = useState([]);
    const [initGridPagingInfo] = useState({ limit: 10, page: 0 });
    const [lastGridPaginInfo, setLastGridPagingInfo] = useState(initGridPagingInfo);
    const [gridMeta, setGridMeta] = useState(null);
    const [statusHeaders] = useState([
        {
            name: 'From',
            key: 'startTimestamp',
            filter: false,
            formatter: 'datetime',
        },
        {
            name: 'To',
            key: 'endTimestamp',
            filter: false,
            formatter: 'datetime',
        },
        { name: 'Status', key: 'status', filter: false },
    ]);
    //FORM
    const { control, errors, handleSubmit, getValues, register, watch } = useForm();
    const watchPeriodBegin = watch('period.begin');
    const watchPeriodEnd = watch('period.end');
    const registerValidationFor = useMemo(
        () => ({
            begin: () => ({ required: 'Choose a begin date.' }),
            end: () => ({
                validate: value =>
                    !value ||
                    moment(getValues('period.begin')).isBefore(value) ||
                    '"To" date is earlier than "From" date.',
            }),
            username: () => register({ required: 'Select an user.' }),
        }),
        [getValues, register]
    );
    const fieldErrorFor = errors;
    // END FORM
    const loadReport = useCallback(
        filter => {
            api.getLiveChatReportForSupervisor(filter)
                .then(reportDataResponse => {
                    if (reportDataResponse.data) {
                        const data = reportDataResponse.data;
                        setDataPeriod(data.period);
                        const formattedData = formatOperatorReportDataForSupervisor(data);
                        setReportData(formattedData);
                    }
                })
                .catch(error => {
                    const [errorList] = axiosErrorHandler(error);
                    setGeneralErrorList(errorList);
                });
        },
        [api, setGeneralErrorList, setReportData]
    );

    const exportReport = useCallback(
        filter => {
            api.exportLiveChatReportForSupervisor(filter)
                .then(response => {
                    if (response) {
                        downloadBlobFileWithLink(response);
                    }
                })
                .catch(error => {
                    const [errorList] = axiosErrorHandler(error);
                    setGeneralErrorList(errorList);
                });
        },
        [api, setGeneralErrorList]
    );

    const loadUsersByPeriod = useCallback(
        period => {
            api.getUsersByPeriod(period)
                .then(usersResponse => {
                    if (usersResponse.data) {
                        setOperatorOptions(
                            usersResponse.data.map(user => ({
                                label: user.username,
                                value: user.username,
                                id: user.id,
                            }))
                        );
                    }
                })
                .catch(error => {
                    const [errorList] = axiosErrorHandler(error);
                    setGeneralErrorList(errorList);
                });
        },
        [api, setGeneralErrorList]
    );

    const getStatusEntries = useCallback(
        meta => {
            const filter = getValues();
            const selectedOperatorUsername = filter.username;
            const userId = operatorOptions.find(op => op.value === selectedOperatorUsername)?.id;
            api.getUserStatusHistory({
                begin: filter.period.begin,
                end: filter.period.end,
                ...meta,
                userId,
            })
                .then(response => {
                    if (response.data) {
                        const statuses = response.data.content;
                        setStatusEntries(statuses);
                        const responseGridMeta = {
                            limit: response.data.size,
                            total: response.data.totalElements,
                            offset: response.data.number * response.data.size,
                        };
                        setGridMeta(responseGridMeta);
                    }
                })
                .catch(error => {
                    const [errorList] = axiosErrorHandler(error);
                    setGeneralErrorList(errorList);
                });
        },
        [getValues, api, operatorOptions, setGeneralErrorList]
    );

    const onGridPage = useCallback(
        event => {
            setLastGridPagingInfo({ limit: event.rows, page: event.page });
            getStatusEntries({
                limit: event.rows,
                page: event.page,
            });
        },
        [getStatusEntries]
    );

    const onSubmit = handleSubmit(data => {
        loadReport(data);
        getStatusEntries(initGridPagingInfo);
    }, validator.extractErrorsFromInvalidForm(setGeneralErrorList));

    const onExportSubmit = useMemo(
        () =>
            handleSubmit(data => {
                exportReport(data);
            }, validator.extractErrorsFromInvalidForm(setGeneralErrorList)),
        [handleSubmit, exportReport, setGeneralErrorList]
    );

    useEffect(() => {
        if (setReportData && setGeneralErrorList && api && getValues && loadReport) {
            if (getValues('username')) {
                loadReport({
                    period: {
                        begin: getValues('period.begin'),
                        end: getValues('period.end'),
                    },
                    username: getValues('username'),
                });
                getStatusEntries(lastGridPaginInfo);
            }
        }
    }, [
        reloadCounter,
        setReportData,
        setGeneralErrorList,
        api,
        getValues,
        loadReport,
        getStatusEntries,
        lastGridPaginInfo,
    ]);

    useEffect(() => {
        if (getValues('period.begin')) {
            loadUsersByPeriod({
                begin: getValues('period.begin'),
                end: getValues('period.end'),
            });
        }
    }, [watchPeriodBegin, watchPeriodEnd, getValues, loadUsersByPeriod]);

    return (
        <div className="container-fluid animated fadeIn card p-4">
            <div className="row">
                <div className="col-12">
                    <h2>Operator Specific Report</h2>
                    <hr />
                </div>
            </div>
            <form className="row" noValidate>
                <div className="col-2">
                    <Control
                        error={fieldErrorFor.period?.begin?.message}
                        registerRef={registerValidationFor?.begin}
                        control={control}
                        name="period.begin"
                        type="datepicker"
                        label="From"
                        showTime
                        value={moment().startOf('day')._d}
                    />
                </div>
                <div className="col-2">
                    <Control
                        error={fieldErrorFor.period?.end?.message}
                        registerRef={registerValidationFor?.end}
                        control={control}
                        name="period.end"
                        type="datepicker"
                        label="To"
                        showTime
                        value={null}
                    />
                </div>
                <div className="col-2">
                    <div className="form-control-block d-flex flex-column">
                        <label>&nbsp;</label>
                        <Button
                            className="p-button-sm"
                            label="Load Report"
                            type="submit"
                            onClick={onSubmit}
                        />
                    </div>
                </div>
                <div className="col-2">
                    <div className="form-control-block d-flex flex-column">
                        <label>&nbsp;</label>
                        <Button
                            className="p-button-sm"
                            label="Export"
                            type="submit"
                            onClick={onExportSubmit}
                        />
                    </div>
                </div>
                <div className="col-12 d-flex justify-content-start">
                    <Control
                        type="select"
                        name="username"
                        label="Operator"
                        options={operatorOptions}
                        error={fieldErrorFor.username?.message}
                        registerRef={registerValidationFor.username}
                        value={preselectedOperator ? preselectedOperator : ''}
                        elementConfig={{ style: { minWidth: '250px' } }}
                    />
                </div>
            </form>
            <hr />
            {dataPeriod && Array.isArray(reportData) && reportData.length ? (
                <>
                    <div className="row">
                        <div className="col-12">
                            <h2 className="display-6 mb-4" style={{ fontWeight: 600 }}>
                                Live chat report for {getValues('username')} from{' '}
                                {RoboadvisorService.formatDateTime(
                                    dataPeriod.begin,
                                    RoboadvisorService.dateTimeMomentFormatMinutes
                                )}{' '}
                                to&nbsp;
                                {RoboadvisorService.formatDateTime(
                                    dataPeriod.end,
                                    RoboadvisorService.dateTimeMomentFormatMinutes
                                )}
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                        {Array.isArray(reportData) && reportData.length
                            ? reportData.map((data, index) => (
                                  <DashboardTile
                                      key={index}
                                      label={data.label}
                                      value={data.value}
                                      unit={data.unit}
                                  />
                              ))
                            : null}
                    </div>
                    <Grid
                        hover={true}
                        paginator={true}
                        lazy={true}
                        total={gridMeta?.total}
                        rows={gridMeta?.limit}
                        first={gridMeta?.offset}
                        onPage={onGridPage}
                        columns={statusHeaders}
                        data={statusEntries}
                    />
                </>
            ) : (
                <span>No report data.</span>
            )}
        </div>
    );
};

export default OperatorSpecificReport;
