import { Button } from "primereact/button";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import ApiRequests from "../http/ApiRequests";
import axiosErrorHandler from "../http/AxiosErrorHandler";

const useChatBotSettings = () => {
    const api = useMemo(() => new ApiRequests(), []);
    const { control } = useForm({});
    const [generalErrorList, setGeneralErrorList] = useState([]);
    const [successMessage, setSuccessMessage] = useState(null);
    const [botResponsesOn, setBotResponsesOn] = useState(false);
    const [textToSpeech, setTextToSpeech] = useState(false);
    const [confidence, setConfidence] = useState("");
    const [forwarding, setForwarding] = useState("");
    const [reviewGridData, setReviewGridData] = useState([]);
    const reviewGridHeaders = [
        { name: "Chat Responses", key: "responses", headerClassName: "reviewgrid-responses" },
        { name: "Text-to-speech", key: "textToSpeech", headerClassName: "reviewgrid-texttospeech" },
        { name: "Confidence", key: "confidence", headerClassName: "reviewgrid-confidence" },
        { name: "Forwarding", key: "forwarding", headerClassName: "reviewgrid-forwarding" },
        { name: "Author", key: "author", headerClassName: "reviewgrid-users" },
        { name: "Time", key: "timestamp", formatter: "datetime", headerClassName: "reviewgrid-ts" },
        { name: "Action", key: "action", headerClassName: "reviewgrid-actions" },
    ];
    const [historyGridData, setHistoryGridData] = useState([]);
    const historyGridHeaders = [
        { name: "Chat Responses", key: "responses", headerClassName: "historygrid-responses" },
        { name: "Text-to-speech", key: "textToSpeech", headerClassName: "historygrid-texttospeech" },
        { name: "Confidence", key: "confidence", headerClassName: "historygrid-confidence" },
        { name: "Forwarding", key: "forwarding", headerClassName: "reviewgrid-forwarding" },
        { name: "Author", key: "author", headerClassName: "historygrid-users" },
        {
            name: "Create Time",
            key: "timestamp",
            formatter: "datetime",
            headerClassName: "historygrid-ts",
        },
        { name: "Reviewer", key: "reviewer", headerClassName: "historygrid-users" },
        {
            name: "Approve Time",
            key: "reviewtimestamp",
            formatter: "datetime",
            headerClassName: "historygrid-ts",
        },
    ];
    const botConfigurationStateRef = useRef();
    const reviewDataStateRef = useRef();
    reviewDataStateRef.current = reviewGridData;
    const historyDataStateRef = useRef();
    historyDataStateRef.current = historyGridData;

    const onApproveConfig = useCallback(
        async id => {
            api.approveBotConfiguration(id)
                .then(res => {
                    setSuccessMessage({ success: "Successfully approved pending configuration" });

                    botConfigurationStateRef.current = res.data;
                    setBotResponsesOn(botConfigurationStateRef.current.enabledResponses);
                    setTextToSpeech(botConfigurationStateRef.current.textToSpeechEnabled);
                    setConfidence(botConfigurationStateRef.current.confidenceThreshold);
                    setForwarding(botConfigurationStateRef.current.forwardingThreshold);
                    setReviewGridData(reviewDataStateRef.current.filter(i => i.id !== id));

                    let historyEntry = {
                        responses: <strong>{res.data.enabledResponses ? "ON" : "OFF"}</strong>,
                        textToSpeech: <strong>{res.data.textToSpeechEnabled ? "ON" : "OFF"}</strong>,
                        confidence: res.data.confidenceThreshold,
                        forwarding: res.data.forwardingThreshold,
                        author: res.data.author,
                        timestamp: res.data.timestamp,
                        reviewer: res.data.reviewer,
                        reviewtimestamp: res.data.reviewTimestamp,
                    };

                    const updatedHistoryData = [historyEntry, ...historyDataStateRef.current];
                    setHistoryGridData(updatedHistoryData);
                })
                .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setGeneralErrorList(errorList);
                });
        },
        [api],
    );

    const onRejectConfig = useCallback(
        async id => {
            api.rejectBotConfiguration(id)
                .then(() => {
                    setSuccessMessage({ success: "Successfully rejected pending configuration" });
                    setReviewGridData(reviewDataStateRef.current.filter(i => i.id !== id));
                })
                .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setGeneralErrorList(errorList);
                });
        },
        [api],
    );

    const ApprovalPanelButtonsPair = useCallback(
        id => {
            return (
                <div className="container-fluid">
                    <div className="row">
                        <Button
                            type="button"
                            className="add-btn mr-2"
                            icon="fa fa-check"
                            onClick={() => onApproveConfig(id)}
                            label="Approve"
                            tooltipOptions={{ position: "top" }}
                        />
                        <Button
                            type="button"
                            className="delete-btn mr-2"
                            icon="fa fa-times"
                            onClick={() => onRejectConfig(id)}
                            label="Reject"
                            tooltipOptions={{ position: "top" }}
                        />
                    </div>
                </div>
            );
        },
        [onApproveConfig, onRejectConfig],
    );

    useEffect(() => {
        async function loadCurrentConfig() {
            try {
                const res = await api.getCurrentBotConfiguration();
                botConfigurationStateRef.current = res.data;
                setBotResponsesOn(res.data.enabledResponses);
                setTextToSpeech(res.data.textToSpeechEnabled);
                setConfidence(res.data.confidenceThreshold);
                setForwarding(res.data.forwardingThreshold);
            } catch (err) {
                setBotResponsesOn(false);
                setTextToSpeech(false);
                setConfidence(0.0);
                setForwarding(0);
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            }
        }

        loadCurrentConfig();
    }, [api]);

    useEffect(() => {
        async function loadApprovalPanelContent() {
            try {
                const res = await api.getPendingBotConfigurations();
                let gridD = res.data.map(entry => ({
                    responses: <strong>{entry.enabledResponses ? "ON" : "OFF"}</strong>,
                    textToSpeech: <strong>{entry.textToSpeechEnabled ? "ON" : "OFF"}</strong>,
                    confidence: entry.confidenceThreshold,
                    forwarding: entry.forwardingThreshold,
                    author: entry.author,
                    timestamp: entry.timestamp,
                    action: ApprovalPanelButtonsPair(entry.id),
                    id: entry.id,
                }));
                setReviewGridData(gridD);
            } catch (err) {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            }
        }

        loadApprovalPanelContent();
    }, [ApprovalPanelButtonsPair, api]);

    useEffect(() => {
        async function loadHistoryPanelContent() {
            try {
                const res = await api.getBotConfigurationHistory();
                let gridD = res.data.map(entry => ({
                    responses: <strong>{entry.enabledResponses ? "ON" : "OFF"}</strong>,
                    textToSpeech: <strong>{entry.textToSpeechEnabled ? "ON" : "OFF"}</strong>,
                    confidence: entry.confidenceThreshold,
                    forwarding: entry.forwardingThreshold,
                    author: entry.author,
                    timestamp: entry.timestamp,
                    reviewer: entry.reviewer,
                    reviewtimestamp: entry.reviewTimestamp,
                }));
                setHistoryGridData(gridD);
            } catch (err) {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            }
        }

        loadHistoryPanelContent();
    }, [api]);

    const onSubmit = async () => {
        api.updateBotConfiguration({
            enabledResponses: botResponsesOn,
            textToSpeechEnabled: textToSpeech,
            confidenceThreshold: confidence,
            forwardingThreshold: forwarding,
        })
            .then(res => {
                setSuccessMessage({ success: "Successfully sent configuration for review" });
                setBotResponsesOn(botConfigurationStateRef.current.enabledResponses);
                setTextToSpeech(botConfigurationStateRef.current.textToSpeechEnabled);
                setConfidence(botConfigurationStateRef.current.confidenceThreshold);
                setForwarding(botConfigurationStateRef.current.forwardingThreshold);

                let reviewEntry = {
                    responses: <strong>{res.data.enabledResponses ? "ON" : "OFF"}</strong>,
                    textToSpeech: <strong>{res.data.textToSpeechEnabled ? "ON" : "OFF"}</strong>,
                    confidence: res.data.confidenceThreshold,
                    forwarding: res.data.forwardingThreshold,
                    author: res.data.author,
                    timestamp: res.data.timestamp,
                    action: ApprovalPanelButtonsPair(res.data.id),
                    id: res.data.id,
                };
                const updatedReviewData = [reviewEntry, ...reviewGridData];
                setReviewGridData(updatedReviewData);
            })
            .catch(err => {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            });
    };

    const isUpdateButtonDisabled = () => {
        if (botConfigurationStateRef.current) {
            return (
                botConfigurationStateRef.current.enabledResponses === botResponsesOn &&
                botConfigurationStateRef.current.textToSpeechEnabled === textToSpeech &&
                botConfigurationStateRef.current.confidenceThreshold.toString() ===
                confidence.toString() &&
                botConfigurationStateRef.current.forwardingThreshold.toString() ===
                forwarding.toString()
            );
        }
    };

    const onEnabledStateChanged = state => {
        setBotResponsesOn(state);
    };

    const onTextToSpeechChanged = state => {
        setTextToSpeech(state);
    };

    const onConfidenceChanged = conf => {
        setConfidence(conf);
    };

    const onForwardingChanged = forw => {
        setForwarding(forw);
    };

    return {
        generalErrorList,
        successMessage,
        onSubmit,
        control,
        confidence,
        onConfidenceChanged,
        forwarding,
        onForwardingChanged,
        botResponsesOn,
        onEnabledStateChanged,
        isUpdateButtonDisabled,
        reviewGridHeaders,
        reviewGridData,
        historyGridHeaders,
        historyGridData,
        textToSpeech,
        onTextToSpeechChanged,
    };
};

export default useChatBotSettings;
