import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ListBox } from 'primereact/listbox';
import React, { useCallback } from 'react';
import './DisableIntentDialog.css';

const DisableIntentDialog = ({ hook, reload }) => {
    const {
        enabledIntentList,
        disabledIntentList,
        selectedEnabledIntentList,
        selectedDisabledIntentList,
        onSelectedEnabledIntent,
        onSelectedDisabledIntent,
        onEnableSelectedIntents,
        onDisableSelectedIntents,
        clearSelectedEnabledIntentList,
        clearSelectedDisaledIntentList,
    } = hook;
    const getStatusColor = useCallback(status => {
        switch (status) {
            case 'NEW':
                return 'new';
            case 'UPDATED':
                return 'updated';
            case 'DELETED':
                return 'deleted';
            default:
                return '';
        }
    }, []);
    const onHide = useCallback(() => {
        reload();
        clearSelectedEnabledIntentList();
        clearSelectedDisaledIntentList();
        hook.closeDialog();
    }, [reload, clearSelectedEnabledIntentList, clearSelectedDisaledIntentList, hook]);
    const renderListItem = option => (
        <div style={{ overflowWrap: 'break-word' }}>
            <p
                style={{
                    padding: '5px 10px 10px 10px',
                    margin: 0,
                    color: 'inherit',
                }}
                className={getStatusColor(option.status)}
            >
                {option.name}
            </p>
        </div>
    );
    return (
        <Dialog
            className="disable-intent-dialog-wrapper"
            visible={hook.dialogIsOpen}
            style={{
                maxWidth: '90vw',
                height: '90vh',
                minHeight: '90vh',
                padding: 0,
            }}
            modal={true}
            header={`Enable / disable intents`}
            // icons={minimizeIcon()}

            onHide={onHide}
        >
            <div className="d-flex flex-row   disable-intent-dialog h-100">
                <div className="d-flex flex-column" style={{ flex: '0 1 100px' }}>
                    <div
                        className="d-flex flex-row justify-content-between align-items-center px-1"
                        style={{ backgroundColor: '#99ffa7' }}
                    >
                        <p className="my-1">
                            <b>Enabled intents:</b>
                        </p>
                        <div>
                            <b>
                                {selectedEnabledIntentList.length > 0 ? (
                                    <Button
                                        className="mr-2 p-button-danger p-button-text"
                                        style={{
                                            color: 'red',
                                            fontWeight: 'bold',
                                            borderBottom: 'border: 12px solid #e91224;',
                                            padding: 0,
                                            border: 'none',
                                        }}
                                        onClick={clearSelectedEnabledIntentList}
                                        label="Clear"
                                    />
                                ) : (
                                    ''
                                )}
                                <span>Selected: {selectedEnabledIntentList.length}</span>
                            </b>
                        </div>
                    </div>
                    <ListBox
                        value={selectedEnabledIntentList}
                        options={enabledIntentList}
                        onChange={onSelectedEnabledIntent}
                        multiple={true}
                        filter
                        optionLabel="name"
                        itemTemplate={renderListItem}
                        style={{
                            minHeight: '300px',
                            maxHeight: '100%',
                            width: '450px',
                            flex: 1,
                        }}
                        listStyle={{
                            display: 'inline-block',
                            width: '100%',
                            maxHeight: 'calc(90vh - 190px)',
                        }}
                    />
                </div>
                <div
                    className="d-flex flex-column px-1"
                    style={{ width: '50px', marginTop: '60px' }}
                >
                    <Button
                        className="mb-4 mt-auto mx-auto"
                        icon="fa fa-angle-double-right"
                        onClick={onDisableSelectedIntents}
                    />
                    <Button
                        className="mb-2 mb-auto mx-auto"
                        icon="fa fa-angle-double-left"
                        onClick={onEnableSelectedIntents}
                    />
                </div>
                <div className="d-flex flex-column" style={{ flex: '0 1 100px' }}>
                    <div
                        className="d-flex flex-row justify-content-between align-items-center px-1"
                        style={{ backgroundColor: '#ff9999' }}
                    >
                        <p className="my-1">
                            <b>Disabled intents:</b>
                        </p>
                        <div>
                            <b>
                                {selectedDisabledIntentList.length > 0 ? (
                                    <Button
                                        className="mr-2 p-button-danger p-button-text"
                                        style={{
                                            color: 'red',
                                            fontWeight: 'bold',
                                            borderBottom: 'border: 12px solid #e91224;',
                                            padding: 0,
                                            border: 'none',
                                        }}
                                        onClick={clearSelectedDisaledIntentList}
                                        label="Clear"
                                    />
                                ) : (
                                    ''
                                )}
                                <span>Selected: {selectedDisabledIntentList.length}</span>
                            </b>
                        </div>
                    </div>
                    <ListBox
                        value={selectedDisabledIntentList}
                        options={disabledIntentList}
                        onChange={onSelectedDisabledIntent}
                        multiple
                        filter
                        optionLabel="name"
                        itemTemplate={renderListItem}
                        style={{
                            minHeight: '300px',
                            maxHeight: '100%',
                            width: '450px',
                            flex: 1,
                        }}
                        listStyle={{
                            display: 'inline-block',
                            width: '100%',
                            maxHeight: 'calc(90vh - 190px)',
                        }}
                    />
                </div>
            </div>
        </Dialog>
    );
};

export default DisableIntentDialog;
