import React, { useEffect, useState } from 'react';
import ChatFeed from '../../components/UI/ChatFeed/ChatFeed';
import FormControl from '../../components/UI/Control/FormControl';
import Control from '../../components/UI/Control/Control';
import useSearchLiveChatTemplate from '../../hooks/useSearchLiveChatTemplate';
import { setGeneralErrorList } from '../../store/actions/liveChatActions';
import { AutoComplete } from 'primereact/autocomplete';

import './LiveChatWindow.css';
import MessagePosition from '../../components/UI/ChatFeed/messagePosition';

const LiveChatWindow = ({
    selectedConversation,
    conversationId,
    messages,
    sendMessage,
    overwriteChatInput,
    sessionId,
    clearOverwrite,
}) => {
    const [suggestedTemplates, setSuggestedTemplates] = useState([]);
    const [selectedTemplateContent, setSelectedTemplateContent] = useState([]);

    const { templates } = useSearchLiveChatTemplate({
        setGeneralErrorList,
    });

    const filterSuggestedTemplates = e => {
        if (e.query.startsWith('/')) {
            const results = templates?.filter(temp => {
                return temp.name.toLowerCase().includes(e.query.substring(1).toLowerCase());
            });
            setSuggestedTemplates(
                results?.map(res => {
                    return { name: res.name, content: res.content };
                })
            );
        }
    };

    const chatMessages =
        Array.isArray(messages) && messages?.length
            ? messages
                  .filter(msg => msg.type === 'TEXT')
                  .map(msg => {
                      let messagePosition = MessagePosition.CENTER;
                      let senderName = '';
                      if (msg.userMessage === false) {
                          senderName = 'System';
                      } else if (msg.customerId) {
                          messagePosition = MessagePosition.LEFT;
                          senderName = `Client ${
                              sessionId.length < 8 ? sessionId : sessionId.substring(0, 7) + '...'
                          }`;
                          // operator or bot message
                      } else if (msg.sentByUser || (!msg.customerId && !msg.sentByUser)) {
                          messagePosition = MessagePosition.RIGHT;
                          if (!msg.customerId && !msg.sentByUser) {
                              senderName = 'chatbot';
                          } else {
                              senderName = 'You';
                          }
                      }

                      return { ...msg, messagePosition, senderName };
                  })
            : [];

    useEffect(() => {
        if (conversationId && overwriteChatInput && overwriteChatInput.content) {
            setSelectedTemplateContent(overwriteChatInput.content);
            clearOverwrite();
        }
    }, [overwriteChatInput, clearOverwrite]);

    return (
        <>
            <ChatFeed
                chatFeedContainerStyle={{ height: '100%', padding: '10px' }}
                messages={chatMessages}
            />
            <div className="px-2 w-100 mt-2">
                {!selectedConversation?.conversationEnded ? (
                    <FormControl>
                        <form
                            className="live-chat-form"
                            onSubmit={e => {
                                e.preventDefault();
                                if (e.target[0].value !== '') {
                                    sendMessage(sessionId, e.target[0].value, conversationId);
                                    setSelectedTemplateContent('');
                                }
                            }}
                        >
                            <AutoComplete
                                className="p-autocomplete"
                                field="name"
                                value={selectedTemplateContent}
                                suggestions={suggestedTemplates}
                                placeholder="Your message..."
                                panelClassName="autocomplete-template-suggestions"
                                onChange={e => {
                                    e.target.value.content
                                        ? setSelectedTemplateContent(e.target.value.content)
                                        : setSelectedTemplateContent(e.target.value);
                                }}
                                completeMethod={filterSuggestedTemplates}
                                itemTemplate={e => {
                                    return (
                                        <>
                                            <p style={{ fontWeight: '600', margin: '0px' }}>
                                                {e.name}
                                            </p>
                                            <p
                                                style={{
                                                    color: '#b1b1b1',
                                                    fontStyle: 'italic',
                                                    margin: '0px',
                                                }}
                                            >
                                                {e.content.length < 90
                                                    ? e.content
                                                    : e.content.slice(0, 90) + '...'}
                                            </p>
                                        </>
                                    );
                                }}
                            />
                        </form>
                    </FormControl>
                ) : (
                    <Control
                        autocomplete="off"
                        name="operatormessage"
                        inputClasses="mt-2"
                        type="text"
                        placeholder="Conversation ended"
                        disabled={true}
                    />
                )}
            </div>
        </>
    );
};

export default LiveChatWindow;
