import React, { useMemo } from 'react';
import { secondsToTimeSpan } from '../../utils/dateTimeUtils';
import TileTimeFormat from '../summaryReport/TileTimeFormat';

const DashboardTile = ({ label, value, unit }) => {
    const formatedTime = useMemo(() => {
        if (unit === 'sec') {
            const time = secondsToTimeSpan(value);
            return <TileTimeFormat time={time} />;
        } else {
            return null;
        }
    }, [value, unit]);
    return (
        <div className="col-3 tile">
            <div>
                <div className="head">
                    <strong className="label">{label}</strong>
                </div>
                <div className="body">
                    {unit === 'sec' ? (
                        <>{formatedTime}</>
                    ) : (
                        <>
                            <span className="value">{value}</span>
                            <span className="unit">{unit}</span>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DashboardTile;
