import { useMemo } from 'react';
import ReportsService from '../../services/reports.service';
import { secondsToTimeSpan } from '../../utils/dateTimeUtils';
import TileTimeFormat from './TileTimeFormat';

const Tile = ({ type, value }) => {
    const reports = new ReportsService();

    const formattedTime = useMemo(() => {
        const time = secondsToTimeSpan(value);
        return <TileTimeFormat time={time} />;
    }, [value]);
    
    const reportItem = reports.getTileDetails(type)
    
    return (
        <div className="col-3 tile">
            <div>
                <div className="head">
                    <strong className="label">{reportItem.label}</strong>
                </div>
                <div className="body">
                    {reportItem.unit === 'sec' ? (
                        <>{formattedTime}</>
                    ) : (
                        <>
                            <span className="value">{value}</span>
                            <span className="unit">{reportItem.unit}</span>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Tile;
