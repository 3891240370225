import { useCallback, useRef, useState } from 'react';
import ApiRequests from '../http/ApiRequests';
import axiosErrorHandler from '../http/AxiosErrorHandler';
import { downloadBlobFileWithLink } from '../utils/fileUtils';

const useImportExportModel = () => {
    const [api] = useState(new ApiRequests());
    const uploadInputRef = useRef();
    const [generalErrorList, setGeneralErrorList] = useState([]);
    const [successMessage, setSuccessMessage] = useState(null);
    const [uploadSpinner, setUploadSpinner] = useState(false);
    const [selectModelFileError, setSelectModelFileError] = useState('');
    const [modelFileIsSelected, setModelFileIsSelected] = useState(false);
    const [downloadInProgress, setDownloadInProgress] = useState(false);

    const onUpload = useCallback(
        event => {
            const formData = new FormData();
            event.files.forEach(file => {
                formData.append('files', file);
            });
            setUploadSpinner(true);
            api.uploadDataFiles(formData)
                .then(() => setSuccessMessage({ success: 'Successfully imported data.' }))
                .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setGeneralErrorList(errorList);
                })
                .finally(() => {
                    setUploadSpinner(false);
                    setModelFileIsSelected(false);
                });
        },
        [api]
    );

    const validateFileFormat = useCallback(file => {
        const regex = /\.zip$/;
        if (file && typeof file.name === 'string') {
            if (file.name.match(regex)) {
                return '';
            } else {
                return 'Only .zip files are supported.';
            }
        }
    }, []);

    const onSelect = useCallback(
        e => {
            const fileList = e.files;
            const selectedFilesCount = fileList.length;
            if (!fileList || fileList.length < 1) {
                setSelectModelFileError("Couldn't select a file");
                return;
            }
            const lastSelectedFile = fileList[selectedFilesCount - 1];
            const errorMessage = validateFileFormat(lastSelectedFile);
            if (errorMessage) {
                setSelectModelFileError(errorMessage);
                setModelFileIsSelected(false);
                if (uploadInputRef.current && uploadInputRef.current.clear) {
                    uploadInputRef.current.clear();
                }
            } else {
                setModelFileIsSelected(true);
                setSelectModelFileError('');
            }
        },
        [validateFileFormat, uploadInputRef]
    );

    const downloadModel = useCallback(() => {
        setDownloadInProgress(true);
        api.downloadModelData()
            .then(response => downloadBlobFileWithLink(response, '', setDownloadInProgress))
            .catch(err => {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            });
    }, [api]);

    const onRemove = useCallback(() => {
        setModelFileIsSelected(false);
    }, []);

    return {
        onSelect,
        onUpload,
        successMessage,
        generalErrorList,
        downloadModel,
        uploadSpinner,
        uploadInputRef,
        modelFileIsSelected,
        selectModelFileError,
        onRemove,
        downloadInProgress,
    };
};

export default useImportExportModel;
