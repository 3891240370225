import validationService from '../services/validationService';

const welcomeMessageSchema = register => {
    const validator = validationService();
    return {
        language: () => register(),
        channel: () => register(),
        types: () =>
            register({
                required: validator.errorMessages.MISSING_TYPE,
            }),
        content: () =>
            register({
                required: validator.errorMessages.MISSING_CONTENT,
            }),
        text: () =>
            register({
                required: validator.errorMessages.MISSING_LABEL,
            }),
        value: () => register(),
        title: () =>
            register({
                required: validator.errorMessages.MISSING_TITLE,
            }),
        subtitle: () => register(),
        image: () =>
            register({
                required: validator.errorMessages.MISSING_IMAGE,
            }),
        url: () =>
            register({
                required: validator.errorMessages.MISSING_URL,
            }),
        metadata: () => register(),
        welcomeMessage: () => register(),
        isDefault: () => register(),
    };
};

export default welcomeMessageSchema;
