import orderBy from 'lodash/orderBy';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import useConfirmDialog from '../hooks/useConfirmDialog';
import ApiRequests from '../http/ApiRequests';
import axiosErrorHandler from '../http/AxiosErrorHandler';
import { sortByDateCompareFunction } from '../utils/sortUtils';

const useAddIntent = () => {
    const api = useMemo(() => new ApiRequests(), []);
    const [intentList, setIntentList] = useState([]);
    const [sortedIntentList, setSortedIntentList] = useState([]);
    const [questionList, setQuestionList] = useState([]);
    const [inputIntentName, setInputIntentName] = useState('');
    const [inputExampleContent, setInputExampleContent] = useState('');
    const [selectedIntentId, setSelectedIntentId] = useState(0);
    const [selectedQuestionId, setSelectedQuestionId] = useState('');
    const [selectedIntentName, setSelectedIntentName] = useState('');
    const [selectedIntentItem, setSelectedIntentItem] = useState('');

    const [addIntentSectionVisible, toggleAddIntentSectionVisible] = useState(false);
    const [addQuestionSectionVisible, toggleAddQuestionSectionVisible] = useState(false);
    const [intentEnabled, setIntentEnabled] = useState(false);
    const [createIntentClicked, setCreateIntentClicked] = useState(false);
    const [editIntentClicked, setEditIntentClicked] = useState(false);

    const [generalErrorList, setGeneralErrorList] = useState([]);
    const [successMessage, setSuccessMessage] = useState(null);

    const [reloadCounter, setReloadCounter] = useState(0);
    const [intentSearchLoading, setIntentSearchLoading] = useState(false);

    const sortOptions = useMemo(
        () => [
            { label: 'Alphabetical', value: 'label' },
            { label: 'Created', value: 'uploadedTs' },
            { label: 'Last Updated', value: 'updatedTs' },
        ],
        []
    );
    const { getValues, ...sortForm } = useForm({
        defaultValues: { sortBy: 'label', sortOrderIsDesc: false },
    });
    const [sortFormChangeIncrement, setSortFormChangeIncrement] = useState(1);
    const watchSortBy = sortForm.watch('sortBy');
    const addIntentSectionRef = useRef();
    const addQuestionSectionRef = useRef();
    const editQuestionInputRef = useRef();
    const intentListRef = useRef();

    const confirm = useConfirmDialog();

    const onSortFormChange = useCallback(() => {
        setSortFormChangeIncrement(Math.random());
    }, []);

    const mapIntentList = useCallback(
        i => ({
            value: i.id,
            label: i.name,
            status: i.status,
            enabledStatus: i.enabled,
            pendingName: i.pendingName,
            uploadedTs: i.uploadedTs,
            uploadedBy: i.uploadedBy,
            updatedTs: i.updatedTs,
            updatedBy: i.updatedBy,
        }),
        []
    );

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await api.getIntentList();
                const intents_list = res.data.map(mapIntentList);
                setIntentList(intents_list);
            } catch (err) {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            }
        };
        fetchData();
    }, [api, reloadCounter, mapIntentList]);

    const reload = useCallback(() => setReloadCounter(Math.random()), [setReloadCounter]);

    const changeSelectedIntentHandler = async data => {
        const currentIntentId = parseInt(data);
        const selectedIntent = intentList.find(i => i.value === currentIntentId);
        const intentEnableStatus =
            selectedIntent.pendingName === null && selectedIntent.status === 'UPDATED'
                ? !selectedIntent.enabledStatus
                : selectedIntent.enabledStatus;
        try {
            setSelectedIntentId(selectedIntent.value);
            setSelectedQuestionId('');
            setIntentEnabled(intentEnableStatus);
            setSelectedIntentName(selectedIntent.label);
            setSelectedIntentItem(selectedIntent);
            setSortedIntentList(
                sortedIntentList.map(intent => ({
                    ...intent,
                    selected: intent.value === currentIntentId ? true : false,
                }))
            );
            const res = await api.getIntentExampleList(selectedIntent.value);
            const intentExampleList = res.data.map(e => ({
                value: e.id,
                label: e.content,
                status: e.status,
            }));
            setQuestionList(intentExampleList);
        } catch (err) {
            const [errorList] = axiosErrorHandler(err);
            setGeneralErrorList(errorList);
        }
    };

    const displayHideAddSectionHandler = (botItemType, isCreateIntentEvent) => {
        setCreateIntentClicked(isCreateIntentEvent);

        if (botItemType === 'intent') {
            if (shouldToggleSection(isCreateIntentEvent)) {
                toggleAddIntentSectionVisible(!addIntentSectionVisible);
                addIntentSectionRef.current.classList.toggle('new-botTrainItem__invisible');
            }
        } else {
            toggleAddQuestionSectionVisible(!addQuestionSectionVisible);
            addQuestionSectionRef.current.classList.toggle('new-botTrainItem__invisible');
        }
    };

    const closeSideWindow = useCallback(() => {
        const ref = addIntentSectionRef.current;
        if (ref) {
            if (!ref.classList.contains('new-botTrainItem__invisible')) {
                ref.classList.add('new-botTrainItem__invisible');
                setEditIntentClicked(false);
                setCreateIntentClicked(false);
            }
        }
    }, [addIntentSectionRef]);

    const shouldToggleSection = isCreateIntentEvent => {
        let shouldToggle = false;
        if (!createIntentClicked && !editIntentClicked) {
            shouldToggle = true;
        } else if (editIntentClicked && !isCreateIntentEvent) {
            shouldToggle = true;
        } else if (editIntentClicked && isCreateIntentEvent && !addIntentSectionVisible) {
            shouldToggle = true;
        } else if (createIntentClicked && isCreateIntentEvent) {
            shouldToggle = true;
        } else if (createIntentClicked && !isCreateIntentEvent && !addIntentSectionVisible) {
            shouldToggle = true;
        }

        if (isCreateIntentEvent) {
            setEditIntentClicked(false);
            setCreateIntentClicked(true);
        } else {
            setEditIntentClicked(true);
            setCreateIntentClicked(false);
        }

        return shouldToggle;
    };

    const changeSelectedQuestionHandler = id => {
        setSelectedQuestionId(id);
        setQuestionList(
            questionList.map(question => ({
                ...question,
                selected: question.value === id ? true : false,
            }))
        );
    };

    const changeNewIntentNameHandler = newIntentNameValue => {
        setInputIntentName(newIntentNameValue.trim());
    };

    const changeNewExampleContentHandler = newExampleContentValue => {
        setInputExampleContent(newExampleContentValue);
    };

    const addIntentHandler = () => {
        const acceptFunc = async () => {
            const data = { name: inputIntentName };
            try {
                const res = await api.addIntent(data);
                const newIntentList = [
                    { value: res.data.id, label: res.data.name, status: 'NEW' },
                    ...intentList,
                ];
                setIntentList(newIntentList);
                setInputIntentName('');
                setSuccessMessage({ success: 'Intent successfully added.' });
            } catch (err) {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            }
        };

        const conformProps = {
            message: 'Are you sure you want to add new intent?',
            header: 'Add new intent',
            icon: 'pi pi-plus-circle',
            accept: () => acceptFunc(),
            reject: () => setInputIntentName(''),
        };
        confirm(conformProps);
    };

    const editIntentNameHandler = () => {
        if (!selectedIntentId) return;
        const data = { name: inputIntentName };
        const acceptFunc = async () => {
            try {
                await api.updateIntentName(selectedIntentId, data);
                setSuccessMessage({
                    success: 'Intent name successfully updated. Change is awaiting review.',
                });
                setInputIntentName('');
                changeListItemStatus(intentList, selectedIntentId, 'UPDATED');
                changeListItemStatus(sortedIntentList, selectedIntentId, 'UPDATED');
                displayHideAddSectionHandler('intent', hasCreateButtonLastClicked());
            } catch (err) {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            }
        };

        const conformProps = {
            message: 'Are you sure you want to change intent name?',
            header: 'Change intent name',
            icon: 'pi pi-exclamation-triangle',
            accept: () => acceptFunc(),
        };
        confirm(conformProps);
    };

    const hasCreateButtonLastClicked = () => {
        return createIntentClicked === true;
    };

    const changeListItemStatus = (itemsList, selectedId, status) => {
        const updatedItem = itemsList.find(i => i.value === selectedId);
        if (updatedItem) {
            updatedItem.status = status;
        }
    };

    const disableIntentHandler = () => {
        if (!selectedIntentId) return;
        const acceptFunc = async () => {
            try {
                await api.updateIntentEnableStatus(selectedIntentId, !intentEnabled);
                const successMessage = `Intent successfully ${
                    intentEnabled ? 'disabled' : 'enabled'
                }. Change is awaiting review.`;
                changeListItemStatus(intentList, selectedIntentId, 'UPDATED');
                changeListItemStatus(sortedIntentList, selectedIntentId, 'UPDATED');
                setSuccessMessage({ success: successMessage });
            } catch (err) {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            }
        };

        const message = `Are you sure you want to ${
            intentEnabled ? 'disable' : 'enable'
        } selected intent?`;
        const conformProps = {
            message: message,
            header: 'Enable/Disable Intent',
            icon: 'pi pi-exclamation-triangle',
            accept: () => acceptFunc(),
        };
        confirm(conformProps);
    };

    const delIntentHandler = () => {
        if (!selectedIntentId) return;

        const acceptFunc = async () => {
            try {
                await api.delIntent(selectedIntentId);
                changeListItemStatus(intentList, selectedIntentId, 'DELETED');
                changeListItemStatus(sortedIntentList, selectedIntentId, 'DELETED');
                setSelectedIntentId('');
                setSuccessMessage({ success: 'Intent successfully deleted.' });
            } catch (err) {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            }
        };
        const conformProps = {
            message: 'Are you sure you want to delete selected intent?',
            header: 'Delete intent',
            icon: 'fa fa-trash-o',
            accept: () => acceptFunc(),
        };
        confirm(conformProps);
    };

    const addExampleHandler = () => {
        const acceptFunc = async () => {
            const data = {
                content: inputExampleContent.trim(),
                intent: {
                    id: parseInt(selectedIntentId),
                },
            };
            try {
                const res = await api.addIntentExample(data);
                const newQuestionList = [
                    { value: res.data.id, label: res.data.content, status: 'NEW' },
                    ...questionList,
                ];
                setQuestionList(newQuestionList);
                setInputExampleContent('');
                setSuccessMessage({ success: 'Sample question successfully added.' });
            } catch (err) {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            }
        };
        acceptFunc();
    };

    const delExampleHandler = async () => {
        if (!selectedQuestionId) return;
        const acceptFunc = async () => {
            try {
                await api.delExample(selectedQuestionId);
                changeListItemStatus(questionList, parseInt(selectedQuestionId), 'DELETED');
                setInputExampleContent('');
                setSuccessMessage({ success: 'Sample question successfully deleted.' });
            } catch (err) {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            }
        };
        const confirmProps = {
            message: 'Are you sure you want to delete selected sample question?',
            header: 'Delete sample question',
            icon: 'fa fa-trash-o',
            accept: () => acceptFunc(),
        };
        confirm(confirmProps);
    };

    const searchIntent = useCallback(
        text => {
            setIntentSearchLoading(true);
            setSelectedIntentId(0);
            closeSideWindow();
            if (intentListRef.current) {
                intentListRef.current.value = '';
            }
            api.searchIntent({ approved: true, name: text })
                .then(response => {
                    if (response.data && Array.isArray(response.data)) {
                        setIntentList(response.data.map(mapIntentList));
                    }
                })
                .catch(error => {
                    const [errorList] = axiosErrorHandler(error);
                    setGeneralErrorList(errorList);
                })
                .finally(() => setIntentSearchLoading(false));
        },
        [setIntentList, api, mapIntentList, closeSideWindow]
    );

    const sortIntents = useCallback((intents, getValues) => {
        const sortBy = getValues('sortBy');
        const sortOrderIsDesc = getValues('sortOrderIsDesc');
        if (sortBy) {
            const listCopy = [...intents];
            if (sortBy === 'label') {
                const sortedByNameIntents = orderBy(
                    listCopy,
                    sortBy,
                    sortOrderIsDesc ? ['desc'] : ['asc']
                );
                setSortedIntentList(sortedByNameIntents);
            } else {
                const sortedIntents = listCopy.sort(
                    sortByDateCompareFunction(sortBy, sortOrderIsDesc)
                );
                setSortedIntentList(sortedIntents);
            }
        }
    }, []);

    useEffect(() => {
        const sortBy = getValues('sortBy');
        if (sortBy) {
            sortIntents(intentList, getValues);
        } else {
            // if sortBy input is reset
            setSortedIntentList(intentList);
        }
    }, [intentList, sortIntents, getValues, sortFormChangeIncrement]);

    return {
        intentList,
        questionList,
        selectedIntentId,
        selectedQuestionId,
        selectedIntentName,
        selectedIntentItem,
        inputIntentName,
        inputExampleContent,
        addIntentSectionVisible,
        addQuestionSectionVisible,
        intentEnabled,
        createIntentClicked,
        editIntentClicked,
        addIntentSectionRef,
        addQuestionSectionRef,
        generalErrorList,
        successMessage,
        changeSelectedIntentHandler,
        changeSelectedQuestionHandler,
        changeNewIntentNameHandler,
        changeNewExampleContentHandler,
        addIntentHandler,
        addExampleHandler,
        delIntentHandler,
        delExampleHandler,
        displayHideAddSectionHandler,
        disableIntentHandler,
        editIntentNameHandler,
        searchIntent,
        intentSearchLoading,
        editQuestionInputRef,
        setGeneralErrorList,
        setSuccessMessage,
        reload,
        getValues,
        sortOptions,
        sortedIntentList,
        sortForm,
        onSortFormChange,
        watchSortBy,
        intentListRef,
    };
};

export default useAddIntent;
