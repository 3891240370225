import ConfigRespForm from '../modules/configResp/ConfigRespForm';

const ConfigResp = () => {
    return (
        <>
            <h1 className="display-4">Configure Bot responses</h1>
            <hr />
            <ConfigRespForm />
        </>
    );
};

export default ConfigResp;
