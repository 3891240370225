import React from 'react';
import styles from './Message.module.css';
import MessageBody from './MessageBody';
import MessagePosition from './messagePosition';
import moment from 'moment';
import { SendingStatus } from '../../../utils/liveChatUtils';

const Message = ({ content, messagePosition, messageTs, bgColor, sendingStatus }) => {
    const timestamp =
        messageTs && typeof messageTs === 'string' ? moment(messageTs).format('HH:mm') : '';
    return (
        <>
            {messagePosition === MessagePosition.LEFT ? (
                <div className="my-1 d-flex flex-row flex-nowrap justify-content-start">
                    <MessageBody style={{ maxWidth: '70%' }} content={content} bgColor={bgColor} />
                    <div
                        className="d-flex align-items-center justify-content-start"
                        style={{ flex: '0 3 100px' }}
                    >
                        <span className={`${styles.timestamp}`}>{timestamp}</span>
                    </div>
                </div>
            ) : null}
            {messagePosition === MessagePosition.RIGHT ? (
                <div className="my-1 d-flex flex-row flex-nowrap justify-content-end">
                    <div
                        className="d-flex align-items-center justify-content-end"
                        style={{ flex: '3 0 50px' }}
                    >
                        <span className={`${styles.timestamp}`}>{timestamp}</span>
                        {sendingStatus === SendingStatus.AWAITING ? (
                            <span title="Sending...">
                                <i
                                    className={`pi pi-check mr-1 ${styles.sendingStatus} ${styles.awaiting}`}
                                ></i>
                            </span>
                        ) : null}
                        {sendingStatus === SendingStatus.SUCCESS ? (
                            <span title="Sent">
                                <i
                                    className={`pi pi-check mr-1 ${styles.sendingStatus} ${styles.success}`}
                                ></i>
                            </span>
                        ) : null}
                        {sendingStatus === SendingStatus.FAILED ? (
                            <span title="Not sent">
                                <i
                                    className={`pi pi-ban mr-1 ${styles.sendingStatus} ${styles.failed}`}
                                ></i>
                            </span>
                        ) : null}
                    </div>
                    <MessageBody
                        style={{ maxWidth: '70%' }}
                        content={content}
                        bgColor={sendingStatus === SendingStatus.FAILED ? `${bgColor}33` : bgColor}
                    />
                </div>
            ) : null}
            {!messagePosition || messagePosition === MessagePosition.CENTER ? (
                <div className="my-1 d-flex flex-row flex-nowrap">
                    <div
                        className="d-flex justify-content-end align-items-center"
                        style={{ flex: '1 0 20px' }}
                    >
                        <span className={` ${styles.timestamp}`}>{timestamp}</span>
                    </div>
                    <MessageBody
                        style={{ flex: '8 0 100px', fontStyle: 'italic' }}
                        content={content}
                        bgColor={bgColor}
                    />

                    <div style={{ flex: '1 0 20px' }} className="text-start"></div>
                </div>
            ) : null}
        </>
    );
};

export default Message;
