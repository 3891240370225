import debounce from 'lodash/debounce';
import { useEffect, useMemo, useState } from 'react';
import ApiRequests from '../http/ApiRequests';
import axiosErrorHandler from '../http/AxiosErrorHandler';

const useSearchLiveChatTemplate = ({ setGeneralErrorList }) => {
    const [api] = useState(new ApiRequests());
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(false);

    const onSearchTemplate = useMemo(
        () =>
            debounce(e => {
                setLoading(true);
                api.searchLiveChatTemplate(e.target.value)
                    .then(response => {
                        if (response.data) {
                            setTemplates(response.data);
                        }
                    })
                    .catch(error => {
                        const [errorList] = axiosErrorHandler(error);
                        setGeneralErrorList(errorList);
                    })
                    .finally(() => setLoading(false));
            }, 500),
        [api, setGeneralErrorList]
    );

    useEffect(() => {
        api.getLiveChatTemplates()
            .then(response => setTemplates(response.data))
            .catch(error => {
                const [errorList] = axiosErrorHandler(error);
                setGeneralErrorList(errorList);
            });
    }, [api, setGeneralErrorList]);

    return {
        templates,
        loading,
        onSearchTemplate,
    };
};
export default useSearchLiveChatTemplate;
