import get from 'lodash/get';
import { Button } from 'primereact/button';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GeneralMessage from "../../components/UI/GeneralMessage";
import Grid from "../../components/UI/Grid/Grid";
import useApprvResp from "../../hooks/useApprvResp";
import ConfigRespService from "../../services/configResp.service";
import RoboadvisorService from "../../services/roboadvisor.service";
import PlayButton from "../configResp/PlayButton";

const MessageResponseBeforeReduxConnect = ({ response, channel, appFeatures }) => {
    const canSupportTextToSpeech =
        appFeatures?.textToSpeech === true &&
        response.type === RoboadvisorService.responseTypes.text &&
        channel === "API";
    return (
        <div className="col-12">
            <p className="mb-0">Message type:</p>
            <p>
                <strong>{get(response, "type", RoboadvisorService.responseTypes.text)}</strong>
                {canSupportTextToSpeech ? (
                    <PlayButton
                        style={{ marginLeft: "10px" }}
                        watchText={get(response, "content", "")}
                        watchTextToSpeech={get(response, "alternativeContent", "")}
                        language={get(response, "language", "EN")}
                    />
                ) : null}
            </p>
            <p className="mb-0">Content:</p>
            <p>
                <strong>{get(response, "content", "")}</strong>
            </p>
            {canSupportTextToSpeech ? (
                <>
                    <p className="mb-0">Text-to-speech:</p>
                    <p>
                        {get(response, "alternativeContent", "")
                            ? get(response, "alternativeContent", "")
                            : "-"}
                    </p>
                </>
            ) : null}
            <hr />
        </div>
    );
};

const mapStateToProps = state => ({
    appFeatures: state.app.features,
});

const MessageResponse = connect(
    mapStateToProps,
    null
)(withRouter(MessageResponseBeforeReduxConnect));


const ButtonsResponse = ({ response }) => {
    return (
        <div className="col-12">
            <p>
                <strong>
                    Message type: {get(response, 'type', RoboadvisorService.responseTypes.text)}
                </strong>
            </p>
            {get(response, 'buttons', []).map((item, index) => {
                return (
                    <div className="container-fluid" key={index}>
                        <div className="row">
                            <div className="col-12">
                                <p className="mb-0">
                                    <strong>Button {index + 1}:</strong>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <p>Label: {get(item, 'text', '')}</p>
                            </div>
                            <div className="col-4">
                                <p>Value: {get(item, 'value', '')}</p>
                            </div>
                            <div className="col-4">
                                <p>Metadata: {get(item, 'metadata', '')}</p>
                            </div>
                        </div>
                    </div>
                );
            })}
            <hr />
        </div>
    );
};

const CarouselResponse = ({ response }) => {
    return (
        <div className="col-12">
            <p>
                <strong>
                    Message type: {get(response, 'type', RoboadvisorService.responseTypes.carousel)}
                </strong>
            </p>
            {get(response, 'carousel', []).map((item, index) => {
                return (
                    <div className="container-fluid" key={index}>
                        <div className="row">
                            <div className="col-12">
                                <p className="mb-0">
                                    <strong>Slide {index + 1}:</strong>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2">
                                <p>Title: {get(item, 'title', '')}</p>
                            </div>
                            <div className="col-2">
                                <p>Subtitle: {get(item, 'subtitle', '')}</p>
                            </div>
                            <div className="col-3">
                                <p>Image resource: {get(item, 'image', '')}</p>
                            </div>
                            <div className="col-3">
                                <p>URL: {get(item, 'url', '')}</p>
                            </div>
                            <div className="col-2">
                                <p>Metadata: {get(item, 'metadata', '')}</p>
                            </div>
                        </div>
                    </div>
                );
            })}
            <hr />
        </div>
    );
};

const Responses = ({ data, channel }) => {
    return data.map((response, index) => {
        const responseType = get(response, 'type', RoboadvisorService.responseTypes.text);
        let formattedResponse = null;

        switch (responseType) {
            case RoboadvisorService.responseTypes.text:
                formattedResponse = <MessageResponse response={response} channel={channel} />;
                break;
            case RoboadvisorService.responseTypes.buttons:
                formattedResponse = <ButtonsResponse response={response} />;
                break;
            case RoboadvisorService.responseTypes.carousel:
                formattedResponse = <CarouselResponse response={response} />;
                break;
            default:
                formattedResponse = <MessageResponse />;
                break;
        }

        return (
            <div className="row" key={index}>
                {formattedResponse}
            </div>
        );
    });
};

const TaskView = ({ intent, onApprove, onReject }) => {
    const configResp = new ConfigRespService();
    return (
        <div className="animated card p-4 fadeIn container-fluid">
            <div className="row">
                <div className="col-12">
                    <h3 className="display-6 mb-4">
                        Review panel for {get(intent, 'responses[0].intent.name', '')}
                    </h3>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                {configResp.checkIfIntentIsLast(get(intent, 'responses', [])) ? (
                                    <p>
                                        <strong>
                                            This Intent Answer is conversation-last and has rating
                                            messages enabled
                                        </strong>
                                    </p>
                                ) : null}
                                {configResp.checkIfLiveChatMessagesShown(
                                    get(intent, 'responses', [])
                                ) ? (
                                    <p>
                                        <strong>
                                            This Intent Answer has Request Live Chat messages
                                            enabled
                                        </strong>
                                    </p>
                                ) : null}
                                <label>Bot responses in order of appearance:</label>
                                <hr />
                                <div className="cotainer-fluid">
                                    <Responses
                                        data={get(intent, 'responses', [])}
                                        channel={get(intent, 'responses[0].channel', '')}
                                    />

                                    <Button
                                        type="button"
                                        className="add-btn mr-2"
                                        icon="fa fa-check"
                                        onClick={onApprove}
                                        label="Approve"
                                        tooltipOptions={{ position: 'top' }}
                                    />

                                    <Button
                                        type="button"
                                        className="delete-btn"
                                        icon="fa fa-ban"
                                        onClick={onReject}
                                        label="Reject"
                                        tooltipOptions={{ position: 'top' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const getRowExpansionTemplate = ({ data, approveTask, rejectTask }) => {
    return (
        <TaskView
            intent={data}
            onApprove={() =>
                approveTask(
                    get(data, 'responses[0].intent.id', -1),
                    get(data, 'responses[0].channel', ''),
                    get(data, 'responses[0].language', '')
                )
            }
            onReject={() =>
                rejectTask(
                    get(data, 'responses[0].intent.id', -1),
                    get(data, 'responses[0].channel', ''),
                    get(data, 'responses[0].language', '')
                )
            }
        />
    );
};

const ApprovalTasksList = () => {
    const {
        tasksForApproval,
        tasksForApprovalHeaders,
        approveTask,
        rejectTask,
        confirmApproveAll,
        confirmRejectAll,
        generalErrorList,
        successMessage,
        onRowExpand,
        onRowCollapse,
        selectedRow,
    } = useApprvResp();

    return (
        <>
            {tasksForApproval.length > 0 ? (
                <div style={{ marginBottom: 10 }}>
                    <Button
                        type="button"
                        className="add-btn mr-2"
                        label={'Approve All'}
                        onClick={confirmApproveAll}
                    />
                    <Button
                        type="button"
                        className="delete-btn mr-2"
                        label={'Reject All'}
                        onClick={confirmRejectAll}
                    />
                </div>
            ) : (
                ''
            )}

            <div className="container-fluid animated fadeIn">
                <div className="row">
                    <div className="col-12">
                        <GeneralMessage
                            className="c-msg"
                            successMessage={successMessage}
                            errorList={generalErrorList}
                        />
                        {tasksForApproval.length ? (
                            <>
                                <h2 className="display-5 mb-4">List of approval tasks:</h2>
                                <Grid
                                    rowExpansionTemplate={data =>
                                        getRowExpansionTemplate({
                                            data,
                                            approveTask,
                                            rejectTask,
                                        })
                                    }
                                    columns={tasksForApprovalHeaders}
                                    data={tasksForApproval}
                                    onRowExpand={onRowExpand}
                                    onRowCollapse={onRowCollapse}
                                    selectedRow={selectedRow}
                                    expandDataKey="id"
                                    expandTable={true}
                                />
                            </>
                        ) : (
                            <h2 className="display-5">List is empty</h2>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ApprovalTasksList;
