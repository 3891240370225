import {
    SET_SUPERVISE_CHAT_GENERAL_ERROR_LIST,
    SET_SUPERVISE_CHAT_WS_CLIENT,
    SET_SUPERVISE_CHAT_CONVERSATIONS,
    SET_OPERATOR_LIST,
    INCREMENT_SUPERVISE_CHAT_PROFILE_COLOR_COUNTER,
    SET_SUPERVISE_CHAT_INCOMMING_MESSAGE,
    SET_SUPERVISE_CHAT_SELECTED_CONVERSATION_ID,
    SET_REFRESH_ACTIVE_CHATS_INTERVAL_ID,
    SET_SUPERVISE_CHAT_MESSAGE_POLLING_INTERVAL_ID,
    SET_SUPERVISE_CHAT_LAST_POLLING_STAMP,
} from '../actions/superviseLiveChatActions';

const INIT_STATE = {
    generalErrorList: [],
    wsClient: null,
    conversations: [],
    operatorList: [],
    profileColorCounter: 0,
    selectedConversationId: 0,
    refreshActiveChatsIntervalId: -1,
    superviseChatMessagePollingIntervalId: -1,
    lastPollingTimestamp: new Date().toISOString(),
};

const superviseLiveChatReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_SUPERVISE_CHAT_GENERAL_ERROR_LIST:
            return {
                ...state,
                generalErrorList: action.payload,
            };
        case SET_OPERATOR_LIST:
            return {
                ...state,
                operatorList: action.payload,
            };
        case INCREMENT_SUPERVISE_CHAT_PROFILE_COLOR_COUNTER:
            return { ...state, profileColorCounter: state.profileColorCounter + 1 };
        case SET_SUPERVISE_CHAT_CONVERSATIONS:
            return { ...state, conversations: action.payload };
        case SET_SUPERVISE_CHAT_WS_CLIENT:
            if (state.wsClient) {
                state.wsClient.deactivate();
            }
            return {
                ...state,
                wsClient: action.payload,
            };
        case SET_REFRESH_ACTIVE_CHATS_INTERVAL_ID:
            if (state.refreshActiveChatsIntervalId) {
                clearInterval(state.refreshActiveChatsIntervalId);
            }
            return {
                ...state,
                refreshActiveChatsIntervalId: action.payload,
            };
        case SET_SUPERVISE_CHAT_INCOMMING_MESSAGE:
            const conversationsWithNewMessage = [...state.conversations];
            conversationsWithNewMessage.forEach(chatW => {
                if (chatW.data.id === action.payload.chatId) {
                    chatW.messages.push({
                        ...action.payload,
                        id: action.payload.id,
                        content: action.payload.messageText,
                        customerId: action.payload.senderIsClient,
                        type: 'TEXT',
                        messageTs: action.payload.messageTs,
                    });
                    if (state.selectedConversationId !== chatW.data.id) {
                        chatW.unreadMessagesCount += 1;
                    }
                }
            });
            return {
                ...state,
                conversations: conversationsWithNewMessage,
            };
        case SET_SUPERVISE_CHAT_SELECTED_CONVERSATION_ID:
            const newConversations = [...state.conversations];
            // clear unread messages for this conversation
            newConversations.some(conv => {
                if (conv.data.id === action.payload) {
                    conv.unreadMessagesCount = 0;
                    return true;
                } else {
                    return false;
                }
            });
            return {
                ...state,
                conversations: newConversations,
                selectedConversationId: action.payload,
            };
        case SET_SUPERVISE_CHAT_MESSAGE_POLLING_INTERVAL_ID:
            return { ...state, superviseChatMessagePollingIntervalId: action.payload };
        case SET_SUPERVISE_CHAT_LAST_POLLING_STAMP:
            return { ...state, lastPollingTimestamp: action.payload };
        default:
            return { ...state };
    }
};

export default superviseLiveChatReducer;
