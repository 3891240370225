import { Tooltip } from 'primereact/tooltip';
import React from 'react';

const FormControlWrapper = ({ children, label, labelClass, info, className }) => {
    return (
        <div className={`form-control-block d-inline-block ${className ? className : ''}`}>
            {label ? (
                <label className={labelClass} htmlFor="">
                    {label}&nbsp;
                    {info ? (
                        <>
                            <span data-pr-tooltip={info} className="label-info">
                                <i className="fa fa-info" aria-hidden="true"></i>
                            </span>
                            <Tooltip target={`.label-info`} />
                        </>
                    ) : null}
                </label>
            ) : null}
            <div>{children}</div>
        </div>
    );
};

export default FormControlWrapper;
