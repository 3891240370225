import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';
import RoboadvisorService from '../../services/roboadvisor.service';

const ModelApprovalPanel = ({ modelApproveSpinner, modelData, confirmApprove, confirmReject }) => {
    if (modelApproveSpinner) {
        return <ProgressSpinner strokeWidth="4" />;
    } else {
        return (
            <>
                <div className="container-fluid card p-4 mb-5 animated fadeIn">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="display-5 mb-4">
                                There is a pending model from{' '}
                                {RoboadvisorService.formatDateTime(
                                    modelData.createdTs,
                                    RoboadvisorService.dateTimeMomentFormatMinutes
                                )}{' '}
                                created by {modelData.createdBy}.
                            </h2>
                        </div>
                        <div className="col-12">
                            <div style={{ marginBottom: 10 }}>
                                <Button
                                    type="button"
                                    className="add-btn mr-2"
                                    label={'Approve'}
                                    onClick={() => confirmApprove(modelData)}
                                />
                                <Button
                                    type="button"
                                    className="delete-btn mr-2"
                                    label={'Reject'}
                                    onClick={() => confirmReject(modelData)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default ModelApprovalPanel;
