import debounce from 'lodash/debounce';
import React, { useCallback, useMemo, useRef } from 'react';
import './TextSearchInput.css';
const TextSearchInput = ({
    loading,
    delaySearch,
    searchCallback,
    placeholder,
    inputRef,
    disabled,
}) => {
    const inputContainerRef = useRef();
    const onSearchText = useMemo(
        () =>
            debounce(
                e => {
                    if (searchCallback) {
                        searchCallback(e.target.value);
                    }
                },
                delaySearch ? delaySearch : 500
            ),
        [delaySearch, searchCallback]
    );
    const onFocus = useCallback(() => {
        if (inputContainerRef.current) {
            inputContainerRef.current.style.border = '1px solid #999';
        }
    }, [inputContainerRef]);
    const onBlur = useCallback(() => {
        if (inputContainerRef.current) {
            inputContainerRef.current.style.border = '1px solid #cccccc';
        }
    }, [inputContainerRef]);

    return (
        <div
            ref={inputContainerRef}
            className="text-search-input form-control d-flex flex-row justify-content-start align-items-center position-relative"
            style={{ width: 'auto' }}
        >
            <i className="pi pi-search" />
            <input
                ref={inputRef}
                className="text-input"
                placeholder={placeholder ? placeholder : 'Search...'}
                onChange={onSearchText}
                onFocus={onFocus}
                onBlur={onBlur}
                style={{
                    padding: 0,
                    border: 'none',
                    marginLeft: '0.4rem',
                    marginRight: '2rem',
                    width: '100%',
                }}
                disabled={disabled}
            />
            {loading ? (
                <i
                    className="pi pi-spin pi-spinner ml-auto position-absolute"
                    style={{ right: '1rem' }}
                />
            ) : null}
        </div>
    );
};

export default TextSearchInput;
