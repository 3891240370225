import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import ApiRequests from '../http/ApiRequests';
import axiosErrorHandler from '../http/AxiosErrorHandler';
import validationService from '../services/validationService';
import { useHistory } from 'react-router-dom';
import { allRoutes } from '../routes/Routes';

const useFormLogin = ({ getCartData, onSuccess, getAppFeatures }) => {
    const api = new ApiRequests();
    const cyrillicRegex = useMemo(() => new RegExp("[а-яА-Я]"), []);
    const { register, handleSubmit, errors } = useForm();
    const [generalErrorList, setGeneralErrorList] = useState([]);
    const validator = validationService();
    const [isCapsLockOn, setCapsLockOn] = useState(false);
    const [isCyrillicOn, setCyrillicOn] = useState(false);

    const capsLockState = useRef();
    capsLockState.current = isCapsLockOn;
    const cyrillicState = useRef();
    cyrillicState.current = isCyrillicOn;

    let history = useHistory();

    const isCharacterCyrillic = useCallback(
        character => {
            return cyrillicRegex.test(character);
        },
        [cyrillicRegex]
    );

    useEffect(() => {
        let passwordInput = document.getElementById("password");
        passwordInput.addEventListener("keypress", event => {
            if (isCharacterCyrillic(event.key)) {
                if (!cyrillicState.current) {
                    setCyrillicOn(true);
                }
            } else {
                if (cyrillicState.current) {
                    setCyrillicOn(false);
                }
            }
            if (event.getModifierState("CapsLock")) {
                if (!capsLockState.current) {
                    setCapsLockOn(true);
                }
            } else {
                if (capsLockState.current) {
                    setCapsLockOn(false);
                }
            }
        });
    }, [isCharacterCyrillic]);

    const onSubmit = data => {
        api.logIn(data)
            .then(res => {
                // load app features on login
                getAppFeatures();
                history.push(allRoutes.base.path);
            })
            .catch(err => {
                if (err.response.status === 401) {
                    const customError = "Invalid username or password";
                    setGeneralErrorList([customError]);
                } else {
                    const [errorList] = axiosErrorHandler(err);
                    setGeneralErrorList(errorList);
                }
            });
    };

    const registerValidationFor = {
        username: () =>
            register({
                required: validator.errorMessages.MISSING_NAME,
            }),
        password: () =>
            register({
                required: validator.errorMessages.MISSING_PASSWORD,
            }),
    };

    const getWarningMessage = () => {
        if (isCyrillicOn) {
            return "Cyrillic Keyboard Layout is ON";
        }
        if (isCapsLockOn) {
            return "Caps Lock is ON";
        }
    };

    return {
        registerValidationFor,
        onSubmit: handleSubmit(
            onSubmit,
            validator.extractErrorsFromInvalidForm(setGeneralErrorList)
        ),
        generalErrorList,
        fieldErrorFor: errors,
        getWarningMessage,
    };
};

export default useFormLogin;
