import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import ApiRequests from '../http/ApiRequests';
import axiosErrorHandler from '../http/AxiosErrorHandler';
import summaryReportFilterSchema from '../schemas/summaryReportFilterSchema';
import ReportsService from '../services/reports.service';
import validationService from '../services/validationService';

const useSummaryReport = () => {
    const groupByOptions = [
            { label: 'Day', value: 'DAY' },
            { label: 'Hour', value: 'HOUR' },
        ],
        defaultGroupByOption = groupByOptions[0].value,
        api = useMemo(() => new ApiRequests(), []),
        reports = useMemo(() => new ReportsService(), []),
        { register, handleSubmit, setValue, errors, control } = useForm(),
        validator = validationService(),
        [generalErrorList, setGeneralErrorList] = useState([]),
        [reportData, setReportData] = useState(null),
        [stillDownload, setStillDownload] = useState(false),
        onSubmitFilter = data => {
            getReportData(data)
                .then(res => {
                    const stats = reports.mergeTotalsAndAveragesData(res);
                    setReportData(stats);
                })
                .catch(err => {
                    const [errorList] = axiosErrorHandler(err);
                    setGeneralErrorList(errorList);
                });
        },
        onExport = data => {
            if (data['begin'] && data['end'] && moment(data['end']).isBefore(data['begin'])) {
                setGeneralErrorList([validator.errorMessages.INCORRECT_SEARCH_PERIOD]);
                return;
            }
            setStillDownload(true);
            api.downloadSummaryReportAsExcel(data)
                .then(response =>
                    reports.downloadBlobFileWithLink(
                        response,
                        'summary_reports.xlsx',
                        setStillDownload
                    )
                )
                .catch(err => {
                    reports.handleBlobError(err, setGeneralErrorList, setStillDownload);
                    setStillDownload(false);
                });
        },
        getReportData = useCallback(
            async data => {
                const [averagesReport, totals] = await Promise.all([
                    api.getAveragesReport(data),
                    api.getTotalStats(data),
                ]);

                return [averagesReport, totals];
            },
            [api]
        );

    // initially will call a report for today 00:00:00
    useEffect(() => {
        setValue('begin', moment().startOf('day').toDate());
        getReportData({ begin: moment().startOf('day').toISOString() })
            .then(res => {
                const stats = reports.mergeTotalsAndAveragesData(res);
                setReportData(stats);
            })
            .catch(err => {
                const [errorList] = axiosErrorHandler(err);
                setGeneralErrorList(errorList);
            });
    }, [getReportData, reports, setValue]);

    return {
        registerValidationFor: summaryReportFilterSchema(register),
        generalErrorList,
        fieldErrorFor: errors,
        control,
        reportData,
        onSubmit: handleSubmit(
            onSubmitFilter,
            validator.extractErrorsFromInvalidForm(setGeneralErrorList)
        ),
        onExport: handleSubmit(
            onExport,
            validator.extractErrorsFromInvalidForm(setGeneralErrorList)
        ),
        groupByOptions,
        defaultGroupByOption,
        stillDownload,
    };
};

export default useSummaryReport;
