const conversRepFilterSchema = register => {
    return {
        dateFrom: () => register(),
        dateTo: () => register(),
        sessionID: () => register(),
        channel: () => register(),
        orderCriteria: () => register(),
        orderDirection: () => register(),
        acceptedBy: () => register(),
    };
};

export default conversRepFilterSchema;
