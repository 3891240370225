import { Button } from 'primereact/button';
import React from 'react';
import PageRootNav from '../../components/PageRootNav/PageRootNav';
import Control from '../../components/UI/Control/Control';
import GeneralMessage from '../../components/UI/GeneralMessage';
import Grid from '../../components/UI/Grid/Grid';
import useWelcomeMessage from '../../hooks/useWelcomeMessage';
import WelcomeMessageDialog from '../../modules/welcomeMessageDialog/WelcomeMessageDialog';

const WelcomeMessage = () => {
    const {
        welcomeMessages,
        selectedWelcomeMessage,
        langOptions,
        channelOptions,
        errors,
        registerValidationFor,
        deleteWelcomeMessage,
        generalErrorList,
        onFilter,
        onClearFilter,
        gridHeaders,
        onAddNew,
        dialogIsVisible,
        closeDialog,
        editDialogIsVisible,
        closeEditDialog,
        onEditAction,
        reload,
        successMessage,
    } = useWelcomeMessage();

    return (
        <div>
            <PageRootNav label="Configure welcome messages" />
            <hr />
            <form className="container-fluid" noValidate>
                <GeneralMessage errorList={generalErrorList} successMessage={successMessage} />
                <div className="row">
                    <div className="col-4">
                        <Control
                            error={errors.language?.message}
                            registerRef={registerValidationFor?.language}
                            name="language"
                            type="select"
                            label="Language"
                            value=""
                            options={langOptions}
                        />
                    </div>
                    <div className="col-4">
                        <Control
                            error={errors.channel?.message}
                            registerRef={registerValidationFor?.channel}
                            name="channel"
                            type="select"
                            label="Channel"
                            options={channelOptions}
                            value=""
                        />
                    </div>
                    <div className="col-4">
                        <div className=" h-100 d-flex align-items-end justify-content-start">
                            <div className="form-control-block">
                                <Button label="Filter" onClick={onFilter} />
                            </div>
                            <div className="form-control-block ml-2">
                                <Button label="Clear Filter" onClick={onClearFilter} />
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="form-control-block">
                            <Button
                                label="Add New"
                                className="p-button-success"
                                icon="fa fa-plus"
                                onClick={onAddNew}
                            />
                        </div>
                    </div>
                </div>
            </form>
            <Grid
                columns={gridHeaders}
                data={welcomeMessages}
                editAction={onEditAction}
                deleteAction={deleteWelcomeMessage}
            />
            {selectedWelcomeMessage && (
                <WelcomeMessageDialog
                    selectedWelcomeMessage={selectedWelcomeMessage}
                    dialogIsVisible={editDialogIsVisible}
                    closeDialog={closeEditDialog}
                    reload={reload}
                />
            )}
            <WelcomeMessageDialog
                selectedWelcomeMessage={null}
                dialogIsVisible={dialogIsVisible}
                closeDialog={closeDialog}
                reload={reload}
            />
        </div>
    );
};

export default WelcomeMessage;
