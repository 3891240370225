import moment from 'moment';
import RoboadvisorService from '../services/roboadvisor.service';

export const reportUnits = {
    NO_UNIT: '',
    NUMBER: 'number',
    MESSAGES: 'messages',
    CONVERSATIONS: 'conversations',
    SECONDS: 'sec',
    USERS: 'users',
    CLIENTS: 'clients',
    OPERATORS: 'operators',
};

export const refreshIntervalOptions = [
    { label: '5 minutes', value: 300 },
    { label: '1 minute', value: 60 },
    { label: '30 seconds', value: 30 },
    { label: '15 seconds', value: 15 },
];

export const formatOperatorReportData = data => {
    return [
        {
            label: 'Accepted Conversations',
            value: data.acceptedConversations,
            unit: reportUnits.CONVERSATIONS,
        },
        {
            label: 'Missed Conversations',
            value: data.missedConversations,
            unit: reportUnits.CONVERSATIONS,
        },
        {
            label: 'Average Time for Conversation',
            value: Math.round(data.avgTimeForConversation),
            unit: reportUnits.SECONDS,
        },
        {
            label: 'Total Time spent in Conversations',
            value: data.totalTimeForConversation,
            unit: reportUnits.SECONDS,
        },
        {
            label: 'The Longest Conversation',
            value: data.maxTimeForConversation,
            unit: reportUnits.SECONDS,
        },
        {
            label: 'Number of Waiting Clients in Queue',
            value: data.waitingConversations,
            unit: reportUnits.CLIENTS,
        },
        {
            label: 'Current Status',
            value: data.user?.status,
            unit: reportUnits.NO_UNIT,
        },
    ];
};

export const formatSummaryReportData = data => {
    return [
        {
            label: 'Accepted Conversations',
            value: data.acceptedConversations,
            unit: reportUnits.CONVERSATIONS,
        },
        {
            label: 'Missed Conversations',
            value: data.missedConversations,
            unit: reportUnits.CONVERSATIONS,
        },
        {
            label: 'Active Conversations',
            value: data.activeConversations,
            unit: reportUnits.CONVERSATIONS,
        },
        {
            label: 'Active Operators',
            value: data.activeAgents,
            unit: reportUnits.OPERATORS,
        },
        {
            label: 'Number of Waiting Clients in Queue',
            value: data.waitingConversations,
            unit: reportUnits.CLIENTS,
        },
        {
            label: 'Average Time for Conversation',
            value: Math.round(data.avgTimeForConversation),
            unit: reportUnits.SECONDS,
        },
        {
            label: 'Max Time for Conversation',
            value: data.maxTimeForConversation,
            unit: reportUnits.SECONDS,
        },
        {
            label: 'Total Time for Conversations',
            value: data.totalTimeForConversation,
            unit: reportUnits.SECONDS,
        },
    ];
};

export const formatOperatorReportDataForSupervisor = data => {
    return [
        {
            label: 'Accepted Conversations',
            value: data.acceptedConversations,
            unit: reportUnits.CONVERSATIONS,
        },
        {
            label: 'Missed Conversations',
            value: data.missedConversations,
            unit: reportUnits.CONVERSATIONS,
        },
        {
            label: 'Number of Waiting Clients in Queue',
            value: data.waitingConversations,
            unit: reportUnits.CLIENTS,
        },
        {
            label: 'Average Time for Conversation',
            value: Math.round(data.avgTimeForConversation),
            unit: reportUnits.SECONDS,
        },
        {
            label: 'Max Time for Conversation',
            value: data.maxTimeForConversation,
            unit: reportUnits.SECONDS,
        },
        {
            label: 'Total Time for Conversations',
            value: data.totalTimeForConversation,
            unit: reportUnits.SECONDS,
        },
        {
            label: 'First Login',
            value: moment(data.firstLogin).format(RoboadvisorService.dateTimeMomentFormatMinutes),
            unit: reportUnits.NO_UNIT,
        },
        {
            label: 'Current Status',
            value: data.user?.status,
            unit: reportUnits.NO_UNIT,
        },
        {
            label: 'Time in Current Status',
            value: data.timeInCurrentStatus,
            unit: reportUnits.SECONDS,
        },
    ];
};
