import React from 'react';
import { ListBox as DefaultListBox } from 'primereact/listbox';
import './ListBox.css';

const ListBox = ({ className, ...props }) => {
    const classNameProp = `${className} ${props.itemTemplate ? 'disable-listbox-padding' : ''}`;
    return <DefaultListBox className={classNameProp} {...props} />;
};

export default ListBox;
