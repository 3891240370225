import { useCallback, useEffect, useMemo, useState } from 'react';
import LocationApproveActionPanel from '../components/LocationCoordinates/LocationApproveActionPanel';
import ViewInMapsButton from '../components/LocationCoordinates/ViewInMapsButton';
import useConfirmDialog from '../hooks/useConfirmDialog';
import ApiRequests from '../http/ApiRequests';
import axiosErrorHandler from '../http/AxiosErrorHandler';

const useLocationCoordinatesApproval = () => {
    const api = useMemo(() => new ApiRequests(), []);
    const [generalErrorList, setGeneralErrorList] = useState([]);
    const [successMessage, setSuccessMessage] = useState(null);
    const [locationList, setLocationList] = useState([]);
    const gridHeaders = [
        { name: 'Location', key: 'location', headerClassName: 'coordinates-location-name' },
        { name: 'Latitude', key: 'lat', headerClassName: 'coordinates-lat-lon' },
        { name: 'Longitude', key: 'lon', headerClassName: 'coordinates-lat-lon' },
        { name: 'View', key: 'view', headerClassName: 'coordinates-view-maps' },
        { name: 'Author', key: 'author', headerClassName: 'coordinates-user' },
        { name: 'Change Type', key: 'changeType' },
        { name: 'Actions', key: 'actions' },
    ];
    const confirm = useConfirmDialog();

    const onSuccessfulSubmit = useCallback((id, isApproved, gridData) => {
        const filteredLocationList = gridData.filter(location => location.id !== id);
        setLocationList(filteredLocationList);
        setSuccessMessage({
            success: `You successfully ${isApproved ? 'approved' : 'rejected'} location.`,
        });
    }, []);

    const onError = useCallback(error => {
        const [errorList] = axiosErrorHandler(error);
        setGeneralErrorList(errorList);
    }, []);

    const formattedLocationList = useMemo(() => {
        if (locationList.length === 0) {
            return [];
        }
        return locationList.map(entry => ({
            id: entry.id,
            location: entry.name,
            lat: entry.latitude,
            lon: entry.longitude,
            view: <ViewInMapsButton props={entry} />,
            author: entry.createdBy,
            changeType: entry.status,
            actions: (
                <LocationApproveActionPanel
                    id={entry.id}
                    onSuccessfulSubmit={onSuccessfulSubmit}
                    onError={onError}
                    gridData={locationList}
                />
            ),
        }));
    }, [locationList, onError, onSuccessfulSubmit]);

    const loadLocationsGridData = useCallback(async () => {
        try {
            const res = await api.getPendingCoordinates();
            setLocationList(res.data);
        } catch (err) {
            const [errorList] = axiosErrorHandler(err);
            setGeneralErrorList(errorList);
        }
    }, [api]);

    const reviewPendingCoordinates = async approve => {
        try {
            await api.reviewPendingCoordinates(approve);
            if (approve === 'approve') {
                setSuccessMessage({ success: 'Successfully approve pending location coordinates' });
            } else {
                setSuccessMessage({ success: 'Changes rejected' });
            }
            loadLocationsGridData();
        } catch (err) {
            const [errorList] = axiosErrorHandler(err);
            setGeneralErrorList(errorList);
        }
    };

    const confirmReviewPendingCoordinates = approve => {
        const action = approve ? 'approve' : 'reject';
        let message = `Are you sure you want to ${action} all pending locations?`;
        if (approve) {
            message += ` You can only approve pending locations that are not created by you.`;
        }
        const confirmationData = {
            message: message,
            header: 'Confirmation',
            icon: 'fa fa-question-circle-o',
            accept: () => reviewPendingCoordinates(action),
        };
        confirm(confirmationData);
    };

    useEffect(() => {
        loadLocationsGridData();
    }, [loadLocationsGridData]);

    return {
        generalErrorList,
        successMessage,
        gridHeaders,
        gridData: formattedLocationList,
        confirmReviewPendingCoordinates,
    };
};

export default useLocationCoordinatesApproval;
