import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { acceptConversation } from '../../store/actions/liveChatActions';
import LiveChatCard from './LiveChatCard';

const AssignedClientsPanel = ({ assignedClients, acceptConversation }) => {
    const clickAssignedClient = useCallback(
        () => acceptConversation(assignedClients[0].conversationID),
        [assignedClients, acceptConversation]
    );
    return (
        <>
            <div
                className="d-flex justify-content-between flex-row align-items-center p-2"
                style={{ borderBottom: '1px solid #cccccc', height: '50px' }}
            >
                <h2 className="display-5" style={{ marginBottom: 0 }}>
                    Assigned
                </h2>
            </div>
            <div style={{ minHeight: 'calc(40% - 50px)', overflowY: 'auto' }}>
                {Array.isArray(assignedClients) && assignedClients.length ? (
                    <div className="d-flex flex-column">
                        <LiveChatCard
                            sessionId={assignedClients[0].sessionID}
                            conversationId={assignedClients[0].conversationID}
                            onClick={clickAssignedClient}
                        />
                        {assignedClients.length - 1 > 0 ? (
                            <p className="ml-2">{`And ${
                                assignedClients.length - 1
                            } more conversations in queue.`}</p>
                        ) : null}
                    </div>
                ) : (
                    <p className="ml-2 mt-1">No assigned conversations.</p>
                )}
            </div>
        </>
    );
};

const mapStateToProps = state => {
    return {
        assignedClients: state.liveChat.assignedClients,
    };
};

const mapDispatchToProps = {
    acceptConversation: acceptConversation,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignedClientsPanel);
