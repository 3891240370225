import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Config from '../../config/config';

const NotificationSound = ({ notificationCounter, hasWaitingChats }) => {
    const audioRef = useRef();

    useEffect(() => {
        if (audioRef.current) {
            // audioRef.current.loop = hasWaitingChats; the loop is for postbank only
            if (notificationCounter || hasWaitingChats) {
                audioRef.current.play();
            }
        }
    }, [hasWaitingChats, notificationCounter]);

    return (
        <audio ref={audioRef}>
            <source src={Config.liveChatAudioNotificationSourceWav} type="audio/wav" />
        </audio>
    );
};
const mapStateToProps = state => {
    return {
        notificationCounter: state.liveChat.notificationCounter,
        hasWaitingChats: state.liveChat.hasWaitingChats,
    };
};

export default connect(mapStateToProps, null)(NotificationSound);
